import { Text, Link, Layout, LabeledValue } from '@loadsmart/miranda-react'
import { get, isEmpty } from 'lodash'
import type { PropsWithChildren, CSSProperties } from 'react'
import { generatePath } from 'react-router-dom'

import { createSummarySetup, SummaryLabelValue } from 'components/Summary'
import { AppRoutes } from 'router/AppRoutes'
import toArray from 'utils/toArray'

import {
  PACKAGE_TYPES_MAP,
  FREIGHT_CLASSES_MAP,
} from './ShippingItems.constants'
import type { PackageType, ShippingItem } from './ShippingItems.types'
import {
  formatWeight,
  formatDimension,
  getYesNoAnswer,
} from './ShippingItems.utils'

export type ShippingItemSummaryContextValue = ShippingItem

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<ShippingItemSummaryContextValue>({
    contextName: 'ShippingItemSummaryContext',
  })

export { useSummaryContext as useShippingItemSummaryContext }

export function ShippingItemSummary({
  item,
  children,
}: PropsWithChildren<{
  readonly item: ShippingItemSummaryContextValue
}>) {
  return (
    <SummaryProvider value={item}>
      {isEmpty(item) ? null : children}
    </SummaryProvider>
  )
}

const ContextBasedShippingItemSummaryName = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  return (
    <SummaryLabelValue
      value={get(item, 'commodity') || '-'}
      data-testid="commodity-summary"
      label="Item name"
      {...props}
    />
  )
}

export function ShippingItemSummaryHandlingUnits(props: {
  readonly style?: CSSProperties
  readonly packageCount?: string | number | null
  readonly packageType?: PackageType | null
}) {
  const { packageCount, packageType, ...rest } = props

  return (
    <SummaryLabelValue
      label="Handling units"
      data-testid="handling_units-summary"
      {...rest}
      value={[
        packageCount,
        packageType ? PACKAGE_TYPES_MAP.get(packageType) : null,
      ]
        .filter(Boolean)
        .join(' ')}
    />
  )
}

const ContextBasedShippingItemSummaryHandlingUnits = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const packageCount = get(item, 'package_count')
  const packageType = get(item, 'package_type')

  return (
    <ShippingItemSummaryHandlingUnits
      packageCount={packageCount}
      packageType={packageType as PackageType}
      {...props}
    />
  )
}

export function ShippingItemSummaryHandlingUnitsCount(props: {
  readonly style?: CSSProperties
  readonly packageCount?: string | number | null
}) {
  const { packageCount, ...rest } = props

  return (
    <SummaryLabelValue
      label="Handling units"
      data-testid="package_count-summary"
      {...rest}
      value={packageCount || '-'}
    />
  )
}

const ContextBasedShippingItemSummaryHandlingUnitsCount = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()
  const packageCount = get(item, 'package_count')

  return (
    <ShippingItemSummaryHandlingUnitsCount
      packageCount={packageCount}
      {...props}
    />
  )
}

export function ShippingItemSummaryHandlingUnitType(props: {
  readonly style?: CSSProperties
  readonly packageType?: PackageType | null
}) {
  const { packageType, ...rest } = props

  return (
    <SummaryLabelValue
      label="Handling unit type"
      data-testid="package_type-summary"
      {...rest}
      value={packageType ? PACKAGE_TYPES_MAP.get(packageType) : '-'}
    />
  )
}

const ContextBasedShippingItemSummaryHandlingUnitType = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()
  const packageType = get(item, 'package_type')

  return (
    <ShippingItemSummaryHandlingUnitType
      packageType={packageType as PackageType}
      {...props}
    />
  )
}

export function ShippingItemSummaryWeight(props: {
  readonly style?: CSSProperties
  readonly weight?: string | number | null
}) {
  const { weight, ...rest } = props

  return (
    <SummaryLabelValue
      label="Item weight"
      data-testid="weight-summary"
      {...rest}
      value={weight ? formatWeight(weight) : '-'}
    />
  )
}

const ContextBasedShippingItemSummaryWeight = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const weight = get(item, 'weight')

  return <ShippingItemSummaryWeight weight={weight} {...props} />
}

export function ShippingItemSummaryDimensions(props: {
  readonly inline?: boolean
  readonly style?: CSSProperties
  readonly length: number | string | null
  readonly width: number | string | null
  readonly height: number | string | null
}) {
  const { length, width, height, ...rest } = props

  return (
    <SummaryLabelValue
      label="Dimensions"
      data-testid="dimensions-summary"
      {...rest}
      value={
        length && width && height
          ? [
              `${formatDimension(length)}″ L`,
              `${formatDimension(width)}″ W`,
              `${formatDimension(height)}″ H`,
            ].join(' x ')
          : '-'
      }
    />
  )
}

const ContextBasedShippingItemSummaryDimensions = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const length = get(item, 'length') ?? ''
  const width = get(item, 'width') ?? ''
  const height = get(item, 'height') ?? ''

  return (
    <ShippingItemSummaryDimensions
      length={length}
      width={width}
      height={height}
      {...props}
    />
  )
}

export function ShippingItemSummaryStackable(props: {
  readonly style?: CSSProperties
  readonly stackable: boolean
}) {
  const { stackable, ...rest } = props

  return (
    <SummaryLabelValue
      label="Stackable"
      data-testid="stackable-summary"
      {...rest}
      value={getYesNoAnswer(stackable)}
    />
  )
}

const ContextBasedShippingItemSummaryStackable = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const isStackable =
    item.stackable || toArray(get(item, 'properties')).includes('stackable')

  return <ShippingItemSummaryStackable stackable={isStackable} {...props} />
}

export function ShippingItemSummaryTurnable(props: {
  readonly style?: CSSProperties
  readonly turnable: boolean
}) {
  const { turnable, ...rest } = props

  return (
    <SummaryLabelValue
      label="Turnable"
      data-testid="turnable-summary"
      {...rest}
      value={getYesNoAnswer(turnable)}
    />
  )
}

const ContextBasedShippingItemSummaryTurnable = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const isTurnable =
    item.turnable || toArray(get(item, 'properties')).includes('turnable')

  return <ShippingItemSummaryTurnable turnable={isTurnable} {...props} />
}

export function ShippingItemSummaryHazmat(props: {
  readonly style?: CSSProperties
  readonly hazmat: boolean
}) {
  const { hazmat, ...rest } = props

  return (
    <SummaryLabelValue
      label="Hazmat"
      data-testid="hazmat-summary"
      {...rest}
      value={getYesNoAnswer(hazmat)}
    />
  )
}

const ContextBasedShippingItemSummaryHazmat = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const isHazmat =
    item.hazmat || toArray(get(item, 'properties')).includes('hazmat')

  return <ShippingItemSummaryHazmat hazmat={isHazmat} {...props} />
}

export function ShippingItemSummaryNmfc(props: {
  readonly style?: CSSProperties
  readonly nmfcCode?: string | null
}) {
  const { nmfcCode, ...rest } = props

  return (
    <SummaryLabelValue
      label="NMFC"
      data-testid="nmfc-summary"
      {...rest}
      value={nmfcCode || '-'}
    />
  )
}

const ContextBasedShippingItemSummaryNmfc = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const nmfcCode = get(item, 'nmfc_code')

  return <ShippingItemSummaryNmfc nmfcCode={nmfcCode} {...props} />
}

export function ShippingItemSummaryFreightClass(props: {
  readonly inline?: boolean
  readonly style?: CSSProperties
  readonly freightClass?: ShippingItemSummaryContextValue['freight_class']
}) {
  const { freightClass, ...rest } = props

  return (
    <SummaryLabelValue
      label="Freight class"
      data-testid="freight_class-summary"
      {...rest}
      value={(freightClass && FREIGHT_CLASSES_MAP.get(freightClass)) ?? '-'}
    />
  )
}

const ContextBasedShippingItemSummaryFreightClass = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useSummaryContext()

  const freightClass = get(item, 'freight_class')

  return (
    <ShippingItemSummaryFreightClass freightClass={freightClass} {...props} />
  )
}

export function ShippingItemSummaryOrders({
  inline = false,
  testid,
  label,
  orderNumbers,
}: {
  readonly inline?: boolean
  readonly testid: string
  readonly label: string
  readonly orderNumbers: string[]
}) {
  return (
    <SummaryLabelValue
      inline={inline}
      label={label}
      data-testid={`${testid}-summary`}
      value={!isEmpty(orderNumbers) ? orderNumbers.join(', ') : '-'}
    />
  )
}

const ContextBasedShippingItemSummaryOrders = () => {
  const item = useSummaryContext()

  return (
    <ShippingItemSummaryOrders
      inline
      testid="orders_primary_refs"
      label="Orders primary ref"
      orderNumbers={item.orders?.map((o) => o.primary_ref) || []}
    />
  )
}

const ContextBasedShippingItemSummarySONumbers = () => {
  const item = useSummaryContext()

  const orders = get(item, 'orders', []) || []

  const soNumbers =
    orders
      ?.map((o) => o.so_number)
      .filter((so_number) => !isEmpty(so_number)) || []

  return (
    <ShippingItemSummaryOrders
      testid="item_so_numbers"
      label="SO Numbers"
      orderNumbers={soNumbers}
    />
  )
}

const ContextBasedShippingItemSummaryPONumbers = () => {
  const item = useSummaryContext()

  const orders = get(item, 'orders', []) || []

  const poNumbers = orders
    .map((o) => o.po_number)
    .filter((po_number) => !isEmpty(po_number))

  return (
    <ShippingItemSummaryOrders
      testid="item_po_numbers"
      label="PO Numbers"
      orderNumbers={poNumbers}
    />
  )
}

export const ShippingItemSummaryPrimaryRefs = ({
  label,
  orders = [],
  inline = false,
  linkSize = 'default',
}: {
  readonly label: string
  readonly orders: ShippingItemSummaryContextValue['orders']
  readonly linkSize?: 'small' | 'default'
  readonly inline?: boolean
}) => {
  return (
    <LabeledValue
      inline={inline}
      label={label}
      data-testid="primary_refs-summary"
    >
      {orders?.length ? (
        <Layout.Group gap="spacing-1" slot="value">
          {orders.map((o) => (
            <Link
              size={linkSize}
              key={o.uuid}
              target="_blank"
              href={generatePath(AppRoutes.OrderDetails, {
                orderId: o.uuid,
              })}
            >
              {o.primary_ref}
            </Link>
          ))}
        </Layout.Group>
      ) : (
        <Text variant="body-md" slot="value" color="color-text-primary">
          -
        </Text>
      )}
    </LabeledValue>
  )
}

const ContextBasedShippingItemSummaryPrimaryRefs = ({
  label,
}: {
  readonly label?: string
}) => {
  const item = useSummaryContext()

  const orders = get(item, 'orders', []) || []

  return (
    <ShippingItemSummaryPrimaryRefs
      label={label ?? 'Primary ref(s).'}
      orders={orders}
    />
  )
}

ShippingItemSummary.Dimensions = ContextBasedShippingItemSummaryDimensions
ShippingItemSummary.FreightClass = ContextBasedShippingItemSummaryFreightClass
ShippingItemSummary.HandlingUnits = ContextBasedShippingItemSummaryHandlingUnits
ShippingItemSummary.HandlingUnitsCount =
  ContextBasedShippingItemSummaryHandlingUnitsCount
ShippingItemSummary.HandlingUnitType =
  ContextBasedShippingItemSummaryHandlingUnitType
ShippingItemSummary.Name = ContextBasedShippingItemSummaryName
ShippingItemSummary.Nmfc = ContextBasedShippingItemSummaryNmfc
ShippingItemSummary.Stackable = ContextBasedShippingItemSummaryStackable
ShippingItemSummary.Turnable = ContextBasedShippingItemSummaryTurnable
ShippingItemSummary.Hazmat = ContextBasedShippingItemSummaryHazmat
ShippingItemSummary.Weight = ContextBasedShippingItemSummaryWeight
ShippingItemSummary.OrderNumbers = ContextBasedShippingItemSummaryOrders
ShippingItemSummary.PONumbers = ContextBasedShippingItemSummaryPONumbers
ShippingItemSummary.SONumbers = ContextBasedShippingItemSummarySONumbers
ShippingItemSummary.PrimaryRefs = ContextBasedShippingItemSummaryPrimaryRefs
