import type { FieldProps, WCCheckbox } from '@loadsmart/miranda-react'
import { Checkbox, Field } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { get } from 'lodash'
import type { CSSProperties, ReactNode, ChangeEvent } from 'react'
import { useCallback } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { TARP_SIZE_NAME } from './FreightInformationTarpSize'
import { TARP_TYPE_NAME } from './FreightInformationTarpType'
import { useFreightInformationFormField } from './useFreightInformationFormField'

export const WITH_TARP_NAME = 'with_tarp'
const WITH_TARP_LABEL = 'With tarp'

function resetTarpDependantFields(checked: boolean) {
  return {
    [WITH_TARP_NAME]: checked,
    [TARP_TYPE_NAME]: undefined,
    [TARP_SIZE_NAME]: undefined,
  }
}

type FreightInformationWithTarpFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: ReactNode
  readonly error?: string
  readonly style?: CSSProperties
  readonly label?: string
}

export function FreightInformationWithTarpFormField({
  required,
  hint,
  error,
  style,
  label = WITH_TARP_LABEL,
}: FreightInformationWithTarpFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()
  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: WITH_TARP_NAME,
    hint,
    error,
  })

  const handleChange = useCallback(
    (event: ChangeEvent<WCCheckbox>) => {
      const checked = get(event, 'target.checked')

      dispatch({
        type: 'HANDLE_CHANGE',
        payload: resetTarpDependantFields(checked),
      })
    },
    [dispatch]
  )

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${WITH_TARP_NAME}-field`}
    >
      <Field.Label style={{ visibility: 'hidden' }}>{label}</Field.Label>
      <Checkbox
        style={{
          minHeight: toCSSValue('global-height-default'),
          display: 'flex',
          alignItems: 'center',
        }}
        checked={Boolean(get(shipment, WITH_TARP_NAME))}
        onChange={handleChange}
      >
        {label}
      </Checkbox>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
