import { combineReducers } from '@reduxjs/toolkit'

import carriersReducer from 'features/carriers/reducer'
import changePasswordReducer from 'features/changePassword/reducer'
import completeRegistrationReducer from 'features/completeRegistration/reducer'
import forgotPasswordReducer from 'features/forgotPassword/reducer'
import laneProposalsReducer from 'features/laneProposals/reducer'
import messagesReducer from 'features/message/reducer'
import resetPasswordReducer from 'features/resetPassword/reducer'
import shipperReducer from 'features/shipper/reducer'
import shipperMembersReducer from 'features/shipperMembers/reducer'
import shipperSettingsReducer from 'features/shipperSettings/reducer'
import shipperSettingsExtraFieldsReducer from 'features/shipperSettingsExtraFields/reducer'
import signUpReducer from 'features/signUp/reducer'

const rootReducer = combineReducers({
  carriers: carriersReducer,
  forgotPassword: forgotPasswordReducer,
  laneProposals: laneProposalsReducer,
  messages: messagesReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,
  signUp: signUpReducer,
  shipperSettings: shipperSettingsReducer,
  shipperSettingsExtraFields: shipperSettingsExtraFieldsReducer,
  shipper: shipperReducer,
  shipperMembers: shipperMembersReducer,
  completeRegistration: completeRegistrationReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
