import { TextField } from '@loadsmart/miranda-react'
import type { TextFieldProps, WCTextField } from '@loadsmart/miranda-react'
import type { ChangeEvent, Ref } from 'react'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { inputStyle } from './Input'

export type MaskedInputRef = {
  inputElement: HTMLInputElement
}

export type currencyMask = {
  prefix: string
  suffix: string
  includeThousandsSeparator: boolean
  thousandsSeparatorSymbol: string
  allowDecimal: boolean
  decimalSymbol: string
  decimalLimit: number
  integerLimit: number
  allowNegative: boolean
  allowLeadingZeroes: boolean
}

const Input = styled(MaskedInput)`
  ${inputStyle}
`

export const defaultCurrencyMask = {
  prefix: '$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
}

export function buildCurrencyComponent(currencyMask: any, placeholder = 'US$') {
  const mask = createNumberMask(currencyMask)

  return function MaskedCurrency(props: any, ref?: Ref<MaskedInputRef>) {
    return ref && Object.keys(ref).length > 0 ? (
      <Input placeholder={placeholder} {...props} mask={mask} ref={ref} />
    ) : (
      <Input placeholder={placeholder} {...props} mask={mask} />
    )
  }
}

const Currency = buildCurrencyComponent(defaultCurrencyMask)

type MirandaCurrencyProps = {
  readonly onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  readonly value: string
  readonly disabled?: boolean
  readonly labelledBy: string
  readonly placeholder?: string
  readonly currencyMask?: currencyMask
  readonly size?: TextFieldProps['size']
}

export const MirandaCurrency = ({
  onChange,
  value,
  disabled,
  labelledBy,
  placeholder = 'Inform the value (US$)',
  currencyMask = defaultCurrencyMask,
  size,
}: MirandaCurrencyProps) => {
  return (
    <MaskedInput
      data-testid="m-currency-mask"
      disabled={disabled}
      value={value ?? ''}
      mask={createNumberMask(currencyMask)}
      placeholder={placeholder}
      onChange={onChange}
      render={(ref, props) => {
        return (
          <TextField
            data-testid="m-currency-input"
            disabled={disabled}
            value={value}
            size={size}
            placeholder={placeholder}
            ref={(textField: WCTextField) => {
              // We need this just to avoid having ref as null/undefined causing render errors
              ref(textField)
            }}
            onInput={props.onChange}
            onChange={props.onChange}
            aria-labelledby={labelledBy}
          />
        )
      }}
    />
  )
}

export default Currency
