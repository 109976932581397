import type { CreateGroupChatProps } from '../hooks/useChat'
import type { Chat, ChatContactInfoResponse, ChatType, RawChat } from '../types'

function compareChatsByMessageDate(chatA: RawChat, chatB: RawChat) {
  if (chatA.last_message?.created_at && chatB.last_message?.created_at) {
    const dateA = new Date(chatA.last_message.created_at)
    const dateB = new Date(chatB.last_message.created_at)
    return dateB.getTime() - dateA.getTime()
  }

  return 0
}

function compareChatsByUnreadAndMessageDate(chatA: RawChat, chatB: RawChat) {
  if (!chatA.last_message && chatB.last_message) {
    return 1
  }

  if (chatA.last_message && !chatB.last_message) {
    return -1
  }

  if (chatA.last_message?.is_unread && !chatB.last_message?.is_unread) {
    return -1
  }

  if (!chatA.last_message?.is_unread && chatB.last_message?.is_unread) {
    return 1
  }

  return compareChatsByMessageDate(chatA, chatB)
}

/**
 * Sort chats by unread and last message date
 * Unread messages are first
 * Last message date is descending
 */
export function sortChats(chats: RawChat[]) {
  const sortedChats = [...chats].sort(compareChatsByUnreadAndMessageDate)
  return sortedChats
}

export function mapApiToChats(chats: RawChat[]): Chat[] {
  return sortChats(chats).map((chat) => {
    const context = chat.context
      ? {
          entityName: chat.context.entity_name,
          entityPk: chat.context.entity_pk,
          source: chat.context.source,
        }
      : undefined

    return {
      uuid: chat.uuid,
      subject: chat.subject,
      lastMessage: {
        createdAt: chat.last_message?.created_at,
        text: chat.last_message?.text,
        isUnread: chat.last_message?.is_unread ?? false,
      },
      participants: chat.participants,
      type: chat.type,
      context,
    }
  })
}

export function mapNewGroupChatToApi(
  groupChat: CreateGroupChatProps,
  userData: ChatContactInfoResponse
) {
  const { contacts, subject, context } = groupChat

  return {
    participants: [
      {
        uuid: userData.uuid,
        type: 'shipper',
      },
      ...contacts.map((contact) => ({
        uuid: contact.uuid,
        type: contact.type ?? '',
      })),
    ],
    subject,
    type: 'group' as ChatType,
    ...(context && {
      context: {
        source: context.source,
        entity_name: context.entityName,
        entity_pk: context.entityPk,
      },
    }),
  }
}
