type RequestedAccessorialsPayload = Pick<
  PriceItemType,
  'uuid' | 'stops_relationship'
>[]

function updateRequestedAccessorialOnPayload(
  uuid: string,
  stopType: StopType,
  requested_accessorials: RequestedAccessorialsPayload
) {
  return requested_accessorials.map((accessorial) => {
    if (accessorial.uuid === uuid) {
      return {
        ...accessorial,
        stops_relationship: [
          ...(accessorial.stops_relationship ?? []),
          stopType,
        ],
      }
    }
    return accessorial
  })
}

export function mapValueToRequestedAccessorialsPayload(
  value: string[]
): RequestedAccessorialsPayload {
  if (!value.length) {
    return []
  }
  let requested_accessorials: RequestedAccessorialsPayload = []

  for (const code of value) {
    const [uuid, stop] = code.split('_')
    const stopType = stop as StopType

    if (!stopType) {
      requested_accessorials = [
        ...requested_accessorials,
        { uuid, stops_relationship: [] },
      ]
    } else {
      const sameUUID = requested_accessorials.find(
        (accessorial) => accessorial.uuid === uuid
      )

      if (sameUUID) {
        requested_accessorials = updateRequestedAccessorialOnPayload(
          uuid,
          stopType,
          requested_accessorials
        )
      } else {
        requested_accessorials = [
          ...requested_accessorials,
          { uuid, stops_relationship: [stopType] },
        ]
      }
    }
  }

  return requested_accessorials
}

export function mapRequestedAccessorialsToValue(
  requested_accessorials:
    | Pick<PriceItemType, 'uuid' | 'stops_relationship'>[]
    | undefined
): string[] {
  if (!requested_accessorials?.length) {
    return []
  }
  return requested_accessorials.reduce(
    (accumulator: string[], priceItemType) => {
      if (!priceItemType.stops_relationship?.length) {
        return [...accumulator, priceItemType.uuid]
      }
      return [
        ...accumulator,
        ...priceItemType.stops_relationship.map(
          (stop) => `${priceItemType.uuid}_${stop}`
        ),
      ]
    },
    []
  )
}

export function getAccessorialsPayloadFields({
  values,
  accessorialsIsEnabled,
  priceItemTypesEnabled,
}: {
  values: { requested_accessorials: string[]; accessorials: string[] }
  accessorialsIsEnabled: boolean
  priceItemTypesEnabled: boolean
}) {
  if (!accessorialsIsEnabled) {
    return {}
  }
  if (priceItemTypesEnabled) {
    return {
      requested_accessorials: mapValueToRequestedAccessorialsPayload(
        values.requested_accessorials
      ),
    }
  }
  return { accessorials: values.accessorials }
}

export function getAccessorialTagName(accessorial: Accessorial) {
  const accessorialName = accessorial?.name ?? ''
  const stop = accessorial?.stop_type?.[0]

  return (stop ? `${accessorialName} [${stop}]` : accessorialName).toUpperCase()
}

export function getPriceItemTypeTagName(
  priceItemType: PriceItemType
): string[] {
  const { label, stops_relationship } = priceItemType

  const [pickup, delivery] = stops_relationship ?? []

  const tagsNames = []

  if (pickup) {
    tagsNames.push(`${label} [${pickup}]`.toUpperCase())
  }

  if (delivery) {
    tagsNames.push(`${label} [${delivery}]`.toUpperCase())
  }

  if (!pickup && !delivery) {
    tagsNames.push(label.toUpperCase())
  }

  return tagsNames
}
