import type {
  useCustomFormFieldProps,
  useCustomFormFieldResult,
} from 'hooks/useCustomFormField'
import { useCustomFormField } from 'hooks/useCustomFormField'

import type { FreightInformation } from './FreightInformation.types'
import { useFreightInformationFormContext } from './FreightInformationForm.context'

type useFreightInformationFormFieldProps = useCustomFormFieldProps & {
  name: keyof Omit<FreightInformation, 'po_numbers' | 'so_numbers'>
}

export function useFreightInformationFormField({
  name,
  hint,
  ...props
}: useFreightInformationFormFieldProps): useCustomFormFieldResult {
  const [shipment] = useFreightInformationFormContext()
  const { errors } = shipment._metadata

  return useCustomFormField({ name, hint, error: props.error ?? errors[name] })
}
