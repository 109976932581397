import { get } from 'lodash'
import { useCallback } from 'react'

import { triggerEvent } from '_shared_/custom-events/events'
import type { FacilityDrawerProps } from 'components/FacilityDrawer/types'
import { mapFormValueToTransientFacilityV2 } from 'utils/adapters/facilityV1toV2'
import { mapFacilityDetailsV2ToV1 } from 'utils/adapters/facilityV2toV1'
import queryClient from 'utils/queryClient'

function refetchShipperFacilities() {
  queryClient.refetchQueries({
    queryKey: ['retrieveShipperFacilities', { sort: 'company_name' }],
  })
}

function refetchFacilityDetailsV2(facilityUUID: string) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
  })
}

/**
 * use this hook with the EventBasedFacilityDrawer and the EventBasedCreateFacilityDrawer.
 */
export function useFacilityDrawer(props?: {
  onFacilitySaved?: (facility: FacilityDetails) => void
}) {
  const onFacilitySaved = get(props, 'onFacilitySaved')

  /**
   * Opens the `CreateFacilityDrawer`.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openCreateFacilityForm = useCallback(
    (options?: {
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
    }) => {
      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-create-facility-form', {
        initialValues: mapFormValueToTransientFacilityV2(
          options?.initialValues
        ),
        onFacilitySaved: (facility) => {
          refetchShipperFacilities()

          handleFacilitySaved?.(mapFacilityDetailsV2ToV1(facility))
        },
      })
    },
    [onFacilitySaved]
  )

  const openFacilityManagerDrawer = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      initialView?: 'details' | 'edit-form'
    }) => {
      const { facilityUUID } = options

      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-facility-manager-v2', {
        ...options,
        facilityUUID,
        onFacilitySaved: (facility) => {
          refetchShipperFacilities()
          refetchFacilityDetailsV2(facilityUUID)

          handleFacilitySaved?.(mapFacilityDetailsV2ToV1(facility))
        },
      })
    },
    [onFacilitySaved]
  )

  /**
   * Opens the `FacilityManagerDrawer` in the edit-form view.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openEditFacilityForm = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
    }) => {
      openFacilityManagerDrawer({ initialView: 'edit-form', ...options })
    },
    [openFacilityManagerDrawer]
  )

  /**
   * Opens the `FacilityManagerDrawer` in the details view.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openFacilityDetails = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
    }) => {
      openFacilityManagerDrawer({ initialView: 'details', ...options })
    },
    [openFacilityManagerDrawer]
  )

  return {
    openCreateFacility: openCreateFacilityForm,
    openEditFacility: openEditFacilityForm,
    openFacilityDetails,
  }
}
