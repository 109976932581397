import { isEmpty } from 'lodash'

import type {
  TransientFacilityV2,
  WeeklyTransientOperatingHours,
} from 'components/FacilityDrawer'
import { createTransientFacilityV2 } from 'components/FacilityDrawer/Facility.helpers'
import { createTransientFacilityLocation } from 'components/FacilityDrawer/LocationSection.helpers'
import {
  createDailyTransientOperatingHours,
  createTransientOperatingHours,
  createWeeklyTransientOperatingHours,
} from 'components/FacilityDrawer/OperatingHoursSection.helpers'
import type { FormValue, OperatingDays } from 'components/FacilityDrawer/types'
import type {
  FacilityAppointmentMode,
  FacilityContactV2,
  FacilityDetailsV2,
  FacilityHoursOfOperationEntry,
  FacilityNoteV2,
} from 'services/facilities'

function mapFacilityNotes(
  notes: FacilityDetails['notes']
): Array<FacilityNoteV2> {
  // @ts-expect-error the notes' UUID from v1 is a string
  return (
    notes?.map((note) => ({
      id: note.uuid,
      note: note.description,
    })) ?? []
  )
}

export function mapFacilityContactV1toV2(contact: Contact): FacilityContactV2 {
  return {
    uuid: contact.uuid,
    email: contact.email ?? null,
    name: contact.name ?? '',
    extension: contact.phone_number_extension,
    phone: contact.phone_number,
  }
}

function mapFacilityContacts(contacts: Contact[] = []): FacilityContactV2[] {
  return contacts.map(mapFacilityContactV1toV2)
}

// Map of day booleans to day_of_week strings
const DAYS_MAP: { [key: string]: DayOfWeek } = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
}

function mapFacilityHoursOfOperation(
  facility: FacilityDetails | Facility
): FacilityHoursOfOperationEntry[] {
  const hoursOfOperationEntries: FacilityHoursOfOperationEntry[] = []

  const operation = facility.hours_of_operations

  if (isEmpty(operation)) {
    return []
  }

  for (const day in DAYS_MAP) {
    if (operation[day as keyof FacilityOperation]) {
      const { closes, opens, mode } = operation
      const appointment_mode =
        mode.toLowerCase() as FacilityHoursOfOperationEntry['appointment_mode']

      hoursOfOperationEntries.push({
        uuid: `${day}-${opens}-${closes}-${mode}`,
        day_of_week: DAYS_MAP[day],
        opens,
        closes,
        appointment_mode,
      })
    }
  }

  return hoursOfOperationEntries
}

export function mapFacilityDetailsV1ToV2(
  facility: FacilityDetails | Facility | undefined | null
): FacilityDetailsV2 | undefined {
  if (isEmpty(facility)) {
    return undefined
  }

  return {
    address: facility.address,
    // address_details does not exists in v1
    address_details: '',
    city: facility.city,
    contacts: mapFacilityContacts(facility.contacts),
    country: facility.country,
    hours_of_operation: mapFacilityHoursOfOperation(facility),
    name: facility.company_name || facility.address,
    notes: mapFacilityNotes(facility.notes),
    state: facility.state,
    uuid: facility.uuid,
    warehouse_uuid: facility.warehouse_uuid ?? null,
    zipcode: facility.zipcode,
    accessorials: [],
  }
}

/**
 * convert `FacilityDrawerProps['initialValues']` from _src/components/FacilityDrawer/types.ts_
 * to `TransientFacilityV2` from _src/components/FacilityDrawer/v2/Facility.types.ts_.
 */
export function mapFormValueToTransientFacilityV2(
  initialValues?: Partial<FormValue>
): TransientFacilityV2 {
  if (initialValues == null) {
    return {} as TransientFacilityV2
  }

  const {
    company_name,
    facility_address,
    operating_days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    mode = 'APPT',
  } = initialValues

  const operatingHours: WeeklyTransientOperatingHours =
    createWeeklyTransientOperatingHours(
      (Object.keys(DAYS_MAP) as (keyof OperatingDays)[]).reduce(
        (overrides, day) => {
          return {
            ...overrides,
            [DAYS_MAP[day]]: createDailyTransientOperatingHours({
              enabled: Boolean(operating_days?.includes(day)),
            }),
          }
        },
        {} as Partial<WeeklyTransientOperatingHours>
      )
    )

  operating_days?.forEach((day) => {
    operatingHours[DAYS_MAP[day]] = createDailyTransientOperatingHours({
      enabled: true,
      operating_hours: [
        createTransientOperatingHours({
          opens: '09:00',
          closes: '16:00',
          appointment_mode: (
            mode || 'APPT'
          ).toLowerCase() as FacilityAppointmentMode,
        }),
      ],
    })
  })

  const location = createTransientFacilityLocation({
    name: company_name ?? '',
    address: facility_address?.address ?? '',
    city: facility_address?.city ?? '',
    state: facility_address?.state ?? '',
    zipcode: facility_address?.zipcode ?? '',
    country: facility_address?.country ?? '',
  })

  return createTransientFacilityV2({
    location,
    hours_of_operation: operatingHours,
  })
}
