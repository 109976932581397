import type { FieldProps, TextProps } from '@loadsmart/miranda-react'
import { Field, Text } from '@loadsmart/miranda-react'
import { useId } from 'react'
import type { ReactNode } from 'react'

export type DetailFieldProps = Pick<FieldProps, 'size' | 'align'> &
  Pick<TextProps, 'variant' | 'color'> & {
    readonly label: ReactNode
    readonly value?: ReactNode
    readonly fallbackValue?: ReactNode
  }

export const DetailField = ({
  label,
  value,
  fallbackValue = <span data-testid="empty-state">-</span>,
  size = 'small',
  align,
  variant,
  color,
}: DetailFieldProps) => {
  const id = useId()

  return (
    <Field size={size} align={align}>
      <Field.Label id={id}>{label}</Field.Label>
      <Text aria-labelledby={id} variant={variant} color={color}>
        {value ? value : fallbackValue}
      </Text>
    </Field>
  )
}
