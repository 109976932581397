import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import * as authService from 'services/auth'
import { parseError } from 'utils/errors'

export type ErrorPayload = { password: string[]; password_confirm: string[] }
export type CompleteRegistrationError = Partial<ErrorPayload> | string | null
export interface State {
  meta: {
    isLoading: boolean
    error: CompleteRegistrationError
  }
}

const initialState: State = {
  meta: { isLoading: false, error: null },
}

export const completeRegistration = createAsyncThunk(
  'completeRegistration/completeRegistration',
  async (
    {
      callback,
      password,
      token,
    }: {
      callback: () => void
      password: string
      token: string
    },
    thunk
  ) => {
    try {
      await authService.completeRegistration(password, token)
      callback()
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.data) {
        return thunk.rejectWithValue(error.response.data)
      }

      return thunk.rejectWithValue(parseError())
    }
  }
)

export const slice = createSlice({
  name: 'completeRegistration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(completeRegistration.pending, (state) => {
      state.meta.isLoading = true
      state.meta.error = null
    })
    builder.addCase(completeRegistration.fulfilled, (state) => {
      state.meta.isLoading = false
    })
    builder.addCase(completeRegistration.rejected, (state, action) => {
      const error = action.payload as CompleteRegistrationError

      state.meta.error = error
      state.meta.isLoading = false
    })
  },
})

export default slice.reducer
