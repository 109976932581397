import { toCSSValue } from '@loadsmart/miranda-tokens'
import type { ColorToken } from '@loadsmart/miranda-tokens'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  from {
    transform: scale(0.5);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
`

interface StatusIndicatorProps {
  color?: ColorToken
}

export const StatusIndicator = styled.span<StatusIndicatorProps>`
  position: relative;
  display: inline-block;
  width: ${toCSSValue('spacing-2')};
  height: ${toCSSValue('spacing-2')};
  border-radius: ${toCSSValue('border-radius-circle')};
  background: ${({ color }) => toCSSValue(color || 'color-brand')};

  &:before {
    background: ${({ color }) => toCSSValue(color || 'color-brand')};
    content: '';
    display: block;
    position: absolute;
    left: calc(${toCSSValue('spacing-0-5')} * -1);
    top: calc(${toCSSValue('spacing-0-5')} * -1);
    width: calc(${toCSSValue('spacing-2')} + ${toCSSValue('spacing-1')});
    height: calc(${toCSSValue('spacing-2')} + ${toCSSValue('spacing-1')});
    border-radius: ${toCSSValue('border-radius-circle')};
    animation: ${pulse} 1.5s infinite ease-in;
  }
`
