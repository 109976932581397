import { Link } from '@loadsmart/miranda-react'

import { DetailField } from 'components/DetailField'

import { getCarrierReferenceUrl } from './utils'

export const CarrierReferenceField = ({
  carrierRefId,
}: {
  readonly carrierRefId: string
}) => {
  return (
    <DetailField
      label="Carrier Reference #"
      value={
        <Link href={getCarrierReferenceUrl(carrierRefId)} target="_blank">
          #{carrierRefId}
        </Link>
      }
    />
  )
}
