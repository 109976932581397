import { Select } from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import {
  Layout,
  Text,
  SpinnerWheel,
  Banner,
  ActionableTag,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { toTitleCase } from 'utils/strings'

import type {
  AccessorialOption,
  AccessorialsOptionsByStop,
} from './AccessorialsSelectByStop.types'
import { useAccessorialsOptionsByStop } from './useAccessorialsOptionsByStop'

type AccessorialsSelectRowProps = {
  readonly stop: StopType | 'general'
  readonly options: AccessorialOption[]
  readonly selected?: AccessorialOption[]
  readonly onChange: (params: {
    stop: StopType | 'general'
    selected: AccessorialOption[]
  }) => void
}

function AccessorialsSelectRow({
  stop,
  options,
  selected = [],
  onChange,
}: AccessorialsSelectRowProps) {
  if (!options.length) {
    return null
  }

  const onSelectChange = (
    event: EventLike<Selectable | Selectable[] | null>
  ) => {
    onChange({
      stop,
      selected: (event.target.value ?? []) as AccessorialOption[],
    })
  }

  const onRemove = (accessorial: AccessorialOption) => {
    onChange({
      stop,
      selected: selected.filter((item) => item.value !== accessorial.value),
    })
  }

  return (
    <Layout.Sidebar sideWidth="60px">
      <Layout.Sidebar.Side padding="none">
        <Layout.Group
          align="center"
          style={{ height: toCSSValue('global-height-default') }}
        >
          <Text color="color-text-tertiary">{toTitleCase(stop)}</Text>
        </Layout.Group>
      </Layout.Sidebar.Side>
      <Layout.Sidebar.Content padding="none">
        <Layout.Stack gap="spacing-2">
          <Select
            data-testid={`${stop}-accessorials-select`}
            name={`${stop}-accessorials-select`}
            placeholder="Select accessorials"
            options={options}
            value={selected as Selectable[]}
            onChange={onSelectChange}
            multiple
          />
          <Layout.Group
            gap="spacing-2"
            data-testid={`${stop}-accessorials-tags`}
          >
            {selected.map((accessorial) => (
              <ActionableTag
                key={accessorial.value}
                size="small"
                onRemove={() => {
                  onRemove(accessorial)
                }}
              >
                {accessorial.label}
              </ActionableTag>
            ))}
          </Layout.Group>
        </Layout.Stack>
      </Layout.Sidebar.Content>
    </Layout.Sidebar>
  )
}

export type AccessorialsSelectByStopProps = {
  readonly mode?: TransportationModeCode
  readonly usage: AccountingObjectType
  readonly selectedAccessorials: AccessorialsOptionsByStop
  readonly onChange: (selectedAccessorials: AccessorialsOptionsByStop) => void
}

/**
 * This component should only be used where the "Price Item Type" model is supported.
 */
export function AccessorialsSelectByStop({
  mode,
  usage,
  selectedAccessorials,
  onChange,
}: AccessorialsSelectByStopProps) {
  const {
    isLoading,
    isError,
    onRefetch,
    generalOptions,
    pickupOptions,
    deliveryOptions,
  } = useAccessorialsOptionsByStop({ mode, usage })

  const onSelectChange = ({
    stop,
    selected,
  }: {
    stop: StopType | 'general'
    selected: AccessorialOption[]
  }) => {
    onChange({
      ...selectedAccessorials,
      [stop]: selected,
    })
  }

  if (isLoading) {
    return (
      <Layout.Group align="center" justify="center">
        <SpinnerWheel size="32px" aria-label="Loading" />
      </Layout.Group>
    )
  }

  if (isError) {
    return (
      <Banner variant="danger">
        <Banner.Title>Failed to load the accessorials options</Banner.Title>
        <Banner.Actions>
          <Banner.ActionPrimary onClick={onRefetch}>
            Try again
          </Banner.ActionPrimary>
        </Banner.Actions>
      </Banner>
    )
  }

  return (
    <Layout.Stack>
      <AccessorialsSelectRow
        stop="general"
        options={generalOptions}
        selected={selectedAccessorials.general}
        onChange={onSelectChange}
      />
      <AccessorialsSelectRow
        stop="pickup"
        options={pickupOptions}
        selected={selectedAccessorials.pickup}
        onChange={onSelectChange}
      />
      <AccessorialsSelectRow
        stop="delivery"
        options={deliveryOptions}
        selected={selectedAccessorials.delivery}
        onChange={onSelectChange}
      />
    </Layout.Stack>
  )
}
