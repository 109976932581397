import { Icon, Layout, Text, Tooltip } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { getCountryMetadataByAbbreviation } from './FacilityDetails.helpers'

export type FacilityCountryProps = Pick<FacilityDetailsV2, 'country'> & {
  readonly labelPosition?: 'inline' | 'tooltip'
}

export function FacilityCountry({
  country,
  labelPosition = 'inline',
}: FacilityCountryProps) {
  const countryMetadata = getCountryMetadataByAbbreviation(country)

  if (!countryMetadata) {
    return null
  }

  const { name, flagIconName } = countryMetadata

  if (labelPosition === 'tooltip') {
    return (
      <Tooltip message={name} placement="top">
        <Icon
          name={flagIconName}
          size="16"
          data-testid="country-flag-with-tooltip"
        />
      </Tooltip>
    )
  }

  return (
    <Layout.Group gap="spacing-1" align="center">
      <Icon name={flagIconName} size="16" />
      <Text
        variant="body-md"
        color="color-text-primary"
        data-testid="facility-country"
      >
        {name}
      </Text>
    </Layout.Group>
  )
}
