import {
  IconSearch as IconSearchBase,
  IconCloseCircle as IconCloseBase,
} from '@loadsmart/icons'
import { useCallback } from 'react'
import type { InputHTMLAttributes, KeyboardEvent } from 'react'
import styled from 'styled-components'

import { hideStyledProps } from 'utils/styles'

import { inputStyle } from './Input'

const searchIconSize = 22
const closeIconSize = 18

const Input = styled.input<{ v2: boolean }>`
  ${inputStyle}
  ${({ v2, theme }) =>
    v2 &&
    `background-color: ${theme.colors.neutralLighter};
    ::placeholder {
        color: ${theme.colors.neutral};
    }
  `}

  border: 1px solid ${({ theme, v2 }) =>
    v2 ? theme.colors.neutralLighter : theme.colors.backgroundLightActive};
  border-radius: 30px;
  padding: 10px 35px;
`
const IconSearch = styled(IconSearchBase)
  .withConfig(hideStyledProps(['v2']))
  .attrs({
    height: searchIconSize,
    width: searchIconSize,
  })`
  color: ${({ theme, v2 }) =>
    v2 ? theme.colors.neutralDark : theme.colors.backgroundLightGray};
  left: 10px;
  opacity: 0.5;
  position: absolute;
  top: calc(50% - ${searchIconSize}px / 2);
`
const IconClose = styled(IconCloseBase).attrs({
  height: closeIconSize,
  width: closeIconSize,
})`
  color: ${({ theme }) => theme.colors.backgroundMediumGray};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: calc(50% - ${closeIconSize}px / 2);
`
const Wrapper = styled.div<{ v2: boolean }>`
  margin-bottom: ${({ v2 }) => (v2 ? '20px' : '10px')};
  position: relative;
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  readonly value: number | string
  readonly v2?: boolean
  readonly disabled?: boolean
  readonly onClear?: (event: MouseEvent) => void
}

export default function SearchBar({ onClear, v2 = false, ...props }: Props) {
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }, [])

  return (
    <Wrapper v2={v2}>
      <Input onKeyDown={onKeyDown} type="text" {...props} v2={v2} />
      <IconSearch v2={v2} />
      {props.value && onClear && <IconClose onClick={onClear} />}
    </Wrapper>
  )
}
