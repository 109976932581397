import { useCallback, useDebugValue, useState } from 'react'

import type { CustomEventMap } from '_shared_/custom-events/custom-events.types'
import { useCustomEventSubscription } from '_shared_/custom-events/useCustomEventSubscription'

type DrawerState<Data> = {
  isOpen: boolean
  open: () => void
  close: () => void
  clearEvent: () => void
  onClosed: () => void
  data: Data | undefined
}

export const useEventBasedDrawer = <K extends keyof CustomEventMap>(
  eventName: K
): DrawerState<CustomEventMap[K]['detail']> => {
  const [isOpen, setIsOpen] = useState(false)

  useDebugValue(isOpen ? 'opened' : 'closed')

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const { data, clearEvent } = useCustomEventSubscription(eventName, open)

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onClosed = useCallback(() => {
    clearEvent()
  }, [clearEvent])

  return {
    isOpen,
    open,
    close,
    onClosed,
    data,
    clearEvent,
  }
}
