import type { FieldProps } from '@loadsmart/miranda-react'
import { Field } from '@loadsmart/miranda-react'
import type { CSSProperties, ReactNode } from 'react'

import { StopSearchField } from 'components/Form'
import type { FacilityDetailsV2 } from 'services/facilities'
import { mapFacilityDetailsV1ToV2 } from 'utils/adapters/facilityV1toV2'
import type { Transient } from 'utils/transient'

import type { Stop } from './Stops.types'
import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

function getValue(stop: Transient<Stop>) {
  if (stop.facility) {
    return {
      ...stop.facility,
      company_name: stop.facility.name,
    }
  }

  return stop.location
}

export const StopFacilityOrAddress = (props: {
  readonly label?: ReactNode
  readonly required?: FieldProps['required']
  readonly hint?: UseStopFormFieldProps['hint']
  readonly style?: CSSProperties
  readonly disabled?: boolean
}) => {
  const { label, required, hint, style, disabled } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    stop,
    dispatch,
  } = useStopFormField({ name: 'facilityOrLocation', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>{label ?? `Stop #${index}`}</Field.Label>
      <StopSearchField
        {...controlProps}
        withFacilities
        addNewFacilityOption
        id={stop._metadata.id}
        disabled={disabled}
        placeholder="Select an address or facility"
        callback={(callbackData) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: callbackData?.isFacility
                ? {
                    facility: {
                      ...mapFacilityDetailsV1ToV2(callbackData),
                      _type: 'facility',
                    } as FacilityDetailsV2,
                    location: null,
                  }
                : {
                    location: callbackData,
                    facility: null,
                    notes: null,
                    contact: null,
                  },
            },
          })
        }}
        value={getValue(stop)}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
