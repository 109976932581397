import { createContext, useContext } from 'react'
import type { PropsWithChildren } from 'react'

export type IndexerProps = {
  readonly index: number
}

export type IndexerContextValue = number

export const IndexerContext = createContext<IndexerContextValue | undefined>(
  undefined
)

export function createIndexProvider({
  displayName = 'IndexerProvider',
}: { displayName?: string } = {}) {
  function IndexerProvider({
    children,
    index,
  }: PropsWithChildren<IndexerProps>) {
    return (
      <IndexerContext.Provider value={index}>
        {children}
      </IndexerContext.Provider>
    )
  }

  IndexerProvider.displayName = displayName

  return IndexerProvider
}

export function useIndexerContext() {
  const context = useContext(IndexerContext)

  if (context === undefined) {
    throw new Error(
      'useIndexerContext must be used within a IndexerContext.Provider'
    )
  }

  return context
}
