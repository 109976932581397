import { createContext, useContext } from 'react'

import type {
  HazmatContact,
  TransientHazmatContact,
} from './HazmatInformation.types'

export type Action = {
  type: 'HANDLE_CHANGE'
  payload: Partial<HazmatContact>
}

export type HazmatContactFormContextValue = [
  TransientHazmatContact,
  (action: Action) => void,
]

export const HazmatContactFormContext = createContext<
  HazmatContactFormContextValue | undefined
>(undefined)

export function useHazmatContactFormContext() {
  const context = useContext(HazmatContactFormContext)

  if (context === undefined) {
    throw new Error(
      'useHazmatContactFormContext must be used within a HazmatContactFormContext.Provider'
    )
  }

  return context
}
