import { createIndexProvider } from 'contexts/indexer'

import { ShippingItemFreightClass } from './ShippingItemFreightClass'
import { ShippingItemHandlingUnits } from './ShippingItemHandlingUnits'
import { ShippingItemHandlingUnitType } from './ShippingItemHandlingUnitType'
import { ShippingItemHeight } from './ShippingItemHeight'
import { ShippingItemLength } from './ShippingItemLength'
import { ShippingItemName } from './ShippingItemName'
import { ShippingItemNMFC } from './ShippingItemNMFC'
import { ShippingItemNMFCSearch } from './ShippingItemNMFCSearch'
import { ShippingItemOrders } from './ShippingItemOrders'
import { ShippingItemProperties } from './ShippingItemProperties'
import { ShippingItemHazmat } from './ShippingItemPropertyHazmat'
import { ShippingItemStackable } from './ShippingItemPropertyStackable'
import { ShippingItemTurnable } from './ShippingItemPropertyTurnable'
import { ShippingItemWeight } from './ShippingItemWeight'
import { ShippingItemWidth } from './ShippingItemWidth'

export * from './DimensionsSummary'
export * from './ShippingItemSummary'
export type * from './ShippingItems.types'
export * from './ShippingItems.constants'
export * from './ShippingItems.utils'
export * from './ShippingItemsForm.utils'

export {
  ShippingItemsForm,
  ShippingItemsFormContext,
  useShippingItemFormField,
} from './ShippingItemsForm'
export type { ShippingItemsFormProps } from './ShippingItemsForm'

export const ShippingItemForm = Object.assign(
  createIndexProvider({ displayName: 'ShippingItemForm' }),
  {
    FreightClass: ShippingItemFreightClass,
    HandlingUnits: ShippingItemHandlingUnits,
    HandlingUnitType: ShippingItemHandlingUnitType,
    Height: ShippingItemHeight,
    Length: ShippingItemLength,
    Name: ShippingItemName,
    Nmfc: ShippingItemNMFC,
    NmfcSearch: ShippingItemNMFCSearch,
    Properties: ShippingItemProperties,
    Weight: ShippingItemWeight,
    Width: ShippingItemWidth,
    Orders: ShippingItemOrders,

    // properties
    Hazmat: ShippingItemHazmat,
    Stackable: ShippingItemStackable,
    Turnable: ShippingItemTurnable,
  }
)
