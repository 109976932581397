import { FreightInformationAcessorials } from './FreightInformationAcessorials'
import {
  FreightInformationBOLNumberFormField,
  FreightInformationBOLNumberSummaryField,
} from './FreightInformationBOLNumber'
import { FreightInformationCarrierRefNumberSummaryField } from './FreightInformationCarrierRefNumber'
import { FreightInformationCustomFields } from './FreightInformationCustomFields'
import {
  FreightInformationEquipmentFormField,
  FreightInformationEquipmentSummaryField,
} from './FreightInformationEquipment'
import {
  FreightInformationEquipmentLengthFormField,
  FreightInformationEquipmentLengthSummaryField,
} from './FreightInformationEquipmentLength'
import {
  FreightInformationExcessiveLengthFormField,
  FreightInformationExcessiveLengthSummaryField,
} from './FreightInformationExcessiveLength'
import { FreightInformationForm as FreightInformationFormWithoutFields } from './FreightInformationForm'
import { FreightInformationHazmatFormField } from './FreightInformationHazmat'
import {
  FreightInformationModeFormField,
  FreightInformationModeSummaryField,
} from './FreightInformationMode'
import {
  FreightInformationPickupNumberFormField,
  FreightInformationPickupNumberSummaryField,
} from './FreightInformationPickupNumber'
import {
  FreightInformationPONumberFormField,
  FreightInformationPONumberSummaryField,
} from './FreightInformationPONumber'
import { FreightInformationPowerOnlyFormField } from './FreightInformationPowerOnly'
import {
  FreightInformationPRONumberFormField,
  FreightInformationPRONumberSummaryField,
} from './FreightInformationPRONumber'
import {
  FreightInformationReferenceNumberFormField,
  FreightInformationReferenceNumberSummaryField,
} from './FreightInformationReferenceNumber'
import {
  FreightInformationSONumberFormField,
  FreightInformationSONumberSummaryField,
} from './FreightInformationSONumber'
import { FreightInformationStopOffCharges } from './FreightInformationStopOffCharges'
import { FreightInformationSubEquipmentFormField } from './FreightInformationSubEquipment'
import { FreightInformationSummary as FreightInformationSummaryWithoutFields } from './FreightInformationSummary'
import { FreightInformationTarpSizeFormField } from './FreightInformationTarpSize'
import { FreightInformationTarpTypeFormField } from './FreightInformationTarpType'
import { FreightInformationTemperatureFormField } from './FreightInformationTemperature'
import { FreightInformationWithTarpFormField } from './FreightInformationWithTarp'

export type * from './FreightInformation.types'
export * from './FreightInformation.utils'

export type { FreightInformationFormProps } from './FreightInformationForm'
export type { FreightInformationSummaryProps } from './FreightInformationSummary'

export const FreightInformationForm = Object.assign(
  FreightInformationFormWithoutFields,
  {
    PONumber: FreightInformationPONumberFormField,
    BOLNumber: FreightInformationBOLNumberFormField,
    SONumber: FreightInformationSONumberFormField,
    PRONumber: FreightInformationPRONumberFormField,
    Mode: FreightInformationModeFormField,
    Equipment: FreightInformationEquipmentFormField,
    EquipmentLength: FreightInformationEquipmentLengthFormField,
    ExcessiveLength: FreightInformationExcessiveLengthFormField,
    ReferenceNumber: FreightInformationReferenceNumberFormField,
    PickupNumber: FreightInformationPickupNumberFormField,
    SubEquipment: FreightInformationSubEquipmentFormField,
    WithTarp: FreightInformationWithTarpFormField,
    TarpType: FreightInformationTarpTypeFormField,
    TarpSize: FreightInformationTarpSizeFormField,
    Temperature: FreightInformationTemperatureFormField,
    PowerOnly: FreightInformationPowerOnlyFormField,
    Hazmat: FreightInformationHazmatFormField,
    Accessorials: FreightInformationAcessorials,
    StopOffCharges: FreightInformationStopOffCharges,
    CustomFields: FreightInformationCustomFields,
  }
)

export const FreightInformationSummary = Object.assign(
  FreightInformationSummaryWithoutFields,
  {
    BOLNumber: FreightInformationBOLNumberSummaryField,
    Equipment: FreightInformationEquipmentSummaryField,
    EquipmentLength: FreightInformationEquipmentLengthSummaryField,
    ExcessiveLength: FreightInformationExcessiveLengthSummaryField,
    Mode: FreightInformationModeSummaryField,
    PickupNumber: FreightInformationPickupNumberSummaryField,
    PONumber: FreightInformationPONumberSummaryField,
    PRONumber: FreightInformationPRONumberSummaryField,
    ReferenceNumber: FreightInformationReferenceNumberSummaryField,
    SONumber: FreightInformationSONumberSummaryField,
    CarrierRefNumber: FreightInformationCarrierRefNumberSummaryField,
  }
)
