import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import type { CSSProperties } from 'react'

import { AccessorialsAccordion } from 'components/Accessorials'
import type { TransientStop } from 'components/StopsManager'
import { plural } from 'utils/strings'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFacilitiesAccessorials } from './useFacilitiesAccessorials'
import { useFreightInformationFormField } from './useFreightInformationFormField'

type FreightInformationAcessorialsProps = {
  readonly mode: TransportationModeCode
  readonly stops: TransientStop[]
  readonly required?: FieldProps['required']
  readonly style?: CSSProperties
}

export function FreightInformationAcessorials({
  mode,
  stops,
  required,
  style,
}: FreightInformationAcessorialsProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps } = useFreightInformationFormField({
    name: 'accessorials',
  })

  const { facilitiesAccessorialsCount } = useFacilitiesAccessorials({
    mode,
    stops,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid="shipment-accessorials-field"
    >
      <AccessorialsAccordion
        mode={mode}
        usage="spot_quote"
        values={{
          requested_accessorials: shipment.requested_accessorials ?? [],
          accessorials: shipment.accessorials ?? [],
        }}
        onChange={(fieldName, value) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [fieldName]: value },
          })
        }}
        hint={
          facilitiesAccessorialsCount
            ? `${facilitiesAccessorialsCount} ${plural('accessorial', facilitiesAccessorialsCount)} from facility settings`
            : undefined
        }
      />
    </Field>
  )
}
