import {
  IconTruckDryvan,
  IconTruckLtlDryvan,
  IconTruckVltl,
  IconTruckPtlDryvan,
  IconTruckRailDrybox,
  IconTruckEuv,
} from '@loadsmart/icons'
import { Layout, Text } from '@loadsmart/miranda-react'
import type { TextProps } from '@loadsmart/miranda-react'
import type { ColorToken } from '@loadsmart/miranda-react/dist/tokens'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { getTransportationMode } from 'utils/transportationMode'

export type TransportationModeVariant =
  | 'full'
  | 'icon-only'
  | 'label-only'
  | 'abbr'
  | 'abbr-only'

// based on src/screens/Shipper/Shipments/Details/components/QuoteInfo/utils.ts
const ICON_BY_MODE: Record<TransportationModeCode, string> = {
  any: IconTruckDryvan,
  drayage: IconTruckDryvan,
  full_container_load: IconTruckDryvan,
  full_truck_load: IconTruckDryvan,
  intermodal: IconTruckRailDrybox,
  less_than_truckload: IconTruckLtlDryvan,
  partial_truckload: IconTruckPtlDryvan,
  rail: IconTruckRailDrybox,
  volume_less_than_truckload: IconTruckVltl,
  expedited: IconTruckEuv,
}

function getIconByMode(mode: string) {
  return ICON_BY_MODE[mode as TransportationModeCode] || IconTruckDryvan
}

export function shouldShowIcon(variant: TransportationModeVariant) {
  return ['full', 'icon-only', 'abbr'].includes(variant)
}

export function shouldShowLabel(variant: TransportationModeVariant) {
  return ['full', 'label-only'].includes(variant)
}

export function shouldShowAbbr(variant: TransportationModeVariant) {
  return ['abbr', 'abbr-only'].includes(variant)
}

function shouldShow(variant: TransportationModeVariant) {
  return {
    showAbbr: shouldShowAbbr(variant),
    showIcon: shouldShowIcon(variant),
    showLabel: shouldShowLabel(variant),
  }
}

export type TransportationModeProps = {
  readonly color?: ColorToken
  readonly iconSize?: number
  readonly name: TransportationModeCode
  readonly textVariant?: TextProps['variant']
  readonly variant?: TransportationModeVariant
}

function TransportationMode(props: TransportationModeProps) {
  const {
    color = 'color-text-primary',
    iconSize = 20,
    textVariant = 'body-md',
    variant = 'abbr',
    name,
    ...others
  } = props
  const { showAbbr, showIcon, showLabel } = shouldShow(variant)
  const Icon = getIconByMode(name)
  const mode = getTransportationMode(name)

  if (!mode) {
    return <span>-</span>
  }

  const isIconOnly = variant === 'icon-only'
  return (
    <Layout.Group align="center" gap="spacing-1" {...others}>
      {showIcon && (
        <Icon
          data-testid="transportation-mode-icon"
          width={iconSize}
          height={iconSize}
          fill={toCSSValue(color)}
          aria-hidden={!isIconOnly}
          aria-label={isIconOnly ? mode.label : undefined}
          title={null}
        />
      )}
      {showLabel && (
        <Text
          data-testid="transportation-mode-label"
          variant={textVariant}
          color={color}
        >
          {mode.label}
        </Text>
      )}
      {showAbbr && (
        <Text
          data-testid="transportation-mode-abbr"
          variant={textVariant}
          color={color}
        >
          {mode.abbr}
        </Text>
      )}
    </Layout.Group>
  )
}

export default TransportationMode
