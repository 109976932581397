import { PageContent } from '@loadsmart/miranda-react'
import { FulfillmentHistoryProvider } from 'fulfillments/components/FulfillmentHistory'
import { useParams } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'
import { MirandaPageGlobalStyle } from 'styles/global'

import { ViewFulfillmentPageHeader } from './components/ViewFulfillmentPageHeader'
import {
  useViewFulfillmentContextData,
  ViewFulfillmentContextProvider,
} from './ViewFulfillmentContext'
import { ViewFulfillmentDetails } from './ViewFulfillmentDetails'

export function ViewFulfillmentPage() {
  const { id } = useParams<{ id: string }>()
  const data = useViewFulfillmentContextData(id)

  return (
    <>
      <WindowTitle title="Fulfillment Details | ShipperGuide" />
      <ViewFulfillmentContextProvider {...data}>
        <FulfillmentHistoryProvider>
          <MirandaPageGlobalStyle />
          <PageContent>
            <ViewFulfillmentPageHeader />
          </PageContent>

          <PageContent.Body>
            <ViewFulfillmentDetails />
          </PageContent.Body>
        </FulfillmentHistoryProvider>
      </ViewFulfillmentContextProvider>
    </>
  )
}
