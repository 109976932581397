import { PageContent } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { FulfillmentHistoryProvider } from 'fulfillments/components/FulfillmentHistory'
import { createGlobalStyle } from 'styled-components'

import { WindowTitle } from '_shared_/components/WindowTitle'

import { ListFulfillmentsPageHeader } from './components/ListFulfillmentsPageHeader'
import { ListFulfillmentsContextProvider } from './ListFulfillmentsContext'
import { ListFulfillmentsTabs } from './ListFulfillmentsTabs'

export const PageStyle = createGlobalStyle`
  body {
  padding: 0;
    overflow: hidden
  }
  main {
    padding: 0 !important;
    background-color: ${toCSSValue('color-background-secondary')} !important;
  }
`

export function ListFulfillmentsPage() {
  return (
    <>
      <WindowTitle title="Fulfillments | ShipperGuide" />
      <PageStyle />
      <PageContent>
        <ListFulfillmentsPageHeader />
      </PageContent>

      <PageContent.Body>
        <FulfillmentHistoryProvider>
          <ListFulfillmentsContextProvider>
            <ListFulfillmentsTabs />
          </ListFulfillmentsContextProvider>
        </FulfillmentHistoryProvider>
      </PageContent.Body>
    </>
  )
}
