import type { MapKeyType } from './types'

export function toSelectOption(label: string, value: string): SelectOption {
  return {
    _type: 'generic',
    value,
    label,
  }
}

// based on: src/screens/NewQuote/LTLQuote/components/CommodityItem/options.ts
export function toSelectOptions(map: Map<string, string>): SelectOption[] {
  const options: SelectOption[] = []

  map.forEach((label, value) => {
    options.push(toSelectOption(label, value))
  })

  return options
}

export const getOptionFromMap = <
  T extends Map<SelectOption['value'], SelectOption>,
>(
  key: MapKeyType<T> | null,
  map: T
): SelectOption | null => {
  if (!key || !map.has(key)) {
    return null
  }

  return map.get(key)!
}

export function fromSelectOption(
  option?: Pick<SelectOption, 'value'> | null
): string | null {
  if (!option) {
    return null
  }

  return String(option.value)
}
