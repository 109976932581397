import {
  SideNavigation,
  TopNavigation,
  useSideNavigation,
} from '@loadsmart/loadsmart-ui'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import type { ReactNode } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import type { InterpolationFunction } from 'styled-components'

import { triggerEvent } from '_shared_/custom-events/events'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { IconCog } from 'components/Icons/top-bar'
import {
  SideNavCollapseWrapper,
  TopNavCollapseWrapper,
} from 'components/Navigation'
import { useSettings } from 'contexts/settings'
import ChatBadge from 'packages/chat/src/components/ChatBadge'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import userWrapper from 'utils/user'

import BeamerBadge from './BeamerBadge'
import HelpCenter from './HelpCenter'
import { SideNavMenu } from './SideNav/SideNavMenu'
import { StyledMainWrapper, StyledPageWIthSideNav } from './styles'
import { UserDropdown } from './UserDropdown'

interface PageWithSideNavProps {
  readonly children: ReactNode
  readonly color?: string
  readonly padding?: string | InterpolationFunction<any>
}

const NavigationLogo = ({
  fbmUser,
  navPosition,
}: {
  readonly fbmUser: boolean
  readonly navPosition: 'side' | 'top'
}) => {
  const {
    values: [
      shipperGuideDarkLogo,
      shipperGuideDefaultLogo,
      FBMDarkLogo,
      FBMDefaultLogo,
    ],
  } = useSettings([
    'settings.SHIPPER_GUIDE_LOGO_DARK_URL',
    'settings.SHIPPER_GUIDE_LOGO_URL',
    'settings.FLATBED_MESSENGER_LOGO_DARK_URL',
    'settings.FLATBED_MESSENGER_LOGO_URL',
  ])

  const imgSize = fbmUser
    ? { width: 144, height: 36 }
    : { width: 150, height: 20 }
  const imgAltText = fbmUser ? 'Flatbed Messenger' : 'Shipper Guide'
  const imgSource = () => {
    if (navPosition === 'side') {
      return fbmUser ? FBMDefaultLogo : shipperGuideDefaultLogo
    }

    return fbmUser ? FBMDarkLogo : shipperGuideDarkLogo
  }

  return (
    <img
      src={imgSource()}
      width={imgSize.width}
      height={imgSize.height}
      alt={imgAltText}
    />
  )
}

function navbarLinkClicked(
  address: string,
  url?: string,
  history?: RouteComponentProps['history']
) {
  analytics.track(AnalyticsEvent.NavbarLink, AnalyticsEventTrigger.click, {
    address,
  })

  if (url) {
    history?.push(url)
  }
}

const ShipperGuideNav = ({
  children,
  color,
  padding,
}: PageWithSideNavProps) => {
  const { user, locationUUID } = useCurrentUser()
  const userIdentifier = user?.user_name || (user?.user_email as string)
  const { open, hide, toggle } = useSideNavigation()

  const history = useHistory()

  const {
    values: [enablePriceMonitor, ordersPageEnabled, enableInAppChat],
  } = useSettings([
    'flags.ENABLE_PRICE_MONITOR',
    'flags.ENABLE_ORDERS_PAGE',
    'flags.ENABLE_IN_APP_CHAT',
  ])

  const isFBMUser = user?.shipper?.business === 'FBM'

  const onOpenChat = () => {
    analytics.track(AnalyticsEvent.NavbarOpenChat)
    triggerEvent('sg:open-chat-drawer')
  }

  return (
    <StyledPageWIthSideNav>
      <SideNavCollapseWrapper open={open}>
        <SideNavigation>
          <SideNavigation.CloseButton size={24} onClick={hide} />
          <SideNavigation.Logo url="/">
            <NavigationLogo navPosition="side" fbmUser={isFBMUser} />
          </SideNavigation.Logo>

          <SideNavMenu
            enablePriceMonitor={enablePriceMonitor}
            enableOrdersPage={ordersPageEnabled}
          />
        </SideNavigation>
      </SideNavCollapseWrapper>
      <StyledMainWrapper color={color} padding={padding}>
        <TopNavCollapseWrapper>
          <TopNavigation>
            <TopNavigation.OpenSideNavButton onClick={toggle} />
            <TopNavigation.Logo url="/">
              <NavigationLogo navPosition="top" fbmUser={isFBMUser} />
            </TopNavigation.Logo>

            <TopNavigation.Menu align="right">
              <BeamerBadge />
              {enableInAppChat && <ChatBadge onClick={onOpenChat} />}
              <HelpCenter onClick={() => navbarLinkClicked('Zendesk')} />
              <TopNavigation.Menu.Item
                icon={
                  <IconCog
                    width="20"
                    height="20"
                    fill={toCSSValue('color-primary-60')}
                  />
                }
                label="Settings"
                aria-label="settings"
                url="/shipper/settings"
                onClick={(event) => {
                  event.preventDefault()
                  navbarLinkClicked('Settings', '/shipper/settings', history)
                }}
              />

              {user ? (
                <UserDropdown user={user} locationUUID={locationUUID} />
              ) : (
                <TopNavigation.Menu.Item label={userIdentifier}>
                  <TopNavigation.Menu.SubItem
                    label="Logout"
                    onClick={(event) => {
                      event.preventDefault()
                      userWrapper.logout()
                    }}
                    className="miranda-topnav-subitem-button"
                  />
                </TopNavigation.Menu.Item>
              )}
            </TopNavigation.Menu>
          </TopNavigation>
        </TopNavCollapseWrapper>
        <main>{children}</main>
      </StyledMainWrapper>
    </StyledPageWIthSideNav>
  )
}

export default ShipperGuideNav
