import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import reduceByKey from 'utils/reduceByKey'

export enum RateType {
  USER = 'user',
  LIVE = 'live',
  SHORT_TERM = 'short_term',
}

interface State {
  proposals: Record<number, LaneProposal>
  meta: {
    isLoading: boolean
    rateTypeView: RateType
  }
}

const initialState: State = {
  proposals: {},
  meta: { isLoading: false, rateTypeView: RateType.USER },
}

export const slice = createSlice({
  name: 'laneProposals',
  initialState,
  reducers: {
    fetchLaneProposalsStart(state) {
      state.meta.isLoading = true
    },
    fetchLaneProposalsSuccess(state, action: PayloadAction<LaneProposal[]>) {
      state.proposals = reduceByKey(action.payload, 'id', {
        meta: { isUpdating: false, error: null },
      })
      state.meta.isLoading = false
    },
    fetchLaneProposalsFailed(state) {
      state.meta.isLoading = false
    },
    updateRateTypeView(state, action: PayloadAction<RateType>) {
      state.meta.rateTypeView = action.payload
    },
  },
})
export default slice.reducer
