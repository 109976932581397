import { defaultTo, isEmpty, uniqBy } from 'lodash'

import type { HandlingUnitsDetails } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { formatDate } from 'utils/dateUtils'
import formatCurrency from 'utils/formatCurrency'

import type {
  AdaptedShipment,
  Shipment,
  Stop,
  Contact,
  StopContact,
} from '../types'

function getStopContact(stop: Stop) {
  const { facility, default_contact_uuid } = stop
  const contact =
    stop.contact ||
    (facility?.contacts.find(
      (facilityContact) => default_contact_uuid === facilityContact.uuid
    ) as Contact)

  if (!contact) {
    return null
  }

  return {
    ...contact,
    label: contact?.name,
    value: contact?.uuid,
  }
}

function getStop(stop: Stop) {
  const facility = stop.facility || { company_name: '', address: '', uuid: '' }
  const contact = getStopContact(stop)

  return {
    label: facility.company_name || facility.address,
    value: facility.uuid,
    _type: stop.stop_type,
    appointment: stop.appointment,
    check_in_at: stop.check_in?.done_at || null,
    check_out_at: stop.check_out?.done_at || null,
    ...(contact && {
      contact: { ...contact },
    }),
  }
}

const getItemsTotalWeight = (items: Array<HandlingUnitsDetails>): number => {
  return items.reduce(
    (acc, item) => acc + parseFloat((item.weight || '0').toString()),
    0
  )
}

function getDeliveryStops(stops: Array<Stop>) {
  return stops
    .filter(
      (_, index) =>
        index !== 0 && index !== stops.lastIndexOf(stops[stops.length - 1])
    )
    .map((stop) => ({
      ...stop,
      ...getStop(stop),
    }))
}

function getIsCancelEnabled(status: string) {
  return [
    'accounting-review',
    'en-route-pickup',
    'at-pickup',
    'booked',
    'en-route-delivery',
    'at-delivery',
  ].includes(status)
}

export function getOrderNumbers(
  shipment: Shipment
): Pick<
  AdaptedShipment,
  'po_numbers' | 'po_number' | 'so_number' | 'so_numbers'
> {
  const itemLevelPONumbers = shipment.items
    .map((item) => item.po_number)
    .filter((po_number) => !isEmpty(po_number))
  const itemLevelSONumbers = shipment.items
    .map((item) => item.so_number)
    .filter((po_number) => !isEmpty(po_number))
  const hasItemLevelNumbers =
    !isEmpty(itemLevelPONumbers) || !isEmpty(itemLevelSONumbers)

  const soNumbers = hasItemLevelNumbers
    ? itemLevelSONumbers
    : shipment.so_numbers
  const poNumbers = hasItemLevelNumbers
    ? itemLevelPONumbers
    : shipment.po_numbers

  return {
    po_numbers: poNumbers as string[],
    so_numbers: soNumbers as string[],
    po_number: poNumbers?.join(', ') ?? '',
    so_number: soNumbers?.join(', ') ?? '',
  }
}

function getTenderDates(shipment: Shipment) {
  const tenderDeadline =
    shipment?.spot_quote?.tendered_rate?.tender.acceptance_deadline || undefined
  const tenderCreatedAt =
    shipment?.spot_quote?.tendered_rate?.tender.created_at || undefined
  return {
    tenderDeadline,
    tenderCreatedAt,
  }
}

export default function shipmentAdapter(shipment: Shipment): AdaptedShipment {
  const { stops = [] } = shipment
  const pickup = stops[0]
  const delivery = stops[stops.length - 1]
  const deliveryStops = getDeliveryStops(stops)
  const formatedFlatRate = formatCurrency(
    shipment.spot_quote?.booked_rate?.flat_rate || '0',
    true,
    false
  )
  const orderNumbers = getOrderNumbers(shipment)
  const tenderDates = getTenderDates(shipment)

  const items = shipment.items.map((item) => {
    // item.orders is deprecated, we need to extract the orders from the order_lines
    const orders = defaultTo(
      item?.order_items?.map((order_item) => order_item.order),
      []
    )
    const itemOrders = uniqBy(orders, (order) => order.uuid)

    return {
      ...item,
      orders: itemOrders,
    }
  })

  return {
    ...shipment,
    ...orderNumbers,
    flat_rate: formatedFlatRate,
    carrier: shipment?.carrier,
    items,
    totalWeight: getItemsTotalWeight(shipment.items),
    pickup: { ...pickup, ...getStop(pickup) },
    pickup_id: pickup.id,
    pickup_notes: pickup.notes as string,
    pickup_contact: getStopContact(pickup) as StopContact,
    pickup_blind_bol: pickup?.blind_bol as boolean,
    pickup_scale_ticket: pickup?.scale_ticket,
    delivery: { ...delivery, ...getStop(delivery) },
    delivery_id: delivery.id,
    delivery_notes: delivery.notes as string,
    delivery_contact: getStopContact(delivery),
    delivery_blind_bol: delivery?.blind_bol,
    delivery_scale_ticket: delivery?.scale_ticket,
    stops: shipment.stops,
    intermediaryStops: deliveryStops || [],
    per_mile_rate: shipment?.spot_quote?.booked_rate?.per_mile_rate,
    ltl_carrier: shipment?.spot_quote?.booked_rate?.ltl_carrier,
    driver: shipment.driver && {
      value: shipment.driver.uuid,
      label: shipment.driver.name,
      phone_number: shipment.driver.phone_number,
    },
    quoteStatus: shipment.spot_quote?.status || '',
    tracking_link: shipment.tracking_link || '',
    isCancelEnabled: getIsCancelEnabled(shipment.status),
    isCanceled: shipment.status === 'canceled',
    canceled_at: shipment.canceled_at
      ? formatDate(shipment.canceled_at)
      : undefined,
    share_details: !!shipment.share_details,
    ...tenderDates,
  }
}
