import type { Currency } from 'rfp/rfp.types'
import {
  CURRENCY,
  DEFAULT_MULTI_CURRENCY_INPUT_MAP,
  MULTI_CURRENCY_INPUT_MAP,
} from 'utils/constants'

export const isMultiCurrency = (currency?: Currency): boolean => {
  return currency !== undefined && currency !== CURRENCY.USD
}

/*
  For instance we only need to remove Loadsmart from non USD RFPs. In the future it could be
  extended to validate other carriers based on a carrier attribute like
  `carrier.accepted_currencies: Currency[]`.
*/
export const validateCurrencyForCarrier = (
  carrier?: Carrier | null,
  currency?: Currency,
  loadsmartDOT?: string
): boolean => {
  if (!carrier || !currency || !loadsmartDOT) {
    return true
  }

  if (carrier.dot === loadsmartDOT && isMultiCurrency(currency)) {
    return false
  }

  return true
}

export const getCurrencyPrefix = (currency?: Currency): string => {
  const { prefix } =
    typeof currency === 'string' && currency in MULTI_CURRENCY_INPUT_MAP
      ? MULTI_CURRENCY_INPUT_MAP[currency]
      : DEFAULT_MULTI_CURRENCY_INPUT_MAP

  return prefix
}
