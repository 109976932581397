import { css } from 'styled-components'

import { screen } from './screen'

export const pageWrapperStyle = css`
  padding: 0 156px;

  ${screen.md} {
    padding: 0 10px;
  }
`
