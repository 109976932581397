import { Text } from '@loadsmart/miranda-react'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'

const PendingScheduleText = () => {
  return (
    <Text variant="body-sm" color="color-danger-60">
      Pending schedule
    </Text>
  )
}

type AppointmentPendingScheduleProps = {
  readonly appointment: StopAppointment
}

export const AppointmentPendingSchedule = ({
  appointment,
}: AppointmentPendingScheduleProps) => {
  if (appointment.status === 'pending') {
    return <PendingScheduleText />
  }
  return null
}
