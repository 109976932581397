import { useMap } from '@vis.gl/react-google-maps'
import { useEffect } from 'react'

import type { InfoBoxProps, Position } from './types'

export class InfoBoxElement {
  [x: string]: any
  content: string | Node
  pixelOffset: google.maps.Size
  position: Position
  zIndex: number | undefined | null
  boxClass: string
  boxStyle: Partial<CSSStyleDeclaration>

  visible: boolean
  div: HTMLDivElement | null

  constructor(options: InfoBoxProps) {
    this.extend(InfoBoxElement, google.maps.OverlayView)

    this.content = options.content || ''
    this.pixelOffset = options.pixelOffset || new google.maps.Size(0, 0)
    this.position = options.position || new google.maps.LatLng(0, 0)
    this.zIndex = options.zIndex || null

    this.boxClass = options.boxClass || ''
    this.boxStyle = options.boxStyle || ({} as Partial<CSSStyleDeclaration>)

    if (typeof options.visible === 'undefined') {
      this.visible = true
    } else {
      this.visible = options.visible
    }

    this.div = null
  }

  get overlay() {
    return this as unknown as google.maps.OverlayView
  }

  createInfoBoxDiv(): HTMLDivElement {
    if (!this.div) {
      this.div = document.createElement('div')
      this.setBoxStyle()

      if (typeof this.content === 'string') {
        this.div.innerHTML = this.content
      } else {
        this.div.appendChild(this.content)
      }

      const panes = this.overlay.getPanes()

      if (panes !== null) {
        panes.floatPane.appendChild(this.div)
      }
    }
    return this.div
  }

  setBoxStyle(): void {
    if (this.div) {
      this.div.className = this.boxClass
      this.div.style.cssText = ''

      for (const i in this.boxStyle) {
        if (Object.prototype.hasOwnProperty.call(this.boxStyle, i)) {
          this.div.style[i] = this.boxStyle[i] || ''
        }
      }

      // Apply required styles
      this.div.style.position = 'absolute'
      this.div.style.visibility = 'hidden'
      if (this.zIndex !== null) {
        this.div.style.zIndex = String(this.zIndex)
      }
      if (!this.div.style.overflow) {
        this.div.style.overflow = 'auto'
      }
    }
  }

  onRemove(): void {
    if (this.div && this.div.parentNode) {
      this.div.parentNode.removeChild(this.div)
      this.div = null
    }
  }

  draw(): void {
    const div = this.createInfoBoxDiv()

    const projection = this.overlay.getProjection()
    const pixPosition = projection.fromLatLngToDivPixel(this.position)

    if (pixPosition !== null) {
      div.style.left = `${pixPosition.x + this.pixelOffset.width}px`
      div.style.top = `${pixPosition.y + this.pixelOffset.height}px`
    }

    if (this.visible) {
      div.style.visibility = 'visible'
    } else {
      div.style.visibility = 'hidden'
    }
  }

  open(map: google.maps.Map): void {
    this.overlay.setMap(map)
  }

  close() {
    this.overlay.setMap(null)
  }

  extend<A extends typeof InfoBoxElement>(
    obj1: A,
    obj2: typeof google.maps.OverlayView
  ): A {
    return function applyExtend(
      this: A,
      object: typeof google.maps.OverlayView
    ): A {
      for (const property in object.prototype) {
        if (!Object.prototype.hasOwnProperty.call(this, property)) {
          this.prototype[property] =
            object.prototype[property as keyof google.maps.OverlayView]
        }
      }

      return this
    }.apply(obj1, [obj2])
  }
}

export const InfoBox = (props: InfoBoxProps) => {
  const map = useMap()

  useEffect(() => {
    if (map) {
      const infoBox = new InfoBoxElement(props)

      infoBox.open(map)

      return () => {
        infoBox.close()
      }
    }

    return () => null
  }, [props, map])

  return null
}
