import { Text, Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import type { FetchLocationOptions } from '@loadsmart/react-location-select'
import { useMemo } from 'react'

import { useFacilityDrawer } from '_shared_/hooks/useFacilityDrawer'
import type { AddressProps, LocationOrFacility } from 'components/Form'
import { LocationSelect } from 'components/LocationSelect'
import { useSelectFacilitySearch } from 'hooks/useSelectFacilitySearch'
import { formatLocationSelectAddress } from 'utils/address'

import StopSelectOption from './StopSelectOption'

function CreateFacilityOption() {
  return (
    <Select.CreatableOption>
      <Text variant="body-bold" color="color-accent">
        Add new Facility
      </Text>
    </Select.CreatableOption>
  )
}

const defaultConfig: FetchLocationOptions = {
  types: ['geocode', 'establishment'],
  restrictions: { country: ['us', 'ca'] },
}

interface StopSearchFieldProps extends AddressProps {
  readonly withFacilities?: boolean
  readonly addNewFacilityOption?: boolean
  readonly config?: Partial<FetchLocationOptions>
}

const extractFacilityFields = (facility: FacilityDetails): Facility => {
  const {
    address,
    city,
    state,
    zipcode,
    country,
    uuid,
    company_name,
    warehouse_uuid,
  } = facility

  return {
    address,
    city,
    state,
    zipcode,
    country,
    uuid,
    company_name,
    warehouse_uuid,
    // Temporarily cast to Facility to avoid TS error until we have migrated to the consolidated Facility type.
  } as Facility
}

export default function StopSearchField({
  onChange,
  callback,
  value,
  withFacilities = false,
  addNewFacilityOption = undefined,
  config,
  ...rest
}: StopSearchFieldProps) {
  const { openCreateFacility } = useFacilityDrawer({
    onFacilitySaved: (facility: FacilityDetails) => {
      callback({
        ...extractFacilityFields(facility),
        isFacility: true,
      })
    },
  })

  const selectValue: GenericOption & Selectable = {
    _type: 'address',
    label: formatLocationSelectAddress(value),
    value: formatLocationSelectAddress(value),
  }

  const useFacilities = useMemo(
    () => (withFacilities ? [useSelectFacilitySearch] : []),
    [withFacilities]
  )

  const locationSelectConfig = useMemo(
    () => ({ ...defaultConfig, ...config }),
    [config]
  )

  return (
    <LocationSelect
      name="locationSelect"
      datasources={useFacilities}
      onChange={(event: EventLike<Selectable | Selectable[] | null>) => {
        const location = event.target.value as LocationOrFacility

        onChange?.(location?.address || '')
        callback(location)
      }}
      config={locationSelectConfig}
      isValidNewOption={addNewFacilityOption}
      createOptionPosition="first"
      onCreate={
        addNewFacilityOption
          ? () => {
              openCreateFacility()
            }
          : undefined
      }
      value={selectValue?.value ? selectValue : null}
      components={{
        CreatableOption: CreateFacilityOption,
        Option: StopSelectOption,
      }}
      {...rest}
    />
  )
}
