import type {
  Chat,
  ChatContactInfoResponse,
  ConversationParticipant,
} from '../types'

export function searchChats(query: string, data?: Chat[]) {
  return (data ?? []).filter((chat) => {
    return (
      chat.lastMessage?.text?.trim().toLowerCase().includes(query) ??
      chat.subject.trim().toLowerCase().includes(query)
    )
  })
}

export function formatParticipants(
  participants?: ConversationParticipant[],
  userData?: ChatContactInfoResponse
) {
  return (participants ?? [])
    .filter(({ uuid }) => uuid !== userData?.uuid)
    .map((participant) => participant.name)
    .join(', ')
}
