import type { Marker } from '@googlemaps/markerclusterer'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import {
  AdvancedMarker,
  toLatLngLiteral,
  useAdvancedMarkerRef,
  useMap,
} from '@vis.gl/react-google-maps'
import { useEffect, useRef, useState } from 'react'

import { InfoBox } from './InfoBox'
import type { MarkerRouteProps, MarkerWithInfoProps } from './types'

const infoBoxStyle = {
  backgroundColor: toCSSValue('color-background-primary'),
  borderRadius: toCSSValue('border-radius-s'),
  boxShadow: toCSSValue('elevation-1'),
  fontFamily: toCSSValue('font-family-default'),
  color: toCSSValue('color-text-primary'),
  fontSize: toCSSValue('font-size-3'),
  fontWeight: toCSSValue('font-weight-bold'),
  padding: `${toCSSValue('spacing-1')} ${toCSSValue('spacing-2')} `,
}

const MarkerWithInfo = ({ point, setMarkerRef }: MarkerWithInfoProps) => {
  const { position } = point
  const [markerRef, marker] = useAdvancedMarkerRef()
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setMarkerRef(marker, `${position.lat}-${position.lng}`)
  }, [marker, position, setMarkerRef])

  return (
    <>
      <AdvancedMarker
        onClick={() => setVisible(!visible)}
        ref={markerRef}
        position={position}
        style={point.markerStyle}
        zIndex={point.zIndex}
      >
        {point.icon}
      </AdvancedMarker>
      {point.info && (
        <InfoBox
          visible={visible}
          position={toLatLngLiteral(position)}
          content={point.info}
          pixelOffset={new google.maps.Size(0, 10)}
          boxStyle={infoBoxStyle}
        />
      )}
    </>
  )
}

export const MarkerRoute = ({
  points,
  clusterMarkers,
  clusterRenderer,
}: MarkerRouteProps) => {
  const map = useMap()
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
  const clusterer = useRef<MarkerClusterer | null>(null)

  useEffect(() => {
    if (clusterMarkers && map && !clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        renderer: clusterRenderer,
      })
    }
  }, [clusterMarkers, clusterRenderer, map])

  useEffect(() => {
    clusterer.current?.clearMarkers()
    clusterer.current?.addMarkers(Object.values(markers))
  }, [markers])

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) {
      return
    }
    if (!marker && !markers[key]) {
      return
    }

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker }
      }

      const newMarkers = { ...prev }
      delete newMarkers[key]

      return newMarkers
    })
  }

  return points.map((point) => {
    const { position } = point
    const positionLiteral = toLatLngLiteral(position)

    return (
      <MarkerWithInfo
        key={`${positionLiteral.lat}-${positionLiteral.lng}`}
        point={point}
        setMarkerRef={setMarkerRef}
      />
    )
  })
}
