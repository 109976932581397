import { capitalize } from 'utils/strings'

export const arraysMatch = (arr1?: unknown[], arr2?: unknown[]) => {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) {
    return false
  }
  return arr1.every((element, index) => element === arr2[index])
}

export function capitalizeArray(arr: string[]) {
  return arr.map((item, index) => {
    const capitalItem = capitalize(item)
    if (index === 0) {
      return capitalItem
    }
    return `, ${capitalItem}`
  })
}

export function hasMoreThanOneItem(array: unknown[]): boolean {
  return array.length > 1
}

export const replaceArrayItem = <T>(
  array: T[],
  index: number,
  value: T
): T[] => {
  return [...array.slice(0, index), value, ...array.slice(index + 1)]
}

export const removeArrayItem = <T>(array: T[], index: number): T[] => {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export const createMapFromTuple = <T>(tuple: readonly T[] | T[]): Map<T, T> => {
  return new Map(tuple.map((item) => [item, item] as const))
}

function isNotNullable<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

export function filterNotNullable<T>(array: (T | null | undefined)[]): T[] {
  return array.filter(isNotNullable)
}

/**
 * Adds values to an array if they satisfy a condition.
 *
 * @param condition - A function that takes a value and returns a boolean.
 * @returns A function that takes multiple values and returns a function that takes an array.
 *
 * @example
 * const isEven = (num) => num % 2 === 0;
 * const addIfEven = addIf(isEven);
 * const addIfEvenNumbers = addIfEven(4, 5, 6);
 * const result = addIfEvenNumbers([1, 2, 3]);
 * console.log(result); // Output: [1, 2, 3, 4, 6]
 */
export const addIf = <Value>(condition: (value: Value) => boolean) => {
  return (...values: Value[]) => {
    return (array: Value[]) => {
      return values.reduce((acc, value) => {
        return condition(value) ? [...acc, value] : acc
      }, array)
    }
  }
}

export const addIfNotNull = addIf(isNotNullable)

/**
 * @param array The array to toggle the item in
 * @param item The item to toggle
 * @returns A new array with the item added if it was not present, or removed if it was present
 */
export const toggleArrayItem = <T>(array: T[], item: T): T[] => {
  const index = array.indexOf(item)

  if (index === -1) {
    return [...array, item]
  }

  return removeArrayItem(array, index)
}
