import type { CreateLanePayload } from 'freightwaves/types'
import httpClient from 'utils/httpClient'

export const getSubscription = async () => {
  const { data } = await httpClient.get(`/freightwaves/subscription`)
  return data
}

export const createSubscription = async () => {
  const { data } = await httpClient.post(`/freightwaves/subscription`)
  return data
}

export const list = async ({
  limit,
  offset,
  search,
  order,
}: {
  limit?: number
  offset?: number
  search?: string
  order?: string
}) => {
  const { data } = await httpClient.get(`/freightwaves/lanes/list`, {
    params: { limit, offset, search, order },
  })
  return data
}

export const createLane = async (payload: CreateLanePayload) => {
  const { data } = await httpClient.post(`/freightwaves/lanes`, payload)
  return data
}

export const deleteLanes = async () => {
  const { data } = await httpClient.delete(`/freightwaves/lanes`)
  return data
}

export const uploadLanes = async ({
  file,
  shouldDeleteLanes,
}: {
  file: File
  shouldDeleteLanes: string
}) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await httpClient.post(
    `/freightwaves/lanes/upload?delete=${shouldDeleteLanes}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}
