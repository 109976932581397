import { toCapitalizeFirstLetter } from '@loadsmart/utils-string'
import { useMemo } from 'react'

import { useShipperFavoriteAccessorials } from 'hooks/useQuery'
import type { UseShipperFavoriteAccessorialsQueryOptions } from 'hooks/useQuery'

import type { AccessorialOption } from './types'

function mapShipperAccessorialToOptions(
  accessorial: ShipperFavoriteAccessorial
): AccessorialOption[] {
  if (!accessorial.stops.length) {
    return [{ label: accessorial.label, value: accessorial.uuid }]
  }
  return accessorial.stops.map((stop) => ({
    label: `${accessorial.label} [${toCapitalizeFirstLetter(stop)}]`,
    // We need to add the stop to the value to differentiate the option for each stop
    value: `${accessorial.uuid}_${stop}`,
  }))
}

function getAccessorialsOptionsByStatus({
  accessorials,
  mode,
  status,
}: {
  accessorials: ShipperFavoriteAccessorial[]
  mode?: TransportationModeCode
  status: AccessorialModeStatus
}): AccessorialOption[] {
  return accessorials
    .filter((accessorial) => {
      if (mode) {
        return (
          accessorial.modes.find(
            (accessorialMode) => accessorialMode.key === mode
          )?.status === status
        )
      }
      return accessorial.modes.some(
        (accessorialMode) => accessorialMode.status === status
      )
    })
    .reduce((accumulator: AccessorialOption[], accessorial) => {
      return [...accumulator, ...mapShipperAccessorialToOptions(accessorial)]
    }, [])
}

export function useAccessorialsOptions(
  options?: { mode?: TransportationModeCode; usage?: AccountingObjectType },
  queryOptions?: UseShipperFavoriteAccessorialsQueryOptions
) {
  const {
    data: accessorials = [],
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useShipperFavoriteAccessorials(options, queryOptions)

  const { favoriteOptions, availableOptions } = useMemo(
    () => ({
      favoriteOptions: getAccessorialsOptionsByStatus({
        accessorials,
        mode: options?.mode,
        status: 'favorite',
      }),
      availableOptions: getAccessorialsOptionsByStatus({
        accessorials,
        mode: options?.mode,
        status: 'available',
      }),
    }),
    [accessorials, options?.mode]
  )

  const isEmpty = !favoriteOptions.length && !availableOptions.length

  return {
    isLoading: (isLoading || isRefetching) && isEmpty,
    isError: isError || isEmpty,
    favoriteOptions,
    availableOptions,
    onRefetch: () => {
      refetch()
    },
  }
}
