import { get, isEmpty } from 'lodash'
import type { PropsWithChildren, CSSProperties } from 'react'

import { createSummarySetup } from 'components/Summary'

import {
  ContactSummaryContactName,
  ContactSummaryContactPhoneNumber,
} from '../StopsManager'
import type { HazmatContact } from './HazmatInformation.types'

export type HazmatContactSummaryContextValue = HazmatContact | undefined

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<HazmatContactSummaryContextValue>({
    contextName: 'HazmatContactSummaryContext',
  })

export type HazmatContactSummaryProps = PropsWithChildren<{
  readonly contact?: HazmatContactSummaryContextValue
}>

export function HazmatContactSummary({
  contact,
  children,
}: HazmatContactSummaryProps) {
  return (
    <SummaryProvider value={contact}>
      {isEmpty(contact) ? null : children}
    </SummaryProvider>
  )
}

export function HazmatContactSummaryContactName(props: {
  readonly style?: CSSProperties
  readonly name?: string | null
}) {
  const { name, ...rest } = props

  return (
    <ContactSummaryContactName
      label="Contact name"
      data-testid="hazmat_contact_name-summary"
      {...rest}
      name={name}
    />
  )
}

function ContextBasedHazmatContactSummaryContactName(props: {
  readonly style?: CSSProperties
}) {
  const contact = useSummaryContext()

  return (
    <HazmatContactSummaryContactName
      {...props}
      name={get(contact, 'hazmat_contact_name') || '-'}
    />
  )
}

export function HazmatContactSummaryContactPhone(props: {
  readonly style?: CSSProperties
  readonly phoneNumber?: string | null
}) {
  const { phoneNumber, ...rest } = props

  return (
    <ContactSummaryContactPhoneNumber
      label="Phone number"
      data-testid="hazmat_contact_phone-summary"
      {...rest}
      phoneNumber={phoneNumber}
    />
  )
}

function ContextBasedHazmatContactSummaryContactPhone(props: {
  readonly style?: CSSProperties
}) {
  const contact = useSummaryContext()

  return (
    <HazmatContactSummaryContactPhone
      {...props}
      phoneNumber={get(contact, 'hazmat_phone_number')}
    />
  )
}

HazmatContactSummary.ContactName = ContextBasedHazmatContactSummaryContactName
HazmatContactSummary.ContactPhone = ContextBasedHazmatContactSummaryContactPhone

export { useSummaryContext as useHazmatContactSummaryContext }
