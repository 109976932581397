import numeral from 'numeral'

import type { Currency } from 'rfp/rfp.types'

import { getCurrencyPrefix } from './currency'

export default function formatCurrency(
  price?: string | number | null,
  cents = true,
  showSymbol = true,
  currency?: Currency
) {
  const format = cents ? '0,0.00' : '0,0'
  const formatted = numeral(price).format(format)

  if (showSymbol && currency) {
    return `${getCurrencyPrefix(currency)}${formatted}`
  }

  if (showSymbol) {
    return `$ ${formatted}`
  }

  return formatted
}

export function formatInputCurrency(rate: string | null) {
  if (!Number(rate)) {
    return ''
  }
  const numeric = Number(rate)
  if (numeric % 1 == 0 || numeric.toString().split('.')[1].length < 2) {
    return numeric.toFixed(2)
  }
  return numeric.toString()
}
