import { set } from 'lodash'
import * as Yup from 'yup'

import {
  clearTransientArrayErrors,
  clearTransientErrors,
  setTransientError,
} from './transient'
import type { Transient } from './transient'

type ValidationValues = {
  [key: string]: unknown
}

type ValidationErrorsMessages<Values> = {
  [K in keyof Values]?: Values[K] extends (infer U)[] // If the value is an array
    ? U extends object // If the array element is an object
      ? ValidationErrorsMessages<U>[] | string | string[] // Recurse into the object or allow string
      : string | string[] // Otherwise, allow string
    : Values[K] extends object // If the value is an object
      ? ValidationErrorsMessages<Values[K]> // Recurse into the object
      : string // Otherwise, allow string
}

export const transformValidationErrors = <Values extends ValidationValues>(
  error: Yup.ValidationError
): ValidationErrorsMessages<Values> => {
  return error.inner.reduce<ValidationErrorsMessages<Values>>(
    (errors, innerError) => {
      return set({ ...errors }, innerError.path!, innerError.message)
    },
    {}
  )
}

export const getValuesShapedErrors = async <Values extends ValidationValues>(
  schema: Yup.AnySchema,
  values: Values
): Promise<ValidationErrorsMessages<Values>> => {
  try {
    await schema.validate(values, { abortEarly: false })
  } catch (error: unknown) {
    if (error instanceof Yup.ValidationError) {
      return transformValidationErrors<Values>(error)
    }

    throw error
  }

  return {}
}

export const getTransientValuesShapedErrors = async (
  schema: Yup.AnySchema,
  values: Transient<unknown> | Array<Transient<unknown>>
): Promise<Transient<unknown> | Array<Transient<unknown>>> => {
  const defaultReturn = Array.isArray(values)
    ? ([] as Array<Transient<unknown>>)
    : ({} as Transient<unknown>)

  try {
    await schema.validate(values, { abortEarly: false })
  } catch (error: unknown) {
    if (error instanceof Yup.ValidationError) {
      const sanitizedValues = Array.isArray(values)
        ? clearTransientArrayErrors(values)
        : clearTransientErrors(values)

      const errors = error.inner.reduce((partialErrors, innerError) => {
        return setTransientError(
          partialErrors,

          innerError.path!,
          innerError.errors[0]
        )
      }, sanitizedValues)

      return errors
    }
    throw error
  }

  return defaultReturn
}

export const transformNanToUndefined = <T extends number>(
  value: T
): T | undefined => {
  return Number.isNaN(value) ? undefined : value
}

export function transformEmptyStringToNull<T extends string>(
  value: T
): T | null {
  return value === '' ? null : value
}

export function validateTransientWithSchema<T extends object>(
  schema: Yup.ObjectSchema<any>,
  object: Transient<T> | T
): [Transient<T> | T, boolean] {
  const cleanObject = clearTransientErrors(object)

  try {
    schema.validateSync(cleanObject, { abortEarly: false })

    const isValid = true

    return [cleanObject, isValid]
  } catch (caughtError: unknown) {
    const isValid = false

    if (!(caughtError instanceof Yup.ValidationError)) {
      throw caughtError
    }

    const objectWithErrors = caughtError.inner.reduce((accumulator, error) => {
      // Non-null assertion used on error.path because path is always defined at this level.
      return setTransientError(accumulator, error.path!, error.message)
    }, cleanObject)

    return [objectWithErrors, isValid]
  }
}
