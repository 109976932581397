import { Layout, Text } from '@loadsmart/miranda-react'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'
import { formatDate } from 'utils/dateUtils'

export type AppointmentDateProps = {
  readonly appointment: StopAppointment | null
  readonly stopDate: string | null
}

export default function AppointmentDateCard({
  appointment,
  stopDate,
}: AppointmentDateProps) {
  const date = appointment?.scheduled_date || appointment?.suggested_date
  const dateString = date?.start || stopDate

  if (!dateString) {
    return null
  }

  return (
    <Layout.Box
      borderRadius="border-radius-s"
      borderColor="color-border"
      borderWidth="border-thin"
      paddingY="spacing-1"
      padding="spacing-4"
    >
      <Layout.Stack gap="none" paddingY="none">
        <Text variant="body-lg-bold">{formatDate(dateString, 'DD')}</Text>
        <Text variant="body-sm" color="color-text-tertiary">
          {formatDate(dateString, 'MMM')}
        </Text>
      </Layout.Stack>
    </Layout.Box>
  )
}
