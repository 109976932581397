import type { Action } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import type { ThunkAction } from 'redux-thunk'

import type { RootState } from './rootReducer'
import rootReducer from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
  enhancers: [Sentry.createReduxEnhancer()],
})

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
