import type { TagProps } from '@loadsmart/miranda-react'
import { Layout, Tag, Text, Tooltip } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import type { Order } from 'orders/types'
import { formatDateUTC, isSameDay } from 'utils/dateUtils'

import { STATUS_SLUG_TO_LABEL } from './constants'

export function formatStatusTag(status: Order['status']) {
  return STATUS_SLUG_TO_LABEL[status].toUpperCase()
}

export function StatusTag({
  value,
  size = 'small',
}: {
  readonly value: Order['status']
  readonly size?: TagProps['size']
}) {
  const variants: Record<Order['status'], TagProps['variant']> = {
    open: 'success',
    planning: 'neutral',
    deleted: 'danger',
    closed: 'neutral',
    planned: 'neutral',
  }

  return (
    <Tag size={size} variant={variants[value]}>
      {formatStatusTag(value)}
    </Tag>
  )
}

export function OrderItemDetail<T>({
  value,
  label,
  // eslint-disable-next-line react/jsx-no-useless-fragment -- when removing the fragment, we get a typescript error
  formatter = (v) => <>{v}</>,
  visible = true,
}: {
  readonly value: T | null
  readonly label: string
  readonly formatter?: (val: Exclude<T, null | ''>) => ReactNode
  readonly visible?: boolean
}) {
  if (!visible) {
    return <div />
  }

  return (
    <Layout.Stack gap="spacing-1">
      <Text color="color-text-tertiary" variant="body-sm">
        {label}
      </Text>
      <Text aria-label={label} color="color-text-primary" variant="body-md">
        {value == null || value === ''
          ? '-'
          : formatter(value as Exclude<T, null | ''>)}
      </Text>
    </Layout.Stack>
  )
}

export function getFormattedDateWindow(props: {
  start: string | null
  end: string | null
}) {
  const fullDateTimeFormat = 'MMM D, YYYY @ h:mm A'
  const simpleDateFormat = 'MMM DD'
  const fullDateFormat = 'MMM DD, YYYY'

  let text = '-'
  let tooltip = null

  if (!props.start && props.end) {
    text = formatDateUTC(props.end, fullDateFormat)
    tooltip = formatDateUTC(props.end, fullDateTimeFormat)
  }

  if (props.start && !props.end) {
    text = formatDateUTC(props.start, fullDateFormat)
    tooltip = formatDateUTC(props.start, fullDateTimeFormat)
  }

  if (!!props.start && !!props.end) {
    if (isSameDay(props.start, props.end)) {
      text = formatDateUTC(props.start, fullDateFormat)
      tooltip = formatDateUTC(props.start, fullDateTimeFormat)
    } else {
      text = `${formatDateUTC(props.start, simpleDateFormat)} - ${formatDateUTC(props.end, simpleDateFormat)}`
      tooltip = `${formatDateUTC(
        props.start,
        fullDateTimeFormat
      )}\u2002-\u2002${formatDateUTC(props.end, fullDateTimeFormat)}`
    }
  }

  return {
    text,
    tooltip,
  }
}

export function DateWindow(props: {
  readonly start: string | null
  readonly end: string | null
}) {
  const { text, tooltip } = getFormattedDateWindow(props)

  if (!tooltip) {
    return (
      <Tooltip message={tooltip} trigger="hover" placement="top">
        <Text>{text}</Text>
      </Tooltip>
    )
  }

  return <Text>{text}</Text>
}
