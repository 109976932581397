import { getEnvVar, getEnvSubdomain, getAppEnv } from 'utils/envVars'

export const TALKJS_APP_ID = getEnvVar('TALKJS_APP_ID')

let internalSuffix = ''

if (['qa', 'staging', 'test', 'development'].includes(getAppEnv())) {
  internalSuffix = '-internal'
}

export const BASE_URL = `https://chat-backend${internalSuffix}.${getEnvSubdomain()}loadsmart.io/api`
