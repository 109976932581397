import { useEffect } from 'react'

import ScriptLoader from 'components/ScriptLoader'
import { BEAMER_PRODUCT_ID } from 'utils/constants'
import { getAppEnv } from 'utils/envVars'
import user from 'utils/user'

const BeamerLoader = () => {
  const env = getAppEnv().toLowerCase()

  const shouldRenderAndInitializeBeamer =
    env === 'production' && user.isLoggedIn && !user.isMTCustomerUser

  useEffect(() => {
    if (!shouldRenderAndInitializeBeamer) {
      return
    }

    const {
      user_name: name,
      user_email: email,
      user_date_joined: dateJoined,
      shipper_company: shipperCompany,
      shipper_is_active: shipperIsActive,
      is_managed_trans: isManagedTrans,
      user_category: userCategory,
      user_is_test: userIsTest,
      user_role: userRole,
      user_business: userBusiness,
      is_supervisor: isSupervisor,
      business: shipperBusiness,
    } = user.data

    window.beamer_config = {
      product_id: BEAMER_PRODUCT_ID,
      user_firstname: name,
      user_email: email,
      user_id: email,
      user_created_at: dateJoined
        ? Math.floor(new Date(dateJoined).getTime() / 1000)
        : undefined,
      user_shipper_company: shipperCompany,
      user_shipper_is_active: shipperIsActive,
      user_is_managed_trans: isManagedTrans,
      user_category: userCategory,
      user_date_joined: dateJoined,
      user_is_test: userIsTest,
      user_role: userRole,
      user_is_supervisor: isSupervisor,
      user_shipper_business: shipperBusiness,
      user_business: userBusiness,
    }
  }, [shouldRenderAndInitializeBeamer])

  if (!shouldRenderAndInitializeBeamer) {
    return null
  }

  return (
    <ScriptLoader
      src="https://app.getbeamer.com/js/beamer-embed.js"
      onLoad={() => undefined}
    />
  )
}

export default BeamerLoader
