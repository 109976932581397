import * as Yup from 'yup'

import { validateTransientWithSchema } from 'utils/yup'

import type { TransientFulfillment } from './Fulfillment'

const FulfillmentFormSchema = Yup.object().shape({
  order_identifier_type: Yup.string().required('Input method is required'),

  po_numbers: Yup.string().when('order_identifier_type', {
    is: 'po_number',
    then: Yup.string().trim().required('PO number(s) is required'),
  }),
  so_numbers: Yup.string().when('order_identifier_type', {
    is: 'so_number',
    then: Yup.string().trim().required('SO number(s) is required'),
  }),
  pickup_number: Yup.string(),
  customer_uuid: Yup.string().when('owner', {
    is: 'customer',
    then: Yup.string().trim().required('Customer is required'),
  }),
  supplier_uuid: Yup.string(),
  stops: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required('Date is required'),
      facility: Yup.object()
        .shape({
          uuid: Yup.string().required('Facility is required'),
        })
        .nullable()
        .required('Facility is required'),
    })
  ),
})

export function validate(
  fulfillment: TransientFulfillment
): [TransientFulfillment, boolean] {
  return validateTransientWithSchema<TransientFulfillment>(
    FulfillmentFormSchema,
    fulfillment
  )
}
