import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface State {
  settings: ShipperSettings
  meta: {
    isLoading: boolean
    prevState: ShipperSettings
  }
}

const initialState: State = {
  settings: {
    extra_fields: {
      full_truck_load: [],
      less_than_truckload: [],
      intermodal: [],
      drayage: [],
    } as ExtraFields,
  } as ShipperSettings,
  meta: {
    isLoading: false,
    prevState: {} as ShipperSettings,
  },
}

export const slice = createSlice({
  name: 'shipperSettings',
  initialState,
  reducers: {
    loadingStart(state) {
      state.meta.isLoading = true
    },
    loadingEnd(state) {
      state.meta.isLoading = false
    },
    updateStart(state) {
      state.meta.prevState = state.settings
    },
    updateEnd(state) {
      state.meta.prevState = {} as ShipperSettings
    },
    revertSettings(state) {
      state.settings = state.meta.prevState
    },
    updateSettings(state, action: PayloadAction<Partial<ShipperSettings>>) {
      state.settings = { ...state.settings, ...action.payload }
    },
    initializeEmptySettings(state) {
      state.settings = { ...state.settings, ...initialState.settings }
    },
    updateExtraField(state, action: PayloadAction<Partial<ExtraFields>>) {
      state.settings.extra_fields = {
        ...state.settings.extra_fields,
        ...action.payload,
      }
    },
  },
})

export default slice.reducer
