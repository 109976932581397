import { Card, Layout, LogoLoader } from '@loadsmart/miranda-react'

export function CardSkeleton({
  title,
  ariaLabel,
}: {
  readonly title: string
  readonly ariaLabel?: string
}) {
  return (
    <Card>
      <Card.Title>{title}</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Group justify="center">
          <LogoLoader size="32px" aria-label={ariaLabel} />
        </Layout.Group>
      </Card.Body>
    </Card>
  )
}
