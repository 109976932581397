import { identity } from '@loadsmart/utils-function'
import type { PropertyPath } from 'lodash'
import { get, isEmpty, isObject, isString, set } from 'lodash'

import toArray from 'utils/toArray'

type AttributeType = 'single' | 'collection'

type AttributeMapperMode = 'transform' | 'omit'

type AttributeMapperOptions = {
  onNull?: AttributeMapperMode
  onUndefined?: AttributeMapperMode
  onEmpty?: AttributeMapperMode
  onCondition?: (
    sourceValue: any,
    source: any,
    sourcePath: PropertyPath
  ) => AttributeMapperMode
}

export type AttributeMapper = {
  from: PropertyPath
  to: PropertyPath
  type?: AttributeType
  transform?: (sourceValue: any, source: any, sourcePath: PropertyPath) => any
  options?: AttributeMapperOptions
}

type TransformationMapper = AttributeMapper[]

const DEFAULT_OPTIONS: AttributeMapperOptions = {
  onNull: 'transform',
  onUndefined: 'transform',
}

const PROPERTY_NAME_OR_ARRAY_REGEX = /[[\].]/g
export function fromPath(path: PropertyPath): string[] {
  if (isEmpty(path)) {
    return []
  }

  if (Array.isArray(path)) {
    return path.map((p) => String(p))
  }

  return String(path).split(PROPERTY_NAME_OR_ARRAY_REGEX).filter(Boolean)
}

function shouldOmitUndefined(
  options: AttributeMapperOptions,
  sourceValue: any
): boolean {
  return sourceValue === undefined && options.onUndefined === 'omit'
}

function shouldOmitNull(
  options: AttributeMapperOptions,
  sourceValue: any
): boolean {
  return sourceValue === null && options.onNull === 'omit'
}

function shouldOmitEmpty(
  options: AttributeMapperOptions,
  sourceValue: any
): boolean {
  if (
    isObject(sourceValue) ||
    isString(sourceValue) ||
    Array.isArray(sourceValue)
  ) {
    return isEmpty(sourceValue) && options.onEmpty === 'omit'
  }

  return false
}

function shouldOmitOnCondition(
  options: AttributeMapperOptions,
  sourceValue: any,
  source: any,
  sourcePath: PropertyPath
): boolean {
  return (
    typeof options.onCondition === 'function' &&
    options.onCondition(sourceValue, source, sourcePath) === 'omit'
  )
}

/**
 * @constructor
 * @param mappers - mapper from frontend state attributes to backend payload attributes,
 * including the transformations.
 */
function AdapterFactory<
  Destination extends object = object,
  Source extends object = object,
>(mappers: TransformationMapper) {
  function adapter(source: Source) {
    const destination = {}

    for (const mapper of mappers) {
      const options = { ...DEFAULT_OPTIONS, ...(mapper.options || {}) }

      const transform = mapper.transform ?? identity
      const sourcePath = fromPath(mapper.from)
      const sourceValue = get(source, mapper.from)

      let destinationValue

      if (
        shouldOmitUndefined(options, sourceValue) ||
        shouldOmitNull(options, sourceValue) ||
        shouldOmitEmpty(options, sourceValue) ||
        shouldOmitOnCondition(options, sourceValue, source, sourcePath)
      ) {
        continue
      }

      if (mapper.type === 'collection') {
        destinationValue = toArray(sourceValue).map((item, index) => {
          return transform(item, source, [...sourcePath, String(index)])
        })
      } else {
        destinationValue = transform(sourceValue, source, sourcePath)
      }

      /**
       * idea: add support for dynamic `to` so we can, for example,
       * have a mapper for a path like `foo.0.bar`.
       */
      set(destination, mapper.to, destinationValue)
    }

    return destination as Destination
  }

  return adapter
}

export default AdapterFactory
