import { useMemo } from 'react'

import { useSettings } from 'contexts/settings'
import { useAccessorials, usePriceItemTypes } from 'hooks/useQuery'

export type AccessorialOption = { label: string; value: string }

export function getAccessorialsOptionsByStop({
  priceItemTypesEnabled,
  accessorials = [],
  priceItemTypes = [],
  stop,
}: {
  priceItemTypesEnabled: boolean
  accessorials: Accessorial[] | undefined
  priceItemTypes: PriceItemType[] | undefined
  stop: StopType | 'general'
}): AccessorialOption[] {
  if (priceItemTypesEnabled) {
    return priceItemTypes
      .filter((priceItemType) => {
        if (stop === 'general') {
          return !priceItemType.stops_relationship?.length
        }
        return (priceItemType.stops_relationship ?? []).includes(stop)
      })
      .map((priceItemType) => ({
        label: priceItemType.label,
        // We need to add the stop to the value to differentiate the checkbox for each stop (except for general)
        value:
          stop === 'general'
            ? priceItemType.uuid
            : `${priceItemType.uuid}_${stop}`,
      }))
  }
  return accessorials
    .filter((accessorial) => {
      if (stop === 'general') {
        return !accessorial.stop_type.length
      }
      return accessorial.stop_type.includes(stop)
    })
    .map((accessorial) => ({
      label: accessorial.name,
      value: accessorial.code,
    }))
}

export function useAccessorialsOptions(
  mode: TransportationModeCode,
  usage: AccountingObjectType
) {
  const {
    values: [priceItemTypesEnabled],
  } = useSettings(['flags.ENABLE_PRICE_ITEM_TYPES'])

  const { data: accessorials, isLoading: accessorialsLoading } =
    useAccessorials(mode, {
      refetchOnWindowFocus: false,
      enabled: !priceItemTypesEnabled,
    })
  const { data: priceItemTypes, isLoading: priceItemTypesLoading } =
    usePriceItemTypes(
      { modes: mode, usage },
      { enabled: !!priceItemTypesEnabled }
    )

  const { pickupOptions, deliveryOptions, generalOptions } = useMemo(
    () => ({
      pickupOptions: getAccessorialsOptionsByStop({
        priceItemTypesEnabled,
        accessorials,
        priceItemTypes,
        stop: 'pickup',
      }),
      deliveryOptions: getAccessorialsOptionsByStop({
        priceItemTypesEnabled,
        accessorials,
        priceItemTypes,
        stop: 'delivery',
      }),
      generalOptions: getAccessorialsOptionsByStop({
        priceItemTypesEnabled,
        accessorials,
        priceItemTypes,
        stop: 'general',
      }),
    }),
    [accessorials, priceItemTypes, priceItemTypesEnabled]
  )

  return {
    isLoading: priceItemTypesEnabled
      ? priceItemTypesLoading
      : accessorialsLoading,
    pickupOptions,
    deliveryOptions,
    generalOptions,
  }
}
