type Dict = { [key: string]: any }
interface WithMeta {
  meta: ObjectMeta
}

const reduceByKey = (
  array: Dict[] | undefined,
  key: string | number,
  extra?: Dict,
  callback?: (obj: Dict) => Dict
) => {
  return (array || []).reduce((result, currentValue) => {
    const resultKey = currentValue[key]
    let item = { ...currentValue, ...extra }
    if (callback) {
      item = callback(item)
    }
    result[resultKey] = item
    return result
  }, {})
}

export function addMeta<T extends WithMeta>(
  records: Record<number, Omit<T, 'meta'>>
): Record<number, T> {
  const copy = { ...records } as Record<number, T>
  for (const key in copy) {
    copy[key].meta = { isUpdating: false, error: null }
  }
  return copy
}

export default reduceByKey
