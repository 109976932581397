import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import type { useHazmatItemFormFieldProps } from './HazmatItemForm'
import { useHazmatItemFormField } from './HazmatItemForm'

export const HazmatItemShippingName = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: useHazmatItemFormFieldProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useHazmatItemFormField({ name: 'hazmat_shipping_name', hint })

  return (
    <Field required={required} style={style} {...fieldProps}>
      <Field.Label {...labelProps}>Proper shipping name</Field.Label>
      <TextField
        {...controlProps}
        placeholder="Enter proper shipping name"
        value={get(item, name, '')}
        onChange={(event) => {
          const {
            target: { value },
          } = event

          dispatch({
            type: 'SET_ITEM',
            payload: { index, changes: { [name]: value } },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
