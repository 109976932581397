import { isEmpty } from 'lodash'

import type {
  FacilityContactV2,
  FacilityDetailsV2,
  FacilityHoursOfOperationEntry,
  FacilityNoteV2,
} from 'services/facilities'
import { joinValidStrings } from 'utils/strings'

function mapFacilityNotesV2ToV1(
  notes: FacilityNoteV2[]
): FacilityDetails['notes'] {
  return notes.map((note) => ({
    uuid: String(note.id),
    description: note.note,
  }))
}

export function mapFacilityContactV2ToV1(contact: FacilityContactV2): Contact {
  return {
    uuid: contact.uuid,
    email: contact.email ?? null,
    name: contact.name,
    phone_number_extension: contact.extension,
    phone_number: contact.phone,
  }
}

function mapFacilityContacts(contacts: FacilityContactV2[]): Contact[] {
  return contacts.map(mapFacilityContactV2ToV1)
}

type DaysOfWeek = Omit<
  FacilityOperation,
  'mode' | 'opens' | 'closes' | 'holiday' | 'uuid'
>

function mapFacilityHoursOfOperation(
  hoursOfOperation: FacilityHoursOfOperationEntry[]
): FacilityOperation | null {
  if (isEmpty(hoursOfOperation)) {
    return null
  }

  const [firstEntry] = hoursOfOperation
  const mode =
    firstEntry.appointment_mode.toUpperCase() as FacilityOperation['mode']
  const opens = firstEntry.opens
  const closes = firstEntry.closes
  const uuid = firstEntry.uuid

  const daysOfWeek = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    holiday: false,
  }

  for (const entry of hoursOfOperation) {
    daysOfWeek[entry.day_of_week.toLowerCase() as keyof DaysOfWeek] = true
  }

  return { mode, opens, closes, uuid, ...daysOfWeek }
}

export function mapFacilityDetailsV2ToV1(
  facility: FacilityDetailsV2
): FacilityDetails {
  return {
    uuid: facility.uuid,
    city: facility.city,
    state: facility.state,
    country: facility.country,
    company_name: facility.name,
    zipcode: facility.zipcode,
    address: joinValidStrings(
      [facility.address, facility.address_details],
      ', '
    ),
    notes: mapFacilityNotesV2ToV1(facility.notes),
    contacts: mapFacilityContacts(facility.contacts),
    hours_of_operation: [],
    hours_of_operations: mapFacilityHoursOfOperation(
      facility.hours_of_operation
    ),
    warehouse_uuid: facility.warehouse_uuid ?? null,
    // Does not exists in the new API
    warehouse_name: null,
    timezone: null,
  }
}
