const cache: Record<string, unknown> = {}

interface CustomStorageEventData {
  key: string
  value: any
}

export const EVENT = 'custom:storage'

function parseJSON(value: string | null) {
  return value === 'undefined' ? undefined : JSON.parse(value ?? '')
}

function dispatchEvent(key: string, value: any) {
  const event = new CustomEvent<CustomStorageEventData>(EVENT, {
    detail: { key, value },
  })
  window.dispatchEvent(event)
}

export function get(key: string) {
  try {
    const cacheValue = cache[key]
    return cacheValue ?? parseJSON(localStorage.getItem(key) || 'null')
  } catch {
    return null
  }
}

export function set(key: string, value: any) {
  let isPersisted = false
  try {
    localStorage.setItem(key, JSON.stringify(value))
    cache[key] = undefined
    isPersisted = true
  } catch {
    cache[key] = value
  } finally {
    let actualValue = value
    const isTTLValue = ['expiry', 'value'].every(
      (item) => value && Object.keys(value).includes(item)
    )

    if (isTTLValue) {
      actualValue = value.value
    }

    dispatchEvent(key, actualValue)
  }

  return isPersisted
}

export function setWithTTL(key: string, value: any, ttlMs = 300) {
  const now = new Date()
  const expiry = now.getTime() + ttlMs
  const item = { value, expiry }

  set(key, item)
}

export function getWithTTL(key: string) {
  const item = get(key)
  if (item === null) {
    return null
  }

  const now = new Date()
  if (!item.expiry || now.getTime() > item.expiry) {
    remove(key)
    return null
  }

  return item.value
}

export function remove(key: string) {
  cache[key] = undefined
  localStorage.removeItem(key)
  dispatchEvent(key, undefined)
}
