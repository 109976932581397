import { CA_STATES, US_STATES, MX_STATES } from 'constants/states'
import { getOptionFromMap, toSelectOptions } from 'utils/selectOption'
import { createTransient } from 'utils/transient'

import type {
  TransientFacilityLocation,
  FacilityLocation,
} from './Facility.types'

const COUNTRIES_MAP = new Map<string, string>([
  ['USA', '🇺🇸 United States'] as const,
  ['CAN', '🇨🇦 Canada'] as const,
  ['MEX', '🇲🇽 Mexico'] as const,
])

export const COUNTRIES_OPTIONS = toSelectOptions(COUNTRIES_MAP)

const COUNTRIES_OPTIONS_MAP = new Map(
  COUNTRIES_OPTIONS.map((option) => [option.value, option] as const)
)

export function getCountryOption(country?: string | null) {
  return getOptionFromMap(country ?? null, COUNTRIES_OPTIONS_MAP)
}

export const USA_STATES_OPTIONS_MAP = new Map(
  US_STATES.map((option) => [option.value, toStateOption(option)] as const)
)

export const CAN_STATES_OPTIONS_MAP = new Map(
  CA_STATES.map((option) => [option.value, toStateOption(option)] as const)
)

export const MEX_STATES_OPTIONS_MAP = new Map(
  MX_STATES.map((option) => [option.value, toStateOption(option)] as const)
)

function toStateOption(option: { label: string; value: string }) {
  return {
    label: `${option.label} (${option.value})`,
    value: option.value,
  }
}

function toStatesOptions(
  options: {
    label: string
    value: string
  }[]
) {
  return options.map(toStateOption)
}

export function getStatesFor(country?: string | null): SelectOption[] {
  if (country === 'USA') {
    return toStatesOptions(US_STATES)
  }

  if (country === 'CAN') {
    return toStatesOptions(CA_STATES)
  }

  if (country === 'MEX') {
    return toStatesOptions(MX_STATES)
  }

  return []
}

export function getStateOption(country?: string | null, state?: string | null) {
  if (!country || !state) {
    return null
  }

  if (country === 'CAN') {
    return getOptionFromMap(state, CAN_STATES_OPTIONS_MAP)
  }

  if (country === 'MEX') {
    return getOptionFromMap(state, MEX_STATES_OPTIONS_MAP)
  }

  return getOptionFromMap(state, USA_STATES_OPTIONS_MAP)
}

function createFacilityV2(
  overrides?: Partial<FacilityLocation>
): FacilityLocation {
  return {
    name: '',
    address: '',
    address_details: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    external_id: '',
    ...overrides,
  }
}

export function createTransientFacilityLocation(
  overrides?: Partial<TransientFacilityLocation>
) {
  return createTransient(createFacilityV2(overrides))
}
