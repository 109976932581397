import { AnalyticsMenu } from './AnalyticsMenu'
import { AppsMenu } from './AppsMenu'
import { ManageMenu } from './ManageMenu'
import { PlanMenu } from './PlanMenu'
import { ProcurementMenu } from './ProcurementMenu'
import { QuotesMenu } from './QuotesMenu'

export const SideNavMenu = ({
  enablePriceMonitor,
  enableOrdersPage,
}: {
  readonly enablePriceMonitor: boolean
  readonly enableOrdersPage: boolean
}) => {
  return (
    <>
      <QuotesMenu />
      {enableOrdersPage && <PlanMenu />}
      <ProcurementMenu />
      <ManageMenu />
      <AnalyticsMenu enablePriceMonitor={enablePriceMonitor} />
      <AppsMenu />
    </>
  )
}
