import httpClient from 'utils/httpClient'

import type {
  ExtraChargeType,
  GenericApiSelectOptionWithCount,
} from '../../services/types'

export const fetchProposalsEquipmentTypes = async () => {
  const { data } = await httpClient.get(
    `proposals/equipment_types?allow_not_set=true&allow_counts=true`
  )
  return data
}

export const fetchAwardAcceptanceDeadlines = async (rfpId: number) => {
  const { data } = await httpClient.get(
    `proposals/award_acceptance_deadlines/?rfp_id=${rfpId}`
  )
  return data
}

export const fetchExtraChargeTypes = async () => {
  const { data } = await httpClient.get(`proposals/extra_charge_type`)
  return data as ExtraChargeType[]
}

export const fetchProposalCarrierInviteStates = async (
  rfpId: string | number
) => {
  const { data } = await httpClient.get(
    `proposals/carrier_invite_states?allow_not_set=true&allow_counts=true&rfp_id=${rfpId}`
  )
  return data as GenericApiSelectOptionWithCount[]
}

export const fetchProposalsBidStatus = async () => {
  const { data } = await httpClient.get(
    `proposals/bid_statuses?allow_not_set=true&allow_counts=true`
  )
  return data as GenericApiSelectOptionWithCount[]
}

export const fetchProposalsRateTypes = async () => {
  const { data } = await httpClient.get(
    `proposals/rfp/rate_types?allow_not_set=true&allow_counts=true`
  )
  return data as GenericApiSelectOptionWithCount[]
}

export const fetchProposalsTimePeriods = async () => {
  const { data } = await httpClient.get(
    `proposals/rfp/time_periods?allow_not_set=true&allow_counts=true`
  )
  return data as GenericApiSelectOptionWithCount[]
}

export const fetchProposalsModes = async () => {
  const { data } = await httpClient.get(
    `proposals/rfp/modes?allow_not_set=true&allow_counts=true`
  )
  return data as GenericApiSelectOptionWithCount[]
}
