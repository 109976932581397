import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  getFulfillmentsAsCustomer,
  getFulfillmentsAsSupplier,
} from 'fulfillments/fulfillment-service'
import { defaultTo } from 'lodash'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import {
  convertNumberFromSearchParam,
  convertNumberToSearchParam,
} from 'suppliers/common/usePaginatedSuppliersList.pagination'

import { useOffsetPagination } from 'hooks/usePagination'
import { generateUseSearchParamsPlugin } from 'utils/filters'

import { usePersistedFilters } from './usePersistedFilters'

export type GetFulfillmentsPagination = {
  page_size?: number
  page?: number
}

export function getDefaultFulfillmentsPaginationParams(
  overrides?: Partial<GetFulfillmentsPagination>
): GetFulfillmentsPagination {
  return {
    page_size: defaultTo(overrides?.page_size, DEFAULT_PAGE_SIZE),
    page: defaultTo(overrides?.page, DEFAULT_PAGE),
  }
}

export function mapPageSizeParam(pagination?: GetFulfillmentsPagination) {
  return {
    page_size: Number(defaultTo(pagination?.page_size, DEFAULT_PAGE_SIZE)),
  }
}

export function mapPageParam(pagination?: GetFulfillmentsPagination) {
  return { page: Number(defaultTo(pagination?.page, DEFAULT_PAGE)) }
}

export function mapParams(pagination?: GetFulfillmentsPagination) {
  return {
    ...mapPageSizeParam(pagination),
    ...mapPageParam(pagination),
  }
}

export const useFulfillmentsListPaginationQueryParamsPlugin =
  generateUseSearchParamsPlugin<GetFulfillmentsPagination>({
    page: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
    page_size: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
  })

export function useFulfillmentListAsCustomerFilters() {
  return usePersistedFilters<GetFulfillmentsPagination>(
    'fulfillments-as-customer-list',
    getDefaultFulfillmentsPaginationParams(),
    useFulfillmentsListPaginationQueryParamsPlugin
  )
}

export function useFulfillmentListAsCustomer() {
  const { clearFilters, filters, restoreFilters, setFilter, setFilters } =
    useFulfillmentListAsCustomerFilters()

  const query = useQuery<PaginatedResult<ListFulfillment>>({
    queryKey: ['getFulfillmentsAsCustomer', filters],
    queryFn: () => getFulfillmentsAsCustomer({ ...mapParams(filters) }),
  })

  const { ...pagination } = useOffsetPagination({
    resultsCount: defaultTo(query.data?.count, 0),
    pageSize: defaultTo(filters.page_size, DEFAULT_PAGE_SIZE),
    currentOffset:
      (defaultTo(filters.page, DEFAULT_PAGE) - 1) * DEFAULT_PAGE_SIZE,
  })

  const onPageChange = useCallback(
    (event: { page: number; pageSize: number }) => {
      const { page, pageSize } = event

      // Miranda's Pagination component uses a 0-index,
      // so we need to +1 the page
      // https://miranda.loadsmart.com/?path=/docs/components-pagination--docs
      setFilters({
        page_size: pageSize,
        page: page + 1,
      })
    },
    [setFilters]
  )

  const { data, isLoading } = query
  return {
    ...pagination,
    data,
    isLoading,
    clearFilters,
    filters,
    restoreFilters,
    onPageChange,
    setFilter,
    setFilters,
  }
}

export function useFulfillmentListAsSupplierFilters() {
  return usePersistedFilters<GetFulfillmentsPagination>(
    'fulfillments-as-supplier-list',
    getDefaultFulfillmentsPaginationParams(),
    useFulfillmentsListPaginationQueryParamsPlugin
  )
}

export function useFulfillmentListAsSupplier() {
  const { clearFilters, filters, restoreFilters, setFilter, setFilters } =
    useFulfillmentListAsSupplierFilters()

  const query = useQuery<PaginatedResult<ListFulfillment>>({
    queryKey: ['getFulfillmentsAsSupplier', filters],
    queryFn: () => getFulfillmentsAsSupplier({ ...mapParams(filters) }),
  })

  const { ...pagination } = useOffsetPagination({
    resultsCount: defaultTo(query.data?.count, 0),
    pageSize: defaultTo(filters.page_size, DEFAULT_PAGE_SIZE),
    currentOffset:
      (defaultTo(filters.page, DEFAULT_PAGE) - 1) * DEFAULT_PAGE_SIZE,
  })

  const onPageChange = useCallback(
    (event: { page: number; pageSize: number }) => {
      const { page, pageSize } = event

      // Miranda's Pagination component uses a 0-index,
      // so we need to +1 the page
      // https://miranda.loadsmart.com/?path=/docs/components-pagination--docs
      setFilters({
        page_size: pageSize,
        page: page + 1,
      })
    },
    [setFilters]
  )

  const { data, isLoading } = query
  return {
    ...pagination,
    data,
    isLoading,
    clearFilters,
    filters,
    restoreFilters,
    onPageChange,
    setFilter,
    setFilters,
  }
}
