import { useEventBasedDrawer } from '_shared_/hooks/useEventBasedDrawer'

import { CreateFacilityDrawer } from './CreateFacilityDrawer'

export function EventBasedCreateFacilityDrawer() {
  const { isOpen, data, close, onClosed } = useEventBasedDrawer(
    'sg:open-create-facility-form'
  )

  if (!data) {
    return null
  }

  return (
    <CreateFacilityDrawer
      {...data}
      isOpen={isOpen}
      onClose={close}
      onClosed={onClosed}
    />
  )
}
