import { Layout, Text } from '@loadsmart/miranda-react'

import AppointmentOpenDockLogo from './AppointmentOpendockLogo'
import { PendingScheduleText } from './AppointmentPendingSchedule'

type AppointmentMissingDateProps = Readonly<{
  warehouseUUID: string | null | undefined
}>

export default function AppointmentMissingDate({
  warehouseUUID,
}: AppointmentMissingDateProps) {
  return (
    <Layout.Stack gap="spacing-1" justify="center">
      <Layout.Group gap="spacing-1" align="center">
        <Text variant="body-md-bold" color="color-text-primary">
          Missing time
        </Text>
        <AppointmentOpenDockLogo warehouseUUID={warehouseUUID} />
      </Layout.Group>
      <PendingScheduleText />
    </Layout.Stack>
  )
}
