import type { TextProps } from '@loadsmart/miranda-react'
import { Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

export type FacilityExternalIDProps = Readonly<
  Pick<FacilityDetailsV2, 'external_id'> & Pick<TextProps, 'variant' | 'color'>
>

export function FacilityExternalID({
  external_id,
  variant = 'body-md',
  color = 'color-text-primary',
}: FacilityExternalIDProps) {
  if (!external_id) {
    return null
  }

  return (
    <Text variant={variant} color={color} data-testid="facility-external-id">
      External ID: {external_id}
    </Text>
  )
}
