import type { TabsProps } from '@loadsmart/miranda-react'
import { Tabs, Tag } from '@loadsmart/miranda-react'
import { defaultTo } from 'lodash'
import { useState } from 'react'

import { useListFulfillmentsContext } from './ListFulfillmentsContext'
import {
  ListFulfillmentsCustomerTable,
  ListFulfillmentsSupplierTable,
} from './ListFulfillmentsTable'

type Tabs = 'as-customer' | 'as-supplier'

export function ListFulfillmentsTabs() {
  const { isSupplier, asCustomerList, asSupplierList } =
    useListFulfillmentsContext()

  const [activeTab, setActiveTab] = useState<Tabs>('as-supplier')

  const onTabChange: TabsProps['onTabChange'] = (event) => {
    const tab = event.detail.value as unknown as Tabs

    setActiveTab(tab)
    if (tab === 'as-customer') {
      asCustomerList?.restoreFilters()
    } else {
      asSupplierList?.restoreFilters()
    }
  }

  if (isSupplier) {
    return (
      <Tabs activeTab={activeTab} onTabChange={onTabChange}>
        <Tabs.Tab name="as-customer">
          Owned by us{' '}
          <Tag size="small">{defaultTo(asCustomerList?.resultsCount, 0)}</Tag>
        </Tabs.Tab>
        <Tabs.Tab name="as-supplier">
          Owned by customer{' '}
          <Tag size="small">{defaultTo(asSupplierList?.resultsCount, 0)}</Tag>
        </Tabs.Tab>
        <Tabs.Panel forTab="as-customer">
          <ListFulfillmentsCustomerTable />
        </Tabs.Panel>
        <Tabs.Panel forTab="as-supplier">
          <ListFulfillmentsSupplierTable />
        </Tabs.Panel>
      </Tabs>
    )
  }

  return <ListFulfillmentsCustomerTable />
}
