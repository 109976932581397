import { Select } from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, Text } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties, ReactNode } from 'react'
import { useMemo } from 'react'

import { useShipperFacilities } from 'hooks/useQuery'
import type { FacilityDetailsV2 } from 'services/facilities'
import { mapFacilityDetailsV1ToV2 } from 'utils/adapters/facilityV1toV2'
import type { Transient } from 'utils/transient'

import {
  buildFacilitiesDatasource,
  CustomFacilityOption,
  FACILITY_ADAPTER,
} from './StopFacility.helpers'
import type { TransientStop } from './Stops.types'
import { useStopFacility } from './useStopFacility'
import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

const CUSTOM_COMPONENTS = {
  CreatableOption: function CreateFacilityOption() {
    return (
      <Select.CreatableOption>
        <Text variant="body-md-bold" color="color-accent-60">
          Add new facility
        </Text>
      </Select.CreatableOption>
    )
  },
  Option: CustomFacilityOption,
}

export const StopFacility = (props: {
  readonly label?: ReactNode
  readonly required?: FieldProps['required']
  readonly hint?: UseStopFormFieldProps['hint']
  readonly style?: CSSProperties
  readonly disabled?: boolean
}) => {
  const { label, required, hint, style, disabled } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    stop,
    dispatch,
  } = useStopFormField({ name: 'facility', hint })

  const facilitiesQuery = useShipperFacilities({}, { sort: 'company_name' })

  const { openCreateFacilityDrawer } = useStopFacility()

  const datasources = useMemo(
    () => [buildFacilitiesDatasource(facilitiesQuery.data)],
    [facilitiesQuery.data]
  )

  function getValue() {
    const facility = get<
      Transient<TransientStop>,
      string,
      FacilityDetailsV2 | null
    >(stop, name, null)

    if (facility) {
      return {
        label: FACILITY_ADAPTER.getLabel({
          company_name: facility.name,
          address: facility.address,
        }),
        value: FACILITY_ADAPTER.getKey(facility),
      } as Selectable
    }
    return null
  }

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>{label ?? `Stop #${index}`}</Field.Label>
      <Select
        {...controlProps}
        isValidNewOption
        disabled={disabled}
        placeholder="Select a facility"
        createOptionPosition="first"
        name={name}
        datasources={datasources}
        components={CUSTOM_COMPONENTS}
        value={getValue()}
        onCreate={openCreateFacilityDrawer}
        onChange={(event) => {
          const selectedFacility = get(
            event,
            'target.value',
            null
          ) as Facility | null

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: selectedFacility
                  ? {
                      ...mapFacilityDetailsV1ToV2(selectedFacility),
                      _type: 'facility',
                    }
                  : null,
                notes: null,
                contact: null,
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
