import { get, set } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { hasTransientError } from 'utils/transient'

import type { TransientFulfillment } from '../domain/Fulfillment'
import { createTransientFulfillment } from '../domain/Fulfillment'
import { validate } from '../domain/Fulfillment.validation'

export type FulfillmentFormContextValue = {
  fulfillment: TransientFulfillment
  setPartialFulfillment: (value: Partial<TransientFulfillment>) => void
  setFulfillment: Dispatch<SetStateAction<TransientFulfillment>>
}

export const FulfillmentFormContext = createContext<
  FulfillmentFormContextValue | undefined
>(undefined)

export function useFulfillmentFormContext() {
  const context = useContext(FulfillmentFormContext)

  if (context === undefined) {
    throw new Error(
      'useFulfillmentFormContext must be used within a FulfillmentFormContext.Provider'
    )
  }

  return context
}

export function FulfillmentFormProvider({
  children,
}: {
  readonly children: React.ReactNode
}) {
  const [fulfillment, setFulfillment] = useState<TransientFulfillment>(
    createTransientFulfillment()
  )

  const setPartialFulfillment = useCallback(
    (partialState: Partial<TransientFulfillment>) => {
      setFulfillment((currentFulfillment) => {
        const updatedFulfillment = Object.keys(partialState).reduce(
          (newFulfillment, path) => {
            return set(newFulfillment, path, get(partialState, path))
          },
          { ...currentFulfillment }
        )

        if (hasTransientError(updatedFulfillment)) {
          const [validatedFulfillment] = validate(updatedFulfillment)
          return validatedFulfillment
        }

        return updatedFulfillment
      })
    },
    [setFulfillment]
  )

  const value = useMemo(() => {
    return {
      fulfillment,
      setFulfillment,
      setPartialFulfillment,
    }
  }, [fulfillment, setPartialFulfillment])

  return (
    <FulfillmentFormContext.Provider value={value}>
      {children}
    </FulfillmentFormContext.Provider>
  )
}
