import type {
  MirandaChangeEvent,
  WCToggleGroup,
} from '@loadsmart/miranda-react'
import {
  Card,
  Field,
  Icon,
  Layout,
  ToggleGroup,
  Tooltip,
} from '@loadsmart/miranda-react'
import { useCreateFulfillmentForm } from 'fulfillments/create/hooks/useCreateFulfillmentForm'
import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'

import { useCurrentUser } from '_shared_/user/useCurrentUser'

import { FulfillmentCustomer } from './FulfillmentCustomer'
import { FulfillmentField } from './FulfillmentField'
import { FulfillmentSupplier } from './FulfillmentSupplier'

export const isSupplier = (shipper?: UserData['shipper']) => {
  if (!shipper) {
    return false
  }
  return shipper.is_supplier
}

const inputMethodOptions = [
  { label: 'Enter POs', value: 'po_number' },
  { label: 'Enter SOs', value: 'so_number' },
]

const ownershipOptions = [
  { label: 'Owned by us', value: 'us' },
  { label: 'Owned by costumer', value: 'customer' },
]

export function FulfillmentFreightInfo() {
  const { fulfillment, setPartialFulfillment } = useCreateFulfillmentForm()
  const { user } = useCurrentUser()

  function handleFreightOwnership(event: MirandaChangeEvent<WCToggleGroup>) {
    setPartialFulfillment({
      owner: event.target.value as TransientFulfillment['owner'],
    })
  }

  function handleInputMethod(event: MirandaChangeEvent<WCToggleGroup>) {
    setPartialFulfillment({
      order_identifier_type: event.target
        .value as TransientFulfillment['order_identifier_type'],
    })
  }

  return (
    <Card id="fulfillment-freight-info">
      <Card.Title>Freight information</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Container>
          <Layout.Row>
            {isSupplier(user?.shipper) && (
              <Layout.Column>
                <Field>
                  <Field.Label>
                    Freight ownership{' '}
                    <Tooltip
                      message={`Select who pays for the shipment. "Owned by us" means the supplier is responsible, while "Owned by customer" means the customer covers the freight costs.`}
                      trigger="hover"
                      placement="top"
                    >
                      <Icon name="question" color="color-text-tertiary" />
                    </Tooltip>
                  </Field.Label>
                  <ToggleGroup
                    name="owner"
                    type="single-strict"
                    options={ownershipOptions}
                    value={fulfillment.owner}
                    onChange={handleFreightOwnership}
                  />
                </Field>
              </Layout.Column>
            )}
            <Layout.Column>
              <Field>
                <Field.Label>
                  Order input method{' '}
                  <Tooltip
                    message={`Select how you'll input orders for this fulfillment: by entering purchase orders (POs), sales orders (SOs), or by selecting from existing orders.`}
                    trigger="hover"
                    placement="top"
                  >
                    <Icon name="question" color="color-text-tertiary" />
                  </Tooltip>
                </Field.Label>
                <ToggleGroup
                  name="order_identifier_type"
                  type="single-strict"
                  options={inputMethodOptions}
                  value={fulfillment.order_identifier_type}
                  onChange={handleInputMethod}
                />
              </Field>
            </Layout.Column>
          </Layout.Row>
          {fulfillment.owner === 'customer' && (
            <Layout.Row>
              <Layout.Column>
                <FulfillmentCustomer />
              </Layout.Column>
            </Layout.Row>
          )}
          <Layout.Row>
            {fulfillment.order_identifier_type === 'po_number' && (
              <Layout.Column>
                <FulfillmentField
                  name="po_numbers"
                  label="PO number(s)"
                  placeholder="Enter PO number(s)"
                  hint="Separate values with commas"
                  required
                />
              </Layout.Column>
            )}
            {fulfillment.order_identifier_type === 'so_number' && (
              <Layout.Column>
                <FulfillmentField
                  name="so_numbers"
                  label="SO number(s)"
                  placeholder="Enter SO number(s)"
                  hint="Separate values with commas"
                  required
                />
              </Layout.Column>
            )}
          </Layout.Row>
          {fulfillment.owner === 'us' && (
            <Layout.Row>
              <Layout.Column>
                <FulfillmentSupplier />
              </Layout.Column>
            </Layout.Row>
          )}
          <Layout.Row>
            <Layout.Column md="6">
              <FulfillmentField
                name="pickup_number"
                label="Pickup number"
                placeholder="Enter pickup number"
              />
            </Layout.Column>
          </Layout.Row>
        </Layout.Container>
      </Card.Body>
    </Card>
  )
}
