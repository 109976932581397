/**
 * Source: https://ask.fmcsa.dot.gov/euf/assets/mcmiscatalog/states.html
 */

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]

export const CA_STATES = [
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon Territory', value: 'YT' },
]

export const MX_STATES = [
  { label: 'Aguascalientes', value: 'AG' },
  { label: 'Baja California Norte', value: 'BN' },
  { label: 'Baja California Sur', value: 'BS' },
  { label: 'Coahuila', value: 'CH' },
  { label: 'Chihuahua', value: 'CI' },
  { label: 'Colima', value: 'CL' },
  { label: 'Campeche', value: 'CP' },
  { label: 'Chiapas', value: 'CS' },
  { label: 'Districto Federal', value: 'DF' },
  { label: 'Durango', value: 'DG' },
  { label: 'Guerrero', value: 'GE' },
  { label: 'Guanajuato', value: 'GJ' },
  { label: 'Hidalgo', value: 'HD' },
  { label: 'Jalisco', value: 'JA' },
  { label: 'Michoacan', value: 'MC' },
  { label: 'Morelos', value: 'MR' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Nayarit', value: 'NA' },
  { label: 'Nuevo Leon', value: 'NL' },
  { label: 'Oaxaca', value: 'OA' },
  { label: 'Puebla', value: 'PU' },
  { label: 'Queretaro', value: 'QE' },
  { label: 'Quintana Roo', value: 'QI' },
  { label: 'Sinaloa', value: 'SI' },
  { label: 'San Luis Potosi', value: 'SL' },
  { label: 'Sonora', value: 'SO' },
  { label: 'Tamaulipas', value: 'TA' },
  { label: 'Tabasco', value: 'TB' },
  { label: 'Tlaxcala', value: 'TL' },
  { label: 'Veracruz', value: 'VC' },
  { label: 'Yucatan', value: 'YU' },
  { label: 'Zacatecas', value: 'ZA' },
]

export const US_STATE_NAMES_BY_VALUE: Record<string, string> = US_STATES.reduce(
  (object, state) => ({ ...object, [state.value]: state.label }),
  {}
)

export const CA_STATE_NAMES_BY_VALUE: Record<string, string> = CA_STATES.reduce(
  (object, state) => ({ ...object, [state.value]: state.label }),
  {}
)

export const MX_STATE_NAMES_BY_VALUE: Record<string, string> = MX_STATES.reduce(
  (object, state) => ({ ...object, [state.value]: state.label }),
  {}
)
