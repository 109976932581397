import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type AlertTypes = 'success' | 'danger' | 'neutral' | 'warning'

export interface AlertMessage {
  closeable?: boolean
  containerId?: string
  description?: string
  id: string
  message: string
  type: AlertTypes
}
interface State {
  messages: Record<string, AlertMessage>
}

const initialState: State = {
  messages: {},
}

export const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<AlertMessage>) {
      state.messages[action.payload.id] = action.payload
    },
    deleteMessage(state, action: PayloadAction<string>) {
      delete state.messages[action.payload]
    },
  },
})

export default slice.reducer
