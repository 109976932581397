import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { FacilityDetailsV2 } from 'services/facilities'
import { saveFacility } from 'services/facilities'

import { buildFacilityPayload } from './Facility.helpers'
import type { TransientFacilityV2 } from './Facility.types'

export function useSaveFacility({
  facilityUUID,
  onSuccess,
  onError,
}: {
  facilityUUID: string
  onSuccess: (updatedFacility: FacilityDetailsV2) => void
  onError: (errors: string[]) => void
}) {
  const { mutate, isLoading } = useMutation<
    FacilityDetailsV2,
    AxiosError<{ errors?: string[] }>,
    TransientFacilityV2
  >({
    mutationFn: (facility: TransientFacilityV2) => {
      return saveFacility({
        facilityUUID,
        facility: buildFacilityPayload(facility),
      })
    },
    onSuccess,
    onError: (error) => {
      onError(error.response?.data?.errors ?? [])
    },
  })

  return { saveFacility: mutate, isSaving: isLoading }
}
