import { Layout } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { Button } from '@loadsmart/miranda-react'
import styled from 'styled-components'

export const Divider = styled.hr<{ enabled: boolean }>`
  border: 0;
  border-bottom: 1px solid;
  border-color: ${({ enabled }) =>
    enabled
      ? getToken('color-neutral-light')
      : getToken('color-neutral-lighter')};
  width: 100%;
`
export const LayoutEmptyStateGroup = styled(Layout.Group)`
  margin-top: 75px;
`

export const EmptyTitle = styled.div`
  background: ${getToken('color-neutral-lighter')};
  border-radius: 30px;
  height: 12px;
  width: 120px;
`

export const RateDetailsButton = styled(Button)`
  --m-button-padding-x: 0;
  --m-button-padding-y: 0;
`

export const UnwrappableGroup = styled(Layout.Group)`
  flex-wrap: nowrap;
`
