import { IconQuestionCircle } from '@loadsmart/icons'
import { Field, Layout, TextField, Tooltip } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import {
  HAZMAT_MIN_UN_NUMBER,
  HAZMAT_MAX_UN_NUMBER,
} from './HazmatInformation.constants'
import type { useHazmatItemFormFieldProps } from './HazmatItemForm'
import { useHazmatItemFormField } from './HazmatItemForm'

export const HazmatItemUNNumber = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: useHazmatItemFormFieldProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useHazmatItemFormField({ name: 'hazmat_un_number', hint })

  return (
    <Field required={required} style={style} {...fieldProps}>
      <Field.Label {...labelProps}>
        <Layout.Group gap="spacing-1" align="center">
          UN number
          <Tooltip
            message={
              <>
                UN numbers (or UN IDs) are four-digit numbers <br />
                ranging from {HAZMAT_MIN_UN_NUMBER}-{HAZMAT_MAX_UN_NUMBER} that
                identify dangerous <br />
                goods or hazardous substances.
              </>
            }
            trigger="hover"
            placement="right"
          >
            <IconQuestionCircle
              title={null}
              color={toCSSValue('color-text-tertiary')}
              width={16}
              height={16}
            />
          </Tooltip>
        </Layout.Group>
      </Field.Label>
      <TextField
        {...controlProps}
        placeholder="e.g. 1021"
        type="number"
        min={HAZMAT_MIN_UN_NUMBER}
        max={HAZMAT_MAX_UN_NUMBER}
        value={get(item, name, '')}
        inputMode="numeric"
        onChange={(event) => {
          const {
            target: { value },
          } = event

          dispatch({
            type: 'SET_ITEM',
            payload: { index, changes: { [name]: value } },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
