import type { GroupProps } from '@loadsmart/miranda-react'
import { EmptyState, Layout } from '@loadsmart/miranda-react'
import { useQueryClient } from 'react-query'

import ErrorIllustration from 'assets/illustrations/generic-error.svg'

type GeneralErrorStateProps = GroupProps & {
  readonly title?: string
  readonly description?: string
  readonly refreshButtonLabel?: string
}

/**
 * Error state component used in panels.
 * Includes an action button to refetch all queries.
 */
const GeneralErrorState = ({
  title = 'Something went wrong',
  description = "Sorry, something went wrong and we couldn't load this page, please refresh to try again.",
  refreshButtonLabel = 'Refresh the page',
  ...groupProps
}: GeneralErrorStateProps) => {
  const queryClient = useQueryClient()

  const onRefresh = () => {
    queryClient.refetchQueries()
  }

  return (
    <Layout.Group align="center" justify="center" {...groupProps}>
      <EmptyState data-testid="error-state">
        <EmptyState.Illustration>
          <img role="presentation" alt="" src={ErrorIllustration} />
        </EmptyState.Illustration>
        <EmptyState.Header>{title}</EmptyState.Header>
        <EmptyState.Message>{description}</EmptyState.Message>
        <EmptyState.Action onClick={onRefresh} variant="primary">
          {refreshButtonLabel}
        </EmptyState.Action>
      </EmptyState>
    </Layout.Group>
  )
}

export default GeneralErrorState
