import toArray from 'utils/toArray'

import type { RawShipmentChargeType, ShipmentChargeType } from '../types'

export const shipmentChargeTypeAdapter = (
  chargeType: RawShipmentChargeType
): ShipmentChargeType => {
  let suffix = ''

  if (chargeType.stop_relationship.includes('pickup')) {
    suffix = ' (Pickup)'
  } else if (chargeType.stop_relationship.includes('delivery')) {
    suffix = ' (Delivery)'
  }

  return {
    uuid: chargeType.uuid,
    code: chargeType.code,
    name: chargeType.name,
    nameWithSuffix: `${chargeType.name}${suffix}`,
    isAccessorial: chargeType.is_accessorial,
  }
}

export const shipmentChargeTypesAdapter = (
  charges?: RawShipmentChargeType[] | null
): ShipmentChargeType[] =>
  toArray(charges).map((charge) => shipmentChargeTypeAdapter(charge))
