import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import * as authService from 'services/auth'
import { parseError } from 'utils/errors'

export type ErrorPayload = { email: string[] }
export type PasswordResetRequestError = Partial<ErrorPayload> | string | null
export interface State {
  meta: {
    isLoading: boolean
    error: PasswordResetRequestError
  }
}

const initialState: State = {
  meta: { isLoading: false, error: null },
}

export const sendPasswordResetRequest = createAsyncThunk(
  'forgotPassword/sendPasswordResetRequest',
  async (
    {
      email,
      callback,
    }: {
      email: string
      callback: () => void
    },
    thunk
  ) => {
    try {
      await authService.sendPasswordResetRequest(email)
      callback()
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.data) {
        return thunk.rejectWithValue(error.response.data)
      }

      return thunk.rejectWithValue(parseError())
    }
  }
)

export const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendPasswordResetRequest.pending, (state) => {
      state.meta.isLoading = true
      state.meta.error = null
    })
    builder.addCase(sendPasswordResetRequest.fulfilled, (state) => {
      state.meta.isLoading = false
    })
    builder.addCase(sendPasswordResetRequest.rejected, (state, action) => {
      const error = action.payload as PasswordResetRequestError

      state.meta.error = error
      state.meta.isLoading = false
    })
  },
})

export default slice.reducer
