import { getCustomers } from 'fulfillments/fulfillment-service'
import { useQuery } from 'react-query'

import { FulfillmentSelect } from './FulfillmentSelect'

export function FulfillmentCustomer() {
  const { data, isLoading } = useQuery({
    queryKey: ['get-customers'],
    queryFn: getCustomers,
    cacheTime: 60000,
    select: (customerList) =>
      customerList.map((customer) => ({
        label: customer.name,
        value: customer.uuid,
      })),
  })

  return (
    <FulfillmentSelect
      name="customer_uuid"
      label="Customer"
      required={true}
      placeholder="Select customer"
      loading={isLoading}
      options={data}
    />
  )
}
