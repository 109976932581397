import { v4 as uuidv4 } from 'uuid'

import type { AppThunk } from 'app/store'
import { SUPPORT_EMAIL } from 'utils/constants'
import { assertHasDotAtEnd } from 'utils/strings'

import type { AlertMessage } from './reducer'
import { slice } from './reducer'

export const { addMessage, deleteMessage } = slice.actions

export const showMessage =
  (data: Omit<AlertMessage, 'id'>, timeout = 4000): AppThunk =>
  (dispatch) => {
    const message = { ...data, id: uuidv4() }
    const hasSupportEmail = `${message.description}${message.message}`.includes(
      SUPPORT_EMAIL
    )
    const descriptionWithDot =
      message.description && message.description.length > 0
        ? assertHasDotAtEnd(message.description)
        : ''

    if (message.type === 'danger' && !hasSupportEmail) {
      message.description = descriptionWithDot
      message.description += descriptionWithDot ? ' ' : ''
      message.description += `If you need help with an error, email ${SUPPORT_EMAIL} for assistance.`
    }

    dispatch(addMessage(message))

    setTimeout(() => {
      dispatch(deleteMessage(message.id))
    }, timeout)
  }
