import { Layout, SpinnerWheel } from '@loadsmart/miranda-react'

export function GlobalSpinner() {
  return (
    <Layout.Stack
      justify="center"
      align="center"
      style={{
        height: '100vh',
      }}
      data-testid="global-spinner"
    >
      <SpinnerWheel size="48" />
    </Layout.Stack>
  )
}
