import type { CSSProperties } from 'react'
import type { Column, ColumnInstance, TableState } from 'react-table'

export interface CustomTableState extends TableState {
  areAllItemsSelected: boolean
}

export type CustomColumn<T extends object> = {
  customWidth?: Column['width']
  style?: CSSProperties
} & Exclude<Column<T>, 'width'>

export type CustomColumnInstance = {
  customWidth?: ColumnInstance['width']
  style?: CSSProperties
} & ColumnInstance

export const ACTION_TYPES = {
  selectAllItems: 'selectAllItems',
  clearSelection: 'clearSelection',
}
