import type { AxiosError } from 'axios'
import { get } from 'lodash'
import { useMutation } from 'react-query'

import type { FacilityDetailsV2 } from 'services/facilities'
import { createFacility } from 'services/facilities'
import toArray from 'utils/toArray'

import { buildFacilityPayload } from './Facility.helpers'
import type { TransientFacilityV2 } from './Facility.types'

export const useCreateNewFacility = ({
  onSuccess,
  onError,
}: {
  onSuccess: (savedFacility: FacilityDetailsV2) => void
  onError: (errors: string[]) => void
}) => {
  const { mutate, isLoading } = useMutation<
    FacilityDetailsV2,
    AxiosError<{ errors?: string[] }>,
    TransientFacilityV2
  >({
    mutationFn: (facility: TransientFacilityV2) => {
      return createFacility({
        facility: buildFacilityPayload(facility),
      })
    },
    onSuccess,
    onError: (error) => {
      onError(toArray(get(error, 'response.data.errors')))
    },
  })

  return { createFacility: mutate, isSaving: isLoading }
}
