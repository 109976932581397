import { Dropdown } from '@loadsmart/loadsmart-ui'
import { Divider, Layout } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import type { InterpolationFunction } from 'styled-components'
import styled from 'styled-components'

export const StyledPageWIthSideNav = styled.div`
  display: flex;
  overflow: hidden;

  aside {
    a {
      text-decoration: none;
    }
  }
`

interface StyledMainWrapperProps {
  color?: string
  padding?: string | InterpolationFunction<any>
}

export const StyledMainWrapper = styled.div<StyledMainWrapperProps>`
  width: 100%;

  main {
    background-color: ${({ color, theme }) => color || theme.colors.white};
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: ${({ padding }) => padding || '24px 24px 0 24px'};
    width: 100%;
  }
`

export const StyledDropdownWrapper = styled(Layout.Box)`
  align-items: center;
  display: flex;

  div[role='menu'] {
    background-color: ${toCSSValue('color-background-primary')};
    max-height: unset;
    max-width: 300px;
    min-width: 200px;
    padding: 12px;
  }

  label > button {
    position: relative;
    right: 2px;
  }

  label:focus,
  label:focus-within,
  label.is-focused {
    color: ${toCSSValue('color-primary-60')};
  }
`

export const StyledDropdownItem = styled(Dropdown.Item)`
  background-color: ${toCSSValue('color-background-primary')};
  margin: 0;

  span:first-child {
    margin-right: 4px;
  }
`

export const StyledDivider = styled(Divider)`
  margin: 12px 0;
`
