import { normalize, schema } from 'normalizr'

import httpClient from 'utils/httpClient'

export interface FetchInviteResponse {
  id: number
  rfp: number
  invite_email_sent_at: string
  rate_type: string
  carrier: Carrier
}

export interface CarrierInviteNormalized {
  carrierInvites: Record<number, Omit<CarrierInvite, 'meta'>>
  carriers: Record<number, Carrier>
}

function getCarrierId(value: CarrierInvite | FetchInviteResponse): string {
  if (typeof value.carrier === 'number') {
    return value.carrier.toString()
  }

  return value.carrier.id.toString()
}

export function normalizeCarrierInviteList(
  data: FetchInviteResponse[]
): CarrierInviteNormalized {
  const normalized = normalize(data, [
    new schema.Entity<CarrierInvite>(
      'carrierInvites',
      {
        carrier: new schema.Entity<Carrier>('carriers', undefined, {
          processStrategy: (entity: Carrier, parent: FetchInviteResponse) => ({
            ...entity,
            invite: parent.id,
          }),
        }),
      },
      {
        idAttribute: getCarrierId,
        processStrategy: ({ invite_email_sent_at, ...entity }) => ({
          ...entity,
          email_sent_at: invite_email_sent_at ?? null,
        }),
      }
    ),
  ])

  // HACK: we have to convert to unknown before we can convert to CarrierInviteNormalized
  const entities = (normalized.entities || {}) as unknown

  return entities as CarrierInviteNormalized
}

export const fetchCarrierInvites = async (rfpId: string | number) => {
  const { data } = await httpClient.get(`/proposals/${rfpId}/carrier_invite`)
  return data
}

export const list = async (rfpId: string | number) => {
  const { data } = await httpClient.get(`/proposals/${rfpId}/carrier_invite`)
  return normalizeCarrierInviteList(data)
}

export const create = async (invite: Partial<CarrierInvite>) => {
  const { data } = await httpClient.post(
    `/proposals/${invite.rfp}/carrier_invite`,
    invite
  )
  return data
}

export const inviteAll = async ({ rfpId }: { rfpId: string | number }) => {
  const { data } = await httpClient.post(
    `/proposals/${rfpId}/carrier_invite/invite_all`
  )
  return data
}

export const remove = async (invite: CarrierInvite) => {
  const { data } = await httpClient.delete(
    `/proposals/${invite.rfp}/carrier_invite/${invite.id}`
  )
  return data
}

export const uninviteAll = async ({ rfpId }: { rfpId: number | string }) => {
  const { data } = await httpClient.delete(
    `/proposals/${rfpId}/carrier_invite/uninvite_all`
  )
  return data
}

export const updateAwardConfirmationLanes = async ({
  action,
  lanesIds,
  linkUUID,
}: {
  action: string
  lanesIds: Array<string | number>
  linkUUID: string
}) => {
  await httpClient.post(
    `/proposals/award-confirmation/${linkUUID}/update_lanes`,
    {
      action,
      lane_proposals_ids: lanesIds,
    }
  )
}

export const submitAwardConfirmation = async ({
  isAcceptAll,
  linkUUID,
}: {
  isAcceptAll: boolean
  linkUUID: string
}) => {
  await httpClient.post(
    `/proposals/award-confirmation/${linkUUID}/submit_award_confirmation`,
    {
      accept_all: isAcceptAll,
    }
  )
}
export const toggleAwardConfirmationAutoSubmit = async ({
  linkUUID,
}: {
  linkUUID: string
}) => {
  await httpClient.post(
    `/proposals/${linkUUID}/toggle_award_confirmation_auto_submit`
  )
}

export const submitCarrierInvite = async (
  linkUUID: string,
  rateType?: string
) => {
  const payload = { rate_type: rateType }
  return await httpClient.post(`/proposals/${linkUUID}/submit`, payload)
}

export const unsubmitCarrierInvite = async (linkUUID: string) => {
  return await httpClient.post(`/proposals/${linkUUID}/unsubmit`)
}

export const bulkCreateInvite = async ({
  rfpId,
  carriers,
}: {
  rfpId: string | number
  carriers: Array<string | number>
}) => {
  return await httpClient.post(
    `/proposals/${rfpId}/carrier_invite/batch_create`,
    { carriers }
  )
}

export const bulkDeleteInvite = async ({
  rfpId,
  invitesId,
}: {
  rfpId: string | number
  invitesId: Array<string | number>
}) => {
  return await httpClient.delete(
    `/proposals/${rfpId}/carrier_invite/bulk_delete`,
    {
      data: { carrier_invites: invitesId },
    }
  )
}

export const downloadAttachments = async (linkUUID: string) => {
  const { data } = await httpClient.get(
    `proposals/${linkUUID}/bulk_rfp_attachment_download`,
    {
      responseType: 'blob',
    }
  )
  return data
}
