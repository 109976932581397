import type {
  BaseFulfillment,
  TransientFulfillment,
} from 'fulfillments/domain/Fulfillment'
import { omit } from 'lodash'

import { formatISODate } from 'utils/dateUtils'

export function createFulfillmentAdapter(
  fulfillment: TransientFulfillment
): BaseFulfillment {
  const payload = omit(fulfillment, [
    '_metadata',
    'owner',
    'input_method',
    'po_numbers',
    'so_numbers',
    'stops',
  ]) as BaseFulfillment

  if (fulfillment.stops[0].facility?.uuid) {
    payload.pickup_facility_uuid = fulfillment.stops[0].facility?.uuid
    payload.pickup_ready_date = fulfillment.stops[0].date
      ? formatISODate(fulfillment.stops[0].date)
      : ''
  }
  if (fulfillment.stops[1].facility?.uuid) {
    payload.delivery_facility_uuid = fulfillment.stops[1].facility?.uuid
    payload.delivery_date = fulfillment.stops[1].date
      ? formatISODate(fulfillment.stops[1].date)
      : ''
  }

  return payload
}
