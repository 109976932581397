import { Field, Layout } from '@loadsmart/miranda-react'
import { NmfcSearchDrawer } from '@loadsmart/strangercodes'
import type { CSSProperties } from 'react'

import { searchNMFC } from 'services/newQuote'
import { noop } from 'utils/noop'

import { useShippingItemFormField } from './ShippingItemsForm'

export const ShippingItemNMFCSearch = (props: {
  readonly style?: CSSProperties
}) => {
  const { style } = props
  const { index, item, dispatch } = useShippingItemFormField({ name: '' })

  return (
    <Field style={style}>
      <Field.Label style={{ visibility: 'hidden' }}>
        Find NMFC and Class
      </Field.Label>
      <Layout.Group
        align="center"
        justify="flex-start"
        style={{ height: '40px' }}
      >
        <NmfcSearchDrawer
          initialSearchTerm={item.commodity ?? ''}
          fetch={searchNMFC}
          onSelect={(nmfcItem, searchTerm) => {
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  freight_class: nmfcItem.freightClass,
                  nmfc_code: nmfcItem.code,
                  commodity: searchTerm,
                },
              },
            })
          }}
          onDataLoaded={noop}
          onError={noop}
        />
      </Layout.Group>
    </Field>
  )
}
