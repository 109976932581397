import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { inputStyle } from './Input'

const Input = styled(MaskedInput)`
  ${inputStyle}
`

const quantityMask = createNumberMask({
  allowDecimal: false,
  allowLeadingZeroes: false,
  allowNegative: false,
  includeThousandsSeparator: true,
  integerLimit: 7,
  prefix: '',
  thousandsSeparatorSymbol: ',',
})

export default function Quantity({ ...props }: any) {
  return <Input mask={quantityMask} {...props} />
}
