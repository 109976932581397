import type { RootState } from 'app/rootReducer'

const sortPriority = {
  danger: 0,
  success: 1,
  neutral: 2,
  warning: 3,
}

export const messageListSelector = (state: RootState) =>
  Object.values(state.messages.messages).sort(
    (message) => sortPriority[message.type]
  )
