import type { WCLink } from '@loadsmart/miranda-react'
import { Link } from '@loadsmart/miranda-react'
import type { MouseEvent, ReactNode } from 'react'

export type LocalLinkProps = Readonly<{
  children: ReactNode
  elementId: string
}>

export const LocalLink = ({ elementId, children }: LocalLinkProps) => {
  const handleClick = (event: MouseEvent<WCLink>) => {
    event.preventDefault()

    const element = document.getElementById(elementId)

    element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Link href={`#${elementId}`} onClick={handleClick}>
      {children}
    </Link>
  )
}
