import httpClient from 'utils/httpClient'
import toArray from 'utils/toArray'

// this is a temporary solution and
// we should change it once the backend fixes this endpoint
const RETRIEVE_ENDPOINT = '/shippers/0'

const ENDPOINT = '/shippers'

export const partialUpdate = async (shipperData: Partial<Shipper>) => {
  const formData = new FormData()
  const headers = {} as any
  const keys = Object.keys(shipperData) as Array<keyof Shipper>
  let shouldUsePlainJSONInsteadOfFormData = false

  keys.forEach((key) => {
    formData.append(key, shipperData[key])
  })

  if (shipperData.logo_email_file) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  /**
   * FormData can't handle null and the only way to delete the logo
   * is to send null to the backend, hence I'm changing the data here.
   */
  if (shipperData.logo_email_file === '') {
    shouldUsePlainJSONInsteadOfFormData = true
    shipperData.logo_email_file = null
  }

  const { data } = await httpClient.patch(
    RETRIEVE_ENDPOINT,
    shouldUsePlainJSONInsteadOfFormData ? shipperData : formData,
    {
      headers,
    }
  )

  return data
}

export type ShipperData = {
  company_name: string
  email: string
  logo_email_url: string
  has_custom_logo_email: boolean
  name: string
  phone_number: string | null
  tax_id: string | null
  rate_con_legal_terms: string | null
}

export const get = async () => {
  const { data } = await httpClient.get<ShipperData>(RETRIEVE_ENDPOINT)
  return data
}

export const getShipperName = async (linkUUID: string) => {
  const { data } = await httpClient.get(`/proposals/${linkUUID}`)
  return data
}

export const getShipperCreditLine = async () => {
  const { data } = await httpClient.get(`/shippers/credit-line`)
  return data
}

export const getShipperCreditCards = async () => {
  const { data } = await httpClient.get(`/shippers/credit-cards`)
  return data
}

export const getShipperRfpQuota = async (rfpUuid: string) => {
  const { data } = await httpClient.get(`/shippers/quotas/rfps/${rfpUuid}`)
  return data
}

export const getCarrierInvitePerRfpQuota = async (rfpUuid: string) => {
  const { data } = await httpClient.get(
    `/shippers/quotas/carrier-invites/${rfpUuid}`
  )
  return data
}

export const createCreditCard = async (token: string) => {
  const { data } = await httpClient.post(`/shippers/credit-cards`, {
    token,
  })
  return data
}

export const getShipperQuotesQuota = async () => {
  const { data } = await httpClient.get('/shippers/quotas/quotes')
  return data
}

export const getBillingInfo = async () => {
  const { data } = await httpClient.get('/shippers/billing')
  return data
}

export const updateBillingInfo = async (formData: BillingInfoValues) => {
  const { data } = await httpClient.patch('/shippers/billing', formData)
  return data
}

export const createShipperLocation = async (
  formData: Partial<ShipperSettingsLocation>
) => {
  const { data } = await httpClient.post(`/shippers`, formData)
  return data
}

export const updateShipperLocation = async ({
  id,
  company_name,
  email,
}: Partial<ShipperSettingsLocation>) => {
  const { data } = await httpClient.patch(`/shippers/${id}`, {
    company_name,
    email,
  })
  return data
}

export const getShipperLocations = async () => {
  const { data } = await httpClient.get(
    '/business_identity_platform/shipper_locations'
  )
  return data
}

export const getShipperLocationsSettings = async (searchQuery?: string) => {
  const { data } = await httpClient.get(`${ENDPOINT}?query=${searchQuery}`)
  return data
}

export type ShipperFavoriteAcsParams = {
  mode?: TransportationModeCode | TransportationModeCode[]
  usage?: AccountingObjectType | AccountingObjectType[]
}

export const getShipperFavoriteAccessorials = async (
  params?: ShipperFavoriteAcsParams
) => {
  let queryParams = '?'
  const modes = toArray(params?.mode)
  const usages = toArray(params?.usage)

  modes.forEach((mode) => {
    queryParams = queryParams + `modes=${mode}&`
  })
  usages.forEach((usage) => {
    queryParams = queryParams + `journey=${usage}&`
  })

  queryParams = queryParams.slice(0, -1)

  const { data } = await httpClient.get(
    `${ENDPOINT}/settings/favorite-accessorials${queryParams}`
  )
  return data
}

export type ShipperFavoriteAcsPayload = {
  uuid: string
  modes: TransportationModeCode[]
}[]

export const postShipperFavoriteAccessorials = async (
  payload: ShipperFavoriteAcsPayload
) => {
  const { data } = await httpClient.post(
    `${ENDPOINT}/settings/favorite-accessorials`,
    payload
  )
  return data
}

export type BillingFile = {
  created: string
  id: string
  name: string
  type: 'w9' | 'other'
  updated: string
  url?: string
  error?: string
}

export const getShipperFiles = async () => {
  const { data } = await httpClient.get(`${ENDPOINT}/files`)
  return data as BillingFile[]
}

export type FilePayload = {
  file: File
  type: 'w9' | 'other'
}

export const postShipperFile = async (payload: FilePayload) => {
  const formData = new FormData()

  formData.append('file', payload.file)
  formData.append('type', payload.type)

  const { data } = await httpClient.post(`${ENDPOINT}/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return data
}

export const deleteShipperFile = async (removedFile: BillingFile) => {
  const { data } = await httpClient.delete(
    `${ENDPOINT}/files/${removedFile.id}`
  )
  return data
}
