import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useEffect, useMemo, useRef } from 'react'

type PolylineEventProps = {
  onClick?: (event: google.maps.MapMouseEvent) => void
  onDrag?: (event: google.maps.MapMouseEvent) => void
  onDragStart?: (event: google.maps.MapMouseEvent) => void
  onDragEnd?: (event: google.maps.MapMouseEvent) => void
  onMouseOver?: (event: google.maps.MapMouseEvent) => void
  onMouseOut?: (event: google.maps.MapMouseEvent) => void
}

type PolylineCustomProps = {
  /**
   * this is an encoded string for the path, will be decoded and used as a path
   */
  encodedPath?: string
}

export type PolylineProps = google.maps.PolylineOptions &
  PolylineEventProps &
  PolylineCustomProps

export function usePolyline(props: PolylineProps) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    encodedPath,
    ...polylineOptions
  } = props
  // This is here to avoid triggering the useEffect below when the callbacks change (which happen if the user didn't memoize them)
  const callbacks = useRef<
    Record<string, ((event: google.maps.MapMouseEvent) => void) | undefined>
  >({})
  callbacks.current = {
    ...callbacks.current,
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
  }

  const geometryLibrary = useMapsLibrary('geometry')

  const polyline = useRef(new google.maps.Polyline()).current
  // update PolylineOptions (note the dependencies aren't properly checked
  // here, we just assume that setOptions is smart enough to not waste a
  // lot of time updating values that didn't change)
  useMemo(() => {
    polyline.setOptions(polylineOptions)
  }, [polyline, polylineOptions])

  const map = useMap()

  useMemo(() => {
    if (!encodedPath || !geometryLibrary) {
      return
    }
    const path = geometryLibrary.encoding.decodePath(encodedPath)
    polyline.setPath(path)
  }, [polyline, encodedPath, geometryLibrary])

  useEffect(() => {
    if (!map) {
      throw new Error('<Polyline> has to be inside a Map component.')
    }

    polyline.setMap(map)

    return () => {
      polyline.setMap(null)
    }
  }, [map, polyline])

  // attach and re-attach event-handlers when any of the properties change
  useEffect(() => {
    // Add event listeners
    const gme = google.maps.event
    const events = [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut'],
    ]
    events.forEach(([eventName, eventCallback]) => {
      gme.addListener(
        polyline,
        eventName,
        (event: google.maps.MapMouseEvent) => {
          const callback = callbacks.current[eventCallback]
          if (callback) {
            callback(event)
          }
        }
      )
    })

    return () => {
      gme.clearInstanceListeners(polyline)
    }
  }, [polyline])

  return polyline
}
