import { useMemo } from 'react'
import type { PropsWithChildren } from 'react'

import { useLinearFeet } from 'components/HandlingUnitsManager/useLinearFeet'
import { createSummarySetup, SummaryLabelValue } from 'components/Summary'
import { numberFormatter } from 'utils/numbers'

import type { Measurable } from './ShippingItems.types'
import {
  calculateTotalWeight,
  calculateTotalVolume,
  calculateTotalDensity,
  formatWeight,
  formatVolume,
  formatLinearFeet,
} from './ShippingItems.utils'

export type DimensionsSummaryContextValue = Measurable[]

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<DimensionsSummaryContextValue>({
    contextName: 'DimensionsSummaryContext',
  })

export type DimensionsSummaryProps = {
  readonly measurables?: Measurable[]
}

/**
 * DimensionsSummary
 * can be used in:
 * - src/screens/Shipper/Shipments/Details/CommodityDetails.tsx
 * - src/screens/Shipper/Shipments/Details/components/CommodityDetails/TimelineShippingItems.tsx ?
 * - src/screens/Shipper/Shipments/Details/CommodityDetails.tsx (twice)
 * - src/components/CommodityDetails/CommodityDetails.tsx
 * - src/screens/Shipper/Shipments/NewShipment/Step1/DimensionsFooter.tsx
 * - src/screens/Shipper/Shipments/NewShipment/Step3/index.tsx
 */
export function DimensionsSummary({
  children,
  measurables,
}: PropsWithChildren<DimensionsSummaryProps>) {
  const contextValue = useMemo(() => measurables || [], [measurables])

  return <SummaryProvider value={contextValue}>{children}</SummaryProvider>
}

export function DimensionSummaryCounter(props: { readonly counter: number }) {
  const { counter } = props

  return (
    <SummaryLabelValue
      inline
      value={`${counter} ${counter === 1 ? 'item' : 'items'}`}
    />
  )
}

/**
 * Render how many measurables are being summarized.
 */
function ContextBasedDimensionsSummaryCounter() {
  const measurables = useSummaryContext()
  const counter = measurables.length

  return <DimensionSummaryCounter counter={counter} />
}

/**
 * Render the total weight (in lbs) of the measurables being summarized.
 */
export function DimensionsSummaryWeight(props: {
  readonly weight: string | number
}) {
  const { weight } = props

  return (
    <SummaryLabelValue
      inline
      label="Total weight"
      value={formatWeight(weight)}
    />
  )
}

/**
 * Render the total weight (in lbs) of the measurables being summarized.
 */
function ContextBasedDimensionsSummaryWeight() {
  const measurables = useSummaryContext()

  return <DimensionsSummaryWeight weight={calculateTotalWeight(measurables)} />
}

export function DimensionsSummaryVolume(props: {
  readonly volume: string | number
}) {
  const { volume } = props

  return <SummaryLabelValue inline label="Cube" value={formatVolume(volume)} />
}

/**
 * Render the total volume (in cubic ft) of the measurables being summarized.
 */
function ContextBasedDimensionsSummaryVolume() {
  const measurables = useSummaryContext()

  return <DimensionsSummaryVolume volume={calculateTotalVolume(measurables)} />
}

export function DimensionsSummaryDensity(props: {
  readonly density: string | number
}) {
  const { density } = props

  return (
    <SummaryLabelValue
      inline
      label="Density"
      value={`${numberFormatter(density, 2)} PCF`}
    />
  )
}

/**
 * Render the total density (totalWeight / totalVolume) of the measurables being summarized.
 * When totalVolume is 0, the density will be 0.
 */
function ContextBasedDimensionsSummaryDensity() {
  const measurables = useSummaryContext()

  return (
    <DimensionsSummaryDensity
      density={calculateTotalDensity(
        calculateTotalWeight(measurables),
        calculateTotalVolume(measurables)
      )}
    />
  )
}

export function DimensionsSummaryLinearFeet(props: {
  readonly linearFeet: string | number | null
}) {
  const { linearFeet } = props

  return (
    <SummaryLabelValue
      inline
      data-testid="dimension-summary-linear-feet"
      label="Linear Feet"
      value={linearFeet ? formatLinearFeet(linearFeet) : '-'}
    />
  )
}

/**
 * Call the `calculate-shipment-dimensions` endpoint to get the linear feet measurements.
 */
function ContextBasedDimensionsSummaryLinearFeet() {
  const measurables = useSummaryContext()
  const linearFeet = useLinearFeet(measurables)

  return <DimensionsSummaryLinearFeet linearFeet={linearFeet} />
}

DimensionsSummary.Counter = ContextBasedDimensionsSummaryCounter
DimensionsSummary.Weight = ContextBasedDimensionsSummaryWeight
DimensionsSummary.Volume = ContextBasedDimensionsSummaryVolume
DimensionsSummary.Density = ContextBasedDimensionsSummaryDensity
DimensionsSummary.LinearFeet = ContextBasedDimensionsSummaryLinearFeet
