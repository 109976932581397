import { customAlphabet } from 'nanoid'

/**
 * with this alphabet and id length we should support 38 ^ 5 (79235168) distinct ids
 */
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz_-1234567890', 5)

function generateId() {
  return `sg-${nanoid()}`
}

export default generateId
