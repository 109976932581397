import { Button, Icon } from '@loadsmart/miranda-react'

import { useFacilityDrawer } from '_shared_/hooks/useFacilityDrawer'
import type { FacilityDetailsV2 } from 'services/facilities'

type FacilityEditButtonProps = Nullable<Pick<FacilityDetailsV2, 'uuid'>>

export function FacilityEditButton({ uuid }: FacilityEditButtonProps) {
  const { openEditFacility } = useFacilityDrawer()

  if (!uuid) {
    return null
  }

  return (
    <Button
      variant="tertiary"
      leading={<Icon name="pencil" size="16" />}
      onClick={() => {
        openEditFacility({ facilityUUID: uuid })
      }}
    >
      Edit facility
    </Button>
  )
}
