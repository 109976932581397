import * as Sentry from '@sentry/react'
import type { ReactNode } from 'react'
import { createContext, useState, useContext, useMemo, useEffect } from 'react'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { getRetries } from '_shared_/utils/query'
import { useSettingsRetrieve } from 'hooks/useQuery'
import * as storage from 'utils/localStorage'
import logger from 'utils/logger'

import { getSettingsStorageKey } from './getStorageKey'

export interface SettingsProviderValue {
  settings: Record<string, any>
  flags: Record<string, boolean | null>
  isLoading: boolean
}

type SettingsReturn = { values: any[]; isLoading: boolean }

const providerValue: SettingsProviderValue = {
  settings: {},
  flags: {},
  isLoading: false,
}

const settingsTTL = 30 * 60000

export const settingsContext = createContext(providerValue)

export function SettingsProvider({
  children,
}: {
  readonly children: ReactNode
}) {
  const { user } = useCurrentUser()
  const [value, setValue] = useState<Omit<SettingsProviderValue, 'isLoading'>>({
    settings: {},
    flags: {},
  })

  const { key, item } = useMemo(() => {
    const storageKey = getSettingsStorageKey(user)
    const storageItem = storage.getWithTTL(storageKey)
    return { key: storageKey, item: storageItem }
  }, [user])

  const { isLoading, isError, error, data, refetch } = useSettingsRetrieve({
    retry: getRetries(10),
    refetchInterval: 300000, // 5 minutes
    refetchOnWindowFocus: false,
  })

  const accessToken = useMemo(() => {
    return user?.accessToken
  }, [user])

  useEffect(() => {
    refetch()
  }, [accessToken, refetch])

  useEffect(() => {
    if (!data) {
      return
    }
    logger.info('settings value', { data })
    storage.setWithTTL(key, data, settingsTTL)
    setValue({ ...data })
  }, [data, key])

  useEffect(() => {
    if (isError) {
      Sentry.captureException(error)
    }
  }, [isError, error])

  useEffect(() => {
    if (item) {
      setValue(item)
    }
  }, [item])

  const innerProviderValue = useMemo(
    () => ({
      ...value,
      isLoading,
    }),
    [value, isLoading]
  )

  return (
    <settingsContext.Provider value={innerProviderValue}>
      {children}
    </settingsContext.Provider>
  )
}

export function parseSettings(
  keys: string[],
  settings: Pick<SettingsProviderValue, 'settings' | 'flags'>
) {
  return keys.map((key) =>
    key.split('.').reduce((acc, curr) => {
      const obj = acc === null ? settings : (acc as any)

      if (!obj) {
        return acc
      }

      return curr in obj ? obj[curr] : null
    }, null)
  )
}

export function useSettings(keys: string[]): SettingsReturn {
  const { isLoading, ...settings } = useContext(settingsContext)
  const values = parseSettings(keys, settings)

  return { values, isLoading }
}
