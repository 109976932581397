export type URLSearchParamsInit =
  | string
  | string[][]
  | Record<string, string | string[] | number | number[] | null | undefined>
  | URLSearchParams

/**
 * Creates and returns a new URLSearchParams object.
 * @param init can be the same init argument that URLSearchParams receives but it can also receive an object with string arrays. { key: ['value1', 'value2', 'value3'] } or even numbers, null or undefined.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
 *
 * @returns URLSearchParams
 */
export function createSearchParams(
  init: URLSearchParamsInit = ''
): URLSearchParams {
  if (
    typeof init === 'string' ||
    Array.isArray(init) ||
    init instanceof URLSearchParams
  ) {
    return new URLSearchParams(init)
  }

  const paramsArray: string[][] = []

  for (const [key, value] of Object.entries(init)) {
    if (value === null || value === undefined) {
      continue
    }

    if (Array.isArray(value)) {
      for (const innerValue of value) {
        paramsArray.push([key, innerValue.toString()])
      }
    } else {
      paramsArray.push([key, value.toString()])
    }
  }

  return new URLSearchParams(paramsArray)
}

export type SortDirection = 'asc' | 'desc'
type SortDescriptor = {
  direction: SortDirection
  column: string
}

/**
 * sortParamToDescriptor can process an argument in the form `-status` or `status` and get the direction and the column
 * A minus symbol would indicate DESC sorting
 * @param sort
 * @returns
 */
export function sortParamToDescriptor(sort: string) {
  const direction = sort.startsWith('-') ? 'desc' : 'asc'
  const column = sort?.replace('-', '')

  return { direction, column } as SortDescriptor
}

/**
 * sortDescriptorToParam will turn a column and direction argument into a string in the form `-status` or `status`
 * that's ready for a URL search param
 * @param sort
 * @returns
 */
export function sortDescriptorToParam(sort: SortDescriptor) {
  const { direction, column } = sort

  return `${direction === 'desc' ? '-' : ''}${column}`
}
