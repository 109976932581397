import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'

import { CreateFulfillmentPage } from './create/CreateFulfillmentPage'
import { ViewFulfillmentPage } from './details/ViewFulfillmentPage'
import { ListFulfillmentsPage } from './list/ListFulfillmentsPage'

export const FulfillmentsRoutePaths = {
  Details: `${AppRoutes.Fulfillments}/:id`,
  List: `${AppRoutes.Fulfillments}/`,
  New: `${AppRoutes.Fulfillments}/new`,
}

export function FulfillmentsRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match
  return (
    <Switch>
      <Route path={`${path}/`} exact component={ListFulfillmentsPage} />
      <Route path={`${path}/new`} exact component={CreateFulfillmentPage} />
      <Route path={`${path}/:id`} exact component={ViewFulfillmentPage} />
    </Switch>
  )
}
