/**
 * This file contains styled components that are unstyled. They will inherit some styles from the parent component.
 *
 * This is useful when you want to add semantic to a @loadsmart/miranda-react component (eg. Text, Card.Title, Header.Title).
 */
import styled, { css } from 'styled-components'

const unstyled = css`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  margin-block: 0;
  margin-inline: 0;
  padding: 0;
`

export const H1 = styled.h1`
  ${unstyled}
`

export const H2 = styled.h2`
  ${unstyled}
`

export const H3 = styled.h3`
  ${unstyled}
`

export const Ul = styled.ul`
  list-style: none;
  ${unstyled}
`

export const P = styled.p`
  ${unstyled}
`
