import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const SHIPPER_SETTINGS_ENDPOINT = '/shippers/settings'

export const partialUpdate = async (params: Partial<ShipperSettings>) => {
  const { data } = await httpClient.patch(SHIPPER_SETTINGS_ENDPOINT, params)
  return data
}

export const get = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<ShipperSettings>(
    SHIPPER_SETTINGS_ENDPOINT,
    config
  )
  return data
}
