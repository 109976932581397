import type { ReactNode } from 'react'

import {
  StyledSideNavigationWrapper,
  StyledTopNavigationWrapper,
} from './styles'

export const SideNavCollapseWrapper = ({
  open,
  children,
}: {
  readonly open: boolean
  readonly children: ReactNode
}) => {
  return (
    <StyledSideNavigationWrapper $open={open}>
      {children}
    </StyledSideNavigationWrapper>
  )
}

export const TopNavCollapseWrapper = ({
  children,
}: {
  readonly children: ReactNode
}) => {
  return <StyledTopNavigationWrapper>{children}</StyledTopNavigationWrapper>
}
