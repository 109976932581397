import { IconSearch, IconSettings } from '@loadsmart/icons'
import {
  Button,
  Checkbox,
  Layout,
  Pagination,
  SpinnerWheel,
  Table,
  Text,
  TextField,
} from '@loadsmart/miranda-react'
import type {
  PaginationChangeEventDetails,
  WCCheckbox,
  WCTextField,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { defaultTo } from 'lodash'
import type { ChangeEvent, ReactNode } from 'react'
import { useCallback } from 'react'
import type {
  CellProps,
  HeaderProps,
  TableToggleRowsSelectedProps,
} from 'react-table'
import styled, { css } from 'styled-components'

import conditional, { whenProps } from 'utils/conditional'

import { plural } from '../../../utils/strings'
import type { CustomTableState } from './Table.types'
import type { TableSelectedRowsIDs } from './Table.utils'
import { getSelectionCount } from './Table.utils'

const PaginationStyled = styled(Pagination)`
  bottom: 0;
  position: sticky;
`

export interface TableDefaultPaginationProps {
  readonly isLoading: boolean
  readonly onPageChange?: (args: { page: number; pageSize: number }) => void
  readonly page?: number
  readonly pageSize: number
  readonly totalCount?: number
}

export function TableDefaultPagination({
  isLoading,
  onPageChange,
  page,
  pageSize,
  totalCount,
}: TableDefaultPaginationProps) {
  const handlePagination = useCallback(
    (event: CustomEvent<PaginationChangeEventDetails>) => {
      onPageChange?.(event.detail)
    },
    [onPageChange]
  )

  if (!totalCount) {
    return null
  }

  return (
    <PaginationStyled
      data-testid="table-pagination"
      count={totalCount}
      disabled={isLoading}
      onChange={handlePagination}
      page={page}
      pageSize={pageSize}
    />
  )
}

const BulkActions = styled(Layout.Box)`
  border-bottom: none;
  bottom: 55px;
  position: sticky;
`
export interface TableDefaultBulkActionsProps {
  readonly bulkActions?: ReactNode
  readonly count?: number
  readonly handleClearSelection: () => void
  readonly handleSelectAll: () => void
  readonly isAllRowsSelected: boolean
  readonly pageSize: number
  readonly selectAllEnabled?: boolean
  readonly selectedRows?: TableSelectedRowsIDs
}

export function TableDefaultBulkActions({
  bulkActions,
  count,
  handleClearSelection,
  handleSelectAll,
  isAllRowsSelected,
  pageSize,
  selectAllEnabled,
  selectedRows,
}: TableDefaultBulkActionsProps) {
  if (!selectedRows?.length || !count) {
    return null
  }

  const isSelectAllEnabled =
    selectAllEnabled && !isAllRowsSelected && count > pageSize

  return (
    <BulkActions
      data-testid="table-bulk-actions"
      aria-label="Table actions"
      backgroundColor="color-background-primary"
      borderColor="color-border"
      borderRadius="border-radius-none"
      borderWidth="border-thin"
    >
      <Layout.Group justify="space-between">
        <Layout.Group align="center" gap="spacing-6">
          <Text variant="body-md-bold" color="color-text-tertiary">
            {getSelectionCount(count, selectedRows)} selected
          </Text>
          <Layout.Group gap="spacing-2">
            <Button
              data-testid="table-bulk-actions-clear-selection"
              onClick={handleClearSelection}
              variant="tertiary"
            >
              Clear
            </Button>
            {isSelectAllEnabled && (
              <Button
                data-testid="table-bulk-actions-select-all"
                onClick={handleSelectAll}
                variant="tertiary"
              >
                Select all across pages
              </Button>
            )}
          </Layout.Group>
        </Layout.Group>
        <Layout.Group align="center" gap="spacing-2">
          {bulkActions}
        </Layout.Group>
      </Layout.Group>
    </BulkActions>
  )
}

export interface TableDefaultToolbarProps {
  readonly onOpenFilters?: () => void
  readonly onSearchInputChange?: (search: string) => void
  readonly searchFilter?: string
  readonly totalCount?: number
}

export function TableDefaultToolbar({
  onOpenFilters,
  onSearchInputChange,
  searchFilter,
  totalCount,
}: TableDefaultToolbarProps) {
  const handleSearchInputChange = useCallback(
    ({ target }: ChangeEvent<WCTextField>) => {
      const { value } = target
      onSearchInputChange?.(value)
    },
    [onSearchInputChange]
  )

  return (
    <Layout.Group align="center" justify="space-between">
      <Layout.Group>
        {onSearchInputChange && (
          <TextField
            data-testid="table-search-input"
            aria-label="search"
            leading={<IconSearch width={16} />}
            onInput={handleSearchInputChange}
            placeholder="Search"
            style={{ width: '250px' }}
            value={searchFilter}
          />
        )}
        {onOpenFilters && (
          <Button
            data-testid="table-open-filters"
            onClick={onOpenFilters}
            leading={<IconSettings width={16} />}
            variant="tertiary"
          >
            Filters
          </Button>
        )}
      </Layout.Group>
      <Text color="color-text-tertiary">
        {totalCount} {plural('result', defaultTo(totalCount, 0))}
      </Text>
    </Layout.Group>
  )
}

export type IndeterminateCheckboxProps = TableToggleRowsSelectedProps & {
  readonly disabled?: boolean
}
export function IndeterminateCheckbox({
  onChange,
  ...rest
}: IndeterminateCheckboxProps) {
  const handleChange = (e: ChangeEvent<WCCheckbox>) => {
    onChange?.({
      target: { checked: e.target.checked },
    } as unknown as ChangeEvent)
  }

  return <Checkbox {...rest} onChange={handleChange} />
}

export type SelectionHeaderProps = HeaderProps<any>
export function SelectionHeader({
  getToggleAllRowsSelectedProps,
  state,
}: SelectionHeaderProps) {
  const { areAllItemsSelected } = state as unknown as CustomTableState

  return (
    <IndeterminateCheckbox
      {...getToggleAllRowsSelectedProps()}
      aria-disabled={areAllItemsSelected}
      disabled={areAllItemsSelected}
    />
  )
}

export type SelectionCellProps = CellProps<any>
export function SelectionCell({ row, state }: SelectionCellProps) {
  const { areAllItemsSelected } = state as unknown as CustomTableState

  return (
    <IndeterminateCheckbox
      {...row.getToggleRowSelectedProps()}
      data-testid="table-selection-checkbox"
      aria-disabled={areAllItemsSelected}
      disabled={areAllItemsSelected}
    />
  )
}

export type TableRowProps = { $selected: boolean }
export const TableRow = styled(Table.Row)<TableRowProps>`
  ${({ $selected }) =>
    $selected &&
    css`
      background: ${toCSSValue('color-background-highlight')};
    `}

  cursor: ${conditional({
    pointer: whenProps({ onClick: Boolean }),
    default: whenProps({ onClick: undefined }),
  })};
`

export interface TableDefaultLoadingProps {
  readonly isLoading: boolean
}

export function TableDefaultLoading({ isLoading }: TableDefaultLoadingProps) {
  if (!isLoading) {
    return null
  }

  return (
    <Layout.Box
      backgroundColor="color-background-primary"
      borderColor="color-divider"
      borderRadius="border-radius-none"
      borderWidth="border-thin"
    >
      <Layout.Group
        data-testid="table-loading-state"
        align="center"
        justify="center"
        style={{ height: '100%' }}
      >
        <SpinnerWheel size="48px" title="Loading..." />
      </Layout.Group>
    </Layout.Box>
  )
}
