/**
 * Produces array for non-array value.
 * @param value - Value to be converted/returned.
 * @return Returns `value` itself if it is an array or
 * an array containing the provided `value`.
 */
const toArray = <T>(value: T | T[] | undefined | null): T[] => {
  if (value == null) {
    return []
  }

  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

export default toArray
