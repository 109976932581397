import {
  Layout,
  TextField,
  Icon,
  Dropdown,
  Field,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import type { CSSProperties } from 'react'
import styled from 'styled-components'

import { getCountryMetadataByAbbreviation } from 'components/FacilityDetails'
import { filterNotNullable } from 'utils/arrays'

import { CONTACT_COUNTRY_OPTIONS } from './FacilityContactsForm.helpers'
import type { TransientFacilityContact } from './FacilityContactsForm.types'

/**
 * TODO: This is a temporary component that mimics the input styles.
 * We should replace it with a miranda implementation when available.
 */
const CountryPrefixMenuButton = styled.button`
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${toCSSValue('spacing-2')};
  padding-block: 0;
  padding-inline: ${toCSSValue('spacing-2')};
  outline: none;
  width: 52px;
  height: 100%;
  border: 1px solid ${toCSSValue('color-border-input')};
  border-radius: 4px 0 0 4px;
  transition: border-color 0.35s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid ${toCSSValue('color-primary-60')};
  }

  .indicator {
    transition: transform 0.35s ease-in-out;
  }

  &[aria-expanded='true'] .indicator {
    transform: rotate(180deg);
  }
`

type CountryPrefixSelectProps = Pick<TransientFacilityContact, 'country'> & {
  readonly onChange: (country: string) => void
}

function CountryPrefixSelect({ country, onChange }: CountryPrefixSelectProps) {
  const selectedCountryMetadata = getCountryMetadataByAbbreviation(country)

  return (
    <Dropdown position="fixed" placement="bottom-start">
      <CountryPrefixMenuButton
        aria-label="Phone country"
        id="country_prefix_menu_trigger"
        aria-haspopup="true"
        tabIndex={0}
      >
        {selectedCountryMetadata && (
          <Icon
            size="16"
            name={selectedCountryMetadata.flagIconName}
            style={{ pointerEvents: 'none' }}
          />
        )}
        <Icon
          className="indicator"
          name="caret-down"
          size="12"
          color="color-text-tertiary"
        />
      </CountryPrefixMenuButton>
      <Dropdown.Menu>
        {CONTACT_COUNTRY_OPTIONS.map((countryOption) => {
          const countryPrefixLabel = `${countryOption.name} (${countryOption.phonePrefix})`

          return (
            <Dropdown.Item
              key={countryOption.value}
              aria-label={countryPrefixLabel}
              onClick={() => {
                onChange(countryOption.value)
              }}
            >
              <Layout.Group
                align="center"
                justify="space-between"
                style={{ width: '100%' }}
              >
                <Layout.Group align="center">
                  <Icon name={countryOption.flagIconName} size="16" />
                  {countryPrefixLabel}
                </Layout.Group>
                {country === countryOption.value && (
                  <Icon name="check" size="16" />
                )}
              </Layout.Group>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

type FacilityContactPhoneProps = {
  readonly contact: Pick<
    TransientFacilityContact,
    'country' | 'phone' | 'extension' | '_metadata'
  >
  readonly onChange: (
    changes: Pick<TransientFacilityContact, 'country' | 'phone' | 'extension'>
  ) => void
  readonly style?: CSSProperties
  readonly labelStyles?: CSSProperties
  readonly hintTestId?: string
}

export function FacilityContactPhone({
  contact,
  onChange,
  labelStyles,
  hintTestId,
  ...rest
}: FacilityContactPhoneProps) {
  const { country, phone, extension } = contact
  const { errors } = contact._metadata
  const phoneFieldErrors = filterNotNullable([errors.phone, errors.extension])

  return (
    <Field {...rest}>
      <Field.Label style={labelStyles} required>
        Phone number
      </Field.Label>
      <Layout.Group
        gap="none"
        style={{ flexWrap: 'nowrap', maxWidth: '210px', flex: 1 }}
      >
        <CountryPrefixSelect
          country={country}
          onChange={(selectedCountry) => {
            onChange({
              country: country === selectedCountry ? null : selectedCountry,
            })
          }}
        />
        <TextField
          style={{
            flex: '1 1 110px',
            minWidth: 0,
            // @ts-expect-error - overriding the border-radius CSS var until we have a proper miranda implementation
            '--m-border-radius-s': 0,
            marginInline: '-1px',
            zIndex: 1,
          }}
          type="tel"
          aria-label="Phone number"
          placeholder="Digits only"
          value={phone ?? ''}
          onChange={(event) => {
            onChange({ phone: event.target.value })
          }}
          maxLength={32}
          status={errors.phone ? 'danger' : 'default'}
        />
        <TextField
          style={{
            width: '50px',
            // @ts-expect-error - overriding the border-radius CSS var until we have a proper miranda implementation
            '--m-border-radius-s': '0 4px 4px 0',
            zIndex: errors.extension ? 2 : 0,
          }}
          type="tel"
          aria-label="Phone extension"
          placeholder="Ext."
          value={extension ?? ''}
          onChange={(event) => {
            onChange({ extension: event.target.value })
          }}
          maxLength={32}
          status={errors.extension ? 'danger' : 'default'}
        />
      </Layout.Group>
      {phoneFieldErrors.map((error) => (
        <Field.Hint key={error} status="danger" data-testid={hintTestId}>
          {error}
        </Field.Hint>
      ))}
    </Field>
  )
}
