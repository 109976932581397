import { pick, set } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import type { PropsWithChildren } from 'react'

import type { useCustomFormFieldProps } from 'hooks/useCustomFormField'
import { useCustomFormField } from 'hooks/useCustomFormField'
import { getTransientError } from 'utils/transient'

import {
  HazmatContactFormContext,
  useHazmatContactFormContext,
} from './HazmatContactForm.context'
import type {
  Action,
  HazmatContactFormContextValue,
} from './HazmatContactForm.context'
import { HAZMAT_CONTACT_KEYS } from './HazmatInformation.constants'
import type {
  HazmatContact,
  TransientHazmatContact,
} from './HazmatInformation.types'
import { createTransientHazmatContact } from './HazmatInformation.utils'

function createReducer() {
  return function reducer(state: TransientHazmatContact, action: Action) {
    switch (action.type) {
      case 'HANDLE_CHANGE': {
        const changes = action.payload

        return Object.keys(changes).reduce(
          (currentState, name) => {
            return set<TransientHazmatContact>(
              currentState,
              name,
              changes[name as keyof HazmatContact]
            )
          },
          { ...state }
        )
      }
      default:
        return state
    }
  }
}

export type HazmatContactFormProps = PropsWithChildren<{
  readonly contact?: TransientHazmatContact | null
  readonly onChange: (contact: TransientHazmatContact) => void
}>

export function HazmatContactForm({
  children,
  contact,
  onChange,
}: HazmatContactFormProps) {
  const reducerRef = useRef(createReducer())

  const dispatch = useCallback(
    (action: Action) => {
      const newHazmatContact = reducerRef.current(
        contact ?? createTransientHazmatContact(),
        action
      )

      // Make sure we only send the changes related to hazmat contact
      onChange(pick(newHazmatContact, HAZMAT_CONTACT_KEYS))
    },
    [contact, onChange]
  )

  const contextValue: HazmatContactFormContextValue = useMemo(() => {
    return [contact ?? createTransientHazmatContact(), dispatch]
  }, [dispatch, contact])

  return (
    <HazmatContactFormContext.Provider value={contextValue}>
      {children}
    </HazmatContactFormContext.Provider>
  )
}

export type useHazmatContactFormFieldProps = {
  name: string
  hint?: useCustomFormFieldProps['hint']
}

export function useHazmatContactFormField(
  props: useHazmatContactFormFieldProps
) {
  const { name, hint } = props

  const [contact, dispatch] = useHazmatContactFormContext()

  const error = getTransientError(contact, name)

  const formFieldProps = useCustomFormField({ name, hint, error })

  return {
    contact,
    dispatch,
    ...formFieldProps,
  }
}
