import { hasTransientError } from 'utils/transient'

import { validate } from '../../domain/Fulfillment.validation'
import { createFulfillmentAdapter } from '../createFulfillment.adapter'
import { useFulfillmentFormContext } from '../CreateFulfillmentContext'
import { useCreateFulfillment } from './useCreateFulfillment'

export function useCreateFulfillmentForm() {
  const { fulfillment, setFulfillment, setPartialFulfillment } =
    useFulfillmentFormContext()
  const { mutate: createFulfillment } = useCreateFulfillment()
  const hasErrors = hasTransientError(fulfillment)

  const submitForm = () => {
    const [validatedFulfillment] = validate(fulfillment)

    if (hasTransientError(validatedFulfillment)) {
      setFulfillment(validatedFulfillment)
      return
    }

    createFulfillment({
      payload: createFulfillmentAdapter(fulfillment),
    })
  }

  return {
    fulfillment,
    hasErrors,
    submitForm,
    setFulfillment,
    setPartialFulfillment,
  }
}
