import type { AxiosRequestConfig } from 'axios'
import { defaultTo } from 'lodash'
import type { Supplier } from 'suppliers/types'

import httpClient from 'utils/httpClient'
import { createSearchParams } from 'utils/searchParams'

import type { Customer } from './domain/Customer'
import type {
  BaseFulfillment,
  Fulfillment,
  ListFulfillment,
} from './domain/Fulfillment'

export async function getFulfillmentDetails(id: string): Promise<Fulfillment> {
  const { data } = await httpClient.get(`/orders/fulfillments/${id}`)
  return data
}

export async function getSuppliers(): Promise<Supplier[]> {
  const { data } = await httpClient.get(`/shippers/suppliers/list`)
  return data
}

export async function getCustomers(): Promise<Customer[]> {
  const { data } = await httpClient.get(`/shippers/customers/list`)
  return data
}

export type CreateFulfillmentParams = {
  payload: BaseFulfillment
  config?: AxiosRequestConfig
}
export function createFulfillment({
  payload,
  config,
}: CreateFulfillmentParams) {
  return httpClient.post('/orders/fulfillments/without-order', payload, config)
}

export const DEFAULT_PAGE_SIZE = 10
export const MAX_LIMIT = 500
export const DEFAULT_PAGE = 1

type ListFulfillmentsParams = {
  page_size: number
  page: number
}

export function getListFulfillmentsParams(
  params: Partial<ListFulfillmentsParams> = {}
): ListFulfillmentsParams {
  return {
    page_size: defaultTo(params?.page_size, DEFAULT_PAGE_SIZE),
    page: defaultTo(params?.page, DEFAULT_PAGE),
  }
}

export async function getFulfillmentsAsCustomer(
  params: ListFulfillmentsParams
): Promise<Omit<PaginatedResult<ListFulfillment>, 'total_count'>> {
  const { data } = await httpClient.get('/orders/fulfillments-as-customer', {
    params: createSearchParams(params),
  })
  return data
}

export async function getFulfillmentsAsSupplier(
  params: ListFulfillmentsParams
): Promise<Omit<PaginatedResult<ListFulfillment>, 'total_count'>> {
  const { data } = await httpClient.get('/orders/fulfillments-as-supplier', {
    params: createSearchParams(params),
  })
  return data
}
