import { pick, set } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import type { PropsWithChildren } from 'react'

import { FREIGHT_INFORMATION_KEYS } from './FreightInformation.constants'
import type {
  FreightInformation,
  TransientFreightInformation,
} from './FreightInformation.types'
import { FreightInformationFormContext } from './FreightInformationForm.context'
import type {
  Action,
  FreightInformationFormContextValue,
} from './FreightInformationForm.context'

function createReducer() {
  return function reducer(state: TransientFreightInformation, action: Action) {
    switch (action.type) {
      case 'HANDLE_CHANGE': {
        const changes = action.payload

        return Object.keys(changes).reduce(
          (currentState, name) => {
            return set<TransientFreightInformation>(
              currentState,
              name,
              changes[name as keyof FreightInformation]
            )
          },
          { ...state }
        )
      }
      default:
        return state
    }
  }
}

export type FreightInformationFormProps = PropsWithChildren<{
  readonly shipment: TransientFreightInformation
  readonly onChange: (shipment: TransientFreightInformation) => void
}>

export function FreightInformationForm({
  children,
  shipment,
  onChange,
}: FreightInformationFormProps) {
  const reducerRef = useRef(createReducer())

  const dispatch = useCallback(
    (action: Action) => {
      const newShipment = reducerRef.current(shipment, action)

      // Make sure we only send the changes related to freight information
      onChange(pick(newShipment, FREIGHT_INFORMATION_KEYS))
    },
    [shipment, onChange]
  )

  const contextValue: FreightInformationFormContextValue = useMemo(() => {
    return [shipment, dispatch]
  }, [dispatch, shipment])

  return (
    <FreightInformationFormContext.Provider value={contextValue}>
      {children}
    </FreightInformationFormContext.Provider>
  )
}
