import { Layout, SpinnerWheel } from '@loadsmart/miranda-react'

import GeneralErrorState from 'components/GeneralErrorState'

import { FulfillmentLaneCard } from './components/FulfillmentLaneCard'
import { FulfillmentNotesCard } from './components/FulfillmentNotesCard'
import { FulfillmentOrdersCard } from './components/FulfillmentOrdersCard'
import { FulfillmentOverviewCard } from './components/FulfillmentOverviewCard'
import { FulfillmentShipmentCard } from './components/FulfillmentShipmentCard'
import { useViewFulfillmentContext } from './ViewFulfillmentContext'

export function ViewFulfillmentDetails() {
  const { fulfillment, isErrorLoadingFulfillment, isLoadingFulfillment } =
    useViewFulfillmentContext()

  if (isLoadingFulfillment) {
    return (
      <Layout.Group align="center" justify="center" style={{ height: '100%' }}>
        <SpinnerWheel size="48px" aria-label="Loading" />
      </Layout.Group>
    )
  }

  if (isErrorLoadingFulfillment || (!isLoadingFulfillment && !fulfillment)) {
    return <GeneralErrorState />
  }

  return (
    <Layout.Stack>
      <Layout.Sidebar gap="spacing-4" sideWidth="390px">
        <Layout.Sidebar.Side padding="none">
          <Layout.Stack>
            <FulfillmentOverviewCard />
            <FulfillmentNotesCard />
          </Layout.Stack>
        </Layout.Sidebar.Side>

        <Layout.Sidebar.Content padding="none">
          <Layout.Stack>
            <FulfillmentShipmentCard />
            <FulfillmentLaneCard />
            <FulfillmentOrdersCard />
          </Layout.Stack>
        </Layout.Sidebar.Content>
      </Layout.Sidebar>
    </Layout.Stack>
  )
}
