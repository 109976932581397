import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'

import { formatFacilityContactPhone } from 'components/FacilityDetails'
import type { FacilityContactV2 } from 'services/facilities'

export const CONTACT_ADAPTER = {
  getKey: (contact: FacilityContactV2) => {
    return contact.uuid
  },
  getLabel: (contact: FacilityContactV2) => {
    return contact.name || formatFacilityContactPhone(contact)
  },
}

export function filterContacts(
  { query, regex }: { query: string; regex: RegExp },
  contacts?: FacilityContactV2[]
) {
  if (contacts == null || query.length < 3) {
    return contacts ?? []
  }

  return contacts.filter((contact) => {
    return (
      regex.test(contact.name ?? '') ||
      regex.test(formatFacilityContactPhone(contact) ?? '')
    )
  })
}

export function buildContactsDatasource(contacts?: FacilityContactV2[]) {
  return (): SelectDatasource<FacilityContactV2> => {
    return {
      type: 'contact',
      adapter: CONTACT_ADAPTER,
      fetch({ query, regex }) {
        return filterContacts({ query, regex }, contacts)
      },
    }
  }
}
