import { useIndexerContext } from 'contexts/indexer'
import type { useCustomFormFieldProps } from 'hooks/useCustomFormField'
import { useCustomFormField } from 'hooks/useCustomFormField'
import { getTransientError } from 'utils/transient'

import { useTransientCollectionFormContext } from './StopsForm'

export type UseStopFormFieldProps = {
  name: string
  hint?: useCustomFormFieldProps['hint']
}

export function useStopFormField(props: UseStopFormFieldProps) {
  const { name, hint } = props

  const [stops, dispatch] = useTransientCollectionFormContext()
  const index = useIndexerContext()
  const stop = stops[index]
  const error = getTransientError(stop, name)

  const formFieldProps = useCustomFormField({ index, name, hint, error })

  return {
    index,
    stop,
    dispatch,
    ...formFieldProps,
  }
}
