import { Text } from '@loadsmart/miranda-react'
import type { TextProps } from '@loadsmart/miranda-react'

export const NotSet = (props: Omit<TextProps, 'ref' | 'color'>) => {
  return (
    <Text color="color-text-placeholder" {...props}>
      Not set
    </Text>
  )
}
