import * as Sentry from '@sentry/react'
import { QueryClient, QueryCache } from 'react-query'
import { toast } from 'react-toastify'

export const queryCache = new QueryCache({
  // Global error handler
  // based on https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
  onError: (error, query) => {
    Sentry.captureException(error)
    if (typeof query.meta?.errorMessage === 'string') {
      toast.error(query.meta.errorMessage)
    }
  },
})

const queryClient = new QueryClient({
  queryCache,
})

export default queryClient
