import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'
import { Tag, Text } from '@loadsmart/miranda-react'
import type { Location } from '@loadsmart/react-location-select'
import { get, isEmpty } from 'lodash'
import type { PropsWithChildren } from 'react'

import { formatFacilityLocation } from 'components/FacilityDetails'
import { createSummarySetup, SummaryLabelValue } from 'components/Summary'
import type { FacilityContactV2 } from 'services/facilities'
import { capitalize, plural } from 'utils/strings'

import { ContactSummaryContactName } from './ContactSummary'
import { StopFacilityContactSummary } from './StopFacilityContactSummary'
import type { TransientStop } from './Stops.types'

export type StopSummaryContextValue = TransientStop

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<StopSummaryContextValue>({
    contextName: 'StopSummaryContext',
  })

export type StopSummaryProps = PropsWithChildren<{
  readonly stop: StopSummaryContextValue
}>

export function StopSummary({ stop, children }: StopSummaryProps) {
  return (
    <SummaryProvider value={stop}>
      {isEmpty(stop) ? null : children}
    </SummaryProvider>
  )
}

export function StopSummaryStopType(props: {
  readonly type?: StopType | null
}) {
  const { type } = props

  return (
    <Text
      variant="heading-sm-bold"
      color="color-text-secondary"
      data-testid="stop_type-summary"
    >
      {type ? capitalize(type) : '-'}
    </Text>
  )
}

function ContextBasedStopSummaryStopType() {
  const stop = useSummaryContext()
  const type = get(stop, 'stop_type')

  return <StopSummaryStopType type={type} />
}

function ContextBasedStopSummaryStopTypeTag() {
  const stop = useSummaryContext()
  const type = get(stop, 'stop_type')

  return <Tag size="small">{type}</Tag>
}

function ContextBasedStopSummaryErrorsCountTag({
  errorsCount,
}: {
  readonly errorsCount?: number
}) {
  if (!errorsCount) {
    return null
  }

  return (
    <Tag size="small" variant="danger">
      {`${errorsCount} ${plural('error', errorsCount)}`}
    </Tag>
  )
}

const DATE_FORMATTER = DateFormatHelper('MMM D, YYYY')

export function StopSummaryDate(props: {
  readonly simple?: boolean
  readonly stopType?: StopType | null
  readonly date?: string | null
}) {
  const { simple, date, stopType } = props

  if (simple && isEmpty(date)) {
    return null
  }

  return (
    <SummaryLabelValue
      label={
        simple ? null : `${[capitalize(stopType || ''), 'Date'].join(' ')}`
      }
      value={isEmpty(date) ? '-' : DATE_FORMATTER.format(DateHelper(date!))}
      data-testid="date-summary"
    />
  )
}

function ContextBasedStopSummaryDate(props: { readonly simple?: boolean }) {
  const stop = useSummaryContext()

  const date = get(stop, 'date')
  const stopType = get(stop, 'stop_type')

  return <StopSummaryDate {...props} date={date} stopType={stopType} />
}

export function StopSummaryContactName(props: {
  readonly name?: string | null
}) {
  return (
    <ContactSummaryContactName {...props} data-testid="contact_name-summary" />
  )
}

type ContactType = FacilityContactV2 | null | undefined

function ContextBasedStopSummaryContact() {
  const stop = useSummaryContext()
  const contact: ContactType = get(stop, 'contact')

  if (isEmpty(contact)) {
    return null
  }

  return <StopFacilityContactSummary contact={contact} />
}

export function StopSummaryInstructions(props: {
  readonly notes?: string | null
}) {
  const { notes } = props

  return (
    <SummaryLabelValue
      data-testid="facility_instructions-summary"
      label="Facility instructions"
      value={isEmpty(notes) ? '-' : notes}
    />
  )
}

function ContextBasedStopSummaryInstructions() {
  const stop = useSummaryContext()
  const notes = get(stop, 'notes')

  return <StopSummaryInstructions notes={notes} />
}

function StopSummaryLocation(props: { readonly location?: Location | null }) {
  const { location } = props

  if (!location) {
    return null
  }

  const address = formatFacilityLocation(location)

  return (
    <Text variant="body-md" color="color-text-secondary">
      {address}
    </Text>
  )
}

function ContextBasedStopLocation() {
  const stop = useSummaryContext()
  const location = get(stop, 'location')

  return <StopSummaryLocation location={location} />
}

StopSummary.Location = ContextBasedStopLocation
StopSummary.Contact = ContextBasedStopSummaryContact
StopSummary.Date = ContextBasedStopSummaryDate
StopSummary.Instructions = ContextBasedStopSummaryInstructions
StopSummary.StopType = ContextBasedStopSummaryStopType
StopSummary.StopTypeTag = ContextBasedStopSummaryStopTypeTag
StopSummary.ErrorsCountTag = ContextBasedStopSummaryErrorsCountTag

export { useSummaryContext as useStopSummaryContext }
