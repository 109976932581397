import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useOauth2 from '_shared_/hooks/useOauth2'

interface LoginThroughBIPProps {
  readonly getHomePage: () => string
}

const LoginThroughBIP = ({ getHomePage }: LoginThroughBIPProps) => {
  const { logIn, isLoggedIn } = useOauth2()
  const history = useHistory()

  useEffect(() => {
    async function redirect() {
      const isLogged = await isLoggedIn()
      if (isLogged) {
        history.push(getHomePage())
      } else {
        logIn()
      }
    }

    redirect()
  }, [history, getHomePage, isLoggedIn, logIn])

  return null
}

export default LoginThroughBIP
