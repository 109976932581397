import {
  Button,
  Card,
  Field,
  Icon,
  Layout,
  Tag,
  Text,
  TextField,
} from '@loadsmart/miranda-react'
import { useEffect } from 'react'

import { useTransientField } from '_shared_/hooks/useTransientField'
import { plural } from 'utils/strings'
import { getTransientErrorsCountFromArray } from 'utils/transient'

import {
  TransientCollectionForm,
  useTransientCollectionFormContext,
} from './FacilityContactForm.context'
import { FacilityContactPhone } from './FacilityContactPhone'
import {
  createTransientFacilityContact,
  isContactEmpty,
} from './FacilityContactsForm.helpers'
import type { TransientFacilityContact } from './FacilityContactsForm.types'

const RemoveFacilityContact = ({ index }: { readonly index: number }) => {
  const [contacts, dispatch] = useTransientCollectionFormContext()

  return (
    <Layout.Group
      align="center"
      style={{
        height: 'var(--m-global-height-default)',
        marginTop: index === 0 ? '25px' : '0',
      }}
    >
      <Button
        aria-label="Remove contact"
        title="Remove contact"
        variant="icon-secondary"
        disabled={index === contacts.length - 1}
        onClick={() => {
          dispatch({ type: 'REMOVE_ITEM', payload: { index } })
        }}
      >
        <Icon
          name="trash"
          size="20"
          style={{ '--m-icon-color': 'currentColor' }}
        />
      </Button>
    </Layout.Group>
  )
}

function FacilityContactRow({
  index,
  contact,
}: {
  readonly index: number
  readonly contact: TransientFacilityContact
}) {
  const [, dispatch] = useTransientCollectionFormContext()
  const { getFieldProps, getHintProps } = useTransientField(
    contact,
    `contact-${index}`
  )

  const fieldLabelStyles = { display: index === 0 ? 'block' : 'none' }

  return (
    <>
      <Field {...getFieldProps('name')}>
        <Field.Label
          id={`label-for-contact-name-${index}`}
          style={fieldLabelStyles}
          required
        >
          Contact name
        </Field.Label>
        <TextField
          aria-labelledby={`label-for-contact-name-${index}`}
          style={{ maxWidth: '180px', flex: 1 }}
          placeholder="Enter name"
          value={contact.name ?? ''}
          onChange={(event) => {
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: { name: event.target.value },
              },
            })
          }}
          maxLength={255}
        />
        <Field.Hint {...getHintProps('name')} />
      </Field>
      <FacilityContactPhone
        style={{ minWidth: 0, maxWidth: 240, flex: '1 1 210px' }}
        contact={contact}
        onChange={(changes) => {
          dispatch({
            type: 'SET_ITEM',
            payload: { index, changes },
          })
        }}
        labelStyles={fieldLabelStyles}
        hintTestId={getHintProps('phone')['data-testid']}
      />
      <Field {...getFieldProps('email')}>
        <Field.Label
          id={`label-for-contact-email-${index}`}
          style={fieldLabelStyles}
        >
          Email
        </Field.Label>
        <TextField
          aria-labelledby={`label-for-contact-email-${index}`}
          style={{ maxWidth: '180px', flex: 1 }}
          placeholder="Enter email"
          value={contact.email ?? ''}
          onChange={(event) => {
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: { email: event.target.value },
              },
            })
          }}
          maxLength={255}
        />
        <Field.Hint {...getHintProps('email')} />
      </Field>
    </>
  )
}

function FacilityContactsList() {
  const [contacts, dispatch] = useTransientCollectionFormContext()

  useEffect(() => {
    // We should always have an empty new line at the end of the list in case the user needs to add a new contact
    const lastContact = contacts[contacts.length - 1]
    const lastContactIsEmpty = lastContact && isContactEmpty(lastContact)

    if (!lastContactIsEmpty) {
      dispatch({ type: 'ADD_ITEM' })
    }
  }, [contacts, dispatch])

  return contacts.map((contact, index) => (
    <Layout.Group
      key={contact._metadata.id}
      align="flex-start"
      gap="spacing-3"
      data-testid="facility-contact-row"
    >
      <FacilityContactRow index={index} contact={contact} />
      <RemoveFacilityContact index={index} />
    </Layout.Group>
  ))
}

type FacilityContactsFormManagerProps = {
  readonly contacts: TransientFacilityContact[]
  readonly onChange: (contacts: TransientFacilityContact[]) => void
}

function FacilityContactsFormManager({
  contacts,
  onChange,
}: FacilityContactsFormManagerProps) {
  return (
    <TransientCollectionForm
      items={contacts}
      createItem={createTransientFacilityContact}
      onChange={onChange}
    >
      <Layout.Stack>
        <FacilityContactsList />
      </Layout.Stack>
    </TransientCollectionForm>
  )
}

export type FacilityContactsFormCardProps = {
  readonly collapsible?: boolean
  readonly initialCollapsed?: boolean
  readonly contacts: TransientFacilityContact[]
  readonly onChange: (contacts: TransientFacilityContact[]) => void
}

export function FacilityContactsFormCard({
  collapsible,
  contacts,
  initialCollapsed,
  onChange,
}: FacilityContactsFormCardProps) {
  const errorsCount = getTransientErrorsCountFromArray(contacts)

  return (
    <Card
      collapsible={collapsible}
      initialCollapsed={initialCollapsed}
      data-testid="facility-contacts-section"
    >
      <Card.Title role="heading" aria-level={2}>
        <Layout.Group gap="spacing-2" align="center">
          <Text variant="heading-sm-bold" color="color-text-secondary">
            Contacts
          </Text>
          {errorsCount > 0 && (
            <Tag variant="danger" size="small">
              &nbsp;{plural(`${errorsCount} error`, errorsCount)}
            </Tag>
          )}
        </Layout.Group>
      </Card.Title>
      <Card.Subtitle role="heading" aria-level={3}>
        Set primary contacts for shipment communication
      </Card.Subtitle>
      <Card.Divider />
      <Card.Body>
        <FacilityContactsFormManager contacts={contacts} onChange={onChange} />
      </Card.Body>
    </Card>
  )
}
