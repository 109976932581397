import { useMemo } from 'react'
import type { PropsWithChildren } from 'react'

import type { FreightInformation } from './FreightInformation.types'
import { FreightInformationSummaryProvider } from './FreightInformationSummary.context'
import type { FreightInformationSummaryContextValue } from './FreightInformationSummary.context'

export type FreightInformationSummaryProps = PropsWithChildren<{
  readonly shipment: FreightInformation
}>

export function FreightInformationSummary({
  children,
  shipment,
}: FreightInformationSummaryProps) {
  const contextValue: FreightInformationSummaryContextValue = useMemo(() => {
    return { shipment }
  }, [shipment])

  return (
    <FreightInformationSummaryProvider value={contextValue}>
      {children}
    </FreightInformationSummaryProvider>
  )
}
