import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import {
  STANDARD_PALLETS_LENGTH,
  STANDARD_PALLETS_WIDTH,
} from 'utils/constants'
import { fromSelectOption } from 'utils/selectOption'

import { PACKAGE_TYPES_OPTIONS } from './ShippingItems.constants'
import { useShippingItemFormField } from './ShippingItemsForm'
import type { UseShippingItemProps } from './ShippingItemsForm'
import {
  getPackageTypeOption,
  hasStandardPalletDimmensions,
} from './ShippingItemsForm.utils'

export const ShippingItemHandlingUnitType = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: UseShippingItemProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useShippingItemFormField({ name: 'package_type', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Handling unit type</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        placeholder="Select handling unit type"
        id={`field-${index}-${name}`}
        name={name}
        options={PACKAGE_TYPES_OPTIONS}
        value={getPackageTypeOption(get(item, name))}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event
          const value = fromSelectOption(option as SelectOption)

          // source: src/screens/Shipper/Shipments/NewShipment/Step1/PalletFields.tsx

          if (value === 'std_pallets') {
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  [name]: value,
                  length: String(STANDARD_PALLETS_LENGTH),
                  width: String(STANDARD_PALLETS_WIDTH),
                },
              },
            })

            return
          }

          // checking the previous unit_type value
          if (
            get(item, name) === 'std_pallets' &&
            hasStandardPalletDimmensions(item)
          ) {
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  [name]: value,
                  length: '',
                  width: '',
                },
              },
            })

            return
          }

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: value,
              },
            },
          })
        }}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
