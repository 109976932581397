import { useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'
import type { UseInfiniteQueryOptions } from 'react-query'

import type { AuditLogsParams, AuditLogsResponse } from 'services/auditLogs'
import { getAuditLogs } from 'services/auditLogs'

export const useAuditLogs = (
  { kind, uuid, related }: Pick<AuditLogsParams, 'kind' | 'uuid' | 'related'>,
  queryOptions?: UseInfiniteQueryOptions<AuditLogsResponse>
) => {
  const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<AuditLogsResponse>(
      ['auditLogs', kind, uuid, related],
      ({ pageParam = 1 }) =>
        getAuditLogs({ kind, uuid, related, page: pageParam }),
      {
        getNextPageParam: (lastPage) => {
          if (!lastPage.next) {
            return null
          }
          const nextURLSearchParams = new URLSearchParams(lastPage.next)
          const nextPageParam = nextURLSearchParams.get('page')

          return nextPageParam ? Number(nextPageParam) : null
        },
        refetchOnWindowFocus: false,
        ...queryOptions,
      }
    )

  const { count, logs } = useMemo(() => {
    if (!data?.pages?.length) {
      return { count: 0, logs: [] }
    }
    return {
      count: data.pages[0].count,
      logs: data.pages.map((page) => page.logs).flat(),
    }
  }, [data])

  return {
    count,
    logs,
    isLoading: status === 'loading',
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
