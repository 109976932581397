import type { CustomEventListener, CustomEventMap } from './custom-events.types'

export const subscribeEvent = <K extends keyof CustomEventMap>(
  eventName: K,
  listener: CustomEventListener<K>
) => {
  document.addEventListener(eventName, listener)
}

export const unsubscribeEvent = <K extends keyof CustomEventMap>(
  eventName: K,
  listener: CustomEventListener<K>
) => {
  document.removeEventListener(eventName, listener)
}

export const triggerEvent = <K extends keyof CustomEventMap>(
  eventName: K,
  data?: CustomEventMap[K]['detail']
) => {
  const event = new CustomEvent(eventName, { detail: data })

  document.dispatchEvent(event)
}
