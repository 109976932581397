export const downloadData = (
  data: ArrayBuffer,
  fileName: string,
  type: string
) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([data], { type }))
  link.setAttribute('download', fileName)
  document.body.append(link)
  link.click()
  link.remove()
}
