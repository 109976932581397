interface TransitionStyleOptions {
  property?: string
  timingFunction?: string
  duration?: string
}

/**
 * Add basic CSS style for default transition parameters
 * @returns {string}
 */
function transitionStyle(options?: TransitionStyleOptions): string {
  // TODO: add parameter to type of transition

  return `
    transition-property: ${
      options?.property ||
      'background, border-color, box-shadow, color, fill, left, stroke, transform, opacity'
    };
    transition-timing-function: ${
      options?.timingFunction || 'cubic-bezier(0.4, 0, 0.2, 1)'
    };
    transition-duration: ${options?.duration || '450ms'};
    transition-delay: 0ms;
  `
}

export default transitionStyle
