import { Banner, Button, Layout } from '@loadsmart/miranda-react'
import { FulfillmentLane } from 'fulfillments/components/FulfillmentLane'

import type { PageIndices } from 'components/PageIndex'
import PageIndex from 'components/PageIndex'

import { FulfillmentFreightInfo } from '../components/FulfillmentFreightInfo'
import { useCreateFulfillmentForm } from './hooks/useCreateFulfillmentForm'

const indexValues: PageIndices = [
  { id: 'fulfillment-freight-info', label: 'Freight Information' },
  { id: 'fulfillment-lane', label: 'Lane' },
]

export function CreateFulfillmentForm() {
  const { hasErrors, submitForm } = useCreateFulfillmentForm()

  return (
    <form>
      <Layout.Container>
        <Layout.Row columnGap="spacing-8">
          <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
            <Layout.Stack gap="spacing-6">
              {hasErrors && (
                <Banner variant="danger">
                  <Banner.Title>
                    Please fix the errors below to proceed
                  </Banner.Title>
                </Banner>
              )}
              <FulfillmentFreightInfo />
              <FulfillmentLane />
              <Layout.Group justify="flex-end">
                <Button type="button" variant="primary" onClick={submitForm}>
                  Create fulfillment
                </Button>
              </Layout.Group>
            </Layout.Stack>
          </Layout.Column>
          <Layout.Column sm="12" md="4" lg="3">
            <PageIndex values={indexValues} />
          </Layout.Column>
        </Layout.Row>
      </Layout.Container>
    </form>
  )
}
