interface FacilitiesAdapterProps {
  facilities: Array<Facility>
}

interface AdaptedFacility extends Facility {
  label: string
  value: string
  className: string
}

interface SelectableContact extends Contact {
  label: string
  value: string
}

export default function facilitiesAdapter({
  facilities,
}: FacilitiesAdapterProps): Array<AdaptedFacility> {
  if (!facilities || !Array.isArray(facilities)) {
    return []
  }

  const adaptedFacilities = facilities.map((facility) => ({
    ...facility,
    location: { lat: '', lng: '' },
    isFixture: true,
    isFacility: true,
    label: facility.company_name || facility.address || '',
    value: facility.uuid,
    className: 'facility__item',
  }))

  return adaptedFacilities
}

interface FacilityContactsAdapterProps {
  contacts: Array<Contact>
}

export function facilityContactsAdapter({
  contacts,
}: FacilityContactsAdapterProps): Array<SelectableContact> {
  if (!contacts || !Array.isArray(contacts)) {
    return []
  }

  const adaptedContacts = contacts.map((contact: Contact) => ({
    label: contact?.name || contact.phone_number,
    value: contact.uuid,
    name: contact?.name,
    phone_number: contact.phone_number,
    email: contact.email,
    uuid: contact.uuid,
  }))

  return adaptedContacts
}
