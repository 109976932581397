import { Icon, Layout, Text } from '@loadsmart/miranda-react'

import { FacilityContactSummary } from 'components/FacilityDetails'
import type { TransientStop } from 'components/StopsManager'

export function StopFacilityContactSummary({
  contact,
}: Pick<TransientStop, 'contact'>) {
  return (
    <FacilityContactSummary contact={contact}>
      <Layout.Group gap="spacing-1" align="center">
        <Icon name="telephone" color="color-text-primary" size="16" />
        <FacilityContactSummary.Name />
        <Text variant="body-md" color="color-text-secondary-inverted">
          ・
        </Text>
        <FacilityContactSummary.Phone />
        <Text variant="body-md" color="color-text-secondary-inverted">
          ・
        </Text>
        <FacilityContactSummary.Email />
      </Layout.Group>
    </FacilityContactSummary>
  )
}
