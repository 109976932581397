import type { TextProps } from '@loadsmart/miranda-react'
import { Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

export type FacilityNameProps = Readonly<
  Pick<FacilityDetailsV2, 'name'> & Pick<TextProps, 'variant' | 'color'>
>

export function FacilityName({
  name,
  variant = 'body-lg-bold',
  color = 'color-text-primary',
}: FacilityNameProps) {
  return (
    <Text variant={variant} color={color} data-testid="facility-name">
      {name}
    </Text>
  )
}
