import { Card } from '@loadsmart/miranda-react'

import { defaultEmptyString } from 'utils/strings'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'

export function FulfillmentNotesCard() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment) {
    return null
  }

  return (
    <Card data-testid="fulfillment-notes-card">
      <Card.Title>Notes</Card.Title>
      <Card.Divider />
      <Card.Body>{defaultEmptyString(fulfillment.notes)}</Card.Body>
    </Card>
  )
}
