import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const Toast = styled(ToastContainer)`
  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.success};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.dangerDark};
  }

  .Toastify__toast-body {
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    line-height: 18px;
    text-align: center;
    width: 100%;
  }
`
