import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { useSettings } from 'contexts/settings'
import { resolveTransportationMode } from 'utils/transportationMode'

export function useAccessorialsPermissionsByShipperBusiness() {
  const {
    values: [accessorialsForFbmEnabled],
  } = useSettings(['flags.ENABLE_ACCESSORIALS_FOR_FBM'])
  const { user } = useCurrentUser()
  const shipperBusiness = user?.shipper?.business ?? 'SAAS'

  const accessorialsEnabledByShipperBusiness: Record<ShipperBusiness, boolean> =
    {
      BROKERAGE: true,
      MTC: true,
      SAAS: true,
      FBM: Boolean(accessorialsForFbmEnabled),
    }
  const isEnabledByBusiness =
    accessorialsEnabledByShipperBusiness[shipperBusiness]

  return { isEnabledByBusiness }
}

export type useAccessorialsPermissionsParams = {
  mode: string
}

function useAccessorialsPermissions({
  mode,
}: useAccessorialsPermissionsParams) {
  const { isEnabledByBusiness } = useAccessorialsPermissionsByShipperBusiness()
  const {
    values: [
      imdlAccessorialsEnabled,
      drayAccessorialsEnabled,
      priceItemTypesEnabledFlag,
    ],
  } = useSettings([
    'flags.ENABLE_IMDL_ACCESSORIALS',
    'flags.ENABLE_DRAY_ACCESSORIALS',
    'flags.ENABLE_PRICE_ITEM_TYPES',
  ])
  const priceItemTypesEnabled = Boolean(priceItemTypesEnabledFlag)

  const accessorialsEnabledByMode: Partial<
    Record<TransportationModeCode, boolean>
  > = {
    full_truck_load: true,
    less_than_truckload: true,
    partial_truckload: true,
    intermodal: Boolean(imdlAccessorialsEnabled),
    drayage: Boolean(drayAccessorialsEnabled),
    expedited: true,
  }
  const modeCode = resolveTransportationMode(mode)
  const isEnabledByMode = Boolean(
    modeCode && accessorialsEnabledByMode[modeCode]
  )

  // Don't validate business type for LTL or PTL
  if (modeCode === 'less_than_truckload' || modeCode === 'partial_truckload') {
    return { accessorialsIsEnabled: isEnabledByMode, priceItemTypesEnabled }
  }

  return {
    accessorialsIsEnabled: isEnabledByMode && isEnabledByBusiness,
    priceItemTypesEnabled,
  }
}

export default useAccessorialsPermissions
