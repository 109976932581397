import { Layout, Tabs, Text } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'

import type { FacilityAccessorial } from 'services/facilities'
import { toTitleCase } from 'utils/strings'

import {
  getAccessorialsByModeAndStop,
  getAccessorialsValue,
} from './FacilityDetails.helpers'

type StopAccessorialsSummaryProps = {
  readonly stop: StopType
  readonly accessorials: FacilityAccessorial[]
}

function StopAccessorialsSummary({
  stop,
  accessorials,
}: StopAccessorialsSummaryProps) {
  return (
    <Layout.Sidebar sideWidth="82px">
      <Layout.Sidebar.Side padding="none">
        <Text color="color-text-tertiary">{toTitleCase(stop)}</Text>
      </Layout.Sidebar.Side>
      <Layout.Sidebar.Content padding="none">
        <Text
          data-testid={`${stop}-accessorials`}
          color={
            isEmpty(accessorials) ? 'color-text-disabled' : 'color-text-primary'
          }
        >
          {getAccessorialsValue(accessorials)}
        </Text>
      </Layout.Sidebar.Content>
    </Layout.Sidebar>
  )
}

type AccessorialsSummaryByStopProps = {
  readonly accessorialsByStop: {
    pickup: FacilityAccessorial[]
    delivery: FacilityAccessorial[]
  }
}

function AccessorialsSummaryByStop({
  accessorialsByStop,
}: AccessorialsSummaryByStopProps) {
  if (
    isEmpty(accessorialsByStop.pickup) &&
    isEmpty(accessorialsByStop.delivery)
  ) {
    return (
      <Layout.Group paddingT="spacing-8" justify="center">
        <Text color="color-text-disabled">No accessorials</Text>
      </Layout.Group>
    )
  }

  return (
    <Layout.Stack>
      <StopAccessorialsSummary
        stop="pickup"
        accessorials={accessorialsByStop.pickup}
      />
      <StopAccessorialsSummary
        stop="delivery"
        accessorials={accessorialsByStop.delivery}
      />
    </Layout.Stack>
  )
}

export type FacilityAccessorialsByModeProps = {
  readonly accessorials: FacilityAccessorial[]
}

export function FacilityAccessorialsByMode({
  accessorials,
}: FacilityAccessorialsByModeProps) {
  const accessorialsByModeAndStop = getAccessorialsByModeAndStop(accessorials)

  return (
    <Tabs>
      <Tabs.Tab name="FTL">FTL</Tabs.Tab>
      <Tabs.Tab name="LTL">LTL/PTL</Tabs.Tab>
      <Tabs.Panel forTab="FTL" data-testid="FTL-accessorials">
        <AccessorialsSummaryByStop
          accessorialsByStop={accessorialsByModeAndStop.FTL}
        />
      </Tabs.Panel>
      <Tabs.Panel forTab="LTL" data-testid="LTL-accessorials">
        <AccessorialsSummaryByStop
          accessorialsByStop={accessorialsByModeAndStop.LTL}
        />
      </Tabs.Panel>
    </Tabs>
  )
}
