import { getToken } from '@loadsmart/miranda-react/dist/tokens'
import styled from 'styled-components'

export const Backdrop = styled.div`
  align-items: center;
  background-color: rgba(
    ${getToken('color-background-secondary-inverted')},
    0.4
  );
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  dialog {
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 0;
    width: 620px;
  }

  img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-top: -1px;
  }
`
