import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import { mapRequestedAccessorialsToValue } from 'components/Accessorials'
import type { TransientStop } from 'components/StopsManager'
import useAccessorialsPermissions from 'hooks/useAccessorialsPermissions'
import type { FacilityAccessorial } from 'services/facilities'
import { arraysMatch } from 'utils/arrays'
import { areTransportationModesEqual } from 'utils/transportationMode'

import { useFreightInformationFormContext } from './FreightInformationForm.context'

function isMatchingFacilityAccessorial({
  accessorial,
  mode,
  stopType,
}: {
  accessorial: FacilityAccessorial
  mode: TransportationModeCode
  stopType: StopType | null
}) {
  // Filter by mode and stop type
  return (
    areTransportationModesEqual(accessorial.mode, mode) &&
    stopType === accessorial.stop
  )
}

export function useFacilitiesAccessorials({
  mode,
  stops,
}: {
  mode: TransportationModeCode
  stops: TransientStop[]
}) {
  const [_, dispatch] = useFreightInformationFormContext()

  const { priceItemTypesEnabled } = useAccessorialsPermissions({ mode })

  const selectedFacilitiesUuids = useRef<string[]>([])
  const [
    selectedStopsWithFacilityAccessorials,
    setSelectedStopsWithFacilityAccessorials,
  ] = useState<TransientStop[]>([])
  const [facilitiesAccessorialsCount, setFacilitiesAccessorialsCount] =
    useState(0)

  useEffect(() => {
    // Create a Map to avoid duplicates
    const stopsWithFacilityAccessorials: Map<string, TransientStop> = stops
      // Consider only the stops with stop_type and a facility with accessorials
      .filter((stop) => stop.stop_type && !isEmpty(stop.facility?.accessorials))
      // Map by facility uuid
      .reduce((map, stop) => {
        map.set(stop.facility!.uuid, stop)

        return map
      }, new Map<string, TransientStop>())
    const newSelectedFacilitiesUuids = Array.from(
      stopsWithFacilityAccessorials.keys()
    )

    // Make sure the new selectedFacilities are different from the previous ones to avoid unnecessary loops
    if (
      !arraysMatch(selectedFacilitiesUuids.current, newSelectedFacilitiesUuids)
    ) {
      selectedFacilitiesUuids.current = newSelectedFacilitiesUuids
      setSelectedStopsWithFacilityAccessorials(
        Array.from(stopsWithFacilityAccessorials.values())
      )
    }
  }, [stops])

  useEffect(() => {
    if (isEmpty(selectedStopsWithFacilityAccessorials)) {
      return
    }

    // Create a Set to avoid duplicates
    const facilitiesAccessorialsSet =
      selectedStopsWithFacilityAccessorials.reduce((set, stop) => {
        const facilityAccessorials = stop.facility!.accessorials.filter(
          (accessorial) =>
            isMatchingFacilityAccessorial({
              accessorial,
              mode,
              stopType: stop.stop_type,
            })
        )
        return new Set<FacilityAccessorial>([...set, ...facilityAccessorials])
      }, new Set<FacilityAccessorial>())
    const facilitiesAccessorials = Array.from(facilitiesAccessorialsSet)

    if (priceItemTypesEnabled) {
      const validPriceItemTypes = facilitiesAccessorials
        .filter(
          (facilityAccessorial) =>
            facilityAccessorial.uuid && facilityAccessorial.stop
        )
        .map((facilityAccessorial) => ({
          uuid: facilityAccessorial.uuid,
          stops_relationship: [facilityAccessorial.stop],
        }))

      if (!isEmpty(validPriceItemTypes)) {
        dispatch({
          type: 'HANDLE_CHANGE',
          payload: {
            requested_accessorials:
              mapRequestedAccessorialsToValue(validPriceItemTypes),
          },
        })
        setFacilitiesAccessorialsCount(validPriceItemTypes.length)
      }
    } else {
      const validAccessorials = facilitiesAccessorials
        .map(
          (facilityAccessorial) => facilityAccessorial.legacy_accessorial_code
        )
        .filter(Boolean)

      if (!isEmpty(validAccessorials)) {
        dispatch({
          type: 'HANDLE_CHANGE',
          payload: { accessorials: validAccessorials },
        })
        setFacilitiesAccessorialsCount(validAccessorials.length)
      }
    }
    // Ignoring "dispatch" from dependencies on purpose because it depends on "shipment" which should not be a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, selectedStopsWithFacilityAccessorials, priceItemTypesEnabled])

  return { facilitiesAccessorialsCount }
}
