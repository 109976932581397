import { MODES } from 'utils/constants'

import type { ShipmentChargesColumnKey } from './types'

export const LATEST_LOCATION_INDEX = 0
export const SHIPMENT_DETAILS_QUERY_KEY = 'retrieveShipmentDetails'
export const SHIPMENT_CHARGES_QUERY_KEY = 'shipmentCharges'
export const SHIPMENT_UPDATE_WS_EVENT = 'shipment-update:updated'
export const SHIPMENT_ACCOUNTING_SUMMARY_KEY = 'shipmentAccountingSummary'
export const SHIPMENT_CHARGE_TYPES_QUERY_KEY = 'shipmentChargeTypes'
export const SHIPMENT_INVOICES = 'shipmentInvoices'
export const SHIPMENT_REQUEST_INVOICE = 'requestCarrierInvoice'
export const SHIPMENT_TIMELINE_QUERY_KEY = 'shipmentTimeline'

export const LTL_MODES = [MODES.LTL, MODES.VLTL, MODES.PTL] as string[]

export enum EQUIPMENT_TYPES {
  FBE = 'FBE',
  DRV = 'DRV',
  RFR = 'RFR',
}

export const PRO_NUMBER_TOOLTIP =
  'A PRO (Progressive Rotating Order) is a unique number assigned by the carrier used to track the shipment.'

export const ALLOW_RATE_CHANGE_SHIPMENT_STATUS = [
  'booked',
  'en-route-pickup',
  'at-pickup',
  'en-route-delivery',
  'at-delivery',
  'accounting-review',
]

export enum FieldsByName {
  REF_NUMBER = 'shipper_ref_number',
  BOL_NUMBER = 'bol_number',
  PO_NUMBER = 'po_number',
  SO_NUMBER = 'so_number',
  PRO_NUMBER = 'carrier_ref_numbers.carrier_pro',
  TEMPERATURE = 'equipment_requirements.temperature',
  EQUIPMENT_TYPE = 'equipment_type',
  SUB_EQUIPMENT = 'equipment_subtypes',
  TARP_TYPE = 'tarp_type',
  TARP_SIZE = 'tarp_size',
  PICKUP_NUMBER = 'carrier_ref_numbers.pickup_number',
}

export const TABS = {
  DETAILS: {
    value: 'details',
    label: 'Details',
  },
  DOCS_AND_CHARGES: {
    value: 'docs-and-charges',
    label: 'Docs and Charges',
    subpath: '/charges',
  },
} as const

export const CHARGES_TABLE_COLUMNS: {
  key: ShipmentChargesColumnKey
  label: string
}[] = [
  { key: 'type', label: 'Type' },
  { key: 'stop', label: 'Stop' },
  { key: 'createdBy', label: 'Submitted by' },
  { key: 'createdAt', label: 'Submitted on' },
  { key: 'status', label: 'Status' },
  { key: 'total', label: 'Amount' },
]

export const CHARGE_DESCRIPTION_MAX_CHARS = 150
export const CHARGE_ACCEPTED_FILE_EXTENSIONS = ['.jpeg', '.jpg', '.png', '.pdf']
export const CHARGE_MAX_FILE_SIZE_IN_MB = 10
