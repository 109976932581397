import type {
  useCustomFormFieldProps,
  useCustomFormFieldResult,
} from 'hooks/useCustomFormField'
import { useCustomFormField } from 'hooks/useCustomFormField'
import { getTransientError } from 'utils/transient'

import type {
  FulfillmentForm,
  TransientFulfillment,
} from '../../domain/Fulfillment'

type useFulfillmentFieldProps = useCustomFormFieldProps & {
  name: keyof Omit<FulfillmentForm, 'handling_units'>
  meta: Omit<TransientFulfillment['_metadata'], 'handling_units'>
}

export function useFulfillmentField({
  name,
  hint,
  meta,
  ...props
}: useFulfillmentFieldProps): useCustomFormFieldResult {
  const errors = getTransientError({ _metadata: meta })

  return useCustomFormField({ name, hint, error: props.error ?? errors[name] })
}
