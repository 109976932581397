import { NullableSummaryText } from 'components/NullableSummaryText'
import type { FacilityContactV2 } from 'services/facilities'

import { formatFacilityContactPhone } from './FacilityDetails.helpers'

type FacilityContactPhoneProps = Nullable<
  Pick<FacilityContactV2, 'country' | 'phone' | 'extension'>
>

export function FacilityContactPhone({
  country,
  phone,
  extension,
}: FacilityContactPhoneProps) {
  return (
    <NullableSummaryText
      text={formatFacilityContactPhone({ country, phone, extension })}
      fallbackText="No phone number"
      data-testid="facility-contact-phone"
    />
  )
}
