import { createTransient } from 'utils/transient'

import type {
  HazmatItem,
  HazmatContact,
  TransientHazmatContact,
} from './HazmatInformation.types'

export function createHazmatItem(overrides?: Partial<HazmatItem>): HazmatItem {
  return {
    hazmat_package_quantity: 0,
    hazmat_package_type: '',
    hazmat_packing_group: '',
    hazmat_shipping_name: '',
    hazmat_un_number: '',
    hazmat_class: '',
    ...overrides,
  }
}

export function createTransientHazmatItem(hazmatItem?: Partial<HazmatItem>) {
  return createTransient(createHazmatItem(hazmatItem))
}

export function createHazmatContact(
  overrides?: Partial<HazmatContact>
): HazmatContact {
  return {
    hazmat_contact_name: '',
    hazmat_phone_number: '',
    ...overrides,
  }
}

export function createTransientHazmatContact(
  contact?: Partial<TransientHazmatContact>
): TransientHazmatContact {
  return createTransient(createHazmatContact(contact))
}
