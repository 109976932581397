import type { ElementType, ReactNode } from 'react'
import { Fragment } from 'react'

interface Props {
  readonly condition: boolean
  readonly children: ReactNode
  readonly wrapper: ElementType<{ children: any }>
}

export default function ConditionalWrapper({
  condition,
  children,
  wrapper,
}: Props) {
  const Component = condition ? wrapper : Fragment
  return <Component>{children}</Component>
}
