import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as authService from 'services/auth'
import { parseError } from 'utils/errors'

export type ErrorPayload = { password: string[]; password_confirm: string[] }
export type PasswordResetError = Partial<ErrorPayload> | string | null
export interface State {
  meta: {
    isLoading: boolean
    error: PasswordResetError
  }
}

const initialState: State = {
  meta: { isLoading: false, error: null },
}

export const resetPassword = createAsyncThunk(
  'resetPassword/resetPassword',
  async (
    {
      callback,
      password,
      token,
    }: {
      callback: () => void
      password: string
      token: string
    },
    thunk
  ) => {
    try {
      await authService.resetPassword(password, token)
      callback()
    } catch (error: any) {
      if (error.response?.status === 404) {
        return thunk.rejectWithValue(
          "The token on this link doesn't exist or has expired."
        )
      }

      if (error.response?.data) {
        return thunk.rejectWithValue(error.response.data)
      }
      return thunk.rejectWithValue(parseError(error))
    }
  }
)

export const slice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.meta.isLoading = true
      state.meta.error = null
    })
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.meta.isLoading = false
    })
    builder.addCase(resetPassword.rejected, (state, action) => {
      const error = action.payload as PasswordResetError

      state.meta.error = error
      state.meta.isLoading = false
    })
  },
})

export default slice.reducer
