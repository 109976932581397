import { Icon, Layout, Table, Text } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'

import type { FacilityDetailsV2 } from 'services/facilities'

import { FacilityContactEmail } from './FacilityContactEmail'
import { FacilityContactName } from './FacilityContactName'
import { FacilityContactPhone } from './FacilityContactPhone'

export type FacilityContactsTableProps = Nullable<
  Pick<FacilityDetailsV2, 'contacts'>
>

export function FacilityContactsTable({
  contacts,
}: FacilityContactsTableProps) {
  if (isEmpty(contacts)) {
    return (
      <Layout.Group paddingY="spacing-8" justify="center">
        <Text color="color-text-disabled">No contacts</Text>
      </Layout.Group>
    )
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>Contact name</Table.HeadCell>
          <Table.HeadCell>Phone number</Table.HeadCell>
          <Table.HeadCell>Email</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {contacts!.map((contact) => {
          return (
            <Table.Row key={contact.uuid}>
              <Table.Cell>
                <Layout.Group gap="spacing-2" align="center">
                  <Icon
                    name="user"
                    size="14"
                    color={
                      contact.name
                        ? 'color-text-placeholder'
                        : 'color-text-disabled'
                    }
                  />
                  <FacilityContactName name={contact.name} />
                </Layout.Group>
              </Table.Cell>
              <Table.Cell>
                <FacilityContactPhone
                  country={contact.country}
                  phone={contact.phone}
                  extension={contact.extension}
                />
              </Table.Cell>
              <Table.Cell>
                <FacilityContactEmail email={contact.email} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}
