import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useEffect, useState } from 'react'

import type { DirectionsRouteProps } from './types'

export const DirectionsRoute = ({
  origin,
  destination,
  waypoints,
  polylineOptions,
}: DirectionsRouteProps) => {
  const map = useMap()
  const routesLibrary = useMapsLibrary('routes')
  const [directionsService, setDirectionsService] =
    useState<google.maps.DirectionsService>()
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer>()

  useEffect(() => {
    if (!routesLibrary || !map) {
      return
    }
    setDirectionsService(new routesLibrary.DirectionsService())
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }))
  }, [routesLibrary, map])

  useEffect(() => {
    if (!directionsService || !directionsRenderer) {
      return () => null
    }

    directionsService
      .route({
        origin,
        destination,
        waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      .then((response) => {
        directionsRenderer.setOptions({
          directions: response,
          routeIndex: 0,
          polylineOptions,
          suppressMarkers: true,
          map,
          preserveViewport: true,
        })
      })

    return () => directionsRenderer.setMap(null)
  }, [
    directionsService,
    directionsRenderer,
    map,
    origin,
    destination,
    waypoints,
    polylineOptions,
  ])

  return null
}
