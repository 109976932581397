import { useContext, createContext } from 'react'

import user from 'utils/user'

type UserProviderValue = {
  user: UserData | null
  locationUUID: string | null
}

export const userContext = createContext<UserProviderValue>({
  user: user.user ?? null,
  locationUUID: user.locationUUID,
})

export function useCurrentUser() {
  const context = useContext(userContext)

  if (process.env.NODE_ENV !== 'production' && !context) {
    throw new Error('useCurrentUser should be wrapped in UserProvider')
  }

  return context
}
