import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const SO_NUMBER_NAME = 'so_number'
const SO_NUMBERS_NAME = 'so_numbers'
const SO_NUMBER_LABEL = 'SO number'

type FreightInformationSONumberSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationSONumberSummaryField(
  props: FreightInformationSONumberSummaryFieldProps
) {
  const { shipment } = useFreightInformationSummaryContext()
  const value = get(shipment, SO_NUMBERS_NAME) ?? get(shipment, SO_NUMBER_NAME)

  return (
    <FreightInformationSummaryField
      label={SO_NUMBER_LABEL}
      name={SO_NUMBER_NAME}
      value={value}
      {...props}
    />
  )
}

type FreightInformationSONumberFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationSONumberFormField({
  required,
  hint,
  error,
  style,
}: FreightInformationSONumberFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: SO_NUMBER_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${SO_NUMBER_NAME}-field`}
    >
      <Field.Label id={`label-${SO_NUMBER_NAME}`}>
        {SO_NUMBER_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${SO_NUMBER_NAME}`}
        placeholder="Enter SO number"
        value={get(shipment, SO_NUMBER_NAME)}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [SO_NUMBER_NAME]: get(event, 'target.value') },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
