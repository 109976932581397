import { useMemo } from 'react'

import { usePriceItemTypes } from 'hooks/useQuery'

import type { AccessorialOption } from './AccessorialsSelectByStop.types'

function getAccessorialsByStop(accessorials: PriceItemType[] = []) {
  const accessorialsByStop: Record<StopType | 'general', AccessorialOption[]> =
    { general: [], pickup: [], delivery: [] }

  accessorials.forEach((accessorial) => {
    if (!accessorial.stops_relationship?.length) {
      accessorialsByStop.general.push({
        label: accessorial.label,
        value: accessorial.uuid,
      })
    } else {
      accessorial.stops_relationship.forEach((stop) => {
        accessorialsByStop[stop].push({
          label: accessorial.label,
          value: accessorial.uuid,
        })
      })
    }
  })

  return accessorialsByStop
}

export function useAccessorialsOptionsByStop({
  mode,
  usage,
}: {
  mode?: TransportationModeCode
  usage: AccountingObjectType
}) {
  const {
    data: accessorials,
    isLoading,
    isError,
    refetch,
  } = usePriceItemTypes({ modes: mode, usage })

  const {
    general: generalOptions,
    pickup: pickupOptions,
    delivery: deliveryOptions,
  } = useMemo(() => getAccessorialsByStop(accessorials), [accessorials])

  return {
    generalOptions,
    pickupOptions,
    deliveryOptions,
    isLoading,
    isError,
    onRefetch: () => {
      refetch()
    },
  }
}
