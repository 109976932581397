import { Card, Layout } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { FacilitySummary } from './FacilitySummary'

export function FacilitySummaryCard({
  facility,
}: {
  readonly facility: FacilityDetailsV2
}) {
  return (
    <Card>
      <Card.Body>
        <FacilitySummary facility={facility}>
          <Layout.Group
            align="flex-start"
            justify="space-between"
            style={{ flexFlow: 'nowrap' }}
          >
            <Layout.Stack gap="none">
              <FacilitySummary.Name />
              <FacilitySummary.Address />
              <Layout.Group gap="spacing-2">
                <FacilitySummary.Location />
                <FacilitySummary.Country />
              </Layout.Group>
              <FacilitySummary.ExternalID />
            </Layout.Stack>
            <FacilitySummary.OpendockConnected />
          </Layout.Group>
        </FacilitySummary>
      </Card.Body>
    </Card>
  )
}
