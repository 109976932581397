import { isBlank } from '@loadsmart/utils-string'
import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { TransientFacilityContact } from 'components/FacilityContactsForm/FacilityContactsForm.types'
import type { FacilityContactV2 } from 'services/facilities'
import { saveFacilityContacts } from 'services/facilities'

export function buildContactsPayload(
  contacts: TransientFacilityContact[]
): Partial<FacilityContactV2>[] {
  const isFilled = (contact: TransientFacilityContact) =>
    [
      contact.name,
      contact.phone,
      contact.country,
      contact.email,
      contact.extension,
    ].filter((value) => !isBlank(value ?? '')).length > 0

  return contacts.filter(isFilled).map((contact) => ({
    uuid: contact.uuid ?? undefined,
    name: contact.name ?? '',
    phone: contact.phone ?? '',
    country: contact.country,
    email: contact.email,
    extension: contact.extension,
  }))
}

export const useSaveFacilityContacts = ({
  facilityUUID,
  onSuccess,
  onError,
}: {
  facilityUUID: string
  onSuccess: (updatedContacts: FacilityContactV2[]) => void
  onError: (errors: string[]) => void
}) => {
  const { mutate, isLoading } = useMutation<
    FacilityContactV2[],
    AxiosError<{ errors?: string[] }>,
    TransientFacilityContact[]
  >({
    mutationFn: (contacts: TransientFacilityContact[]) => {
      return saveFacilityContacts({
        facilityUUID,
        contacts: buildContactsPayload(contacts),
      })
    },
    onSuccess,
    onError: (error) => {
      onError(error.response?.data?.errors ?? [])
    },
  })

  return { saveContacts: mutate, isSaving: isLoading }
}
