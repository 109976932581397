import { Layout, Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

export type FacilityInstructionsProps = Pick<FacilityDetailsV2, 'notes'>

export function FacilityInstructions({ notes }: FacilityInstructionsProps) {
  const instructions = notes[0]?.note

  if (!instructions) {
    return (
      <Layout.Group paddingY="spacing-8" justify="center">
        <Text color="color-text-disabled">No instructions</Text>
      </Layout.Group>
    )
  }

  return (
    <Layout.Box borderWidth="border-thin">
      <Text data-testid="facility-instructions">{instructions}</Text>
    </Layout.Box>
  )
}
