import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface State {
  extra_fields: ShipperSettingsExtraFields
  meta: {
    prevState: ShipperSettingsExtraFields
  }
}

const initialState: State = {
  extra_fields: {} as ShipperSettingsExtraFields,
  meta: {
    prevState: {} as ShipperSettingsExtraFields,
  },
}

export const slice = createSlice({
  name: 'shipperSettingsExtraFields',
  initialState,
  reducers: {
    updateStart(state) {
      state.meta.prevState = state.extra_fields
    },
    updateExtraFieldsOptions(
      state,
      action: PayloadAction<Partial<ShipperSettingsExtraFields>>
    ) {
      state.extra_fields = {
        ...state.extra_fields,
        ...action.payload,
      }
    },
    revertChanges(state) {
      state.extra_fields = state.meta.prevState
    },
  },
})

export default slice.reducer
