import { CarrierReferenceField } from 'screens/Shipper/Shipments/Details/CarrierReferenceField'
import user from 'utils/user'

import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'

export function FreightInformationCarrierRefNumberSummaryField() {
  const { shipment } = useFreightInformationSummaryContext()

  const carrierRefId = shipment.carrier_ref_numbers?.loadsmart_ref_number

  if (!carrierRefId || !user.isMTInternalUser) {
    return null
  }

  return <CarrierReferenceField carrierRefId={carrierRefId} />
}
