import { Text, Layout } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useId } from 'react'
import styled from 'styled-components'

import { LocalLink } from '_shared_/components/LocalLink'
import { H3 } from '_shared_/components/unstyled-elements'

const IndexWrapper = styled(Layout.Stack)`
  position: sticky;
  top: ${toCSSValue('spacing-3')};
`
const List = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${toCSSValue('spacing-4')};
`

export type PageIndices = ReadonlyArray<{ id: string; label: string }>

type PageIndexProps = Readonly<{
  values: PageIndices
}>

export default function PageIndex({ values }: PageIndexProps) {
  const titleId = useId()

  return (
    <IndexWrapper data-testid="page-index" gap="spacing-4">
      <H3 id={titleId}>
        <Text variant="body-sm" color="color-text-tertiary">
          Go to
        </Text>
      </H3>
      <nav aria-labelledby={titleId}>
        <List>
          {values.map(({ id, label }) => (
            <li key={id}>
              <LocalLink elementId={id}>{label}</LocalLink>
            </li>
          ))}
        </List>
      </nav>
    </IndexWrapper>
  )
}
