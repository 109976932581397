export function getEnvVar(name: string): string {
  const environment = `${process.env.REACT_APP_ENVIRONMENT}`.toUpperCase()
  return process.env[`REACT_APP_${name}_${environment}`] || ''
}

export function getAppEnv() {
  return process.env.REACT_APP_ENVIRONMENT || ''
}

export function getEnvSubdomain() {
  switch (`${process.env.REACT_APP_ENVIRONMENT}`.toLowerCase()) {
    case 'production':
      return ''
    case 'sandbox':
      return 'sandbox.'
    case 'qa':
      return 'qa.'
    default:
      return 'staging.'
  }
}
