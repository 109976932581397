import {
  IconDryvan,
  IconTruckBobtail,
  IconTruckBulktanker,
  IconTruckConestoga,
  IconTruckContainer,
  IconTruckDrayage,
  IconTruckDryvan,
  IconTruckFlatbed,
  IconTruckFlatrack,
  IconTruckHotshot,
  IconTruckLtlDryvan,
  IconTruckRailDrybox,
  IconTruckReefer,
  IconTruckRollOnRollOff,
  IconTruckSprinter,
  IconTruckStraight,
  IconTruckVentilated,
  IconBoxTruck,
} from '@loadsmart/icons'
import { Layout, Text } from '@loadsmart/miranda-react'
import type { TextProps } from '@loadsmart/miranda-react'
import type { ColorToken } from '@loadsmart/miranda-react/dist/tokens'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { getEquipmentType } from 'utils/equipmentTypeV2'
import type { CanonicalEquipmentTypeName } from 'utils/equipmentTypeV2'

type EquipmentTypeVariant =
  | 'full'
  | 'icon-only'
  | 'label-only'
  | 'abbr'
  | 'abbr-only'

// based on src/utils/equipmentType.ts
const ICON_BY_TYPE: Record<CanonicalEquipmentTypeName, string> = {
  drv: IconDryvan,
  fbe: IconTruckFlatbed,
  ltl: IconTruckLtlDryvan,
  rfr: IconTruckReefer,
  imc: IconTruckDrayage,
  rail: IconTruckRailDrybox,
  pwr: IconTruckBobtail,
  tank: IconTruckBulktanker,
  pnmc: IconTruckDryvan,
  vnt: IconTruckVentilated,
  '40hc': IconTruckContainer,
  cst: IconTruckConestoga,
  drb: IconTruckContainer,
  fra: IconTruckFlatrack,
  hot: IconTruckHotshot,
  roro: IconTruckRollOnRollOff,
  sbt: IconTruckStraight,
  spr: IconTruckSprinter,
  btr: IconBoxTruck,
  cgv: IconTruckSprinter,
  str: IconTruckStraight,
}

function getIconByType(type: string) {
  return ICON_BY_TYPE[type as CanonicalEquipmentTypeName] || IconDryvan
}

export function shouldShowIcon(variant: EquipmentTypeVariant) {
  return ['full', 'icon-only', 'abbr'].includes(variant)
}

export function shouldShowLabel(variant: EquipmentTypeVariant) {
  return ['full', 'label-only'].includes(variant)
}

export function shouldShowAbbr(variant: EquipmentTypeVariant) {
  return ['abbr', 'abbr-only'].includes(variant)
}

function shouldShow(variant: EquipmentTypeVariant) {
  return {
    showAbbr: shouldShowAbbr(variant),
    showIcon: shouldShowIcon(variant),
    showLabel: shouldShowLabel(variant),
  }
}

export type EquipmentTypeProps = {
  readonly color?: ColorToken
  readonly iconSize?: number
  readonly name: CanonicalEquipmentTypeName
  readonly textVariant?: TextProps['variant']
  readonly variant?: EquipmentTypeVariant
}

function EquipmentType(props: EquipmentTypeProps) {
  const {
    color = 'color-text-primary',
    iconSize = 20,
    textVariant = 'body-md',
    variant = 'abbr',
    name,
    ...others
  } = props
  const { showAbbr, showIcon, showLabel } = shouldShow(variant)
  const Icon = getIconByType(name)
  const equipmentType = getEquipmentType(name)

  if (!equipmentType) {
    return <span>-</span>
  }

  const isIconOnly = variant === 'icon-only'
  return (
    <Layout.Group align="center" gap="spacing-1" {...others}>
      {showIcon && (
        <Icon
          data-testid="equipment-type-icon"
          width={iconSize}
          height={iconSize}
          fill={toCSSValue(color)}
          aria-hidden={!isIconOnly}
          aria-label={isIconOnly ? equipmentType.label : undefined}
          title={null}
        />
      )}
      {showLabel && (
        <Text
          data-testid="equipment-type-label"
          variant={textVariant}
          color={color}
        >
          {equipmentType.label}
        </Text>
      )}
      {showAbbr && (
        <Text
          data-testid="equipment-type-abbr"
          variant={textVariant}
          color={color}
        >
          {equipmentType.abbr}
        </Text>
      )}
    </Layout.Group>
  )
}

export default EquipmentType
