import { Button, Icon, Layout } from '@loadsmart/miranda-react'
import { useState } from 'react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { FacilityHoursOfOperationTable } from './FacilityHoursOfOperationTable'

type FacilityHoursOfOperationCollapsibleProps = Nullable<
  Pick<FacilityDetailsV2, 'hours_of_operation'>
>

export function FacilityHoursOfOperationCollapsible({
  hours_of_operation,
}: FacilityHoursOfOperationCollapsibleProps) {
  const [hoursOfOperationVisible, setHoursOfOperationVisible] = useState(false)

  const toggleHoursOfOperation = () => {
    setHoursOfOperationVisible(!hoursOfOperationVisible)
  }

  return (
    <Layout.Stack align="flex-start">
      <Button
        variant="tertiary"
        trailing={
          <Icon
            name={hoursOfOperationVisible ? 'caret-up' : 'caret-down'}
            size="16"
          />
        }
        onClick={toggleHoursOfOperation}
      >
        Operating hours
      </Button>

      {hoursOfOperationVisible && (
        <FacilityHoursOfOperationTable
          hours_of_operation={hours_of_operation}
        />
      )}
    </Layout.Stack>
  )
}
