import { useEventBasedDrawer } from '_shared_/hooks/useEventBasedDrawer'

import { ContactsManagerDrawer } from './ContactsManagerDrawer'

export function EventBasedContactsManagerDrawer() {
  const { isOpen, data, close, onClosed } = useEventBasedDrawer(
    'sg:open-contacts-manager-v2'
  )

  if (!data) {
    return null
  }

  return (
    <ContactsManagerDrawer
      {...data}
      isOpen={isOpen}
      onClose={close}
      onClosed={onClosed}
    />
  )
}
