import { Button } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps'
import { useState } from 'react'

import { useSettings } from 'contexts/settings'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import { Container } from './Map'
import type { MapProps } from './types'

export const Map = ({ page, style, ...rest }: MapProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const {
    values: [GOOGLE_PLACES_API_KEY],
  } = useSettings(['settings.GOOGLE_PLACES_API_KEY'])

  if (!isVisible) {
    return (
      <div style={{ height: '100%' }}>
        <Container>
          <Button
            variant="primary"
            onClick={() => {
              setIsVisible(true)
              analytics.track(
                AnalyticsEvent.MapContainerSeeMap,
                AnalyticsEventTrigger.click,
                { page }
              )
            }}
          >
            See the map
          </Button>
        </Container>
      </div>
    )
  }

  return (
    <APIProvider apiKey={GOOGLE_PLACES_API_KEY}>
      <GoogleMap
        defaultCenter={{ lat: 41.831397, lng: -87.627229 }}
        defaultZoom={10}
        gestureHandling="greedy"
        fullscreenControl={false}
        scrollwheel={false}
        streetViewControl={false}
        zoomControl={false}
        mapTypeControl={false}
        style={{
          ...style,
          borderRadius: toCSSValue('border-radius-m'),
          overflow: 'hidden',
        }}
        {...rest}
      />
    </APIProvider>
  )
}
