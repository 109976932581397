import { Layout, Text } from '@loadsmart/miranda-react'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'
import ReadonlyAppointmentCard from './Appointment/ReadonlyAppointmentCard'

export function FulfillmentPickupAppointment() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment?.appointment) {
    return null
  }

  return (
    <Layout.Stack gap="spacing-2" data-testid="fulfillment-appointment">
      <Text variant="heading-sm-bold">Appointment</Text>
      <ReadonlyAppointmentCard
        appointment={fulfillment.appointment}
        stopDate={fulfillment.appointment.stop.date}
        warehouseUUID={fulfillment.pickup_facility.warehouse_uuid}
      />
    </Layout.Stack>
  )
}
