import { FulfillmentsRoutePaths } from 'fulfillments/FulfillmentsRoutes'
import { useCallback } from 'react'
import { useHistory, generatePath } from 'react-router-dom'

export function useGoToNewFulfillment() {
  const history = useHistory()
  const goToNewFulfillmentForm = useCallback(() => {
    history.push(FulfillmentsRoutePaths.New)
  }, [history])

  return goToNewFulfillmentForm
}

export function useGoToFulfillmentDetails() {
  const history = useHistory()

  const goToFulfillmentDetails = useCallback(
    (uuid: string) => {
      history.push(
        generatePath(FulfillmentsRoutePaths.Details, {
          id: uuid,
        })
      )
    },
    [history]
  )

  return goToFulfillmentDetails
}
