import { IconQuestionCircle, IconSettings } from '@loadsmart/icons'
import { Layout, Text, Button } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import styled from 'styled-components'

import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import { ACCESSORIALS_HELP_URL, ACCESSORIALS_SETTINGS_URL } from './constants'

const HelpLink = styled.a`
  align-items: center;
  display: flex;
  gap: ${toCSSValue('spacing-0-5')};
`

export function AccessorialsSelectFooter() {
  return (
    <Layout.Group align="center" justify="space-between">
      <HelpLink
        href={ACCESSORIALS_HELP_URL}
        target="_blank"
        onClick={() => {
          analytics.track(AnalyticsEvent.FAQ, AnalyticsEventTrigger.click, {
            placement: 'quote accessorial',
          })
        }}
      >
        <IconQuestionCircle
          height={16}
          width={16}
          fill={toCSSValue('color-text-link')}
          title={null}
        />
        <Text variant="body-sm-underline" color="color-text-link">
          Need help about the accessorial list? Check our article
        </Text>
      </HelpLink>
      <Button
        variant="tertiary"
        leading={<IconSettings height={12} width={12} title={null} />}
        href={ACCESSORIALS_SETTINGS_URL}
        target="_blank"
        onClick={() => {
          analytics.track(
            AnalyticsEvent.ManageFavorites,
            AnalyticsEventTrigger.click
          )
        }}
      >
        Manage favorites
      </Button>
    </Layout.Group>
  )
}
