import type { FieldProps } from '@loadsmart/miranda-react'
import { get, isEmpty } from 'lodash'
import type { CSSProperties } from 'styled-components'

import { SummaryLabelValue } from 'components/Summary'
import toArray from 'utils/toArray'

import type { FreightInformation } from './FreightInformation.types'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'

/**
 * Recursive utility type Leaves<T, Prev = never> that is designed to iterate
 * over the properties of a given type T and create a union of strings representing
 * the paths to all leaves in the object type. A leaf is considered to be any property
 * that is not an object itself. It captures the keys of non-object values as string literal types.
 */
type Leaves<T, Prev = never> = T extends object
  ? {
      [K in keyof T]-?: Exclude<K, symbol> extends infer Key
        ? Key extends Prev
          ? never
          : T[K] extends undefined
            ? never
            : `${Exclude<K, symbol>}${Leaves<T[K], Key> extends never
                ? ''
                : `.${Leaves<T[K], Key>}`}`
        : never
    }[keyof T]
  : never

export type FreightInformationSummaryFieldProps = Pick<FieldProps, 'align'> & {
  readonly label: string
  readonly name: Leaves<FreightInformation>
  readonly value?: string | string[]
  readonly style?: CSSProperties
  readonly tip?: string
}

export function FreightInformationSummaryField({
  label,
  name,
  value,
  ...fieldProps
}: FreightInformationSummaryFieldProps) {
  const { shipment } = useFreightInformationSummaryContext()
  const fieldValue = toArray(value || get(shipment, name)).filter(Boolean) //?

  return (
    <SummaryLabelValue
      data-testid={`${name}-summary`}
      {...fieldProps}
      label={label}
      value={isEmpty(fieldValue) ? '-' : fieldValue.join(', ')}
    />
  )
}
