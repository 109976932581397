import styled from 'styled-components'

import NotFoundImage from 'assets/icons/not_found.svg'

const Page = styled.div`
  text-align: center;
`
const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`
const Bold = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;
  margin-top: 40px;
`
const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;
`
const P = styled.p`
  color: ${({ theme }) => theme.colors.textNoMatch};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`
const SmallErrorImage = styled.object`
  height: 250px;
  width: 400px;
`
const TextContainer = styled(P)`
  max-width: 410px;
`

export function NetworkError() {
  return (
    <Page>
      <object type="image/svg+xml" data={NotFoundImage}>
        Network Error
      </object>
      <Bold>We&apos;re sorry</Bold>
      <P>
        There appears to be an issue with your browser receiving requests from{' '}
        <b>shipper.guide</b>.
      </P>
      <P>
        Please try to add the domain <b>shipper.guide</b> to your firewall
        allowed list and refresh the page. If the problem persists, please reach
        out to <a href="mailto:info@shipper.guide">info@shipper.guide</a> for
        further assistance. Thank you for your patience!
      </P>
    </Page>
  )
}

export function NotFound() {
  return (
    <Page>
      <object type="image/svg+xml" data={NotFoundImage}>
        Not Found
      </object>
      <Bold>Sorry, we could not find what you are looking for</Bold>
      <P>
        Please contact{' '}
        <a href="mailto:info@shipper.guide">info@shipper.guide</a> for support
        and we will be more than happy to assist you.
      </P>
    </Page>
  )
}

export function NoRecommendedCarriers() {
  return (
    <ErrorContainer>
      <SmallErrorImage type="image/svg+xml" data={NotFoundImage}>
        Empty Carriers
      </SmallErrorImage>
      <Title>Recommended Carriers</Title>
      <TextContainer>
        Sorry, no carriers were found for the lanes in this bid. We will be
        constantly improving our carrier recommendation algorithm.
      </TextContainer>
    </ErrorContainer>
  )
}
