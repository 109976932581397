import { Select } from '@loadsmart/loadsmart-ui'
import type { FieldProps } from '@loadsmart/miranda-react'
import { Field } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { get } from 'lodash'
import type { CSSProperties } from 'react'
import styled from 'styled-components'

import type { Mode } from 'utils/types'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useEquipmentLengths } from './useEquipmentLengths'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const StyledSelect = styled(Select)`
  [role='presentation'] {
    border-color: ${toCSSValue('color-border-input')};
  }
`

const EQUIPMENT_LENGTH_NAME = 'equipment_length'
const EQUIPMENT_LENGTH_LABEL = 'Equipment length'

type FreightInformationEquipmentLengthSummaryFieldProps = Partial<
  Omit<FreightInformationSummaryFieldProps, 'label' | 'name' | 'value'>
>

export function FreightInformationEquipmentLengthSummaryField(
  props: FreightInformationEquipmentLengthSummaryFieldProps
) {
  const { shipment } = useFreightInformationSummaryContext()
  const equipmentLength = shipment[EQUIPMENT_LENGTH_NAME]

  return (
    <FreightInformationSummaryField
      label={EQUIPMENT_LENGTH_LABEL}
      name={EQUIPMENT_LENGTH_NAME}
      value={equipmentLength ? `${equipmentLength}'` : '-'}
      {...props}
    />
  )
}

type FreightInformationEquipmentLengthFormFieldProps = {
  readonly mode: Mode
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationEquipmentLengthFormField({
  mode,
  required,
  disabled,
  hint,
  error,
  style,
}: FreightInformationEquipmentLengthFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()
  const { lengths, fromLengthToSelectOption } = useEquipmentLengths(mode)

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: EQUIPMENT_LENGTH_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${EQUIPMENT_LENGTH_NAME}-field`}
    >
      <Field.Label>{EQUIPMENT_LENGTH_LABEL}</Field.Label>
      <StyledSelect
        name={EQUIPMENT_LENGTH_NAME}
        placeholder="Select length"
        options={lengths}
        value={fromLengthToSelectOption(shipment[EQUIPMENT_LENGTH_NAME])}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [EQUIPMENT_LENGTH_NAME]: get(event, 'target.value.value'),
            },
          })
        }}
        disabled={disabled}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
