import type { FieldProps } from '@loadsmart/miranda-react'
import type { CSSProperties, ReactNode } from 'react'

import { useSettings } from 'contexts/settings'

import { StopFacility } from './StopFacility'
import { StopFacilityOrAddress } from './StopFacilityOrAddress'
import type { UseStopFormFieldProps } from './useStopFormField'

export const RenderStopFacilityOrAddress = (
  props: Readonly<{
    label?: ReactNode
    required?: FieldProps['required']
    hint?: UseStopFormFieldProps['hint']
    style?: CSSProperties
    disabled?: boolean
  }>
) => {
  const {
    values: [enableShipmentFormAddress],
  } = useSettings(['flags.ENABLE_SHIPMENT_FORM_ADDRESS'])

  if (enableShipmentFormAddress) {
    return <StopFacilityOrAddress {...props} />
  }

  return <StopFacility {...props} />
}
