import { useQuery } from 'react-query'
import type { UseQueryOptions } from 'react-query'

import {
  getTarpTypes,
  getSubEquipment,
  getMetadata,
} from '../services/metadata'

export const useMetadata = () =>
  useQuery({
    queryKey: ['getMetadata'],
    queryFn: () => getMetadata(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })

export const useTarpTypes = () =>
  useQuery({
    queryKey: ['getTarpTypes'],
    queryFn: () => getTarpTypes(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })

interface SubEquipmentsType {
  FBE: { value: string; label: string }[]
}

export const useSubEquipments = (
  options?: Omit<
    UseQueryOptions<SubEquipmentsType, Error>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<SubEquipmentsType, Error>({
    refetchInterval: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['getSubEquipments'],
    queryFn: () => getSubEquipment(),
  })
}
