import type { CSSProperties } from 'react'
import styled from 'styled-components'

export const TextClamp = styled.div<{
  lineClamp?: number
  wordBreak?: CSSProperties['wordBreak']
}>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lineClamp ?? 1};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${(props) => props.wordBreak ?? 'none'};
`

export const isTextClamped = (textClampElement: HTMLDivElement | null) => {
  if (!textClampElement) {
    return false
  }
  return textClampElement.scrollHeight > textClampElement.clientHeight
}
