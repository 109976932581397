import { createSummarySetup } from 'components/Summary'

import type { FreightInformation } from './FreightInformation.types'

export type FreightInformationSummaryContextValue = {
  shipment: FreightInformation
}

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<FreightInformationSummaryContextValue>({
    contextName: 'FreightInformationContext',
  })

export {
  SummaryProvider as FreightInformationSummaryProvider,
  useSummaryContext as useFreightInformationSummaryContext,
}
