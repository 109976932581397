import { Text } from '@loadsmart/miranda-react'
import type { TextProps } from '@loadsmart/miranda-react'

export type NullableSummaryTextProps = Omit<TextProps, 'color'> & {
  readonly text?: string | null
  readonly fallbackText?: string
}

export function NullableSummaryText({
  text,
  fallbackText = 'N/A',
  ...props
}: NullableSummaryTextProps) {
  return (
    <Text
      variant="body-md"
      {...props}
      color={text ? 'color-text-primary' : 'color-text-disabled'}
    >
      {text || fallbackText}
    </Text>
  )
}
