import { IconArrowRight, IconCompany } from '@loadsmart/icons'
import { LoadingDots, TopNavigation } from '@loadsmart/loadsmart-ui'
import { Layout, Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import SearchDialog from 'components/SearchDialog'
import StringLimiter from 'components/StringLimiter'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import { LogoutButton } from './LogoutButton'
import {
  StyledDivider,
  StyledDropdownItem,
  StyledDropdownWrapper,
} from './styles'

const iconColor = toCSSValue('color-primary-60')

export const SupervisorDropdown = ({
  label,
  selectedLocation,
  locationList,
  filteredLocationList,
  onLocationSelect,
  modalState,
  openModal,
  closeModal,
}: {
  readonly label: string
  readonly selectedLocation: ShipperLocation | null
  readonly locationList: ShipperLocation[]
  readonly filteredLocationList: ShipperLocation[]
  readonly onLocationSelect: (uuid: string) => void
  readonly modalState: boolean
  readonly openModal: () => void
  readonly closeModal: () => void
}) => {
  if (!selectedLocation) {
    return <LoadingDots />
  }

  return (
    <>
      <Layout.Box
        borderWidth="border-thin"
        paddingX="spacing-2"
        paddingY="none"
      >
        <Layout.Group gap="spacing-6" align="center" justify="space-between">
          <Layout.Box paddingX="none" paddingY="none">
            <Layout.Group gap="spacing-1" align="center">
              <IconCompany width={16} height={16} fill={iconColor} />
              <Text color="color-primary-60">
                {selectedLocation.locationName}
              </Text>
            </Layout.Group>
          </Layout.Box>
          <StyledDropdownWrapper paddingX="none" paddingY="none">
            <TopNavigation.Menu.Item label={label}>
              {filteredLocationList.length > 0 && (
                <>
                  {filteredLocationList
                    .slice(0, 3)
                    .map(({ uuid, locationName }) => (
                      <StyledDropdownItem
                        key={uuid}
                        leading={
                          <IconCompany
                            width={16}
                            height={16}
                            fill={iconColor}
                          />
                        }
                        onClick={() => {
                          analytics.track(
                            AnalyticsEvent.NavbarSwitchLocation,
                            AnalyticsEventTrigger.click,
                            {
                              current_location: selectedLocation.locationName,
                              new_location: locationName,
                              page: window.location.pathname,
                            }
                          )

                          onLocationSelect(uuid)
                        }}
                      >
                        <Text variant="body-md-bold">
                          <StringLimiter limiter={22} value={locationName} />
                        </Text>
                      </StyledDropdownItem>
                    ))}
                  {filteredLocationList.length > 3 && (
                    <StyledDropdownItem
                      leading={
                        <IconArrowRight
                          width={16}
                          height={16}
                          fill={iconColor}
                        />
                      }
                      onClick={() => {
                        analytics.track(
                          AnalyticsEvent.NavbarSeeAllLocations,
                          AnalyticsEventTrigger.click,
                          {
                            page: window.location.pathname,
                          }
                        )
                        openModal()
                      }}
                    >
                      <Text variant="body-md-bold">See all locations</Text>
                    </StyledDropdownItem>
                  )}
                  <StyledDivider />
                </>
              )}
              <LogoutButton />
            </TopNavigation.Menu.Item>
          </StyledDropdownWrapper>
        </Layout.Group>
      </Layout.Box>
      <SearchDialog
        title="Switch location"
        placeholder="Search for locations"
        defaultSelectedItem={{
          id: selectedLocation.uuid,
          label: selectedLocation.locationName,
          value: selectedLocation.uuid,
        }}
        list={locationList.map((location) => ({
          id: location.uuid,
          label: location.locationName,
          value: location.uuid,
        }))}
        isOpen={modalState}
        onClose={closeModal}
        onConfirm={(item) => {
          analytics.track(
            AnalyticsEvent.NavbarSeeAllLocationsSwitch,
            AnalyticsEventTrigger.click,
            {
              current_location: selectedLocation.locationName,
              new_location: item.label,
              page: window.location.pathname,
            }
          )

          onLocationSelect(item.value)
        }}
      />
    </>
  )
}
