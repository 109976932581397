import { IconQuestionCircle } from '@loadsmart/icons'
import { Select } from '@loadsmart/loadsmart-ui'
import { Field, Layout, Tooltip } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { fromSelectOption } from 'utils/selectOption'

import { useSettings } from '../../contexts/settings'
import {
  EXCESSIVE_LENGTH_CLASS_MAP,
  EXCESSIVE_LENGTH_CLASS_OPTIONS,
} from './FreightInformation.constants'
import { getExcessiveLengthOption } from './FreightInformation.utils'
import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const EXCESSIVE_LENGTH_NAME = 'ltl_excessive_length_class'
const EXCESSIVE_LENGTH_LABEL = 'Excessive length'

type FreightInformationExcessiveLengthSummaryFieldProps = Partial<
  Omit<FreightInformationSummaryFieldProps, 'label' | 'name' | 'value'>
>

export function FreightInformationExcessiveLengthSummaryField(
  props: FreightInformationExcessiveLengthSummaryFieldProps
) {
  const { shipment } = useFreightInformationSummaryContext()
  const excessiveLength = shipment[EXCESSIVE_LENGTH_NAME]

  return (
    <FreightInformationSummaryField
      label={EXCESSIVE_LENGTH_LABEL}
      name={EXCESSIVE_LENGTH_NAME}
      value={
        excessiveLength ? EXCESSIVE_LENGTH_CLASS_MAP.get(excessiveLength) : '-'
      }
      {...props}
    />
  )
}

type FreightInformationExcessiveLengthFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationExcessiveLengthFormField({
  required,
  disabled,
  hint,
  error,
  style,
}: FreightInformationExcessiveLengthFormFieldProps) {
  const {
    values: [isAddExcessiveLengthAccessorialBasedOnItemDimensionsEnabled],
  } = useSettings([
    'flags.ADD_EXCESSIVE_LENGTH_ACCESSORIAL_BASED_ON_ITEM_DIMENSIONS',
  ])
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { name, fieldProps, hintProps } = useFreightInformationFormField({
    name: EXCESSIVE_LENGTH_NAME,
    hint,
    error,
  })

  if (isAddExcessiveLengthAccessorialBasedOnItemDimensionsEnabled) {
    return null
  }

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${name}-field`}
    >
      <Field.Label>
        <Layout.Group gap="spacing-1" align="center">
          {EXCESSIVE_LENGTH_LABEL}
          <Tooltip
            message="Shipments containing articles greater than 8ft may be subject to an additional charge."
            trigger="hover"
            placement="right"
          >
            <IconQuestionCircle
              title={null}
              color={toCSSValue('color-text-tertiary')}
              width={16}
              height={16}
            />
          </Tooltip>
        </Layout.Group>
      </Field.Label>
      <Select
        name={name}
        placeholder="No excessive length"
        options={EXCESSIVE_LENGTH_CLASS_OPTIONS}
        value={getExcessiveLengthOption(get(shipment, name))}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event

          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [name]: fromSelectOption(option as SelectOption),
            },
          })
        }}
        disabled={disabled}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
