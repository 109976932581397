import {
  CA_STATE_NAMES_BY_VALUE,
  MX_STATE_NAMES_BY_VALUE,
  US_STATE_NAMES_BY_VALUE,
} from 'constants/states'

export function resolveCountry(
  countryAbbreviation: string | null | undefined
): 'USA' | 'CAN' | 'MEX' | null {
  if (!countryAbbreviation) {
    return null
  }
  switch (countryAbbreviation.toUpperCase()) {
    case 'US':
    case 'USA':
      return 'USA'
    case 'CA':
    case 'CAN':
      return 'CAN'
    case 'MX':
    case 'MEX':
      return 'MEX'
    default:
      return null
  }
}

export function getStateNameByAbbreviation(
  abbreviation: string,
  country: string
): string | null {
  const resolvedCountry = resolveCountry(country)

  if (resolvedCountry === 'USA') {
    return US_STATE_NAMES_BY_VALUE[abbreviation] ?? null
  }

  if (resolvedCountry === 'CAN') {
    return CA_STATE_NAMES_BY_VALUE[abbreviation] ?? null
  }

  if (resolvedCountry === 'MEX') {
    return MX_STATE_NAMES_BY_VALUE[abbreviation] ?? null
  }

  return null
}
