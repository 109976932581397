import { isBlank } from '@loadsmart/utils-string'
import { debounce, get, isString, set } from 'lodash'
import { useRef, useCallback, useEffect, useState } from 'react'

import type { Filters, FiltersPluginHook } from './filters.types'
import { pluginsRunner } from './plugins'

export type UseFiltersProps<F> = {
  initialValues: Filters<F>
  onChange?: (filters: Filters<F>) => void
  options?: {
    /**
     * Value in milliseconds to trigger filter.
     */
    debounceTime?: number
  }
}

/**
 * Custom hook manage multiple filters
 */
export function useFilters<F>(
  props: UseFiltersProps<F>,
  ...plugins: FiltersPluginHook<F>[]
) {
  const { initialValues, onChange, options } = props

  const pluginsRunnerRef = useRef(
    pluginsRunner(plugins.map((plugin) => plugin()))
  )

  const [filters, setFilters] = useState(() => {
    return getPluginsRunner().onInit(initialValues)
  })

  function getPluginsRunner() {
    return pluginsRunnerRef.current
  }

  function getPublishChanges() {
    function publishChanges(filterValues: Filters<F>) {
      if (!onChange) {
        return
      }

      let changes = {} as Filters<F>

      for (const filter in filterValues) {
        const value = get(filterValues, filter)

        if (value != null && (!isString(value) || !isBlank(value))) {
          changes = set(changes, [filter], value)
        }
      }

      onChange(changes)
    }

    if (options?.debounceTime) {
      return debounce(publishChanges, options.debounceTime)
    }

    return publishChanges
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publishChanges = useCallback(getPublishChanges(), [])

  useEffect(() => {
    getPluginsRunner().onChange(filters)
  }, [filters])

  useEffect(() => {
    publishChanges(filters)
  }, [filters, onChange, publishChanges])

  const publicSetFilter = useCallback((filter: keyof F, value: unknown) => {
    setFilters((currentFilters: Filters<F>) => ({
      ...currentFilters,
      [filter]: value,
    }))

    getPluginsRunner().onChange({ [filter]: value } as Filters<F>)
  }, [])

  const publicSetFilters = useCallback((newFilters: Filters<F>) => {
    setFilters((currentFilters: Filters<F>) => ({
      ...currentFilters,
      ...newFilters,
    }))

    getPluginsRunner().onChange(newFilters)
  }, [])

  return {
    values: filters,
    setFilter: publicSetFilter,
    setFilters: publicSetFilters,
  }
}
