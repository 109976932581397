import { Button, Checkbox, Icon, Layout, Text } from '@loadsmart/miranda-react'
import onboardStep1Image from '_assets_/images/onboard-step-1.svg'
import onboardStep2Image from '_assets_/images/onboard-step-2.svg'
import onboardStep3Image from '_assets_/images/onboard-step-3.svg'
import onboardStep4Image from '_assets_/images/onboard-step-4.svg'
import onboardStep5Image from '_assets_/images/onboard-step-5.svg'
import onboardStep6Image from '_assets_/images/onboard-step-6.svg'
import onboardStep7Image from '_assets_/images/onboard-step-7.svg'
import { useEffect, useRef, useState } from 'react'

import useLocalStorageState from 'hooks/useLocalStorageState'

import { Backdrop } from './styles'

const STEP_CONTENT_MAP = [
  {
    image: onboardStep1Image,
    title: 'Get Instant Spot Rates',
    text: (
      <>
        Access real-time spot rates from Loadsmart and other
        <br /> brokers, with Loadsmart guaranteeing 100% tender
        <br /> acceptance for reliable capacity.
      </>
    ),
  },
  {
    image: onboardStep2Image,
    title: 'Invite Carriers to Bid',
    text: (
      <>
        Easily invite your trusted carriers to compete for spot market
        <br /> shipments, ensuring you get the best offers while
        <br /> maintaining strong partnerships.
      </>
    ),
  },
  {
    image: onboardStep3Image,
    title: 'Run RFPs & Manage Contracts',
    text: (
      <>
        Easily create RFPs, run mini-bids, and manage contracts from
        <br /> one platform, streamlining procurement for both spot and
        <br /> contract freight.
      </>
    ),
  },
  {
    image: onboardStep4Image,
    title: 'Book & Manage Shipments',
    text: (
      <>
        Effortlessly book, track, and communicate directly with
        <br /> carriers through in-app chat, ensuring smooth shipment
        <br /> execution and timely issue resolution.
      </>
    ),
  },
  {
    image: onboardStep5Image,
    title: 'Benchmark Costs & Performance',
    text: (
      <>
        Use live market data to compare your freight costs and
        <br /> performance, uncovering opportunities for savings and
        <br /> service improvements.
      </>
    ),
  },
  {
    image: onboardStep6Image,
    title: 'Track Performance with Reports',
    text: (
      <>
        Run reports on anything, create metrics and KPIs that are
        <br /> meaningful to you around rates, carriers, lanes, contracts, or
        <br /> any facet of your operation.
      </>
    ),
  },
  {
    image: onboardStep7Image,
    title: 'Lower Costs and Increase Efficiency with AI',
    text: (
      <>
        Unlock actionable recommendations and predictive
        <br /> analytics with Copilot and FreightIntel, empowering you to
        <br /> make smarter, faster freight decisions.
      </>
    ),
  },
]

export default function OnboardDialog() {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [step, setStep] = useState(0)

  const [dontShowAgain, setDontShowAgain] = useLocalStorageState(
    'onboard-dialog',
    { initialValue: false }
  )

  const [isClosed, setIsClosed] = useState(dontShowAgain)

  const [shouldDontShowAgain, setShouldDontShowAgain] = useState(false)

  useEffect(() => {
    if (!dialogRef.current) {
      return
    }

    if (dontShowAgain) {
      dialogRef.current.close()
    } else {
      dialogRef.current.show()
    }
  }, [dontShowAgain, dialogRef])

  if (isClosed) {
    return null
  }

  return (
    <Backdrop>
      <dialog ref={dialogRef}>
        <Layout.Stack gap="none">
          {STEP_CONTENT_MAP.map((stepContent, index) => (
            <img
              key={`onboard-image${index}`}
              src={stepContent.image}
              style={{ display: step === index ? 'block' : 'none' }}
              alt={`Onboard step ${index + 1}`}
            />
          ))}
          <Layout.Stack gap="spacing-10" padding="spacing-12">
            <Layout.Stack gap="spacing-4">
              <Text variant="heading-md-bold">
                {STEP_CONTENT_MAP[step].title}
              </Text>
              <Text variant="body-md">{STEP_CONTENT_MAP[step].text}</Text>
            </Layout.Stack>
            {step <= 5 ? (
              <Layout.Group align="center" justify="space-between">
                <Button
                  variant="primary"
                  style={{ width: '206px' }}
                  onClick={() => setStep(step + 1)}
                >
                  Next <Icon name="arrow-right" color="color-text-inverted" />
                </Button>

                <Text variant="body-md" color="color-text-placeholder">
                  {step + 1} / 7
                </Text>
              </Layout.Group>
            ) : (
              <Layout.Group align="center" justify="space-between">
                <Button
                  variant="primary"
                  style={{ width: '206px' }}
                  onClick={() => {
                    setIsClosed(true)
                    setDontShowAgain(shouldDontShowAgain)
                    dialogRef.current?.close()
                  }}
                >
                  Close & get started
                  <Icon name="check" color="color-text-inverted" />
                </Button>

                <Checkbox
                  onChange={(event) => {
                    setShouldDontShowAgain(event.target.checked)
                  }}
                >
                  Don&apos;t show again
                </Checkbox>
              </Layout.Group>
            )}
          </Layout.Stack>
        </Layout.Stack>
      </dialog>
    </Backdrop>
  )
}
