import { IconAlertCircle } from '@loadsmart/icons'
import { Select, Text as OldText } from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import {
  Layout,
  Divider,
  Text,
  SpinnerWheel,
  EmptyState,
  Checkbox,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { TextClamp, isTextClamped } from 'components/TextClamp'

import type {
  AccessorialNameLabelProps,
  AccessorialNameProps,
  AccessorialOption,
  AccessorialsSelectProps,
  CheckboxGridProps,
} from './types'
import { useAccessorialsOptions } from './useAccessorialsOptions'

const AccessorialName = forwardRef<HTMLDivElement, AccessorialNameProps>(
  function AccessorialNameWithRef({ name }, ref) {
    return (
      <TextClamp ref={ref} wordBreak="break-all">
        {/* We need to use the old Text component here until we find out how to make TextClamp work with the web component */}
        <OldText>{name}</OldText>
      </TextClamp>
    )
  }
)

const AccessorialNameLabel = ({
  name,
  optionsCount,
}: AccessorialNameLabelProps) => {
  const clampRef = useRef<HTMLDivElement>(null)
  const [isClamped, setIsClamped] = useState(false)

  useLayoutEffect(() => {
    /*
     * Since this element is rendered inside a miranda Checkbox the isTextClamped result is always false
     * because the elements' scrollHeight and clientHeight are 0 at that point, only when adding the timeout the actual heights are calculated.
     */
    setTimeout(() => {
      setIsClamped(isTextClamped(clampRef.current))
    }, 0)
  }, [optionsCount])

  if (isClamped) {
    return (
      <Tooltip trigger="hover" message={name}>
        <AccessorialName ref={clampRef} name={name} />
      </Tooltip>
    )
  }

  return <AccessorialName ref={clampRef} name={name} />
}

function CheckboxGrid({ options, value, onChange }: CheckboxGridProps) {
  return (
    <Layout.Grid minColumnWidth="30%" gap="spacing-2">
      {options.map((option) => (
        <Checkbox
          key={option.value}
          id={option.value}
          checked={value.includes(option.value)}
          onChange={(event) => {
            if (event.target.checked) {
              onChange([...value, option.value])
            } else {
              onChange(value.filter((item) => item !== option.value))
            }
          }}
        >
          <AccessorialNameLabel
            name={option.label}
            optionsCount={options.length}
          />
        </Checkbox>
      ))}
    </Layout.Grid>
  )
}

/**
 * This component should only be used if the "favorite accessorials" feature is enabled.
 */
export function AccessorialsSelect({
  mode,
  usage,
  queryOptions,
  value,
  onChange,
}: AccessorialsSelectProps) {
  const { isLoading, isError, onRefetch, favoriteOptions, availableOptions } =
    useAccessorialsOptions({ mode, usage }, queryOptions)

  const { availableSelected, favoriteSelected } = useMemo(
    () => ({
      availableSelected: availableOptions.filter((option) =>
        value.includes(option.value)
      ),
      favoriteSelected: favoriteOptions.filter((option) =>
        value.includes(option.value)
      ),
    }),
    [availableOptions, favoriteOptions, value]
  )

  const onSelectChange = (
    event: EventLike<Selectable | Selectable[] | null>
  ) => {
    const selected = (event.target.value ?? []) as AccessorialOption[]

    onChange([...favoriteSelected, ...selected].map((option) => option.value))
  }

  if (isLoading) {
    return (
      <Layout.Group align="center" justify="center">
        <SpinnerWheel size="32px" aria-label="Loading" />
      </Layout.Group>
    )
  }

  if (isError) {
    return (
      <Layout.Group align="center" justify="center">
        <EmptyState variant="card-horizontal">
          <EmptyState.Illustration>
            <IconAlertCircle
              fill={toCSSValue('color-text-error')}
              title={null}
            />
          </EmptyState.Illustration>
          <EmptyState.Header>Something went wrong</EmptyState.Header>
          <EmptyState.Action onClick={onRefetch}>Try again</EmptyState.Action>
        </EmptyState>
      </Layout.Group>
    )
  }

  return (
    <Layout.Stack>
      {favoriteOptions.length ? (
        <>
          <Text variant="body-sm-bold">Favorites</Text>
          <CheckboxGrid
            options={favoriteOptions}
            value={value}
            onChange={onChange}
          />
        </>
      ) : (
        <Layout.Stack gap="none" align="center">
          <Text variant="body-sm-bold" color="color-text-placeholder">
            No favorites selected.
          </Text>
          <Text variant="body-sm" color="color-text-placeholder">
            Go to &quot;Manage favorites&quot; to select your most used
            accesssorials.
          </Text>
        </Layout.Stack>
      )}
      <Divider />
      <Select
        name="accessorials-select"
        placeholder="Search all accessorials"
        options={availableOptions}
        disabled={!availableOptions.length}
        value={availableSelected as Selectable[]}
        onChange={onSelectChange}
        multiple
      />
      {availableSelected.length > 0 && (
        <CheckboxGrid
          options={availableSelected}
          value={value}
          onChange={onChange}
        />
      )}
    </Layout.Stack>
  )
}
