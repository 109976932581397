import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'

import type { FacilityContactV2 } from 'services/facilities'

import { FacilityContactEmail } from './FacilityContactEmail'
import { FacilityContactName } from './FacilityContactName'
import { FacilityContactPhone } from './FacilityContactPhone'
import type { FacilityContactSummaryContextValue } from './FacilityContactSummary.context'
import {
  FacilityContactSummaryProvider,
  useFacilityContactSummaryContext,
} from './FacilityContactSummary.context'

function ContextBasedFacilityContactName() {
  const { contact } = useFacilityContactSummaryContext()

  return <FacilityContactName name={contact.name} />
}

function ContextBasedFacilityContactPhone() {
  const { contact } = useFacilityContactSummaryContext()

  return (
    <FacilityContactPhone
      country={contact.country}
      phone={contact.phone}
      extension={contact.extension}
    />
  )
}

function ContextBasedFacilityContactEmail() {
  const { contact } = useFacilityContactSummaryContext()

  return <FacilityContactEmail email={contact.email} />
}

export type FacilityContactSummaryProps = PropsWithChildren<
  Nullable<FacilityContactSummaryContextValue>
>

export function FacilityContactSummary({
  contact,
  children,
}: FacilityContactSummaryProps) {
  const contextValue = useMemo(() => {
    // Considering the render validation below, the contact will always exist for consumers
    return { contact: contact ?? ({} as FacilityContactV2) }
  }, [contact])

  if (!contact) {
    return null
  }

  return (
    <FacilityContactSummaryProvider value={contextValue}>
      {children}
    </FacilityContactSummaryProvider>
  )
}

FacilityContactSummary.Name = ContextBasedFacilityContactName
FacilityContactSummary.Phone = ContextBasedFacilityContactPhone
FacilityContactSummary.Email = ContextBasedFacilityContactEmail
