import type { CustomTheme, Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { toCSSValue } from '@loadsmart/miranda-tokens/dist'

import rem from './rem'

interface BorderRadiuses {
  normal: string
  round: string
}

interface BorderStyles {
  active: string
  dashed: string
  inactive: string
  normal: string
  ribbon: string
  transparent: string
  table: string
}

interface Widths {
  minWidth: string
  maxWidth: string
}

interface StatusColors {
  color: string
  shadow: string
}
type Status =
  | 'published'
  | 'draft'
  | 'finalized'
  | 'archived'
  | 'closed'
  | 'awarded'
  | 'booked'
  | 'awarding'
type StatusColorsParams = Record<Status, StatusColors>

interface ThemeColors {
  background: string
  backgroundDisabled: string
  backgroundError: string
  backgroundGray: string
  backgroundGrayLightest: string
  backgroundHover: string
  backgroundInfo: string
  backgroundLaneError: string
  backgroundLight: string
  backgroundLightActive: string
  backgroundLightGray: string
  backgroundMainGradient: string
  backgroundMediumGray: string
  backgroundReturnLink: string
  backgroundSecondary: string
  backgroundSuccess: string
  backgroundTransparentAccentMinus20: string
  backgroundYellow: string
  backgroundYellowLight: string
  backgroundYellowLighter: string
  backgroundWhiteNeutral: string
  black: string
  black90: string
  borderLight: string
  borderMenu: string
  borderSecondary: string
  danger: string
  dangerLight: string
  dangerDark: string
  dangerDarker: string
  inactive: string
  infoHighlight: string
  light: string
  main: string
  mainGreen: string
  mediumGray: string
  mediumGreen: string
  neutral: string
  neutralDark: string
  neutralDarker: string
  neutralDarkest: string
  neutralLight: string
  neutralLighter: string
  neutralLightest: string
  secondaryGreen: string
  success: string
  successLight: string
  text: string
  textConfirmation: string
  textDark: string
  textError: string
  textInfo: string
  textNoMatch: string
  textPlaceholder: string
  textSecondary: string
  textStatusYellow: string
  textSuccess: string
  warning: string
  warningDark: string
  white: string
  whiteBroken: string
  textAccent: string
  textTertiary: string
  iconLight: string
  mapLine: string
}

export const colors: ThemeColors = {
  background: '#F5F4F5',
  backgroundDisabled: '#F2F2F2',
  backgroundError: '#FFCCBC',
  backgroundGray: '#BDC7D0',
  backgroundGrayLightest: '#F8F9F9',
  backgroundHover: '#E7EBEF',
  backgroundInfo: '#C0E1F5',
  backgroundLaneError: '#ffebe4',
  backgroundLight: '#DEDEDE',
  backgroundLightActive: '#C4C4C4',
  backgroundLightGray: '#DADADA',
  backgroundMainGradient:
    'linear-gradient(45.01deg, #00D7D7 23.29%, #84F11E 88.12%)',
  backgroundMediumGray: '#CACCCF',
  backgroundReturnLink: '#CFD6DC',
  backgroundSecondary: '#333B43',
  backgroundSuccess: '#C5FAD2',
  backgroundTransparentAccentMinus20: 'rgba(12, 169, 51, 0.2)',
  backgroundYellow: '#FFF5D6',
  backgroundYellowLight: 'rgba(255, 206, 79, 0.3)',
  backgroundYellowLighter: 'rgba(255, 245, 214, 0.3)',
  backgroundWhiteNeutral: '#FDFDFD',
  black: '#000000',
  black90: 'rgba(0, 0, 0, 0.9)',
  borderLight: '#B2B8BB',
  borderMenu: '#979797',
  borderSecondary: '#6A7884',
  danger: '#E86C60',
  dangerLight: '#FFD6E2',
  dangerDark: '#E80045',
  dangerDarker: '#8F002B',
  inactive: '',
  light: '#F0F0F0',
  main: '#29D454',
  mainGreen: '#29d454',
  mediumGray: '#CACCCF',
  mediumGreen: '#33CC60',
  neutral: '#929AA1',
  neutralLighter: '#E9EBEC',
  neutralLight: 'rgba(233, 235, 236, 0.3)',
  neutralDark: '#575C61',
  neutralDarker: '#313336',
  neutralDarkest: '#0A0A0B',
  neutralLightest: '#F8F9F9',
  text: '#303030',
  textDark: '#3C3C3C',
  textConfirmation: '#505050',
  textError: '#D0021B',
  textInfo: '#2A5C85',
  textNoMatch: '#404041',
  textPlaceholder: '#949494',
  textSecondary: '#1D2124',
  textStatusYellow: '#FFD54F',
  textSuccess: '#118043',
  white: '#FFFFFF',
  whiteBroken: '#FCFCFC',
  warning: '#FFCE4F',
  warningDark: '#955800',
  infoHighlight: '#55B0FB',
  secondaryGreen: '#009600',
  successLight: '#C0ECD3',
  success: '#48C881',
  textAccent: '#0CA933',
  textTertiary: '#939AA1',
  iconLight: '#757575',
  mapLine: '#00CB2C',
}

interface Shadows {
  standard: string
  table: string
  white: string
  shadow01: string
  shadow02: string
  shadow03: string
  shadow04: string
  shadow05: string
  shadow06: string
}

export interface Theme {
  borderRadiuses: BorderRadiuses
  borderStyles: BorderStyles
  colors: ThemeColors
  statuses: StatusColorsParams
  fontFamily: string
  fontSize: string
  fontWeight: string
  widths: Widths
  shadows: Shadows
}

export interface RFPGuideTheme extends Theme, CustomTheme {}

type MirandaTokenOverride = Partial<
  Record<keyof typeof Themes.Miranda, string | number>
>

export const MirandaCompatFix: MirandaTokenOverride = {
  'side-navigation-menu-title-font-size': toCSSValue('font-size-2'),
  'toggle-single-font-size': toCSSValue('font-size-3'),
  'toggle-multiple-font-size': toCSSValue('font-size-3'),
}

export const MirandaOverrides: MirandaTokenOverride = {
  'tooltip-max-width': rem('500px'),
}

export const theme: Theme = {
  borderRadiuses: {
    round: '1.8rem',
    normal: '0.25rem',
  },

  borderStyles: {
    transparent: 'thin solid transparent',
    normal: `thin solid ${colors.text}`,
    ribbon: `thick solid ${colors.main}`,
    active: `thin solid ${colors.main}`,
    inactive: `thin solid ${colors.inactive}`,
    dashed: `medium dashed ${colors.inactive}`,
    table: '1px solid rgba(0, 0, 0, 0.1)',
  },

  colors: {
    ...colors,
  },

  statuses: {
    published: {
      color: colors.success,
      shadow: '0px 0px 4px rgba(72, 200, 129, 0.4)',
    },
    draft: {
      color: colors.warning,
      shadow: '0px 0px 4px rgba(255, 206, 79, 0.4)',
    },
    finalized: {
      color: colors.mediumGray,
      shadow: '0px 0px 4px rgba(202, 204, 207, 0.4)',
    },
    archived: {
      color: colors.mediumGray,
      shadow: '0px 0px 4px rgba(202, 204, 207, 0.4)',
    },
    closed: {
      color: colors.success,
      shadow: '0px 0px 4px rgba(72, 200, 129, 0.4)',
    },
    awarding: {
      color: colors.infoHighlight,
      shadow: '0px 0px 4px rgba(85, 176, 251, 0.4)',
    },
    awarded: {
      color: colors.infoHighlight,
      shadow: '0px 0px 4px rgba(85, 176, 251, 0.4)',
    },
    booked: {
      color: colors.textAccent,
      shadow: '0px 0px 4px 0px rgba(72, 200, 129, 0.4)',
    },
  },

  fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
  fontSize: '12px',
  fontWeight: '500',

  widths: {
    minWidth: '102rem',
    maxWidth: '192rem',
  },

  shadows: {
    standard: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    table: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    white: '0px 1px 0px white',
    shadow01: '0px 2px 6px rgba(10, 10, 11, 0.4)',
    shadow02: '0px 2px 10px rgba(41, 212, 84, 0.38)',
    shadow03: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    shadow04: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    shadow05: '0px 6px 4px rgba(0, 0, 0, 0.25)',
    shadow06: '0px 0px 40px rgba(0, 0, 0, 0.1)',
  },
}
