import { createSummarySetup } from 'components/Summary'
import type { FacilityContactV2 } from 'services/facilities'

export type FacilityContactSummaryContextValue = {
  contact: Nullable<
    Pick<
      FacilityContactV2,
      'name' | 'country' | 'phone' | 'extension' | 'email'
    >
  >
}

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<FacilityContactSummaryContextValue>({
    contextName: 'FacilityContactSummaryContext',
  })

export {
  SummaryProvider as FacilityContactSummaryProvider,
  useSummaryContext as useFacilityContactSummaryContext,
}
