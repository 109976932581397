import { datadogRum } from '@datadog/browser-rum'
import type { BrowserOptions } from '@sentry/react'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { hotjar } from 'react-hotjar'

import * as constants from 'utils/constants'
import logger from 'utils/logger'
import loggedUser, { formatUserForAnalytics } from 'utils/user'
import {
  initIntercom,
  setupIntercomSettings,
  trackIntercomEvent,
} from 'vendor/intercom'

export const shouldTrack = () =>
  ['production', 'staging'].includes(constants.ENV)

export const getIgnoredErrorsStaging = () =>
  constants.ENV === 'staging' ? ['ChunkLoadError'] : []

const sentryConfig = (): BrowserOptions => ({
  dsn: constants.SENTRY_DSN,
  environment: constants.ENV,
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // Ignoring Microsoft SafeLink error
    // Taken from: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:1',

    // Resize observer errors can be safely ignored because they don't reflect an actual bug: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
    // We have fixed them for the most part but we still have an error every once in a while that we're unable to reproduce.
    // FWIW, these seem to appear on dropdowns. (like Selects)
    'ResizeObserver loop completed with undelivered notifications.', // Only one user with Firefox on Windows is running into this one on quotes/new
    'ResizeObserver loop limit exceeded',
    constants.SHIPMENT_PROOF_OF_DELIVERY_ERROR,
    // See https://loadsmart.atlassian.net/browse/PNP-236 for more info
    'Failed to init talkjs session',
    // temporary ignore just to reduce the noise in the slack channel while we investigate the issue
    ...getIgnoredErrorsStaging(),
  ],
  // https://sentry.io/organizations/loadsmart/issues/3524986589/events/706141aad0ce432d9b2f905fca144700/?project=5357855
  denyUrls: ['https://app.shipper.guide/en-US.js'],
  release: constants.RELEASE_VERSION
    ? 'rfp-web@' + constants.RELEASE_VERSION
    : undefined,
  normalizeDepth: 6,
})
const datadogConfig = () => ({
  applicationId: constants.DATADOG_APPLICATION_ID,
  clientToken: constants.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: constants.DATADOG_SERVICE_NAME,
  env: constants.ENV,
  version: constants.RELEASE_VERSION,
  sessionSampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  premiumSampleRate: 0,
})

export enum AnalyticsEvent {
  ShipperGuidePageViews = 'ShipperGuide Pageviews',

  //Test Event
  TestEvent = 'Test Event',

  //Global
  FAQ = 'FAQ',

  //RFP Details
  RFPImportLanes = 'RFP / Import Lanes',
  RFPImportLanesFileUpload = 'RFP / Import Lanes / File Upload',
  RFPCreateLaneButtonClicked = 'RFP / Create Lane Button',
  RFPLaneRowClicked = 'RFP / Lane / Open',
  RFPLaneCarrierMenu = 'RFP / Lane / Carrier Menu',
  RFPSidePanelOpen = 'RFP / Side Panel / Open',
  RFPSidePanelClose = 'RFP / Side Panel / Close',
  RFPSaveLaneSuccess = 'RFP / Side Panel / Save Lane Success',
  RFPSaveLaneError = 'RFP / Side Panel / Save Lane Error',
  RFPFinalize = 'RFP / Finalize',
  RFPFinalizeConfirmation = 'RFP / Finalize / Confirmation',
  RFPPublish = 'RFP / Publish',
  RFPPublishConfirmation = 'RFP / Publish / Confirmation',
  RFPCopyInviteLink = 'RFP / Copy Invite Link',
  RFPInviteOrReinviteCarrier = 'RFP / Invite or Resend Invite Email',
  RFPInviteOrReinviteCarrierConfirmation = 'RFP / Invite or Resend Invite Email / Modal confirmation',
  RFPInviteAllToBid = 'RFP / Invite All To Bid',
  RFPFinalizedDialogGoToContractsPage = 'RFP / Finalized / Dialog / Go to Contracts Page',
  RFPAddCarrierButtonClick = 'RFP / Add Carrier Button Click',
  RFPInvitedCarriersColumnSelect = 'RFP / Invited Carriers / Indicators column select',

  //RFP Downloads
  RFPDownloadAllBids = 'RFP / Download / All bids',
  RFPDownloadAwardedCarriers = 'RFP / Download / Awarded carriers',
  RFPDownloadAwardedLetters = 'RFP / Download / Awarded letters',

  //Carrier Recommendation
  RFPListDraftView = 'RFP List / Draft / View',
  LaneActionsDropdownClick = 'Lane / Actions Dropdown / Click',
  LaneActionsDropdownFindCarriersClick = 'Lane / Actions Dropdown / Find Carriers / Click',
  RecommendedCarrierAddToBidClick = 'Find Carriers / Recommended Carrier / Add To Bid / Click',

  //Carriers
  ShipperInviteCarrierManually = 'Carriers / Shipper Invite Carrier Manually',
  ShipperUploadCarrierList = 'Carriers / Shipper Upload Carrier List',
  CarrierToggleAutoSubmitRates = 'Carriers / Toggle Auto-Submit Rates',
  CarrierUploadLanesFileError = 'Carriers / Upload Lanes File Error',
  CarrierModalSignup = 'Carrier Page / Modal / Sign up',
  CarrierCTASignup = 'Carrier Page / Sign up',

  //RFP List (Old Home Page)
  HomeCreateNewBidButton = 'Home / Create New Bid Button',
  HomeBidStatusFilter = 'Home / Bid Status Filters',
  HomeBidSortingDirection = 'Home / Bid Sorting Direction',
  HomeBidSortingColumn = 'Home / Bid Sorting Column',
  HomeBidMenuSelected = 'Home / Bid Menu',
  RFPListSearch = 'RFP List / RFP Search',
  RFPListFilter = 'RFP List / RFP Filter',

  //Award Scenarios
  AwardScenariosFilter = 'Award Scenarios / Filter',
  AwardScenariosAllocateAll = 'Award Scenarios / Allocate All',
  AwardScenariosAllocatedAllConfirm = 'Award Scenarios / Allocate All / Confirm',
  AwardScenariosAllocateSelected = 'Award Scenarios / Allocate Selected',
  AwardScenariosBackupCarriersFilterActive = 'Award Scenarios / Backup Carriers Filter Active',
  AwardScenariosSplitCarriersFilterActive = 'Award Scenarios / Split Carriers Filter Active',
  AwardScenariosCarrierFilter = 'Award Scenarios / Carrier Filter',

  //Sign Up Page
  SignUpView = 'SignUp / View',
  SignUpButtonClicked = 'SignUp / Submit Button',
  SignUpError = 'SignUp / SignUp Error',
  SignUpSuccess = 'SignUp / SignUp Success',
  SignUpReadTerms = 'SignUp / Read Terms',
  SignUpReadUserAgreement = 'SignUp / Read User Agreement',
  SignUpAcceptTerms = 'SignUp / Accept Terms',
  SignUpLoginClicked = 'SignUp / Login Link',

  // Sign Up Revamped Page
  SignupCardClicked = 'Signup / Segmentation / User Card',
  SignupLoginLinkClicked = 'Signup / Segmentation / Login Link',

  //Login Page
  LoginView = 'Login / View',
  LoginButtonClicked = 'Login / Submit Button',
  LoginError = 'Login / Login Error',
  LoginSuccess = 'Login / Login Success',
  LoginForgotPasswordClicked = 'Login / Forgot Password Link',
  LoginSignUpClicked = 'Login / Sign Up Link',
  RedirectUserToLogin = 'Redirect User to Login',

  //Forgot Password Page
  ForgotPasswordButtonClicked = 'Forgot password / Send me the link',
  ForgotPasswordLogin = 'Forgot password / Login',
  ForgotPasswordLoginSuccess = 'Forgot password / Email sent / Login',

  //Reset Password Page
  ResetPasswordSuccess = 'Reset Password / Success',

  // RFP creation
  CreateRFP = 'RFP Guide / RFP Created',

  //One Step Bid Page
  CreateBidCancel = 'Create Bid / Cancel Button',
  CreateBidSuccess = 'Create Bid / Create Bid',
  CreateBidMode = 'Create Bid / Mode',
  CreateBidReceiveRates = 'Create Bid / Receive Rates',
  CreateBidVolumeFrequency = 'Create Bid / Volume Frequency',
  CreateBidAwardAcceptanceDeadline = 'Create Bid / Award Acceptance Deadline',

  // Spot Quote
  CreateSpotQuoteButton = 'Spot quote / overview / create new quote',
  SubmitNewSpotQuote = 'Spot quote /  New quote form / Create quote button',
  PageViewSpotQuote = 'Spot quote / overview / pageviews',
  PageViewSpotQuoteDetails = 'Spot quote / quote details / pageviews',
  InviteAllCarriers = 'Spot quote / Invite all carriers',
  AwardCarrier = 'Spot quote / Award carrier',
  CancelAwardDialog = 'Spot quote / award carrier / dialog / cancel',
  ConfirmAwardDialog = 'Spot quote / award carrier / dialog / confirm',
  InviteCarrierToBid = 'Spot quote / Invite carrier to bid',
  CancelAward = 'Spot quote / Bid list / cancel award',
  TenderShipment = 'Spot quote / tender shipment',
  ContactDrawerSubmit = 'Spot quote / contact drawer / tender shipment',
  ContactDrawerClose = 'Spot quote / contact drawer / cancel button',
  CancelTender = 'Spot Quote / Bid List / Cancel Tender',
  ViewBidHistory = 'Spot quote / show other bids',
  ManageCarrier = 'Spot quote / manage carriers',
  DuplicateQuote = 'Spot quote / Duplicate quote',
  SpotQuoteAttachFile = 'Spot quote / Attach file',
  SpotQuoteUploadFile = 'Spot quote / Upload file',
  SpotQuoteRemoveFile = 'Spot quote / remove file',
  SpotQuoteRefreshRates = 'Spot quote / Refresh rates',

  // New Quote Page
  NewQuotePageview = 'New Quote pageview',
  NewLTLQuotePageView = 'New LTL Quote pageview',
  NewQuoteGetQuote = 'New Quote / Get quote',
  NewQuoteTender = 'New Quote / Tender',
  NewQuoteConfirmTender = 'New Quote / Tender / Confirm tender load',
  NewQuoteTenderAction = 'New Quote / Tender action',
  NewQuoteCheckout = 'New Quote / Checkout',
  NewLTLQuoteServiceDetails = 'New LTL Quote / Service details',
  NewQuoteQuoteIdLink = 'New Quote / Quote details ID link',
  NewQuoteConfirmGetNewQuote = 'New Quote / Changing form modal / Confirm get new quote',
  NewQuoteInviteToBid = 'New Quote / Invite to bid',
  NewLTLQuoteSortingRate = 'New LTL Quote / Sorting rates',
  NewQuotePickupDateSelection = 'New Quote / Pickup date selection',
  NewQuoteBannerManuallyQuoting = 'New Quote / Banner manually quoting',
  NewQuoteOpenBenchmarkModal = 'New Quote / Benchmark / Open modal',
  NewQuoteRateView = 'New Quote / Rate / View',

  // Quote Details Page
  QuoteDetailsPageView = 'Quote details / pageview',
  QuoteDetailsAddNotes = 'Quote details / Add notes',
  QuoteDetailsSaveNote = 'Quote details / Add notes / Save note',
  QuoteDetailsSaveField = 'Quote details / Save field',
  QuoteDetailsCheckout = 'Quote details / Checkout',
  QuoteDetailsCancel = 'Quote details / Cancel',
  QuoteDetailsTender = 'Quote details / Tender',
  QuoteDetailsCancelTender = 'Quote details / Cancel tender',
  QuoteDetailsConfirmTender = 'Quote details / Tender / Confirm tender load',
  QuoteDetailsConfirmTenderAddCarrierResult = 'Quote details / Tender / Add carrier to network / Result',
  QuoteDetailsConfirmTenderSelectCarrier = 'Quote details / Tender / Add carrier to network / Select',
  QuoteDetailsConfirmTenderBack = 'Quote details / Tender / Confirm tender load / Back',
  QuoteDetailsInviteCarriersTab = 'Quote details / Invite Carriers tab',
  QuoteDetailsInviteCarriersToBid = 'Quote details / Invite carriers / Invite to bid',
  QuoteDetailsInviteAllCarriers = 'Quote details / Invite carriers / Invite all carriers',
  QuoteDetailsRateTab = 'Quote details / Rates tab',
  QuoteDetailsSelectCarrierRate = 'Quote details / Select carrier rate',
  QuoteDetailsSortingRate = 'Quote details / Sorting rates',
  QuoteDetailsFilteringRate = 'Quote Details / Filtering rates',
  QuoteDetailsShowHiddenRates = 'Quote Details / Show hidden rates',
  QuoteDetailsSearchRate = 'Quote details / Search rates',
  QuoteDetailsSearchCarriers = 'Quote details / Search carriers',
  QuoteDetailsUserRating = 'Quote details / User Rating',
  QuoteDetailsCounterOffer = 'Quote details / Counter Offer',
  QuoteDetailsCounterOfferSend = 'Quote details / Counter offer / Send',
  QuoteDetailsCompleteDetails = 'Quote details / Complete details',
  QuoteDetailsCompleteShipmentBanner = 'Quote details / Complete shipment banner',
  QuoteDetailsBookedBanner = 'Quote details / Banner booked quote',
  QuoteDetailsBidAgainBanner = 'Quote details / Banner quote updated / Bid again',
  QuoteDetailsInviteCarriersSuggestedLoad = 'Quote details / Invite carriers tab / Suggested carriers / Load',
  QuoteDetailsCarrierInviteResult = 'Quote details / Carrier invite / Result',
  QuoteDetailsRateTabOptionClick = 'Quote details / Rates tab / Option click',
  QuoteDetailsRateTabChatIconClick = 'Quote details / Rates tab / Chat icon',
  QuoteDetailsCarrierInviteOptionClick = 'Quote details / Carrier invite / Option click',
  QuoteDetailsCarrierInviteChatIconClick = 'Quote details / Carrier invite / Chat icon',
  QuoteDetailsAccessorialEdition = 'Quote details / Accessorial edition',
  QuoteDetailsCarrierNotes = 'Quote details / Carrier notes',
  QuoteDetailsCounterOfferDrawer = 'Quote details / Counter offer drawer',
  QuoteDetailsRateView = 'Quote details / Rate / View',
  QuoteDetailsRateStartChat = 'Quote Details / Rate Card / Start Chat',
  QuoteDetailsRateLearnMore = 'Quote Details / Rate Card / Learn More',

  //Quote Details Page (single quote flow, redefined)
  QuoteDetailsAddNotesWithProperties = 'Quote Details / Add bid notes action',
  QuoteDetailsFilterCarrierByTag = 'Quote Details / Filter action',
  QuoteDetailsInviteAllCarriersAction = 'Quote Details / Invite all carriers action',
  QuoteDetailsInvitedSelectedCarriers = 'Quote Details / Invite selected carriers action',
  QuoteDetailsInviteUniqueCarrier = 'Quote Details / Invite carrier to bid individually action',
  QuoteDetailsTenderAction = 'Quote Details / Tender action',
  TimeToTender = 'Time to tender',
  TimeToBookAgnostic = 'Time to book agnostic',
  QuoteDetailsBannerManuallyQuoting = 'Quote Details / Banner manually quoting',
  QuoteDetailsDelete = 'Quote Details / Delete quote',
  QuoteDetailsRateLayoutToggle = 'Quote Details / Rates layout toggle',
  QuoteDetailsOpenBenchmarkModal = 'Quote Details / Benchmark / Open modal',

  //Upload Files Component
  RemoveUploadedFile = 'Upload Files / Remove File Button',
  UploadButtonClicked = 'Upload Files / Upload File Button',

  //Breadcrumbs Navigator Component
  BreadcrumbPreviousPage = 'Breadcrumb / Previous Page',
  BreadcrumbBackButton = 'Breadcrumb / Back Button',

  //Navbar Component
  NavbarLink = 'Navbar / Navigation Link',
  NavbarLogo = 'Navbar / Navigation Logo',
  NavbarLogout = 'Navbar / Logout',
  NavbarLogin = 'Navbar / Login',
  NavbarSwitchLocation = 'Navbar / Switch location',
  NavbarSeeAllLocations = 'Navbar / See all locations',
  NavbarSeeAllLocationsSwitch = 'Navbar / See all locations / Switch location',
  NavbarOpenChat = 'Navbar / Open Chat',

  SettingsOptions = 'Settings Options',
  SaveAccountDetails = 'Save Account Details',
  SaveCompanyDetails = 'Save Company Details',
  AddNewMember = 'Add New Member',
  EditMember = 'Edit Member',
  NewMemberSignIn = 'New Member Sign In',
  DeleteMember = 'Delete Member',
  CancelInvite = 'Cancel Invite',
  ResendInvite = 'Resend Invite',
  SaveExtraFields = 'SaveExtraFields',
  PasswordUpdated = 'Password updated',

  // Company Billing Information
  BillingInfo = 'Billing information',

  //Carrier Award Confirmation
  AwardConfirmation = 'Award confirmation',
  SendFeedback = 'Send Feedback',

  //Carrier Management
  OpenAddNewCarrierPanel = 'Carrier Management / Open The Add New Carrier Panel',
  AddNewCarrierSearchBarClicked = 'Carrier Management / Add New Carrier / Click on Search Bar',
  AddNewCarrierSearchOptionSelected = 'Carrier Management / Add New Carrier / Search Bar Option Selected',
  AddNewCarrierSuccess = 'Carrier Management / Add New Carrier Success',
  AddNewCarrierError = 'Carrier Management / Add New Carrier Error',
  DetailsFilterClicked = 'Carrier Management / Filters / Details',
  AnalyticsFilterClicked = 'Carrier Management / Filters / Analytics',
  OpenEditCarrierPanelFromRowClick = 'Carrier Management / Open The Carrier Details Panel From Row Click',
  OpenCarrierRowMenu = 'Carrier Management / Open The Carrier Row Menu',
  OpenEditCarrierPanelFromEditOptionClick = 'Carrier Management / Carrier Row Menu / Open The Edit Carrier Panel From Edit Option Click',
  OpenDeleteCarrierDialogFromDeleteOptionClick = 'Carrier Management / Carrier Row Menu / Open The Delete Carrier Dialog From Delete Option Click',
  EditCarrier = 'Carrier Management / Edit Carrier Panel / Save & Close',
  OpenDeleteCarrierDialogFromEditCarrierPanel = 'Carrier Management / Edit Carrier Panel / Open The Delete Carrier Dialog From Edit Carrier Panel Trash Icon',
  DeleteCarrier = 'Carrier Management / Delete Carrier Dialog / Delete Carrier',
  DeleteCarrierCancel = 'Carrier Management / Delete Carrier Dialog / Delete Carrier Cancel ',
  AddNewTagToCarrier = 'Carrier Management / Edit Carrier Panel / Add New Tag to Carrier',
  CopiedLastInviteLink = 'Carrier Management / Copied Last Invite Link',
  DownloadAddCarriersTemplate = 'Carrier Management / Template',
  SearchCarriers = 'Carrier Management / Search',
  AnalyticsToggleChange = 'Carrier Management / Tabs',
  ExportCarriers = 'Carrier Management / Export carriers',
  AddCarrier = 'Carrier Management / Add a carrier',
  ImportCarriers = 'Carrier Management / Import carriers',
  ImportCarriersSuccess = 'Carrier Management / Import carriers success',
  CarriersTableSort = 'Carrier Management / Table sort',

  //Carrier Details
  ClickOnEditCarrierButton = 'Carriers / Carrier Details / Edit Carrier',

  //Settings
  AverageWeight = 'Settings / Lane / Average Weight changed',
  AddLocation = 'Settings / Locations / New location added',
  EditLocation = 'Settings / Locations / Location edited',

  //Recommended Carriers
  ClickOnView = 'RFP / Carriers / Recommended / Click on View',
  ViewAddAndInviteToBid = 'RFP / Carriers / Recommended / View / Click on Add & Invite to Bid',
  ViewAddToCarriers = 'RFP / Carriers / Recommended / View / Click on Add to Carriers',
  ViewInviteToBid = 'RFP / Carriers / Recommended / View / Click on Invite to Bid',
  AddAndInviteToBid = 'RFP / Carriers / Recommended / Click on Add & Invite to Bid',
  AddToCarriers = 'RFP / Carriers / Recommended / Click on Add to Carriers',
  InviteToBid = 'RFP / Carriers / Recommended / Click on Invite to Bid',

  //Counter Offer
  CounterOfferNewRateTooltip = 'RFP / Lane / Counter Offer / New Rate Tooltip',
  CounterOfferWaitingTooltip = 'RFP / Lane / Counter Offer / Waiting Tooltip',
  CounterOfferStart = 'RFP / Lane / Carrier Menu / Counter Offer',
  CounterOfferCancel = 'RFP / Lane / Counter Offer / Cancel',
  CounterOfferSend = 'RFP / Lane / Counter Offer / Send',

  //Unsubscribe
  HoverUnsubIconFromRFPInvitedCarriersTableRow = 'RFP / Carriers / Row / Unsubscribed Icon',
  HoverUnsubIconFromCarriersTableRow = 'Carriers / Carrier Row / Unsubscribed Icon',

  //Carriers
  CarrierSubmitOfferOpenModal = 'Carrier Page / Submit',
  CarrierSubmitConfirm = 'Carrier Page / Submit Confirmation / Submit',
  CarrierSubmitCancel = 'Carrier Page / Submit Confirmation / Cancel',
  CarrierUnsubmitOfferOpenModal = 'Carrier Page / Unsubmit',
  CarrierUnsubmitConfirm = 'Carrier Page / Unsubmit Confirmation / Unsubmit',
  CarrierUnsubmitCancel = 'Carrier Page / Unsubmit Confirmation / Cancel',
  CarrierExpandLane = 'Carrier Page / Lane / Collapse',
  CarrierCollapseLane = 'Carrier Page / Lane / Expand',

  //Shipments
  NewShipmentButton = 'Shipment Overview / new shipment button',
  ShipmentDetails = 'Shipment Overview / Shipment details',
  ShipmentsQuoteDetails = 'Shipment Overview / Quote details',
  CancelShipment = 'Shipment Overview / Cancel shipment',
  CancelShipmentConfirmation = 'Shipment Overview / Cancel shipment confirmation',
  DeleteShipment = 'Shipment Overview / Delete shipment',
  DeleteShipmentConfirmation = 'Shipment Overview / Delete shipment confirmation',
  RequestScheduling = 'Shipment Overview / Request scheduling',
  ShipmentOverviewArchive = 'Shipment Overview / Archive shipment',
  ShipmentOverviewUnarchive = 'Shipment Overview / Unarchive shipment',
  ShipmentOverviewDuplicate = 'Shipment Overview / Duplicate shipment',
  ShipmentOverviewSort = 'Shipment Overview / Sort',
  RequestSchedulingConfirmation = 'Shipment Overview / Request scheduling confirmation',
  ShipmentOverviewQuote = 'Shipment Overview / Get Quote',
  ShipmentOverviewPageView = 'Shipment Overview / Pageviews',
  ShipmentOverviewSearchBar = 'Shipment Overview / Search bar usage',
  NewShipmentAddNewFacility = 'New Shipment / New facility',
  NewShipmentSaveNewFacility = 'New Shipment / Save facility',
  NewShipmentAddStop = 'New Shipment / Add Stop',
  NewShipmentReorderStops = 'New Shipment / Reorder Stops',
  NewShipmentRemoveStop = 'New Shipment / Remove Stop',
  NewShipmentAddCommodity = 'New Shipment / Add commodity',
  NewShipmentRemoveCommodity = 'New Shipment / Remove commodity',
  NewShipmentStepBack = 'New Shipment / Step Back',
  NewShipmentCreateShipment = 'New Shipment / Create Shipment',
  NewShipmentCreateShipmentGetQuote = 'New Shipment / Create and get price',
  NewShipmentAddOrders = 'New shipment / Add orders',
  NewShipmentOrdersValidation = 'New shipment / orders / validations',
  EditFacility = 'New Shipment / Edit facility button',
  TenderAgainClick = 'Tender Again',

  ShipmentTrackingView = 'Shipment Tracking / View',
  ShipmentTrackingAnotherShipment = 'Shipment Tracking / Track Another Shipment / Confirm',
  PublicShipmentTrackingSeeLocationChecks = 'Public Shipment Tracking / See All Location Checks',
  ShipmentTrackingDetails = 'Shipment / External tracking',
  ShipmentQuoteDetails = 'Shipment / Quote details',
  ShipmentDownloadBOL = 'Shipment / Download BOL',
  ShipmentDownloadShippingLabel = 'Shipment / Download shipping label',
  ArchiveShipment = 'Shipment / Archive action',
  UnarchiveShipment = 'Shipment / Unarchive action',
  BulkArchiveShipment = 'Shipment / Bulk Archive action',
  BulkUnarchiveShipment = 'Shipment / Bulk Unarchive action',
  CopyShipment = 'Shipment / Copy action',
  ShipmentListImportShipments = 'Shipments / Import Shipments',
  ShipmentListShortcuts = 'Shipments Overview / Big Numbers',
  ShipmentListActionButton = 'Shipments Overview / Action Button',
  ShipmentListActionsDropdownClick = 'Shipments Overview / Actions Dropdown / Click',
  ShipmentListRequestSchedulingAction = 'Shipments Overview / Sent scheduling request',
  ShipmentListTabChanged = 'Shipments / Shipment list / Tabs',
  ShipmentDetailsPageView = 'Shipments / Shipment details / View',
  ShipmentDetailsTabChanged = 'Shipments / Shipment details / Tabs',
  ShipmentShareTrackingClick = 'Shipments/ Shipment details / Share tracking',
  ShipmentDetailsSeeMap = 'Shipments / Shipment details / See the map',
  ShipmentDetailsTimelineDownloadPOD = 'Shipments / Shipment details / Timeline / Download POD',
  ShipmentDetailsBannerDownloadPOD = 'Shipments / Shipment details / Banner / Download POD',
  ShipmentDetailsArchiveShipment = 'Shipments / Shipment details / Archive action',
  ShipmentDetailsUnarchiveShipment = 'Shipments / Shipment details / Unarchive action',
  ShipmentDetailsCarrierAskUpdate = 'Shipments / Shipment details / Tracking card',
  ShipmentDetailsCopyShipment = 'Shipments / Shipment details / Copy action',
  ShipmentDetailsCarrierActions = 'Shipment details / Carrier / Actions',
  ShipmentDetailsAskingUpdate = 'Shipment details / Carrier / Asked for update',
  ShipmentDetailsAskingInvoice = 'Shipment details / Carrier / Request invoice',
  ShipmentDetailsSaved = 'Shipments / Shipment details / Save',
  ShipmentDetailsShowCarrierDetails = 'Shipment details / Carrier / Show carrier details',
  ShipmentListApplyFilters = 'Shipment Overview / Apply Filters',
  ShipmentListIdSelection = 'Shipment Overview / Id selection',
  ShipmentListBannerNotifications = 'Shipments Overview / banner notifications',
  ShipmentDetailsDocumentDownload = 'Shipments / Shipment details / Document download',
  ShipmentDetailsChargeCreated = 'Shipments details / Rates / Charge Created',
  ShipmentDetailsChargeRejected = 'Shipments details / Rates / Charge Rejected',
  ShipmentDetailsChargeCanceled = 'Shipments details / Rates / Charge Canceled',
  ShipmentDetailsChargeApproved = 'Shipments details / Rates / Charge Approved',
  ShipmentDetailsReactivateShipmentClick = 'Shipments / Shipment details / Reactivate shipment',
  ShipmentDetailsRateOpenQuoteDetails = 'Shipment details / Open quote details',
  ShipmentDetailsOpenContractPage = 'Shipment Details / Rate / Open contract page',
  ShipmentDetailsRateTenderAgain = 'Shipment Details / Rate / Tender again',

  ShipmentDetailsAddStop = 'Shipments / Shipment details / Add stop',
  ShipmentDetailsAppointment = 'Shipments / Shipment details / Schedule appointment',
  ShipmentDetailsAppointmentScheduled = 'Shipments / Shipment details / Appointment scheduled',
  ShipmentDetailsAppointmentDeleted = 'Shipments / Shipment details / Appointment deleted',
  ShipmentDetailsAppointmentTimeAcknowledged = 'Shipments / Shipment details / Acknowledge schedule',
  ShipmentDetailsOpendockAppointment = 'Shipments / Shipment details / Open dock schedule appointment',
  ShipmentDetailsLaneAdded = 'Shipment details / Edit / Lanes / Add new',
  ShipmentDetailsLaneRemoved = 'Shipment details / Edit / Lanes / Remove',
  ShipmentAutoTenderRulesButton = 'Shipments / auto tender rules button',
  ShipmentAutoTenderRulesSave = 'Auto tender rules / save',
  AuditLogClick = 'Audit log / Accessed',
  ShipmentOverviewTenderAgain = 'Shipment Overview / Tender again',
  ShipmentOverviewGetCarrierQuotes = 'Shipment Overview / Get carrier quotes',
  ShipmentOverviewExtendDeadline = 'Shipment Overview / Extend deadline',
  ShipmentOverviewEditShipment = 'Shipment Overview / Edit',
  ShipmentOverviewQuotingRatesAvailable = 'Shipment Overview / Quoting / Rates available',
  ShipmentOverviewTenderingRatesAvailable = 'Shipment Overview / Tendering / Rates available',

  // Tags
  TagsDialogView = 'Tags component / Individual mode / Add tags dialog view',
  TagsDialogSelectedTag = 'Tags component / Individual mode / Selected tag',
  TagsDialogCreateNewTag = 'Tags component / Individual mode / Create new tag',
  TagsDialogCreateCreateTagConfirmation = 'Tags component / Individual mode / Create tag confirmation',
  TagsBulkAddDialogView = 'Tags component / Bulk mode / Add tags dialog view',
  TagsBulkAddDialogAddTagsConfirmation = 'Tags component / Bulk mode / Add tags confirmation',
  TagsBulkRemoveDialogView = 'Tags component / Bulk mode / Remove tags dialog view',
  TagsBulkRemoveDialogRemoveTagsConfirmation = 'Tags component / Bulk mode / Remove tags confirmation',
  TagsBulkDialogSelectTag = 'Tags component / Bulk mode / Select tag',

  // Manage exceptions
  ManageExceptionsView = 'Manage exceptions dialog / View',
  ManageExceptionsEdited = 'Manage exceptions dialog / Edited',

  //Routing Guide
  RoutingGuideTenderToRFP = 'Routing Guide / Tender to RFP',
  RoutingGuideGetQuote = 'Routing Guide / Get Quotes',
  RoutingGuideGoToQuote = 'Routing Guide / Go to Quote',
  RoutingGuideCancelTender = 'Routing Guide / Cancel Tender ',
  RoutingGuideNewBid = 'Routing Guide / New Bid',

  //Lanes
  LaneSummaryHighestTooltip = 'Lanes / Lane Summary / Highest Tooltip',
  LaneSummaryAverageTooltip = 'Lanes / Lane Summary / Average Tooltip',
  LaneSummaryLowestTooltip = 'Lanes / Lane Summary / Lowest Tooltip',
  LaneSummaryContractTooltip = 'Lanes / Lane Summary / Contract Tooltip',
  LaneSummaryLiveMarketRateTooltip = 'Lanes / Lane Summary / Live Market Tooltip',
  LanesSearch = 'Lanes / Search',
  OpenLane = 'Lanes / Lane',
  LanesLaneGoToRFP = 'Lanes / Lane / Lane Overview / Recent Bids / Go To RFP',
  LanesLaneAwardsDropdown = 'Lanes / Lane / Lane Overview / Awards Dropdown',
  LanesFilter = 'Lanes / Filter',
  LanesDeleteSelected = 'Lanes / Delete Selected',

  //Generate report
  HomeGenerateReport = 'Home / Generate Report',
  HomeGenerateReportExport = 'Home / Generate Report / Export',

  //Checkout
  CheckoutView = 'New Quote / Checkout / View',
  CheckoutBackToQuote = 'New Quote / Checkout / Back to Quote',
  CheckoutBookNow = 'New Quote / Checkout / Book Now',
  CheckoutSuccess = 'New Quote / Checkout / Success',
  CheckoutError = 'New Quote / Checkout / Error',
  CheckoutFullAddress = 'New Quote / Checkout / Has Full Address',
  CheckoutServiceDetails = 'New Quote / Checkout / Service Details',
  CheckoutRefreshQuoteAction = 'New Quote / Checkout / Refresh quote',
  CheckoutRefreshQuoteBanner = 'New Quote / Checkout / Refresh quote banner',

  // Agnostic Checkout
  CheckoutAgnosticBookNow = 'Checkout / Agnostic / Book Now',
  CheckoutAgnosticBackToQuote = 'Checkout / Agnostic / Back to Quote',
  CheckoutAgnosticQuoteLink = 'Checkout / Agnostic / Quote Link',

  // LTL Checkout Page
  LtlCheckoutAddFacility = 'New Quote / Checkout / Add Facility / View',
  LtlCheckoutAddFacilitySuccess = 'New Quote / Checkout / Add Facility / Success',

  //Bulk Quote
  BulkCreateFTLQuotesFromShipmentList = 'Shipments / actions / Bulk FTL Quotes',
  BulkQuotesDetailsClickShipmentBreadcrumb = 'Bulk Quote details / Shipments Breadcrumb',

  // Import Shipments
  ImportShipmentsUploadFile = 'Import Shipments / Upload File',
  ImportShipmentsUploadNewFile = 'Import Shipments / upload new file',
  ImportShipmentsErrorTemplate = 'Import shipments / download error template',
  ImportShipmentsSuccess = 'Import Shipments / Success',
  ImportShipmentsBackToShipments = 'Import Shipments / back to shipments',
  ImportShipmentsQuote = 'Import Shipments / Quote with carriers',
  ImportShipmentsErrors = 'Import Shipments / FE errors',

  //Multi Quote
  MultiQuoteButtonClick = 'Quotes / Multi Quote button',
  MultiQuoteUploadFile = 'Multi Quote / Upload File',
  MultiQuoteClickQuoteWithCarriers = 'Multi Quote / Quote with carriers',
  MultiQuoteBackToQuoteList = 'Multi Quote / Back to quotes',
  MultiQuoteDownloadErrorReport = 'Multi Quote / download error report',
  MultiQuoteSuccess = 'Multi Quote / Success',
  MultiQuoteFEErrors = 'Multi Quote / FE errors',

  //Facility Management
  FacilityManagementView = 'Facility Management / View',
  FacilityManagementNewFacility = 'Facility Management / New Facility',
  FacilityManagementEditFacility = 'Facility Management / Edit facility',
  FacilityManagementContextualMenu = 'Facility Management / Contextual Menu',
  FacilityManagementDeleteFacility = 'Facility Management / Contextual Menu / Delete',
  FacilityManagementDeleteFacilityConfirm = 'Facility Management / Contextual Menu / Delete / Confirm',
  FacilityManagementDeleteFacilityCancel = 'Facility Management / Contextual Menu / Delete / Cancel',
  FacilityManagementAddressNotFound = 'Facility Management / Address not found',
  FacilityManagementSave = 'Facility Management / Save',
  FacilityManagementFacilityCreated = 'Facility created',
  FacilityManagementFacilityUpdated = 'Facility edited',

  FacilityDrawerDetailsView = 'Facility Management / View Facility',
  ContactsDrawerDetailsView = 'View contacts drawer',
  ContactsDrawerEditView = 'Edit contacts drawer',

  //Contracts
  ContractDetails = 'Contracts Overview / Contracts details',
  ArchiveContract = 'Contracts Overview / Archive Contract',
  DuplicateContract = 'Contracts Overview / Duplicate Contract',
  EditContract = 'Contracts Overview / Edit Contract',
  ContractAuditLogs = 'Contracts Overview / Logs / Open Modal',
  NewContractButton = 'Contracts Overview / New Contract button',
  NewContractCreateContract = 'New Contract / Create Contract',
  EditContractUpdateContract = 'Edit Contract / Update Contract',
  TenderContractLane = 'Contracts / Lane / Tender Lane',
  TenderContractLaneConfirm = 'Contracts / Lane / Tender Lane / Confirm',
  TenderContractLaneCancel = 'Contracts / Lane / Tender Lane / Cancel',

  //Bulk Quote Freemium Quota
  GoUnlimitedButtonClick = 'Bulk Invite / Hubspot Banner clicks',
  HubSpotFormClose = 'Bulk Invite / Closed form',

  // Orders
  OrdersListPageView = 'Orders List / pageviews',
  OrdersListSearch = 'Orders List / Search',
  OrdersListFilters = 'Orders List / Apply filters',
  OrdersListNewShipment = 'Orders List / New shipment',
  OrdersListNewShipmentFromSelection = 'Orders List / New shipment selection',
  OrdersListLineDropdown = 'Orders List / line dropdown',
  OrderDeleted = 'Order Deleted / Success',
  OrderDetailsPageView = 'Order Details / pageviews',
  OrderDetailsGoToShipment = 'Order Details / Go to Associated Shipment Link',

  // Quotes
  DuplicatedQuote = 'Quotes / Duplicate Quote',
  CreateNewQuote = 'Quotes / Create new quote',
  QuotesBulkDelete = 'Quotes / Bulk delete quotes',
  QuotesDelete = 'Quotes / Delete quote',
  QuotesFiltered = 'Quotes / Apply filters',
  QuotesNoFilters = 'Quotes / Clear filters',
  QuoteOpenRateDetails = 'Quotes / Open rate details',
  QuotesPageView = 'Quotes / pageviews',
  QuoteSelectRate = 'Quotes / Select rate',
  QuotesSearch = 'Quotes / Search',
  QuotesSorting = 'Quotes / Sort',
  QuoteSuggestedRates = 'Quotes / Suggested rates',
  QuotesTargetLaneUpload = 'Quote details / Target lane upload',

  // LTL Quote only
  LTLQuoteNmfcDrawerView = 'New LTL Quote / NMFC drawer / View',
  LTLQuoteNmfcDrawerResults = 'New LTL Quote / NMFC drawer / Show results',
  LTLQuoteNmfcDrawerSelect = 'New LTL Quote / NMFC drawer / Select',

  // Bulk Invite
  BulkInviteTagsFilter = 'Bulk invite / Carrier tags',

  BulkInviteFromQuotes = 'Quotes / Invite to Bid',
  BulkInviteFromShipments = 'Shipments / Invite to Bid',
  BulkInvitePageView = 'Bulk Invite / pageviews',
  BulkInviteAddNotes = 'Bulk Invite / add notes',
  BulkInviteSelectAll = 'Bulk invite / select all',
  BulkInviteAllCarriers = 'Bulk invite / Invite all Carriers',
  BulkInviteSelectedCarriers = 'Bulk invite / Invite selected',
  BulkInviteCarrier = 'Bulk invite / Invite unique',
  BulkInviteSearchCarriers = 'Bulk invite / search',
  BulkInviteViewUnavailableAction = 'Bulk Invite / view unavailable actions',
  BulkInviteIDLink = 'Bulk Invite / ID link',

  // Bulk Tender
  BulkTenderModalUsage = 'Bulk tender / modal usage',
  ShipmentsBulkTender = 'Shipments / bulk tender',

  // In App Chat
  CreateNewChat = 'In App Chat / Create new chat',
  NewChatCreated = 'In App Chat / New chat created',
  NewChatContactCreated = 'In App Chat / New contact created',
  ChatNotification = 'In App Chat / Chat notification',
  ChatPageView = 'In App Chat / View chat page',
  ChatMessageSent = 'In App Chat / New message sent',
  ChatSubjectChange = 'In App Chat / Edit group chat name',
  LeaveChatGroup = 'In App Chat / Leave chat group',
  ManageChatGroupContacts = 'In App Chat / Manage group chat contacts',

  // Accessorials
  ManageFavorites = 'Accessorial / Manage favorites',

  // FreightWaves
  FreightWavesHowItWorks = 'FreightWaves / How it works',
  FreightWavesDownloadTemplate = 'FreightWaves / Download template',
  FreightWavesUploadFile = 'FreightWaves / Upload file',
  FreightWavesAddANewLane = 'FreightWaves / Add a new lane',
  FreightWavesDeleteAllLanes = 'FreightWaves / Delete all lanes',
  FreightWavesContractTooltip = 'FreightWaves / Contract tooltip',
  FreightWavesOTRITooltip = 'FreightWaves / OTRI tooltip',
  FreightWavesRateTypeToogle = 'FreightWaves / Rate type toggle',
  FreightWavesTableSort = 'FreightWaves / Table sort',
  FreightWavesWelcomeActivateTrial = 'FreightWaves / Welcome / Activate trial',
  FreightWavesWelcomeActivateTrialSuccess = 'FreightWaves / Welcome / Activate trial / Success',
  FreightWavesLearnMore = 'FreightWaves / Learn more',
  FreightWavesWelcome = 'FreightWaves / Welcome',
  FreightWavesUserAgreement = 'FreightWaves / User agreement',
  FreightWavesDeleteAllLanesAllLanesDeleted = 'FreightWaves / Delete all lanes / All lanes deleted',
  FreightWavesAddANewLaneSaveLane = 'FreightWaves / Add a new lane / Save lane',
  FreightWavesUploadLanesSelectFile = 'FreightWaves / Upload lanes / Select file',
  FreightWavesUploadLanesFileUploaded = 'FreightWaves / Upload lanes / File uploaded',

  MapContainerSeeMap = 'Map Container / See the map',

  // Carrier Relationship Status
  OutOfNetworkModalConfirm = 'Out-of-network Modal / Update Status',
  OutOfNetworkModalCancel = 'Out-of-network Modal / Cancel',

  // Save Filters
  SaveFilterDropdownView = 'Save filter / Dropdown view',
  SaveFilterSelectedFilter = 'Save filter / Selected a filter',
  SaveFilterDeleteDialogView = 'Save filter / Delete dialog view',
  SaveFilterDeletedFilter = 'Save filter / Deleted filter',
  SaveFilterDialogView = 'Save filter / Dialog view',
  SaveFilterNewFilterCreated = 'Save filter / New filter created',
}

export enum AnalyticsEventTrigger {
  click = 'click',
  select = 'select',
  redirect = 'redirect',
  success = 'success',
  error = 'error',
  hover = 'hover',
  view = 'view',
}

export enum IntercomEvent {
  GetQuote = 'Get quote',
  ChargesTabTour = 'ACS Execution Tab Tour',
  AddNewChargeTour = 'ACS Execution Add Charge Tour',
  ShipmentDetailsTour = 'Shipment Details Feature Tour',
}

let isUserSet = false

function hotjarRegister(user: UserData) {
  try {
    const hj = (window as any).hj
    hj('tagRecording', [
      `env:${constants.ENV}`,
      `shipper:${user.shipper_company}`,
    ])
  } catch (error: any) {
    logger.error('Failed to register hotjar user.', {
      error,
      user: { ...user },
    })
    Sentry.captureException(error)
  }
}

function hotjarIdentify(user: UserData) {
  try {
    hotjar.identify(user.user_uuid, {
      paidUser: user.shipper_is_active,
      testUser: user.user_is_test,
      email: user.user_email,
      mtUser: user.is_managed_trans,
      company: user.shipper_company,
    })
  } catch (error: any) {
    logger.error('Failed to identify hotjar user.', {
      error,
      user: { ...user },
    })
    Sentry.captureException(error)
  }
}

function setMixPanelUser(userData: any): void {
  const {
    email,
    name,
    company,
    roles,
    type,
    shipper_test,
    user_category,
    business_type,
  } = userData
  mixpanel.identify(email)
  mixpanel.register(userData)
  mixpanel.people.set({
    id: email,
    $email: email,
    $name: name,
    company,
    roles,
    type,
    shipper_test,
    user_category,
    business_type,
  })
}

const setupIntercom = (user: UserData) => {
  try {
    setupIntercomSettings({
      email: user.user_email,
      name: user.user_name,
      createdAt: Math.floor(new Date(user.user_date_joined).getTime() / 1000),
    })
    initIntercom()
  } catch (error) {
    Sentry.captureException(error)
  }
}

const setGA4User = (...args: unknown[]) => {
  // I've noticed (reading some examples) that gtag it's just a wrapper to window.dataLayer.push
  // so this is why this function just reference window.dataLayer
  // https://developers.google.com/tag-platform/tag-manager/web/datalayer#how_data_layer_information_is_processed
  window.dataLayer?.push(args)
}

export default {
  init: () => {
    if (!shouldTrack()) {
      return
    }

    Sentry.init(sentryConfig())
    mixpanel.init(constants.MIXPANEL_TOKEN)
    hotjar.initialize(constants.HOTJAR_ID, constants.HOTJAR_VERSION)
    logger.init()
    datadogRum.init(datadogConfig())
  },
  setUser: (user: UserData) => {
    if (!shouldTrack() || isUserSet) {
      return
    }

    const formattedData = user.user_uuid ? formatUserForAnalytics(user) : user
    const userData = { ...formattedData }

    // taken from: https://support.google.com/analytics/answer/12370404#zippy=%2Cgoogle-tag-websites
    setGA4User('set', 'user_properties', { ...user, ...userData })
    setMixPanelUser(formattedData)
    Sentry.setUser({ ...user, ...userData })
    hotjarRegister(user)
    hotjarIdentify(user)
    logger.setUser(userData)
    datadogRum.setUser(userData)
    setupIntercom(user)

    isUserSet = true
  },
  track: (
    event: AnalyticsEvent,
    trigger = 'click',
    props: Record<string, any> = { trigger: '' },
    specialFormat: string[] = []
  ) => {
    const { co2_emissions, market_rate, contract_rate, preferences } = props
    if (specialFormat.includes('lane')) {
      props.co2_emission_calculated = !!co2_emissions
      props.market_rate_calculated = !!market_rate
      props.short_term_available = !!contract_rate
    }

    if (specialFormat.includes('rfpLanes')) {
      delete props.preferences
    }

    if (specialFormat.includes('lane') || specialFormat.includes('rfpLanes')) {
      const modes = preferences.reduce(
        (acc: string[], preference: LanePreference) => {
          if (!acc.includes(preference.mode)) {
            return [...acc, preference.mode]
          }
          return acc
        },
        []
      )
      props.mode = modes.join(', ')
      props.multimode = modes.length > 1
    }

    if (!shouldTrack() || loggedUser.user?.user_is_test) {
      // eslint-disable-next-line no-console
      console.log(
        `analytics.track('${event}', ${trigger}, ${JSON.stringify(props)})`
      )
      return
    }

    props.trigger = trigger
    mixpanel.track(event, props)
    logger.info(event, { ...props, metric_type: event })
  },
  reset: () => {
    if (!shouldTrack()) {
      return
    }
    mixpanel.reset()
  },
  sendEventToIntercom: (
    event: IntercomEvent,
    props: Record<string, unknown> = {}
  ) => {
    if (!shouldTrack()) {
      // eslint-disable-next-line no-console
      console.log(
        `analytics.sendEventToIntercom('${event}', ${JSON.stringify(props)})`
      )
      return
    }

    trackIntercomEvent(event, props)
    logger.info(event, { ...props, metric_type: event })
  },
}
