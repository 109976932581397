import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import styled, { css } from 'styled-components'

const lockedCSS = css`
  border-color: ${toCSSValue('color-border-input')};
`

function getInputStatusCSS(status: string) {
  switch (status) {
    case 'locked':
      return lockedCSS
  }
}

export const inputStyle = css<{ status?: string; error?: boolean }>`
  border: 1px solid ${toCSSValue('color-border-input')};
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  line-height: 21px;
  outline: none;
  padding: 10px 8px;
  width: 100%;
  background-color: ${toCSSValue('color-background-primary')};

  &[type='number'] {
    appearance: textfield;
  }

  ::placeholder {
    color: ${toCSSValue('color-text-placeholder')};
  }

  :focus {
    border: 1px solid ${toCSSValue('color-primary-100')};
  }

  @supports (-moz-appearance: none) {
    line-height: 14px;
  }

  :disabled {
    background: ${toCSSValue('color-background-tertiary')};

    ::-webkit-input-placeholder {
      color: ${toCSSValue('color-text-placeholder')};
    }
    ::-moz-placeholder {
      color: ${toCSSValue('color-text-placeholder')};
    }
    :-ms-input-placeholder {
      color: ${toCSSValue('color-text-placeholder')};
    }
    :-moz-placeholder {
      color: ${toCSSValue('color-text-placeholder')};
    }
  }

  ${({ status }) => status && getInputStatusCSS(status)}

  ${({ error }) =>
    error &&
    `
    border: 1px solid ${toCSSValue('color-danger-60')};
  `}
`

export default styled.input`
  ${inputStyle}
`
