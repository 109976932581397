import type { TextProps } from '@loadsmart/miranda-react'
import { Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { formatFacilityLocation } from './FacilityDetails.helpers'

export type FacilityLocationProps = Readonly<
  Pick<FacilityDetailsV2, 'city' | 'state' | 'zipcode'> &
    Pick<TextProps, 'variant' | 'color'>
>

export function FacilityLocation({
  city,
  state,
  zipcode,
  variant = 'body-md',
  color = 'color-text-primary',
}: FacilityLocationProps) {
  return (
    <Text variant={variant} color={color} data-testid="facility-location">
      {formatFacilityLocation({ city, state, zipcode })}
    </Text>
  )
}
