import {
  DateFormatHelper,
  DateHelper,
  Drawer,
  Spinner,
} from '@loadsmart/loadsmart-ui'
import { Button, Divider, Layout, Text } from '@loadsmart/miranda-react'
import { Fragment } from 'react'

import { formatCurrentTimezoneAbbreviation } from 'utils/dateUtils'

import type {
  AuditLogsDrawerBodyProps,
  AuditLogsDrawerProps,
  AuditLogItemProps,
} from './types'
import { useAuditLogs } from './useAuditLogs'

function AuditLogItem({ log }: AuditLogItemProps) {
  return (
    <Layout.Stack gap="spacing-2">
      <Text variant="body-sm" color="color-text-secondary">
        {`${DateFormatHelper('MM/DD/YYYY HH:mm:ss').format(
          DateHelper(log.timestamp, { normalize: false })
        )} (${formatCurrentTimezoneAbbreviation()})`}
      </Text>
      <Text variant="body-md" color="color-text-secondary">
        <strong>{log.name}</strong>
        {log.description && (
          <>
            <strong>{' • '}</strong>
            {log.description}
          </>
        )}
        {log.username && (
          <>
            {' '}
            <Text variant="body-md" color="color-text-placeholder">
              by
            </Text>{' '}
            <strong>{log.username}</strong>
          </>
        )}
      </Text>
    </Layout.Stack>
  )
}

function AuditLogsDrawerBody({
  emptyMessage = 'There are no updates yet.',
  kind,
  uuid,
  related,
  queryOptions,
}: AuditLogsDrawerBodyProps) {
  const { logs, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useAuditLogs({ kind, uuid, related }, queryOptions)

  if (isLoading) {
    return (
      <Layout.Stack align="center">
        <Spinner size={48} aria-label="Loading" />
      </Layout.Stack>
    )
  }

  if (!logs.length) {
    return (
      <Layout.Stack align="center">
        <Text variant="body-md-bold">{emptyMessage}</Text>
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack gap="spacing-3" align="stretch">
      {logs.map((log, index) => (
        <Fragment key={log.uuid}>
          <AuditLogItem log={log} />
          {index < logs.length - 1 && <Divider />}
        </Fragment>
      ))}
      {(hasNextPage || isFetchingNextPage) && (
        <Layout.Stack align="center" justify="center">
          {isFetchingNextPage ? (
            <Spinner size={40} aria-label="Loading more" />
          ) : (
            <Button
              variant="secondary"
              onClick={() => {
                fetchNextPage()
              }}
            >
              Load more
            </Button>
          )}
        </Layout.Stack>
      )}
    </Layout.Stack>
  )
}

export function AuditLogsDrawer({
  isOpen,
  onClose,
  title,
  emptyMessage = 'There are no updates yet.',
  kind,
  uuid,
  related,
  queryOptions,
}: AuditLogsDrawerProps) {
  return (
    <Drawer open={isOpen} onClose={onClose} data-testid="audit-logs-drawer">
      <Drawer.Header>{title}</Drawer.Header>
      <Drawer.Body>
        <AuditLogsDrawerBody
          emptyMessage={emptyMessage}
          kind={kind}
          uuid={uuid}
          related={related}
          queryOptions={queryOptions}
        />
      </Drawer.Body>
    </Drawer>
  )
}
