import { Icon, Layout, Text, Tooltip } from '@loadsmart/miranda-react'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'
import {
  formatPreviousScheduledTime,
  getAppointmentDateTime,
  getAppointmentDescription,
} from 'screens/Shipper/Shipments/Details/v2/AppointmentCard/utils'
import { formatTimezoneAbbreviation } from 'utils/dateUtils'
import { capitalize } from 'utils/strings'

import AppointmentMissingDate from './AppointmentMissingDate'
import AppointmentOpenDockLogo from './AppointmentOpendockLogo'

type AppointmentDateTimeProps = Readonly<{
  appointment: StopAppointment
  warehouseUUID: string | null | undefined
}>

export default function AppointmentDateTime({
  appointment,
  warehouseUUID,
}: AppointmentDateTimeProps) {
  const { date: appointmentDate, time: appointmentTime } =
    getAppointmentDateTime(appointment)

  const previousScheduledTime = formatPreviousScheduledTime(appointment)

  return appointmentDate && appointmentTime ? (
    <Layout.Group align="center" gap="spacing-1">
      <Text variant="body-md-bold" color="color-text-primary">
        {getAppointmentDescription(appointment)}{' '}
      </Text>
      <Text variant="body-md-bold" color="color-text-disabled">
        <span style={{ textDecorationLine: 'line-through' }}>
          {previousScheduledTime}
        </span>
      </Text>
      <Text variant="body-md-bold" color="color-text-primary">
        {`(${appointment.mode} -`.toUpperCase()}
      </Text>
      <Text variant="body-md-bold" color="color-text-primary">
        {capitalize(`${appointment.loading_type})`)}
      </Text>
      {appointmentDate.timezone && (
        <>
          <Text variant="body-md-bold">•</Text>
          <Text
            variant="body-md-bold"
            color="color-text-primary"
            data-testid="timezone"
          >
            {formatTimezoneAbbreviation(appointmentDate.timezone)}
          </Text>
          <Tooltip
            placement="right"
            trigger="hover"
            message={
              <Layout.Stack gap="spacing-0-5">
                <span>{appointmentDate.timezone}</span>
                <span>Facility location timezone</span>
              </Layout.Stack>
            }
          >
            <Icon name="question" size="16px" color="color-text-tertiary" />
          </Tooltip>
        </>
      )}
      <AppointmentOpenDockLogo warehouseUUID={warehouseUUID} />
    </Layout.Group>
  ) : (
    <AppointmentMissingDate warehouseUUID={warehouseUUID} />
  )
}
