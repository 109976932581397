import { IconArrowLeft, IconPlus } from '@loadsmart/icons'
import { Button, Icon } from '@loadsmart/miranda-react'
import type { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { createTransientCollectionSetup } from 'components/TransientCollectionForm'
import { useIndexerContext } from 'contexts/indexer'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import type { TransientStop } from './Stops.types'

export const { TransientCollectionForm, useTransientCollectionFormContext } =
  createTransientCollectionSetup<TransientStop>()

const IconArrowDown = styled(IconArrowLeft)`
  transform: rotate(90deg);
`

const IconArrowUp = styled(IconArrowLeft)`
  transform: rotate(-90deg);
`

const StopsFormAddStop = ({
  children = 'Add stop',
  addAt,
}: {
  readonly children?: ReactNode
  readonly addAt?: number
}) => {
  const [, dispatch] = useTransientCollectionFormContext()

  return (
    <Button
      size="small"
      variant="tertiary"
      leading={
        <IconPlus
          aria-hidden="true"
          width={12}
          title={null}
          aria-label={null}
        />
      }
      onClick={() => {
        analytics.track(
          AnalyticsEvent.ShipmentDetailsLaneAdded,
          AnalyticsEventTrigger.click
        )
        dispatch({ type: 'ADD_ITEM', ...(addAt ? { payload: { addAt } } : {}) })
      }}
    >
      {children}
    </Button>
  )
}

const StopsFormRemoveStop = () => {
  const [, dispatch] = useTransientCollectionFormContext()
  const index = useIndexerContext()

  return (
    <Button
      aria-label="Remove stop"
      title="Remove stop"
      size="small"
      variant="icon-secondary"
      onClick={() => {
        dispatch({ type: 'REMOVE_ITEM', payload: { index } })
        analytics.track(
          AnalyticsEvent.ShipmentDetailsLaneRemoved,
          AnalyticsEventTrigger.click
        )
      }}
    >
      <Icon name="trash" size="20" color="color-primary-100" />
    </Button>
  )
}

const StopsFormMoveStopUp = () => {
  const [, dispatch] = useTransientCollectionFormContext()
  const index = useIndexerContext()

  return (
    <Button
      aria-label="Move stop up"
      title="Move stop up"
      size="small"
      variant="icon"
      onClick={() => {
        dispatch({ type: 'MOVE_ITEM', payload: { index, direction: 'up' } })
      }}
    >
      <IconArrowUp width={12} height={12} title={null} />
    </Button>
  )
}

const StopsFormMoveStopDown = () => {
  const [, dispatch] = useTransientCollectionFormContext()
  const index = useIndexerContext()

  return (
    <Button
      aria-label="Move stop down"
      title="Move stop down"
      size="small"
      variant="icon"
      onClick={() => {
        dispatch({ type: 'MOVE_ITEM', payload: { index, direction: 'down' } })
      }}
    >
      <IconArrowDown width={12} height={12} title={null} />
    </Button>
  )
}

export type StopsFormProps = PropsWithChildren<{
  readonly stops: TransientStop[]
  readonly createStop: (stop?: Partial<TransientStop>) => TransientStop
  readonly onChange: (items: TransientStop[]) => void
  readonly onAddStop?: (addedAt: number) => void
  readonly onDeleteStop?: (stopIndex: number) => void
}>

function InnerStopsForm(props: StopsFormProps) {
  const { children, stops, createStop, onChange, onAddStop, onDeleteStop } =
    props

  return (
    <TransientCollectionForm
      items={stops}
      createItem={createStop}
      onChange={onChange}
      onAddItem={onAddStop}
      onDeleteItem={onDeleteStop}
    >
      {children}
    </TransientCollectionForm>
  )
}

export const StopsForm = Object.assign(InnerStopsForm, {
  AddStop: StopsFormAddStop,
  RemoveStop: StopsFormRemoveStop,
  MoveStopUp: StopsFormMoveStopUp,
  MoveStopDown: StopsFormMoveStopDown,
})
