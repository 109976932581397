import { buildCurrencyComponent, defaultCurrencyMask } from './Currency'

const ConversionRate = buildCurrencyComponent(
  {
    ...defaultCurrencyMask,
    prefix: '',
    decimalLimit: 5,
  },
  '1.325'
)

export default ConversionRate
