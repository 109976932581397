import { forwardRef, useImperativeHandle } from 'react'
import type { Ref } from 'react'

import type { PolylineProps } from './usePolyline'
import { usePolyline } from './usePolyline'

type PolylineRef = Ref<google.maps.Polyline | null>

/**
 * Component to render a polyline on a map
 */
export const Polyline = forwardRef((props: PolylineProps, ref: PolylineRef) => {
  const polyline = usePolyline(props)

  useImperativeHandle(ref, () => polyline, [polyline])

  return null
})

Polyline.displayName = 'Polyline'
