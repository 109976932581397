import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, Toggle, ToggleGroup } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const NAME = 'add_stop_off_charges'
const LABEL = 'Stop off charges'
const YES_VALUE = 'yes'
const NO_VALUE = 'no'

type FreightInformationStopOffChargesProps = Readonly<{
  required?: FieldProps['required']
  disabled?: boolean
  hint?: string
  error?: string
  style?: CSSProperties
}>

export function FreightInformationStopOffCharges({
  required,
  disabled,
  hint,
  error,
  style,
}: FreightInformationStopOffChargesProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${NAME}-field`}
    >
      <Field.Label id={`label-${NAME}`}>{LABEL}</Field.Label>
      <ToggleGroup
        aria-labelledby={`label-${NAME}`}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [NAME]: String(get(event, 'target.value')) === YES_VALUE,
            },
          })
        }}
        value={shipment[NAME] ? YES_VALUE : NO_VALUE}
        type="single-strict"
        disabled={disabled}
      >
        <Toggle value={NO_VALUE}>No</Toggle>
        <Toggle value={YES_VALUE}>Yes</Toggle>
      </ToggleGroup>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
