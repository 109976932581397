const IconMegaPhone = ({ ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.25 7.50002C15.249 6.63837 14.9063 5.81229 14.297 5.20302C13.6877 4.59374 12.8617 4.25101 12 4.25002H9.52003C9.30503 4.23689 6.23253 4.00002 3.30378 1.54377C3.12159 1.39081 2.89954 1.29301 2.66371 1.26186C2.42788 1.23071 2.18805 1.2675 1.9724 1.36791C1.75675 1.46832 1.57423 1.62818 1.44627 1.82872C1.31831 2.02925 1.25023 2.26214 1.25002 2.50002V12.5C1.24852 12.7382 1.31577 12.9717 1.44371 13.1726C1.57164 13.3735 1.75482 13.5332 1.97127 13.6325C2.1868 13.7343 2.42706 13.7718 2.66335 13.7407C2.89964 13.7095 3.12197 13.611 3.30378 13.4569C4.80286 12.1967 6.58635 11.3206 8.50003 10.9044V12.5419C8.49999 12.7476 8.55075 12.9502 8.64778 13.1316C8.74482 13.313 8.88513 13.4677 9.05628 13.5819C9.08402 13.6006 9.11305 13.6173 9.14315 13.6319L10.0457 14.0731C10.2125 14.173 10.4003 14.2326 10.5941 14.2471C10.788 14.2617 10.9826 14.2308 11.1625 14.157C11.3423 14.0831 11.5025 13.9683 11.6302 13.8217C11.7579 13.6751 11.8496 13.5008 11.8982 13.3125L12.5913 10.6988C13.3381 10.5597 14.0128 10.1638 14.4984 9.57959C14.984 8.99537 15.2499 8.25969 15.25 7.50002ZM2.75002 11.9769V3.02502C5.01065 4.77939 7.26065 5.40627 8.50003 5.62877V9.37502C7.26065 9.59502 5.01065 10.2219 2.75002 11.9769ZM10.5244 12.6375L10 12.3813V10.75H11.025L10.5244 12.6375ZM12 9.25002H10V5.75002H12C12.4642 5.75002 12.9093 5.93439 13.2375 6.26258C13.5657 6.59077 13.75 7.03589 13.75 7.50002C13.75 7.96415 13.5657 8.40927 13.2375 8.73746C12.9093 9.06564 12.4642 9.25002 12 9.25002Z" />
  </svg>
)

export default IconMegaPhone
