import { Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties, ReactNode } from 'react'

import { useTarpTypes } from 'hooks/useMetadata'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

export const TARP_TYPE_NAME = 'tarp_type'
const TARP_TYPE_LABEL = 'Tarp type'

type FreightInformationTarpTypeFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: ReactNode
  readonly error?: string
  readonly style?: CSSProperties
  readonly label?: string
}

export function FreightInformationTarpTypeFormField({
  required,
  disabled,
  hint,
  error,
  style,
  label = TARP_TYPE_LABEL,
}: FreightInformationTarpTypeFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()
  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: TARP_TYPE_NAME,
    hint,
    error,
  })
  const { data: tarpOptions } = useTarpTypes()
  const selectedTarpType = tarpOptions?.find(
    (option: GenericOption) => option.value === shipment[TARP_TYPE_NAME]
  )

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${TARP_TYPE_NAME}-field`}
    >
      <Field.Label>{label}</Field.Label>
      <Select
        name={TARP_TYPE_NAME}
        placeholder={TARP_TYPE_LABEL}
        options={tarpOptions}
        value={selectedTarpType}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [TARP_TYPE_NAME]: get(event, 'target.value.value'),
            },
          })
        }}
        disabled={disabled}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
