import { Badge } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { IconMegaPhone } from 'components/Icons/top-bar'
import user from 'utils/user'

export default function BeamerBadge() {
  if (user.isMTCustomerUser) {
    return null
  }

  return (
    <div
      className="beamerTrigger"
      aria-label="beamer badge"
      style={{ cursor: 'pointer' }}
    >
      <Badge counter={0}>
        <IconMegaPhone
          width="22"
          height="22"
          fill={toCSSValue('color-primary-60')}
          style={{ marginTop: '2px' }}
        />
      </Badge>
    </div>
  )
}
