import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { pageWrapperStyle } from 'styles/page'

export const HeaderStyled = styled.header`
  ${pageWrapperStyle}
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  height: 50px;
  justify-content: space-between;
`

export const NavigationLink = styled(Link)<{ selected?: boolean }>`
  color: ${({ theme }) => theme.colors.background};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 15px;
  margin: 8px 0px;
  padding: 0 14px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  ${({ selected, theme }) =>
    selected &&
    `
    color: ${theme.colors.main};
  `}
`

export const Navigation = styled.nav`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`
