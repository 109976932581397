import {
  Card,
  Divider,
  LabeledValue,
  Layout,
  Text,
} from '@loadsmart/miranda-react'
import {
  formatFulfillmentContact,
  formatFulfillmentDeliveryDate,
  formatFulfillmentPickupReadyDate,
  formatFulfillmentStop,
} from 'fulfillments/fulfillment-utils'

import {
  formatFacilityLocation,
  getCountryMetadataByAbbreviation,
} from 'components/FacilityDetails'
import { FacilityCountry } from 'components/FacilityDetails/FacilityCountry'
import { defaultEmptyString } from 'utils/strings'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'
import { FulfillmentHandlingUnitsGrid } from './FulfillmentHandlingUnitsGrid'
import { FulfillmentLaneMap } from './FulfillmentLaneMap'
import { FulfillmentPickedUpItemsSummary } from './FulfillmentPickedUpItemsSummary'
import { FulfillmentPickupAppointment } from './FulfillmentPickupAppointment'

export function FulfillmentLaneCard() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment) {
    return null
  }

  return (
    <Card data-testid="fulfillment-lane-card">
      <Card.Title>Lane</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack gap="spacing-6">
          <FulfillmentLaneMap fulfillment={fulfillment} />

          <Layout.Stack gap="spacing-2">
            <Text variant="heading-sm-bold">
              Pickup at {formatFulfillmentStop(fulfillment.pickup_facility)}
            </Text>
            <Text variant="body-md-bold">
              Ready on {formatFulfillmentPickupReadyDate(fulfillment)}
            </Text>
          </Layout.Stack>

          <FulfillmentPickupAppointment />

          <Layout.Grid minColumnWidth="400px">
            <LabeledValue
              data-testid="fulfillment-pickup-facility"
              label="Facility"
              value={
                <Layout.Stack gap="spacing-1">
                  <Text>{fulfillment.pickup_facility.name}</Text>
                  <Text>{fulfillment.pickup_facility.address}</Text>
                  <Layout.Group align="center" gap="spacing-1">
                    {formatFacilityLocation(fulfillment.pickup_facility)}
                    <FacilityCountry
                      country={fulfillment.pickup_facility.country}
                      labelPosition="tooltip"
                    />
                    {
                      getCountryMetadataByAbbreviation(
                        fulfillment.pickup_facility.country
                      )?.name
                    }
                  </Layout.Group>
                </Layout.Stack>
              }
            />
            <LabeledValue
              data-testid="fulfillment-pickup-facility-contact"
              label="Contact"
              value={
                <Layout.Stack gap="spacing-1">
                  {formatFulfillmentContact(fulfillment.pickup_contact).map(
                    (line) => (
                      <Text key={line}>{line}</Text>
                    )
                  )}
                </Layout.Stack>
              }
            />
          </Layout.Grid>

          <LabeledValue
            data-testid="fulfillment-pickup-facility-instructions"
            label="Facility instructions"
            value={defaultEmptyString(fulfillment.pickup_instructions)}
          />

          <Layout.Stack gap="spacing-2">
            <FulfillmentPickedUpItemsSummary />
            <FulfillmentHandlingUnitsGrid />
          </Layout.Stack>

          <Divider />

          <Layout.Stack gap="spacing-2">
            <Text variant="heading-sm-bold">
              Delivery at {formatFulfillmentStop(fulfillment.delivery_facility)}
            </Text>
            <Text variant="body-md-bold">
              {formatFulfillmentDeliveryDate(fulfillment)}
            </Text>
          </Layout.Stack>

          <Layout.Grid minColumnWidth="400px">
            <LabeledValue
              data-testid="fulfillment-delivery-facility"
              label="Facility"
              value={
                <Layout.Stack gap="spacing-1">
                  <Text>{fulfillment.delivery_facility.name}</Text>
                  <Text>{fulfillment.delivery_facility.address}</Text>
                  <Layout.Group align="center" gap="spacing-1">
                    {formatFacilityLocation(fulfillment.delivery_facility)}
                    <FacilityCountry
                      country={fulfillment.delivery_facility.country}
                      labelPosition="tooltip"
                    />
                    {
                      getCountryMetadataByAbbreviation(
                        fulfillment.delivery_facility.country
                      )?.name
                    }
                  </Layout.Group>
                </Layout.Stack>
              }
            />
            <LabeledValue
              data-testid="fulfillment-delivery-facility-contact"
              label="Contact"
              value={
                <Layout.Stack gap="spacing-1">
                  {formatFulfillmentContact(fulfillment.delivery_contact).map(
                    (line) => (
                      <Text key={line}>{line}</Text>
                    )
                  )}
                </Layout.Stack>
              }
            />
          </Layout.Grid>

          <LabeledValue
            data-testid="fulfillment-delivery-facility-instructions"
            label="Facility instructions"
            value={defaultEmptyString(fulfillment.delivery_instructions)}
          />
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
