import { Text } from '@loadsmart/miranda-react'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'

type OutOfTimeProps = {
  readonly appointment: StopAppointment | null
}

export function AppointmentOutOfTime({ appointment }: OutOfTimeProps) {
  if (appointment?.out_of_time) {
    return (
      <Text variant="body-sm" color="color-danger-60">
        Out of schedule time
      </Text>
    )
  }

  return null
}
