import { Layout, SpinnerWheel, Text } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import useOauth2, { logOut } from '_shared_/hooks/useOauth2'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { SUPPORT_EMAIL } from 'utils/constants'
import * as storage from 'utils/localStorage'
import queryClient from 'utils/queryClient'

import { getAxiosErrorResponseData } from '../utils/errors'

interface LoginThroughBIPCallbackProps {
  readonly getHomePage: () => string
}

export const PENDING_ACTIVATION_ERROR_CODE = 1001

const LoginThroughBIPCallback = ({
  getHomePage,
}: LoginThroughBIPCallbackProps) => {
  const history = useHistory()

  const onLoginSuccess = useCallback(() => {
    analytics.track(AnalyticsEvent.LoginSuccess, AnalyticsEventTrigger.success)
    queryClient.refetchQueries({
      queryKey: ['retrieveSettings'],
      exact: false,
    })
    storage.remove('settings')
    history.push(getHomePage())
  }, [history, getHomePage])

  const onLoginFailure = useCallback(
    (error: unknown) => {
      analytics.track(AnalyticsEvent.LoginError, AnalyticsEventTrigger.error)
      console.error('Failed to log in', error)

      const errorCode = get(getAxiosErrorResponseData(error), 'error_code')

      if (errorCode == PENDING_ACTIVATION_ERROR_CODE) {
        history.push('/pending_activation')
        return
      }

      toast.error(
        `Failed to log in. If you need help with an error, email ${SUPPORT_EMAIL} for assistance.`
      )

      logOut()
    },
    [history]
  )

  const { handleLogInCallback } = useOauth2({
    onLoginSuccess,
    onLoginFailure,
  })

  useEffect(() => {
    handleLogInCallback()
  }, [handleLogInCallback])

  return (
    <Layout.Stack
      justify="center"
      align="center"
      style={{ margin: 'auto', height: '100vh' }}
    >
      <Text variant="heading-sm-bold">Authentication in progress...</Text>
      <SpinnerWheel size="48" />
    </Layout.Stack>
  )
}

export default LoginThroughBIPCallback
