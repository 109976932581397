import { createContext, useContext } from 'react'

import type {
  FreightInformation,
  TransientFreightInformation,
} from './FreightInformation.types'

export type Action = {
  type: 'HANDLE_CHANGE'
  payload: Partial<FreightInformation>
}

export type FreightInformationFormContextValue = [
  TransientFreightInformation,
  (action: Action) => void,
]

export const FreightInformationFormContext = createContext<
  FreightInformationFormContextValue | undefined
>(undefined)

export function useFreightInformationFormContext() {
  const context = useContext(FreightInformationFormContext)

  if (context === undefined) {
    throw new Error(
      'useFreightInformationFormContext must be used within a FreightInformationFormContext.Provider'
    )
  }

  return context
}
