import { isEmpty } from 'lodash'

import { useCurrentUser } from './useCurrentUser'

export function useCurrentShipperUUID() {
  const { locationUUID, user } = useCurrentUser()

  if (!isEmpty(locationUUID)) {
    return locationUUID
  }

  return user?.shipper.uuid
}
