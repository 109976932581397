import { useSettings } from 'contexts/settings'

type LogoVariant = 'dark' | 'default'

export default function AuthPagesLogo({
  isFbm,
  variant,
}: {
  readonly isFbm: boolean
  readonly variant: LogoVariant
}) {
  const {
    values: [shipperGuideDarkLogo, shipperGuideLogo, FBMDarkLogo, FBMLogo],
  } = useSettings([
    'settings.SHIPPER_GUIDE_LOGO_DARK_URL',
    'settings.SHIPPER_GUIDE_LOGO_URL',
    'settings.FLATBED_MESSENGER_LOGO_DARK_URL',
    'settings.FLATBED_MESSENGER_LOGO_URL',
  ])

  const imgSize = isFbm ? 36 : 20
  const imgAltText = isFbm ? 'Flatbed Messenger' : 'Shipper Guide'

  const getImgSource = () => {
    let imgSource = ''
    if (isFbm) {
      imgSource = variant === 'dark' ? FBMDarkLogo : FBMLogo
    } else {
      imgSource = variant === 'dark' ? shipperGuideDarkLogo : shipperGuideLogo
    }
    return imgSource
  }

  return <img src={getImgSource()} height={imgSize} alt={imgAltText} />
}
