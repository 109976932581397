import { Icon, Layout } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { ControlPosition, MapControl } from '@vis.gl/react-google-maps'
import styled from 'styled-components'

const Control = styled(Layout.Box)`
  box-shadow: ${toCSSValue('elevation-1')};
  cursor: pointer;
  margin: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FitBoundsControl = ({
  onClick,
}: {
  readonly onClick: () => void
}) => {
  return (
    <MapControl position={ControlPosition.RIGHT_BOTTOM}>
      <Control
        data-testid="fit-bounds-control"
        backgroundColor="color-neutral-0"
        borderRadius="border-radius-s"
        padding="none"
        onClick={onClick}
      >
        <Icon name="pin-map" size="26px" color="color-neutral-90" />
      </Control>
    </MapControl>
  )
}
