// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { getEnvVar } from 'utils/envVars'

const INTERCOM_APP_ID = getEnvVar('INTERCOM_APP_ID')

export const setupIntercomSettings = (userData: {
  name: string
  email: string
  createdAt: number | undefined
}) => {
  // Taken from Intercom
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: INTERCOM_APP_ID,
    name: userData.name,
    email: userData.email,
    created_at: userData.createdAt,
  }
}

export const initIntercom = () => {
  /* eslint-disable */
  // Taken from Intercom https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  // prettier-ignore
  ;(function () {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}

export const trackIntercomEvent = (
  event: string,
  props: Record<string, unknown>
) => {
  window.Intercom('trackEvent', event, props)
}
