import type { TagVariant } from '@loadsmart/miranda-react'
import { toCapitalizeFirstLetter } from '@loadsmart/utils-string'
import { getIn } from 'formik'

import type { HandlingUnitsDetails } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { useSettings } from 'contexts/settings'
import { facilityContactsAdapter } from 'utils/adapters/facilities'
import { MODES } from 'utils/constants'
import { getTimeDiff } from 'utils/dateUtils'
import { getEnvSubdomain } from 'utils/envVars'
import { isTransportationModeOneOf } from 'utils/transportationMode'

import { LTL_MODES } from './constants'
import type {
  AdaptedShipment,
  ShipmentCharge,
  ShipmentChargeStatus,
  ShipmentInvoice,
  ShipmentInvoices,
  Stop,
} from './types'

export function getShipmentTrackUrl() {
  return `https://app.${getEnvSubdomain()}shipper.guide/shipment-tracking`
}

export function getCarrierReferenceUrl(carrierRefId: string) {
  return `https://alice.${getEnvSubdomain()}loadsmart.com/shipments/loadsmart_ref/${carrierRefId}`
}

export function getStop({
  formValues,
  fieldName,
  shipmentStatus,
}: {
  formValues: AdaptedShipment
  fieldName: string
  shipmentStatus: string
}) {
  const stop: Stop = getIn(formValues, fieldName)
  const stopIndex = stop.stop_index
  const stopType = stopIndex ? `delivery-${stopIndex}` : 'pickup'
  const checkedOut = Boolean(stop.check_in?.done_at || stop.check_out?.done_at)

  const itemIsCurrent = stopType === shipmentStatus
  const itemIsPrevious = !itemIsCurrent && checkedOut

  return {
    stop,
    stopIndex,
    stopType,
    itemIsCurrent,
    itemIsPrevious,
  }
}

export function getInitialContacts(facility?: Facility | null) {
  if (!facility) {
    return []
  }

  const { contacts } = facility
  const adaptedContacts = facilityContactsAdapter({
    contacts,
  })

  return adaptedContacts
}

export const getShipmentChargeStatusTagVariant = (
  status: ShipmentChargeStatus
): TagVariant => {
  switch (status) {
    case 'approved':
      return 'success'
    case 'rejected':
      return 'warning'
    case 'canceled':
      return 'danger'
    case 'pending':
    default:
      return 'neutral'
  }
}

export const getShipmentChargeActionAuthor = (
  charge: ShipmentCharge
): { label: string; value?: string } | undefined => {
  switch (charge.status.value) {
    case 'approved':
      return { label: 'Approved by', value: charge.approvedBy }
    case 'rejected':
      return { label: 'Rejected by', value: charge.rejectedBy }
    case 'canceled':
      return { label: 'Canceled by', value: charge.canceledBy }
    case 'pending':
    default:
      return undefined
  }
}

export const getShipmentChargeActionReason = (
  charge: ShipmentCharge
): { label: string; value?: string } | undefined => {
  switch (charge.status.value) {
    case 'rejected':
      return { label: 'Rejection reason', value: charge.rejectReason }
    case 'canceled':
      return { label: 'Canceling reason', value: charge.cancelReason }
    case 'approved':
    case 'pending':
    default:
      return undefined
  }
}

export function canRemoveStop({
  fieldName,
  isEditing,
}: {
  fieldName: string
  isEditing: boolean
}) {
  const isIntermediaryStop = fieldName.startsWith('intermediaryStops')
  return isIntermediaryStop && isEditing
}

export function getStopTitle(stop: Stop, index: number) {
  if (stop.stop_type) {
    return toCapitalizeFirstLetter(stop.stop_type)
  }

  return `Stop #${index}`
}

export function lastRequestIsLessThanFourHours(lastRequest?: string | null) {
  if (!lastRequest) {
    return false
  }

  const hoursFromLastRequest = getTimeDiff(lastRequest)
  return hoursFromLastRequest >= -4
}

export function getInvoicesTotal(shipmentInvoices: ShipmentInvoices) {
  if (!shipmentInvoices || !shipmentInvoices.length) {
    return 'Unavailable'
  }

  const totalInvoiced = shipmentInvoices
    .map((invoice: ShipmentInvoice) => invoice.total)
    .reduce((a, b) => Number(a) + Number(b), 0)

  return totalInvoiced || 'Unavailable'
}

export function stopToTitle(stop: Stop): string {
  if (!stop.city && !stop.zipcode && !stop.state) {
    return 'N/A'
  }
  return `${stop.city}, ${stop.state} ${stop.zipcode}`
}

export const isLtl = (mode: string) => LTL_MODES.includes(mode)

export function formatAnalyticsShipmentType({
  status,
  is_loadsmart_carrier,
}: Partial<AdaptedShipment>) {
  if (status !== 'booked') {
    return 'not-booked'
  }

  if (is_loadsmart_carrier) {
    return 'brokerage'
  }

  return 'agnostic'
}

export function getFacilitySelectValue(
  formValues: Partial<AdaptedShipment>,
  facilityFieldName: string
) {
  const facility = getIn(formValues, facilityFieldName) || {}

  return {
    ...facility,
    value: facility.uuid || '',
    label: facility.company_name || '',
  }
}

export function useEnableAppointments(mode: MODES) {
  const {
    values: [enableShipmentAppointments],
  } = useSettings(['flags.ENABLE_SHIPMENT_APPOINTMENTS'])

  return (
    enableShipmentAppointments &&
    !isTransportationModeOneOf(mode, [MODES.LTL, MODES.VLTL])
  )
}

export function or(...values: any[]) {
  return values.reduce((value, acc) => value || acc, false)
}

export function and(...values: any[]) {
  return values.reduce((value, acc) => value && acc, true)
}

export function hasHandlingUnitCommodities(
  items?: HandlingUnitsDetails[]
): boolean {
  return (
    items?.some((item) => item.commodities && item.commodities.length > 0) ??
    false
  )
}
