import { Label, TextField } from '@loadsmart/loadsmart-ui'
import type { InputHTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

import { Error } from 'components/Input'

const Container = styled.div<{ hasError: boolean; span?: number }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  [role='alert'] {
    color: ${({ theme }) => theme.colors.dangerDarker} !important;
    font-size: 12px;
    margin-bottom: 0;
  }

  ${({ span }) =>
    span &&
    `
    grid-column-end: span ${span};
  `}

  ${({ hasError, theme }) =>
    hasError &&
    `
    > div:not(:hover):not(:focus) {
      border-color: ${theme.colors.dangerDarker};
    }

    > div:focus, > div:focus-within, > div.is-focused{
      border-color: ${theme.colors.textAccent};
      box-shadow: 0px 0px 4px 0px rgb(51 204 96)
    }
  `}
`
enum Status {
  Success = 'success',
  Danger = 'danger',
  Warn = 'warn',
  Neutral = 'neutral',
}

interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly label: string
  readonly name: string
  readonly touched: boolean
  readonly error?: string
  readonly handleFieldTouched?: (name: string) => void
  readonly handleChange?: (e: any) => void
  readonly component?: ReactNode
  [otherProps: string]: unknown
}

export const FormField = ({
  label,
  name,
  value,
  required = false,
  placeholder = '',
  touched,
  error = '',
  handleFieldTouched,
  handleChange,
  component,
  trailing,
  ...otherProps
}: FormFieldProps) => {
  const hasError = touched && !!error
  const status = hasError ? Status.Danger : Status.Neutral
  const trailingComponent = hasError && trailing ? trailing : null

  return (
    <Container hasError={hasError} {...otherProps}>
      <Label htmlFor={name} required={required}>
        {label}
      </Label>
      {component ? (
        component
      ) : (
        <TextField
          id={name}
          data-testid={name}
          name={name}
          type="text"
          value={value}
          placeholder={placeholder}
          onBlur={() => handleFieldTouched && handleFieldTouched(name)}
          onChange={(e) => handleChange && handleChange(e)}
          status={status}
          trailing={trailingComponent as ReactNode}
        />
      )}
      {hasError && <Error error={error} />}
    </Container>
  )
}
