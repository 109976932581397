/**
 * Scrolls the specified container to the top smoothly.
 *
 * @param containerQuery - A CSS selector string to identify the container to scroll. Defaults to 'main'.
 */
export function scrollToTop(containerQuery: string = 'main'): void {
  document.querySelector(containerQuery)?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}
