import { NullableSummaryText } from 'components/NullableSummaryText'
import type { FacilityContactV2 } from 'services/facilities'

type FacilityContactNameProps = Nullable<Pick<FacilityContactV2, 'name'>>

export function FacilityContactName({ name }: FacilityContactNameProps) {
  return (
    <NullableSummaryText
      text={name}
      fallbackText="No name"
      data-testid="facility-contact-name"
    />
  )
}
