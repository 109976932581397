import { isEmpty } from 'lodash'
import { useQuery } from 'react-query'

import {
  getListOrdersParams,
  listOrders,
  MAX_LIMIT,
} from 'orders/order-service'

export function useGetOrdersByUUID(UUIDs: string[], enabled: boolean = false) {
  const filterParams = getListOrdersParams({
    limit: MAX_LIMIT,
    offset: 0,
    uuid: !isEmpty(UUIDs) ? UUIDs.join(',') : null,
  })

  const query = useQuery({
    enabled,
    queryKey: ['getOrdersByUUID', filterParams],
    queryFn: () =>
      listOrders({
        ...filterParams,
      }),
    refetchOnWindowFocus: false,
  })

  return {
    ...query,
  }
}
