import type { SelectOptionProps } from '@loadsmart/loadsmart-ui'
import { Select, HighlightMatch, useSelect } from '@loadsmart/loadsmart-ui'
import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Text, Layout } from '@loadsmart/miranda-react'

import OpenDockIcon from 'assets/imgs/opendock-icon.png'

export const FACILITY_ADAPTER = {
  getKey: (facility: Pick<Facility, 'uuid'>) => facility.uuid,
  getLabel: (facility: Pick<Facility, 'company_name' | 'address'>) => {
    return facility.company_name || facility.address
  },
}

export function buildFacilitiesDatasource(facilities?: Facility[]) {
  return (): SelectDatasource<Facility> => {
    return {
      type: 'facility',
      adapter: FACILITY_ADAPTER,
      fetch({ query, regex }) {
        if (facilities == null || query.length < 3) {
          return facilities ?? []
        }

        /* istanbul ignore next -- ignoring for coverage as this is tested inside the loadsmart-ui library */
        return facilities.filter((facility) => {
          return (
            regex.test(facility.company_name ?? '') ||
            regex.test(facility.address)
          )
        })
      },
    }
  }
}

export function CustomFacilityOption({ value }: SelectOptionProps) {
  const select = useSelect()
  const option = select.getOption(value) as Facility
  const query = select.query

  const { warehouse_uuid, address } = option
  const label = FACILITY_ADAPTER.getLabel(option)

  return (
    <Select.Option value={value}>
      <Layout.Stack gap="spacing-0-5">
        <Layout.Group gap="spacing-1" align="center">
          <Text variant="body-md-bold">
            <HighlightMatch text={label} match={query} data-testid={value} />
          </Text>
          &nbsp;
          {warehouse_uuid && (
            <img src={OpenDockIcon} height={12} alt="OpenDock" />
          )}
        </Layout.Group>
        <Text variant="body-sm">{address}</Text>
      </Layout.Stack>
    </Select.Option>
  )
}
