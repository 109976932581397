import { isEmpty, min } from 'lodash'

import type { PackageType } from 'components/ShippingItemsManager'
import { formatDimensionNumber } from 'components/ShippingItemsManager'
import type { ListOrder, ListOrderItem, Order, OrderItem } from 'orders/types'
import { PACKAGE_TYPE_LABELS } from 'screens/NewQuote/LTLQuote/LTLQuote.constants'
import { numberFormatter } from 'utils/numbers'
import { plural } from 'utils/strings'

import { ALLOWED_STATUSES_FOR_CONSOLIDATION } from './constants'

/**
 * Formats units dimension for display purposes. Returns empty string if one of the values is null.
 * @param values [L, W, H]: Array of 3 numbers as strings (ordered as Length/Width/Height)
 * @param options showUnits (defaults to true)
 * @returns 16″ L x 27″ W x 8″ H
 */
export function formatUnitDimensions(
  values: [L: string | null, W: string | null, H: string | null],
  { showUnits = true } = {}
) {
  const units = ['L', 'W', 'H']

  if (values.some(isEmpty)) {
    return ''
  }

  const dimensions = values.map((value, index) => {
    const unit = showUnits ? ` ${units[index]}` : ''
    return `${numberFormatter(value, 1)}″${unit}`
  })

  return dimensions.join(' x ')
}

export function formatPackageInfo(
  package_count: ListOrderItem['package_count'],
  package_type: ListOrderItem['package_type']
) {
  const count = numberFormatter(package_count)

  if (!package_type) {
    return count
  }

  return `${count} ${PACKAGE_TYPE_LABELS.get(package_type)}`
}

export function formatStackable(stackable: boolean) {
  return stackable ? 'Stackable' : 'Do not stack'
}

export function formatTurnable(stackable: boolean) {
  return stackable ? 'Turnable' : 'Do not turn'
}

export function formatDensity(density: number | string | null): string {
  if (density == null) {
    return ''
  }

  density = Number(density)

  if (Number.isNaN(density)) {
    return ''
  }

  return `${formatDimensionNumber(density)} PCF`
}

const SHORT_PACKAGE_TYPE_LABELS = new Map<PackageType, string>(
  PACKAGE_TYPE_LABELS
)
SHORT_PACKAGE_TYPE_LABELS.set('std_pallets', 'Pallets')

export function getFormattedPackageType(
  packageType: OrderItem['package_type'],
  customPackageType: OrderItem['custom_package_type'] | undefined,
  packageCount: OrderItem['package_count']
) {
  let formattedPackageType = ''

  if (customPackageType) {
    formattedPackageType = plural(customPackageType, +packageCount)
  } else if (packageType) {
    const shortPackageType = SHORT_PACKAGE_TYPE_LABELS.get(packageType)
    if (shortPackageType !== undefined) {
      formattedPackageType = shortPackageType
    }
  } else {
    formattedPackageType = plural('Pallet', +packageCount)
  }

  return formattedPackageType
}

export function formatCommodityAndPackaging(
  commodity: OrderItem['commodity'],
  packageCount: OrderItem['package_count'],
  packageType: OrderItem['package_type'],
  customPackageType: OrderItem['custom_package_type'] | undefined
) {
  const formattedPackageType = getFormattedPackageType(
    packageType,
    customPackageType,
    packageCount
  )

  if (isEmpty(commodity)) {
    return `${packageCount} ${formattedPackageType.toLocaleLowerCase()}`
  }

  return `${packageCount} ${formattedPackageType.toLocaleLowerCase()} of ${commodity}`
}

export function isOrderPlannable(order?: ListOrder | Order) {
  return !!order && ALLOWED_STATUSES_FOR_CONSOLIDATION.includes(order.status)
}

export function calculateOrdersTotalWeight(orders: Order[] | ListOrder[]) {
  return orders.reduce((sum, order) => {
    if (
      !isEmpty(order.total_weight) &&
      !Number.isNaN(Number(order.total_weight))
    ) {
      return sum + Number(order.total_weight)
    }

    return sum
  }, 0)
}

/**
 * Formats the temperature requirements for an Order Item
 * @param item Order Item
 * @returns If there is non-empty value for the max_temperature field, it returns the formatted
 * temperature with the UOM. Otherwise, it returns an empty string
 */
export function formatOrderItemTemperatureRequirements(
  item:
    | ListOrderItem
    | OrderItem
    | Pick<ListOrderItem, 'max_temperature' | 'max_temperature_uom'>
) {
  if (isEmpty(item.max_temperature)) {
    return ''
  }

  return `${numberFormatter(item.max_temperature)}${
    item.max_temperature_uom === 'celsius' ? ' °C' : ' °F'
  }`
}

export function convertCelsiusToFahrenheit(
  temperatureInCelsius: number | string | null | undefined
) {
  const convertedTemperatureInCelsius = Number(temperatureInCelsius)

  if (
    temperatureInCelsius == null ||
    (typeof temperatureInCelsius === 'string' &&
      isEmpty(temperatureInCelsius)) ||
    Number.isNaN(convertedTemperatureInCelsius)
  ) {
    return undefined
  }

  return (convertedTemperatureInCelsius * 9) / 5 + 32
}

/**
 * Finds the lowest max_temperature in fahrenheit among Order Items.
 * It converts temperatures in celsius to fahrenheit before the comparison.
 * @param items Order items
 * @returns The lowest, non-NaN value of max_temperature and its UOM (fahrenheit). Empty string in case no value is found.
 */
export function getOrderItemsLowestTemperature(
  items:
    | OrderItem[]
    | ListOrderItem[]
    | Pick<OrderItem, 'max_temperature' | 'max_temperature_uom'>[]
) {
  const temperatures = items
    .filter(
      (item) =>
        !isEmpty(item.max_temperature) &&
        !isEmpty(item.max_temperature_uom) &&
        !Number.isNaN(Number(item.max_temperature))
    )
    .map((item) => {
      if (item.max_temperature_uom === 'fahrenheit') {
        return Number(item.max_temperature)
      }

      return convertCelsiusToFahrenheit(item.max_temperature)
    })

  const lowestTemperature = min(temperatures)

  if (lowestTemperature === undefined) {
    return {
      max_temperature_uom: '',
      max_temperature: '',
    }
  }

  return {
    max_temperature_uom: 'fahrenheit',
    max_temperature: String(lowestTemperature),
  }
}
