export default function getFileListForDeletion(
  newFiles: any[],
  oldFiles: Attachment[]
): number[] {
  const files: number[] = []
  const onlyExisting = newFiles?.filter((file) => !(file instanceof File))
  oldFiles.forEach((oldFile) => {
    const stillExists = onlyExisting.find(
      (nf: any) => oldFile.file_name === nf.name
    )
    if (!stillExists) {
      files.push(oldFile.id)
    }
  })
  return files
}
