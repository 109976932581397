import { createTransient } from 'utils/transient'

import type {
  TransientFacilityInstruction,
  FacilityInstruction,
} from './Facility.types'

function createFacilityInstruction(
  overrides?: Partial<FacilityInstruction>
): FacilityInstruction {
  return {
    note: '',
    ...overrides,
  }
}

export function createTransientFacilityInstruction(
  overrides?: Partial<TransientFacilityInstruction>
) {
  return createTransient(createFacilityInstruction(overrides))
}
