import { HazmatContactForm as HazmatContactFormWithoutFields } from './HazmatContactForm'
import { HazmatContactName } from './HazmatContactName'
import { HazmatContactPhone } from './HazmatContactPhone'
import { HazmatItemClass } from './HazmatItemClass'
import { HazmatItemPackageQuantity } from './HazmatItemPackageQuantity'
import { HazmatItemPackageType } from './HazmatItemPackageType'
import { HazmatItemPackingGroup } from './HazmatItemPackingGroup'
import { HazmatItemShippingName } from './HazmatItemShippingName'
import { HazmatItemUNNumber } from './HazmatItemUNNumber'

export type { HazmatContactFormProps } from './HazmatContactForm'
export type * from './HazmatInformation.types'
export * from './HazmatInformation.utils'
export * from './HazmatInformation.constants'
export * from './HazmatItemSummary'
export * from './HazmatContactSummary'

export const HazmatContactForm = Object.assign(HazmatContactFormWithoutFields, {
  ContactName: HazmatContactName,
  ContactPhone: HazmatContactPhone,
})

export const HazmatItemForm = Object.assign(
  {},
  {
    Class: HazmatItemClass,
    PackageQuantity: HazmatItemPackageQuantity,
    PackageType: HazmatItemPackageType,
    PackingGroup: HazmatItemPackingGroup,
    ShippingName: HazmatItemShippingName,
    UNNumber: HazmatItemUNNumber,
  }
)
