import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type ErrorPayload = {
  current_password: string[]
  new_password_confirmation: string[]
  new_password: string[]
  non_field_errors: string[]
}
export type ChangePasswordError = Partial<ErrorPayload> | null
export interface State {
  meta: {
    isLoading: boolean
    error: ChangePasswordError
  }
}

const initialState: State = {
  meta: { isLoading: false, error: null },
}

export const slice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    updatePasswordLoadingStart(state) {
      state.meta.isLoading = true
      state.meta.error = null
    },
    updatePasswordLoadingEnd(state) {
      state.meta.isLoading = false
    },
    updatePasswordFailure(
      state,
      action: PayloadAction<Partial<ChangePasswordError>>
    ) {
      state.meta.error = action.payload
    },
    updatePasswordResetErrors(state) {
      state.meta.error = null
    },
  },
})

export default slice.reducer
