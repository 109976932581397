import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import httpClient from 'utils/httpClient'
import { resolveCountry } from 'utils/states'

/**
 * This type represents a facility entry returned by the list endpoint of the new Facilities API.
 */
export type FacilityV2 = {
  uuid: string
  name: string
  address: string
  address_details: string
  city: string
  state: string
  zipcode: string
  country: string
  warehouse_uuid?: string | null
  contacts: number
  external_id?: string | null
}

export const FACILITIES_V2_ENDPOINT = '/facilities/facility'

export async function getFacilities(config?: Omit<AxiosRequestConfig, 'data'>) {
  const { data } = await httpClient.get<PaginatedResult<FacilityV2>>(
    FACILITIES_V2_ENDPOINT,
    config
  )

  return data
}

export type FacilityContactV2 = {
  uuid: string
  name: string
  phone: string
  country?: string | null
  email?: string | null
  extension?: string | null
}

export const FACILITY_APPOINTMENT_MODES = ['appt', 'fcfs'] as const

export type FacilityAppointmentMode =
  (typeof FACILITY_APPOINTMENT_MODES)[number]

export type FacilityHoursOfOperationEntry = {
  uuid: string
  day_of_week: DayOfWeek
  opens: string
  closes: string
  appointment_mode: FacilityAppointmentMode
}

export type FacilityNoteV2 = {
  id: number
  note: string
}

export type FacilityAccessorial = Pick<
  PriceItemType,
  'uuid' | 'standard_code' | 'label'
> & {
  mode: 'FTL' | 'LTL' | 'PTL'
  stop: StopType
  legacy_accessorial_code: string
}

/**
 * This type represents a facility object returned by the facility details endpoint of the new Facilities API.
 */
export type FacilityDetailsV2 = Omit<FacilityV2, 'contacts'> & {
  contacts: FacilityContactV2[]
  hours_of_operation: FacilityHoursOfOperationEntry[]
  notes: FacilityNoteV2[]
  accessorials: FacilityAccessorial[]
}

export async function getFacilityDetails(
  facilityUUID: string,
  config?: Omit<AxiosRequestConfig, 'data'>
): Promise<FacilityDetailsV2> {
  const { data } = await httpClient.get<FacilityDetailsV2>(
    `${FACILITIES_V2_ENDPOINT}/${facilityUUID}`,
    config
  )

  return {
    ...data,
    // This endpoint can receive either the SG uuid (v2) or the legacy magazzino uuid (v1),
    // but the response will always include the SG uuid.
    // We need to set the provided uuid in the response to avoid a possible mismatch.
    uuid: facilityUUID,
    country: resolveCountry(data.country)!,
  }
}

export async function getHasOpendockFacilities(
  config?: Omit<AxiosRequestConfig, 'data'>
) {
  const { data } = await httpClient.get<boolean>(
    `${FACILITIES_V2_ENDPOINT}/opendock-connected`,
    config
  )

  return data
}

export async function saveFacilityContacts({
  facilityUUID,
  contacts,
  config,
}: {
  facilityUUID: string
  contacts: Partial<FacilityContactV2>[]
  config?: Omit<AxiosRequestConfig, 'data'>
}) {
  const { data } = await httpClient.put<
    FacilityContactV2[],
    AxiosResponse<FacilityContactV2[]>,
    Partial<FacilityContactV2>[]
  >(`${FACILITIES_V2_ENDPOINT}/${facilityUUID}/contact`, contacts, config)

  return data
}

/**
 * This type represents a facility details object in its INSERT (create) mode.
 */
export type FacilityDetailsPayloadV2 = Nullable<
  Omit<FacilityV2, 'contacts'>
> & {
  contacts: Nullable<FacilityContactV2>[]
  hours_of_operation: Nullable<FacilityHoursOfOperationEntry>[]
  notes: Nullable<FacilityNoteV2>[]
  accessorials: Pick<FacilityAccessorial, 'uuid' | 'stop' | 'mode'>[]
}

export async function saveFacility({
  facilityUUID,
  facility,
  config,
}: {
  facilityUUID: string
  facility: FacilityDetailsPayloadV2
  config?: Omit<AxiosRequestConfig, 'data'>
}) {
  const { data } = await httpClient.put<
    FacilityDetailsV2,
    AxiosResponse<FacilityDetailsV2>,
    FacilityDetailsPayloadV2
  >(`${FACILITIES_V2_ENDPOINT}/${facilityUUID}`, facility, config)

  return data
}

export async function createFacility({
  facility,
  config,
}: {
  facility: FacilityDetailsPayloadV2
  config?: Omit<AxiosRequestConfig, 'data'>
}) {
  const { data } = await httpClient.post<
    FacilityDetailsPayloadV2,
    AxiosResponse<FacilityDetailsV2>,
    FacilityDetailsPayloadV2
  >(FACILITIES_V2_ENDPOINT, facility, config)

  return data
}

export async function deleteFacility({
  facilityUUID,
  config,
}: {
  facilityUUID: string
  config?: Omit<AxiosRequestConfig, 'data'>
}) {
  const { data } = await httpClient.delete(
    `${FACILITIES_V2_ENDPOINT}/${facilityUUID}`,
    config
  )

  return data
}

export async function deleteContact({
  facilityUUID,
  contactUUID,
  config,
}: {
  facilityUUID: string
  contactUUID: string
  config?: Omit<AxiosRequestConfig, 'data'>
}) {
  const { data } = await httpClient.delete(
    `${FACILITIES_V2_ENDPOINT}/${facilityUUID}/contact/${contactUUID}`,
    config
  )
  return data
}
