import { IconLogout } from '@loadsmart/icons'
import { Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import useOauth2 from '_shared_/hooks/useOauth2'
import userWrapper from 'utils/user'

import { StyledDropdownItem } from './styles'

export function LogoutButton() {
  const { logOut, oAuth2Flow } = useOauth2()

  function onLogout() {
    if (oAuth2Flow.isEnabled) {
      logOut()
    } else {
      userWrapper.logout()
    }
  }

  return (
    <StyledDropdownItem
      leading={
        <IconLogout
          width={16}
          height={16}
          fill={toCSSValue('color-primary-60')}
        />
      }
      onClick={(event) => {
        event.preventDefault()
        onLogout()
      }}
    >
      <Text variant="body-md-bold">Logout</Text>
    </StyledDropdownItem>
  )
}
