import { useMap } from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useState } from 'react'

import { FitBoundsControl } from './FitBoundsControl'
import type { FitBoundsProps } from './types'

export const FitBounds = ({ path, control }: FitBoundsProps) => {
  const PADDING = 50
  const map = useMap()
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>()

  useEffect(() => {
    if (!map) {
      throw new Error('<FitBounds> has to be inside a Map component.')
    }

    const newBounds = new google.maps.LatLngBounds()

    path.forEach((point) => {
      newBounds.extend(point)
    })

    setBounds(newBounds)
  }, [map, path])

  const fitBounds = useCallback(() => {
    if (map && bounds) {
      map.fitBounds(bounds, PADDING)
    }
  }, [bounds, map])

  useEffect(() => {
    fitBounds()
  }, [bounds, fitBounds])

  const handleControlClick = () => {
    fitBounds()
  }

  if (control) {
    return <FitBoundsControl onClick={handleControlClick} />
  }

  return null
}
