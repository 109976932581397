import { NullableSummaryText } from 'components/NullableSummaryText'
import type { FacilityContactV2 } from 'services/facilities'

type FacilityContactEmailProps = Nullable<Pick<FacilityContactV2, 'email'>>

export function FacilityContactEmail({ email }: FacilityContactEmailProps) {
  return (
    <NullableSummaryText
      text={email}
      fallbackText="No email"
      data-testid="facility-contact-email"
    />
  )
}
