import { Radio } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

const RfpRadio = styled(Radio)`
  align-items: center;
  display: flex;
  font-weight: bolder;
`

export default RfpRadio
