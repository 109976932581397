import { IconTrash, IconPlus } from '@loadsmart/icons'
import { Button } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import { createTransientCollectionSetup } from 'components/TransientCollectionForm'
import type { TransientCollectionFormProps } from 'components/TransientCollectionForm'
import { useIndexerContext } from 'contexts/indexer'
import { useCustomFormField } from 'hooks/useCustomFormField'
import type { useCustomFormFieldProps } from 'hooks/useCustomFormField'
import { getTransientError } from 'utils/transient'

import type { TransientShippingItem } from './ShippingItems.types'

export type ShippingItemsFormProps =
  TransientCollectionFormProps<TransientShippingItem>

const {
  TransientCollectionForm,
  TransientCollectionFormContext,
  useTransientCollectionFormContext,
} = createTransientCollectionSetup<TransientShippingItem>()

const ShippingItemsFormAddShippingItem = ({
  children = 'Add Item',
}: {
  readonly children?: ReactNode
}) => {
  const [, dispatch] = useTransientCollectionFormContext()
  return (
    <Button
      size="small"
      variant="tertiary"
      leading={<IconPlus width={12} title={null} aria-label={null} />}
      onClick={() => {
        dispatch({ type: 'ADD_ITEM' })
      }}
    >
      {children}
    </Button>
  )
}

const ShippingItemsFormRemoveShippingItem = () => {
  const [, dispatch] = useTransientCollectionFormContext()
  const index = useIndexerContext()

  return (
    <Button
      aria-label="Remove Item"
      title="Remove Item"
      size="small"
      variant="icon"
      onClick={() => {
        dispatch({ type: 'REMOVE_ITEM', payload: { index } })
      }}
    >
      <IconTrash width={12} height={12} title={null} />
    </Button>
  )
}

export type UseShippingItemProps = {
  name: string
  hint?: useCustomFormFieldProps['hint']
}

export function useShippingItemFormField(props: UseShippingItemProps) {
  const { name, hint } = props

  const [items, dispatch] = useTransientCollectionFormContext()
  const index = useIndexerContext()
  const item = items[index]
  const error = getTransientError(item, name)

  const formFieldProps = useCustomFormField({ index, name, hint, error })

  return {
    index,
    item,
    dispatch,
    ...formFieldProps,
  }
}

export {
  TransientCollectionFormContext as ShippingItemsFormContext,
  useTransientCollectionFormContext as useShippingItemsForm,
}

export const ShippingItemsForm = Object.assign(TransientCollectionForm, {
  AddShippingItem: ShippingItemsFormAddShippingItem,
  RemoveShippingItem: ShippingItemsFormRemoveShippingItem,
})
