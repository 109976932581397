import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'

import { aliceCarrierDetails, carrierDetails } from 'services/carriers'

interface State {
  carriers: Record<number, Carrier>
  aliceCarriers: Record<number, Carrier>
  aliceCarrierDetail: AliceCarrier | undefined
  carrierDetail: Carrier | undefined
  meta: {
    isLoading: boolean
    isLoadingAliceCarriers: boolean
    isLoadingCarrierDetail: boolean
    isUploading: boolean
    search: string
    searchAlice: string
    uploadErrors: string[] | null
  }
}

export const fetchAliceCarrier = createAsyncThunk(
  'carriers/fetchAliceCarrier',
  (carrierUUID: string) => aliceCarrierDetails(carrierUUID)
)

export const fetchCarrierDetails = createAsyncThunk(
  'carriers/details',
  (id: string | number) => carrierDetails(id)
)

const initialState: State = {
  carriers: {},
  aliceCarriers: {},
  aliceCarrierDetail: undefined,
  carrierDetail: undefined,
  meta: {
    isLoading: false,
    isLoadingAliceCarriers: false,
    isLoadingCarrierDetail: false,
    isUploading: false,
    search: '',
    searchAlice: '',
    uploadErrors: null,
  },
}

export const slice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    clearAliceCarrierDetail(state) {
      state.aliceCarrierDetail = undefined
    },
    clearCarrierDetail(state) {
      state.carrierDetail = undefined
    },
    aliceSearchUpdated(state, action: PayloadAction<string>) {
      state.meta.searchAlice = action.payload
    },
    fetchAliceCarriersStart(state) {
      state.meta.isLoadingAliceCarriers = true
    },
    fetchAliceCarriersSuccess(state, action: PayloadAction<Carrier[]>) {
      const data = normalize(action.payload, [
        new schema.Entity<Carrier>('carriers', undefined, {
          idAttribute: 'uuid',
        }),
      ])
      state.aliceCarriers = data.entities.carriers || {}
      state.meta.isLoadingAliceCarriers = false
    },
    fetchAliceCarriersFailed(state) {
      state.meta.isLoadingAliceCarriers = false
    },
    fetchCarriersStart(state) {
      state.meta.isLoading = true
    },
    fetchCarriersSuccess(state, action: PayloadAction<Carrier[]>) {
      const data = normalize(action.payload, [
        new schema.Entity<Carrier>('carriers'),
      ])
      state.carriers = data.entities.carriers || {}
      state.meta.isLoading = false
    },
    fetchCarriersFailed(state) {
      state.meta.isLoading = false
    },
    createCarrier(state, action: PayloadAction<Carrier>) {
      state.carriers[action.payload.id] = action.payload
    },
    searchUpdated(state, action: PayloadAction<string>) {
      state.meta.search = action.payload
    },
    uploadCarriersStart(state: State) {
      state.meta.isUploading = true
      state.meta.uploadErrors = null
    },
    uploadCarriersSuccess(state: State) {
      state.meta.isUploading = false
    },
    uploadCarriersFailed(state: State, action: PayloadAction<string[]>) {
      state.meta.isUploading = false
      state.meta.uploadErrors = action.payload
    },
    uploadCarriersClearErrors(state: State) {
      state.meta.uploadErrors = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAliceCarrier.pending, (state) => {
      state.meta.isLoadingCarrierDetail = true
    })
    builder.addCase(fetchAliceCarrier.fulfilled, (state, action) => {
      state.meta.isLoadingCarrierDetail = false
      state.aliceCarrierDetail = action.payload
    })
    builder.addCase(fetchAliceCarrier.rejected, (state) => {
      state.meta.isLoadingCarrierDetail = false
    })
    builder.addCase(fetchCarrierDetails.pending, (state) => {
      state.meta.isLoadingCarrierDetail = true
    })
    builder.addCase(fetchCarrierDetails.fulfilled, (state, action) => {
      state.meta.isLoadingCarrierDetail = false
      state.carrierDetail = action.payload
      state.aliceCarrierDetail = state.carrierDetail?.info
    })
    builder.addCase(fetchCarrierDetails.rejected, (state) => {
      state.meta.isLoadingCarrierDetail = false
    })
  },
})

export default slice.reducer
