import type { ReactNode } from 'react'

import { SummaryLabelValue } from 'components/Summary'
import { formatPhoneNumber } from 'utils/phone'

/**
 * Renders the contact name and phone number.
 */
export function ContactSummaryContact(props: {
  readonly label?: ReactNode
  readonly name?: string | null
  readonly phoneNumber?: string | null
}) {
  const { name, phoneNumber, ...rest } = props

  return (
    <SummaryLabelValue
      label="Contact"
      {...rest}
      value={
        [name, formatPhoneNumber(phoneNumber)].filter(Boolean).join(' ') || '-'
      }
    />
  )
}

export function ContactSummaryContactName(props: {
  readonly label?: ReactNode
  readonly name?: string | null
}) {
  const { name, ...rest } = props

  return <SummaryLabelValue label="Contact" {...rest} value={name || '-'} />
}

export function ContactSummaryContactPhoneNumber(props: {
  readonly label?: ReactNode
  readonly phoneNumber?: string | null
}) {
  const { phoneNumber, ...rest } = props

  return (
    <SummaryLabelValue
      label="Phone number"
      {...rest}
      value={phoneNumber ? formatPhoneNumber(phoneNumber) : '-'}
    />
  )
}

export function ContactSummaryContactEmail(props: {
  readonly label?: ReactNode
  readonly email?: string | null
}) {
  const { email, ...rest } = props

  return <SummaryLabelValue label="Email" {...rest} value={email || '-'} />
}
