import toArray from 'utils/toArray'

import { toSelectOption } from './selectOption'

export const TRANSPORTATION_MODE_ALIASES = {
  any: ['any'],
  drayage: ['dray', 'drayage'],
  full_container_load: ['fcl', 'full_container_load', 'full container load'],
  full_truck_load: ['ftl', 'full_truck_load', 'full truck load'],
  intermodal: ['imdl', 'intermodal'],
  less_than_truckload: ['ltl', 'less_than_truckload', 'less than truckload'],
  partial_truckload: [
    'ptl',
    'partial_truck_load',
    'partial_truckload',
    'partial truck load',
  ],
  rail: ['rail'],
  volume_less_than_truckload: [
    'vltl',
    'volume_less_than_truckload',
    'volume less than truckload',
  ],
  expedited: ['exp', 'expedited'],
}

export type TransportationMode = {
  abbr: string
  value: TransportationModeCode
  label: string
}

export const TRANSPORTATION_MODES: Record<
  TransportationModeCode,
  TransportationMode
> = {
  any: {
    abbr: 'ANY',
    value: 'any',
    label: 'Any',
  },
  drayage: {
    abbr: 'DRAY',
    value: 'drayage',
    label: 'Drayage',
  },
  full_container_load: {
    abbr: 'FCL',
    value: 'full_container_load',
    label: 'Full Container Load',
  },
  full_truck_load: {
    abbr: 'FTL',
    value: 'full_truck_load',
    label: 'Full Truckload',
  },
  less_than_truckload: {
    abbr: 'LTL',
    value: 'less_than_truckload',
    label: 'Less Than Truckload',
  },
  partial_truckload: {
    abbr: 'PTL',
    value: 'partial_truckload',
    label: 'Partial Truckload',
  },
  intermodal: {
    abbr: 'IMDL',
    value: 'intermodal',
    label: 'Intermodal',
  },
  rail: {
    abbr: 'RAIL',
    value: 'rail',
    label: 'Rail',
  },
  volume_less_than_truckload: {
    abbr: 'VLTL',
    value: 'volume_less_than_truckload',
    label: 'Volume Less Than Truckload',
  },
  expedited: {
    abbr: 'EXP',
    value: 'expedited',
    label: 'Expedited',
  },
} as const

/**
 * Converts a transportation mode string to a canonical transportation mode name.
 * The canonical mode name is considered an implicit alias of itself.
 * @param mode string for mode to be resolved to a canonical transportation mode name.
 * @returns canonical name for the given `mode`; `null` if `mode` is invalid.
 */
export function resolveTransportationMode(
  mode: string
): TransportationModeCode | null {
  if (!mode) {
    return null
  }

  const lowerCaseMode = mode.toLowerCase()

  if (lowerCaseMode in TRANSPORTATION_MODE_ALIASES) {
    return lowerCaseMode as TransportationModeCode
  }

  for (const canonicalCode in TRANSPORTATION_MODE_ALIASES) {
    if (
      TRANSPORTATION_MODE_ALIASES[
        canonicalCode as TransportationModeCode
      ].includes(lowerCaseMode)
    ) {
      return canonicalCode as TransportationModeCode
    }
  }

  return null
}

export function isValidTransportationMode(mode: string): boolean {
  return resolveTransportationMode(mode) !== null
}

type TransportationModeTransformFunction<T> = (mode: TransportationMode) => T

export function getTransportationMode<T = TransportationMode>(
  mode: string,
  transform: TransportationModeTransformFunction<T> = (value) => value as T
): T | null {
  const canonicalCode = resolveTransportationMode(mode)

  return canonicalCode != null
    ? transform(TRANSPORTATION_MODES[canonicalCode])
    : null
}

export function getTransportationModes<T = TransportationMode>(
  modes: string | string[],
  transform: (mode: TransportationMode) => T = (mode) => mode as T
): T[] {
  return toArray(modes).reduce((array, mode) => {
    const transformedMode = getTransportationMode(mode, transform)

    if (transformedMode == null) {
      return array
    }

    return array.concat(transformedMode)
  }, [] as T[])
}

export function areTransportationModesEqual(
  mode1: string,
  mode2: string
): boolean {
  const canonicalCode1 = resolveTransportationMode(mode1)
  const canonicalCode2 = resolveTransportationMode(mode2)

  if (canonicalCode1 == null || canonicalCode2 == null) {
    return false
  }

  return canonicalCode1 === canonicalCode2
}

export function isTransportationModeOneOf(
  mode: string,
  oneOfModes: string[]
): boolean {
  const canonicalModeName = resolveTransportationMode(mode)
  const canonicalOneOfModeNames = toArray(oneOfModes).map(
    resolveTransportationMode
  )

  if (canonicalModeName == null) {
    return false
  }

  return canonicalOneOfModeNames.includes(canonicalModeName)
}

export function fromModeToSelectOption(value: string): SelectOption | null {
  return getTransportationMode(value, (mode: TransportationMode) =>
    toSelectOption(mode.abbr, mode.value)
  )
}
