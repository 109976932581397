import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { COMMODITY_PACKAGE_TYPES_OPTIONS } from 'components/ShippingItemsManager'
import { fromSelectOption } from 'utils/selectOption'

import type { useHazmatItemFormFieldProps } from './HazmatItemForm'
import { useHazmatItemFormField } from './HazmatItemForm'
import { getHazmatPackageTypeOption } from './HazmatItemForm.utils'

export const HazmatItemPackageType = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: useHazmatItemFormFieldProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props

  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useHazmatItemFormField({ name: 'hazmat_package_type', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Package type</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        placeholder="Select package type"
        id={`field-${index}-${name}`}
        name={name}
        options={COMMODITY_PACKAGE_TYPES_OPTIONS}
        value={getHazmatPackageTypeOption(get(item, name))}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event
          const value = fromSelectOption(option as SelectOption)

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: value,
              },
            },
          })
        }}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
