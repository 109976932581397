import { Layout, Text } from '@loadsmart/miranda-react'

import { formatFulfillmentWeight } from '../../fulfillment-utils'
import { useViewFulfillmentContext } from '../ViewFulfillmentContext'

export function FulfillmentPickedUpItemsSummary() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment) {
    return null
  }

  return (
    <Layout.Group
      align="center"
      gap="spacing-0-5"
      data-testid="fulfillment-pickup-HUs-summary"
    >
      <Text color="color-text-tertiary">
        <b>{fulfillment.handlingUnitsTotalPackageCount}</b> H/U being picked up
        at this facility
      </Text>
      <Text color="color-text-tertiary">&bull;</Text>
      <Text color="color-text-tertiary">
        Total weight <b>{formatFulfillmentWeight(fulfillment)}</b>
      </Text>
    </Layout.Group>
  )
}
