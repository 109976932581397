import { useState, useCallback } from 'react'

export function useModal(isOpen = false) {
  const [modalState, setModalState] = useState<boolean>(isOpen)
  const closeModal = useCallback(() => {
    setModalState(false)
  }, [])
  const openModal = useCallback(() => {
    setModalState(true)
  }, [])
  const toggleModal = useCallback(() => {
    setModalState(!modalState)
  }, [modalState])

  return { modalState, closeModal, openModal, toggleModal, setModalState }
}
