import { Layout } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import styled from 'styled-components'

export const ContentWrapper = styled(Layout.Stack)`
  .daily-operating-hours .day-of-week m-field-label,
  .daily-operating-hours .actions m-field-label {
    visibility: hidden;
  }

  m-divider {
    --m-divider-margin-y: 0;
  }

  m-icon {
    --m-icon-color: currentColor;
  }

  m-divider:last-of-type {
    display: none;
  }

  .day-of-week {
    flex: 1;
  }

  .day-of-week m-checkbox {
    height: ${toCSSValue('global-height-default')};
    display: inline-flex;
  }

  @media (min-width: 680px) {
    .operating-hours-row m-field-label {
      display: none;
    }

    .daily-operating-hours .day-of-week m-field-label {
      display: none;
    }

    .daily-operating-hours:first-of-type
      .operating-hours-row:first-of-type
      m-field-label {
      display: block;
    }

    .daily-operating-hours:first-of-type .day-of-week m-field-label {
      display: block;
    }
  }
`
