import type { IconProps } from '@loadsmart/miranda-react'

import type { FacilityHoursOfOperationEntry } from 'services/facilities'

export const SUPPORTED_COUNTRIES_METADATA: Record<
  'USA' | 'CAN' | 'MEX',
  { name: string; flagIconName: IconProps['name']; phonePrefix: string }
> = {
  USA: {
    name: 'United States',
    flagIconName: 'united-states-flag',
    phonePrefix: '+1',
  },
  CAN: {
    name: 'Canada',
    flagIconName: 'canada-flag',
    phonePrefix: '+1',
  },
  MEX: {
    name: 'Mexico',
    flagIconName: 'mexico-flag',
    phonePrefix: '+52',
  },
}

export const DEFAULT_HOURS_OF_OPERATION: Pick<
  FacilityHoursOfOperationEntry,
  'day_of_week' | 'opens' | 'closes' | 'appointment_mode'
>[] = [
  {
    day_of_week: 'Monday',
    opens: '09:00',
    closes: '16:00',
    appointment_mode: 'appt',
  },
  {
    day_of_week: 'Tuesday',
    opens: '09:00',
    closes: '16:00',
    appointment_mode: 'appt',
  },
  {
    day_of_week: 'Wednesday',
    opens: '09:00',
    closes: '16:00',
    appointment_mode: 'appt',
  },
  {
    day_of_week: 'Thursday',
    opens: '09:00',
    closes: '16:00',
    appointment_mode: 'appt',
  },
  {
    day_of_week: 'Friday',
    opens: '09:00',
    closes: '16:00',
    appointment_mode: 'appt',
  },
]
