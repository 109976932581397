import type { FacilityAccessorial } from 'services/facilities'

import type { TransientAccessorialsByModeAndStop } from './Facility.types'

/**
 * Creates the empty lists of accessorials for every supported mode and stop type.
 */
export function createTransientAccessorialsByModeAndStop(
  overrides?: Partial<TransientAccessorialsByModeAndStop>
): TransientAccessorialsByModeAndStop {
  return {
    FTL: {
      pickup: [],
      delivery: [],
    },
    LTL: {
      pickup: [],
      delivery: [],
    },
    PTL: {
      pickup: [],
      delivery: [],
    },
    ...overrides,
  }
}

/**
 * Creates the lists of accessorials for every supported mode and stop type based on the provided facility accessorials.
 */
export function createTransientAccessorialsByModeAndStopFromFacilityAccessorials(
  accessorials: FacilityAccessorial[]
): TransientAccessorialsByModeAndStop {
  const accessorialsByModeAndStop = createTransientAccessorialsByModeAndStop()

  accessorials
    // Filtering out possible invalid data returned from the BE
    .filter(
      (accessorial) =>
        ['FTL', 'LTL', 'PTL'].includes(accessorial.mode) &&
        ['pickup', 'delivery'].includes(accessorial.stop)
    )
    .forEach((accessorial) => {
      accessorialsByModeAndStop[accessorial.mode][accessorial.stop].push({
        label: accessorial.label,
        value: accessorial.uuid,
      })
    })

  return accessorialsByModeAndStop
}
