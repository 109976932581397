import { DatePicker, DateHelper } from '@loadsmart/loadsmart-ui'
import type { CalendarConstraint } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties, ReactNode } from 'react'

import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

export function endOfDay(date?: string | null) {
  if (date == null) {
    return null
  }

  return DateHelper(date)
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .toString()
}

const CONSTRAINTS: CalendarConstraint[] = [
  [null, DateHelper().subtract('day', 1).toString()],
]

export const StopDate = (props: {
  readonly label?: ReactNode
  readonly required?: FieldProps['required']
  readonly hint?: UseStopFormFieldProps['hint']
  readonly style?: CSSProperties
  readonly disabled?: boolean
}) => {
  const { label, required, hint, style, disabled } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    stop,
    dispatch,
  } = useStopFormField({ name: 'date', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>
        {label ?? `Stop #${index} date`}
      </Field.Label>

      <DatePicker
        {...controlProps}
        disabled={disabled}
        value={get(stop, 'date', null)}
        name={name}
        constraints={CONSTRAINTS}
        onChange={(event) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: endOfDay(get(event, 'target.value', null)),
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
