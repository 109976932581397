import type { WCFieldProps } from '@loadsmart/miranda-react'

import { getTransientError } from 'utils/transient'
import type { Transient } from 'utils/transient'

/**
 * Based on a `Transient` object, returns a hook for handling transient fields,
 * using Miranda's `Field` component.
 *
 * @param obj - The object containing transient fields.
 * @param prefix - An optional prefix for the field paths, when applied to the ids and data-testid attributes.
 *
 * @example
 * ```jsx
 * const { getFieldProps, getHintProps } = useTransientField(transientObject)
 *
 * <Field {...getFieldProps('somePath')}>
 *  <Field.Hint {...getHintProps('somePath')} />
 * </Field>
 * ```
 */
export function useTransientField<T>(obj: Transient<T> | T, prefix = '') {
  return {
    getFieldProps: (path: string) => {
      const error = getTransientError(obj, path)
      const status: WCFieldProps['status'] = error ? 'danger' : 'default'

      return {
        status,
      }
    },
    getHintProps: (path: string) => {
      const error = getTransientError(obj, path)

      return {
        // TODO: what to do when there's also a tip to be shown
        hidden: !error,
        children: error,
        'data-testid': ['hint-for', ...(prefix ? [prefix] : []), path].join(
          '-'
        ),
      }
    },
  }
}
