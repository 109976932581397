import { toSelectOptions } from 'utils/selectOption'
import type { HazmatPackingGroup, HazmatClass } from 'utils/types'

import type { TransientHazmatContact } from './HazmatInformation.types'

/** This is valid number, represented as string for convenience. You can safely call `Number(...)` on it. */
export const HAZMAT_MIN_UN_NUMBER = '0004'
/** This is valid number, represented as string for convenience. You can safely call `Number(...)` on it. */
export const HAZMAT_MAX_UN_NUMBER = '3534'

export const HAZMAT_CLASSES_MAP = new Map<HazmatClass, string>([
  ['minor_blast_explosive', '1.4 Minor Blast Explosive'] as const,
  ['insensitive_explosive', '1.5 Insensitive Explosive'] as const,
  ['very_insensitive_explosive', '1.6 Very Insensitive Explosive'] as const,
  ['flammable_gas', '2.1 Flammable Gas'] as const,
  ['non_flammable_gas', '2.2 Non-Flammable Gas'] as const,
  ['poison_gas', '2.3 Poison Gas'] as const,
  ['combustible_liquid', '3 Combustible Liquid'] as const,
  ['flammable_liquid', '3 Flammable Liquid'] as const,
  ['flammable_solid', '4.1 Flammable Solid'] as const,
  ['spontaneously_combustible', '4.2 Spontaneously Combustible'] as const,
  ['dangerous_when_wet', '4.3 Dangerous When Wet'] as const,
  ['oxidizer', '5.1 Oxidizer'] as const,
  ['organic_peroxide', '5.2 Organic Peroxide'] as const,
  ['poisonous_toxic', '6 Poisonous/Toxic'] as const,
  ['corrosive', '8 Corrosive'] as const,
  ['miscellaneous_dangerous_good', '9 Miscellaneous Dangerous Good'] as const,
])

export const HAZMAT_CLASSES_OPTIONS = toSelectOptions(HAZMAT_CLASSES_MAP)

export const HAZMAT_CLASSES_OPTIONS_MAP = new Map(
  HAZMAT_CLASSES_OPTIONS.map((option) => [option.value, option] as const)
)

export const HAZMAT_PACKING_GROUP_MAP = new Map<HazmatPackingGroup, string>([
  ['group_1', 'Group I'] as const,
  ['group_2', 'Group II'] as const,
  ['group_3', 'Group III'] as const,
])

export const HAZMAT_PACKING_GROUP_OPTIONS = toSelectOptions(
  HAZMAT_PACKING_GROUP_MAP
)

export const HAZMAT_PACKING_GROUP_OPTIONS_MAP = new Map(
  HAZMAT_PACKING_GROUP_OPTIONS.map((option) => [option.value, option] as const)
)

export const HAZMAT_CONTACT_KEYS: (keyof TransientHazmatContact)[] = [
  '_metadata',
  'hazmat_contact_name',
  'hazmat_phone_number',
]
