import { getHexColor } from '@loadsmart/miranda-react/dist/tokens'
import type { Fulfillment } from 'fulfillments/domain/Fulfillment'
import { useMemo } from 'react'

import type { MarkerRoutePoint } from 'components/Map'
import { FitBounds, MapV2, MarkerRoute, Polyline } from 'components/Map'
import { StopPin } from 'screens/Shipper/Shipments/Details/v2/map/icons/StopPin'
import { SHIPMENT_MAP_GOOGLE_MAP_ID } from 'utils/constants'

const STRAIGHT_ROUTE_COLOR = getHexColor('color-neutral-60')

export const getFulfillmentPath = (fulfillment: Fulfillment) => {
  return [
    {
      lat: Number(fulfillment.pickup_facility.latitude),
      lng: Number(fulfillment.pickup_facility.longitude),
    },
    {
      lat: Number(fulfillment.delivery_facility.latitude),
      lng: Number(fulfillment.delivery_facility.longitude),
    },
  ]
}

export const getMarkerRoutePointFromShipment = (
  fulfillment: Fulfillment
): MarkerRoutePoint[] => {
  return [
    {
      icon: <StopPin checkedIn={false} />,
      info: 'Pickup',
      markerStyle: { transform: 'translateY(50%)' },
      position: {
        lat: Number(fulfillment.pickup_facility.latitude),
        lng: Number(fulfillment.pickup_facility.longitude),
      },
    },
    {
      icon: <StopPin checkedIn={false} />,
      info: 'Delivery',
      markerStyle: { transform: 'translateY(50%)' },
      position: {
        lat: Number(fulfillment.delivery_facility.latitude),
        lng: Number(fulfillment.delivery_facility.longitude),
      },
    },
  ]
}

export type FulfillmentLaneMapProps = Readonly<{
  fulfillment: Fulfillment
  height?: string
}>

export const FulfillmentLaneMap = ({
  fulfillment,
  height = '300px',
}: FulfillmentLaneMapProps) => {
  const path = useMemo(() => {
    return getFulfillmentPath(fulfillment)
  }, [fulfillment])

  return (
    <div style={{ height }}>
      <MapV2
        page="Fulfillment details"
        mapId={SHIPMENT_MAP_GOOGLE_MAP_ID}
        zoomControl
        fullscreenControl
      >
        <Polyline path={path} strokeColor={STRAIGHT_ROUTE_COLOR} />

        <MarkerRoute points={getMarkerRoutePointFromShipment(fulfillment)} />

        <FitBounds path={path} control />
      </MapV2>
    </div>
  )
}
