import {
  useFulfillmentListAsCustomer,
  useFulfillmentListAsSupplier,
} from 'fulfillments/hooks/useFulfillmentList'
import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { useCurrentUser } from '_shared_/user/useCurrentUser'

export type ListFulfillmentsContextValue = {
  asCustomerList?: ReturnType<typeof useFulfillmentListAsCustomer>
  isSupplier?: boolean
  asSupplierList?: ReturnType<typeof useFulfillmentListAsCustomer>
}

export const ListFulfillmentsContext =
  createContext<ListFulfillmentsContextValue>({})

export function useListFulfillmentsContext() {
  const context = useContext(ListFulfillmentsContext)

  if (context === undefined) {
    throw new Error(
      'useListFulfillmentsContext must be used within a ListFulfillmentsContextProvider'
    )
  }

  return context
}

export function ListFulfillmentsContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const asCustomerList = useFulfillmentListAsCustomer()
  const asSupplierList = useFulfillmentListAsSupplier()
  const { user } = useCurrentUser()

  const context = useMemo(
    () => ({
      isSupplier: user?.shipper.is_supplier,
      asCustomerList,
      asSupplierList,
    }),
    [asCustomerList, asSupplierList, user]
  )

  return (
    <ListFulfillmentsContext.Provider value={context}>
      {children}
    </ListFulfillmentsContext.Provider>
  )
}
