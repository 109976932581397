import { useState, useEffect } from 'react'

export const DEFAULT_PAGE_SIZE = 10

export default function usePagination(
  pageSize = DEFAULT_PAGE_SIZE,
  resultsCount = 0,
  defaultPage = 0
) {
  const safePageSize = Math.max(pageSize, DEFAULT_PAGE_SIZE)
  const [pageCount, setPageCount] = useState(defaultPage)
  const [page, setPage] = useState(0)

  useEffect(() => setPage(defaultPage), [defaultPage])

  useEffect(
    () => setPageCount(resultsCount / safePageSize),
    [pageSize, resultsCount, safePageSize]
  )

  return { pageCount, page, setPage }
}

/**
 * Hook to handle pagination.
 * @param {Object} props
 * @param props.resultsCount - number of total listing items
 * @param props.pageSize - number of elements to be displayed per page
 * @param props.currentOffset - index of the first element to be displayed on the page
 * @returns {{ currentPage: number; totalPages: number; getOffsetForPage: (page: number) => number}}
 */
export function useOffsetPagination(props: {
  pageSize: number
  resultsCount: number
  currentOffset: number
}) {
  const { resultsCount, pageSize, currentOffset } = props

  const totalPages = Math.ceil(resultsCount / pageSize)
  const currentPage = currentOffset / pageSize

  function getOffsetForPage(page: number) {
    return page * pageSize
  }

  return {
    currentOffset,
    currentPage,
    pageSize,
    resultsCount,
    totalPages,
    getOffsetForPage,
  }
}
