import ScriptLoader from 'components/ScriptLoader'
import { shouldTrack } from 'utils/analytics'
import { GA_4_TRACKING_ID } from 'utils/constants'

const onLoad = () => {
  // code taken from Google Analytics 4 configuration
  // and adapted to match TS and eslint checks
  window.dataLayer = window.dataLayer ?? []
  function gtag(...args: unknown[]) {
    window.dataLayer?.push(args)
  }
  gtag('js', new Date())

  gtag('config', GA_4_TRACKING_ID)
}

const GoogleAnalytics4Loader = () => {
  if (!shouldTrack()) {
    return null
  }

  return (
    <ScriptLoader
      src={`https://www.googletagmanager.com/gtag/js?id=${GA_4_TRACKING_ID}`}
      onLoad={onLoad}
    />
  )
}

export default GoogleAnalytics4Loader
