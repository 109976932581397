import { defaultTo } from 'lodash'

import httpClient from 'utils/httpClient'
import { createSearchParams } from 'utils/searchParams'

import type { Supplier } from './types'

export const DEFAULT_LIMIT = 20
export const DEFAULT_OFFSET = 0

type SuppliersListParams = {
  limit: number
  offset: number
}

export function getSuppliersListParams(
  params: Partial<SuppliersListParams> = {}
): SuppliersListParams {
  return {
    limit: defaultTo(params?.limit, DEFAULT_LIMIT),
    offset: defaultTo(params?.offset, DEFAULT_OFFSET),
  }
}

export async function listSuppliers(
  params: Partial<SuppliersListParams> = {}
): Promise<PaginatedResult<Supplier>> {
  const { data } = await httpClient.get('/shippers/suppliers/list', {
    params: createSearchParams(getSuppliersListParams(params)),
  })

  return data
}
