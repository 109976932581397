import toArray from 'utils/toArray'

import type { Filters, FiltersPlugin } from '../filters.types'

/**
 * Function responsible for triggering plugin events.
 * @param argPlugins - list of plugins to trigger.
 * @returns An object with methods to handle plugin initialization and changes.
 */
export function pluginsRunner<T>(argPlugins: FiltersPlugin<T>[]) {
  const plugins = toArray(argPlugins)

  return {
    onInit(fields: Filters<T>): Filters<T> {
      let newFields = structuredClone(fields)

      for (const plugin of plugins) {
        newFields = { ...newFields, ...(plugin.onInit(newFields) || {}) }
      }

      return newFields
    },
    onChange(fields: Filters<T>): void {
      for (const plugin of plugins) {
        plugin.onChange(fields || {})
      }
    },
  }
}
