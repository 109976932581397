import { useEffect, useState } from 'react'

interface ScriptLoaderProps {
  readonly src: string
  readonly onLoad: () => void
}

const ScriptLoader = ({ src, onLoad }: ScriptLoaderProps) => {
  const [script, setScript] = useState<HTMLScriptElement | null>(null)

  useEffect(() => {
    const newScript = document.createElement('script')
    newScript.src = src
    newScript.async = true

    setScript(newScript)

    document.body.appendChild(newScript)

    return () => {
      document.body.removeChild(newScript)
    }
  }, [src])

  useEffect(() => {
    if (!script) {
      return () => undefined
    }

    const currentOnLoad = onLoad

    script.addEventListener('load', currentOnLoad)

    return () => {
      script.removeEventListener('load', currentOnLoad)
    }
  }, [script, onLoad])

  return null
}

export default ScriptLoader
