import { MODES } from 'utils/constants'
import { toSelectOptions } from 'utils/selectOption'
import { areTransportationModesEqual } from 'utils/transportationMode'
import type { Mode } from 'utils/types'

const DEFAULT_EQUIPMENT_LENGTHS_MAP = new Map<string, string>([
  ['26', "26'"] as const,
  ['53', "53'"] as const,
  ['48', "48'"] as const,
])

const IMDL_EQUIPMENT_LENGTHS_MAP = new Map<string, string>([
  ['20', "20'"] as const,
  ['40', "40'"] as const,
  ['48', "48'"] as const,
  ['53', "53'"] as const,
  ['40 HC', "40' HC"] as const,
  ['45 HC', "45' HC'"] as const,
])

export const DEFAULT_EQUIPMENT_LENGTH_OPTIONS_MAP = new Map(
  toSelectOptions(DEFAULT_EQUIPMENT_LENGTHS_MAP).map(
    (option) => [option.value, option] as const
  )
)

export const IMDL_EQUIPMENT_LENGTH_OPTIONS_MAP = new Map(
  toSelectOptions(IMDL_EQUIPMENT_LENGTHS_MAP).map(
    (option) => [option.value, option] as const
  )
)

function fromLengthToSelectOptionDefault(value?: string): SelectOption | null {
  if (!value) {
    return null
  }

  return DEFAULT_EQUIPMENT_LENGTH_OPTIONS_MAP.get(value) ?? null
}

function fromLengthToSelectOptionImdl(value?: string): SelectOption | null {
  if (!value) {
    return null
  }

  return IMDL_EQUIPMENT_LENGTH_OPTIONS_MAP.get(value) ?? null
}

export function useEquipmentLengths(mode: Mode) {
  const isImdl = areTransportationModesEqual(mode, MODES.IMDL)

  const lengths = isImdl
    ? toSelectOptions(IMDL_EQUIPMENT_LENGTHS_MAP)
    : toSelectOptions(DEFAULT_EQUIPMENT_LENGTHS_MAP)

  const fromLengthToSelectOption = isImdl
    ? fromLengthToSelectOptionImdl
    : fromLengthToSelectOptionDefault

  return { lengths, fromLengthToSelectOption }
}
