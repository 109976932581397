import { useCallback } from 'react'

import { triggerEvent } from '_shared_/custom-events/events'
import { useSettings } from 'contexts/settings'
import { mapFacilityContactV2ToV1 } from 'utils/adapters/facilityV2toV1'
import queryClient from 'utils/queryClient'

function refetchFacilityDetails(facilityUUID: string) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetails', facilityUUID],
  })
}

function refetchFacilityDetailsV2(facilityUUID: string) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
  })
}

type OpenContactsManagerOptions = {
  facilityUUID: string
  onContactSaved?: (contact: Contact) => void
  initialView?: 'details' | 'edit-form'
}

/**
 * use this hook with the EventBasedContactsDrawer and the EventBasedContactsDrawer.
 */
export function useContactsDrawer(props?: {
  onContactSaved?: (contact: Contact) => void
}) {
  const {
    values: [contactsDrawerV2Enabled],
  } = useSettings(['flags.ENABLE_CONTACTS_DRAWER_V2'])
  const isV2FlagEnabled = Boolean(contactsDrawerV2Enabled)

  const { onContactSaved } = props ?? {}

  const openContactsManagerDrawer = useCallback(
    (options: OpenContactsManagerOptions) => {
      const { facilityUUID, onContactSaved: scopedOnContactSaved } = options

      if (!facilityUUID) {
        return
      }

      const handleContactSaved = scopedOnContactSaved ?? onContactSaved

      triggerEvent('sg:open-contacts-manager-v2', {
        facilityUUID,
        onContactsSaved: (contacts) => {
          if (handleContactSaved) {
            contacts.forEach((contact) =>
              handleContactSaved(mapFacilityContactV2ToV1(contact))
            )
          }

          refetchFacilityDetailsV2(facilityUUID)
        },
        initialView: options.initialView,
      })
    },
    [onContactSaved]
  )

  /**
   * Opens the `ContactsManagerDrawer` in the edit-form view.
   * The `onContactSaved` callback provided here override the `onContactSaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openEditContactsForm = useCallback(
    (options: Omit<OpenContactsManagerOptions, 'initialView'>) => {
      openContactsManagerDrawer({ initialView: 'edit-form', ...options })
    },
    [openContactsManagerDrawer]
  )

  /**
   * Opens the `ContactsManagerDrawer` in the details view.
   * The `onContactSaved` callback provided here override the `onContactSaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openContactsDetails = useCallback(
    (options: Omit<OpenContactsManagerOptions, 'initialView'>) => {
      openContactsManagerDrawer({ initialView: 'details', ...options })
    },
    [openContactsManagerDrawer]
  )

  /**
   * @deprecated
   * Opens the `ContactsManagerDrawer`.
   * The `onContactSaved` callback provided here overrides the `onContactSaved` callback provided to the `useContactsDrawer` hook (if any).
   * Please prefer to use the specific openEditContactsForm or openContactsDetails function instead.
   */
  const openContactsManagerV2 = useCallback(
    (options: OpenContactsManagerOptions) => {
      openContactsManagerDrawer(options)
    },
    [openContactsManagerDrawer]
  )

  /**
   * @deprecated
   * Opens the `ContactsDrawer`.
   * The `onContactSaved` callback provided here overrides the `onContactSaved` callback provided to the `useContactsDrawer` hook (if any).
   * Please prefer to use the specific openEditContactsForm or openContactsDetails function instead.
   */
  const openContactsManager = useCallback(
    (options: Omit<OpenContactsManagerOptions, 'initialView'>) => {
      const { facilityUUID, onContactSaved: scopedOnContactSaved } = options

      if (!facilityUUID) {
        return
      }

      const handleContactSaved = scopedOnContactSaved ?? onContactSaved

      triggerEvent('sg:open-contacts-manager', {
        facilityUUID,
        callback: (contact) => {
          handleContactSaved?.(contact)

          refetchFacilityDetails(facilityUUID)
        },
      })
    },
    [onContactSaved]
  )

  if (isV2FlagEnabled) {
    return {
      openContactsDetails,
      openEditContactsForm,
      openContactsManager: openContactsManagerV2,
    }
  }

  return {
    openEditContactsForm: openContactsManager,
    openContactsManager,
  }
}
