import type {
  TransientFacilityHoursOfOperation,
  WeeklyTransientOperatingHours,
} from './Facility.types'

type UseOperatingHoursSectionProps = {
  weeklyOperatingHours: WeeklyTransientOperatingHours
  onChange?: (weeklyOperatingHours: WeeklyTransientOperatingHours) => void
}

export function useOperatingHoursManager(props: UseOperatingHoursSectionProps) {
  const { weeklyOperatingHours, onChange } = props

  const days = Object.keys(weeklyOperatingHours) as DayOfWeek[]

  function getDailyOperatingHoursSetup(dayOfWeek: DayOfWeek) {
    const dailyOperatingHours = weeklyOperatingHours[dayOfWeek]
    const { enabled, operating_hours: operatingHours } = dailyOperatingHours

    return {
      enabled,
      dailyOperatingHours: operatingHours,
      canAddOperatingHoursInterval: enabled,
      canRemoveOperatingHoursInterval: enabled && operatingHours.length > 1,
      onEnabledChange: (newEnabled: boolean) => {
        onChange?.({
          ...weeklyOperatingHours,
          [dayOfWeek]: {
            ...weeklyOperatingHours[dayOfWeek],
            enabled: newEnabled,
          },
        })
      },
      onDailyOperatingHoursChange: (
        newDailyOperatingHours: TransientFacilityHoursOfOperation[]
      ) => {
        onChange?.({
          ...weeklyOperatingHours,
          [dayOfWeek]: {
            ...weeklyOperatingHours[dayOfWeek],
            operating_hours: newDailyOperatingHours,
          },
        })
      },
    }
  }

  return {
    showNoHoursSetBanner: days.every(
      (day) => !weeklyOperatingHours[day].enabled
    ),
    getDailyOperatingHoursSetup,
  }
}
