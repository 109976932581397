import httpClient from 'utils/httpClient'

export const getMetadata = async () => {
  const { data } = await httpClient.get('metadata')
  return data
}

export const getTarpTypes = async () => {
  const { data } = await httpClient.get('metadata/tarp-types')
  return data
}

export const getSubEquipment = async () => {
  const { data } = await httpClient.get('metadata/equipment-subtypes')
  return data
}
