import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, Toggle, ToggleGroup } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const POWER_ONLY_NAME = 'power_only'
const POWER_ONLY_LABEL = 'Power only'
const YES_VALUE = 'yes'
const NO_VALUE = 'no'

type FreightInformationPowerOnlyFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationPowerOnlyFormField({
  required,
  disabled,
  hint,
  error,
  style,
}: FreightInformationPowerOnlyFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: POWER_ONLY_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${POWER_ONLY_NAME}-field`}
    >
      <Field.Label id={`label-${POWER_ONLY_NAME}`}>
        {POWER_ONLY_LABEL}
      </Field.Label>
      <ToggleGroup
        aria-labelledby={`label-${POWER_ONLY_NAME}`}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [POWER_ONLY_NAME]:
                String(get(event, 'target.value')) === YES_VALUE,
            },
          })
        }}
        value={shipment[POWER_ONLY_NAME] ? YES_VALUE : NO_VALUE}
        type="single-strict"
        disabled={disabled}
      >
        <Toggle value={NO_VALUE}>No</Toggle>
        <Toggle value={YES_VALUE}>Yes</Toggle>
      </ToggleGroup>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
