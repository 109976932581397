import httpClient from 'utils/httpClient'

export const list = async (lane: number) => {
  const { data } = await httpClient.get(`/proposals/lanes/${lane}/proposals`)
  return data
}

export const createLaneProposal = async (
  linkUUID: string,
  lane: CarrierLaneProposal
) => {
  const { data } = await httpClient.post(
    `/proposals/${linkUUID}/lane_proposals`,
    lane
  )
  return data
}

export const updateLaneProposal = async (
  linkUUID: string,
  data: CarrierLaneProposal
) => {
  return httpClient.put(
    `/proposals/${linkUUID}/lane_proposals/${data.id}`,
    data
  )
}

export const destroyLaneProposal = async (
  linkUUID: string,
  data: CarrierLaneProposal
) => {
  return httpClient.delete(`/proposals/${linkUUID}/lane_proposals/${data.id}`)
}

export const partialUpdate = async ({
  laneId,
  carrierId,
  payload,
  successMessage,
}: {
  laneId: number
  carrierId: number
  payload: Partial<LaneProposal>
  successMessage?: string
}) => {
  const { data } = await httpClient.patch(
    `/proposals/lanes/${laneId}/proposals/${carrierId}`,
    payload
  )
  return { data, successMessage }
}

export const rankedAwards = async ({
  laneId,
  payload,
}: {
  laneId: number
  payload: Partial<LaneProposal>[]
}) => {
  return await httpClient.post(
    `/proposals/lanes/${laneId}/proposals/ranked_awards`,
    { ranked_awards: payload }
  )
}
