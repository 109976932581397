import { Text } from '@loadsmart/miranda-react'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'

type AppointmentCardFooterProps = {
  readonly appointment: StopAppointment | null
}

export default function AppointmentCardScheduledBy({
  appointment,
}: AppointmentCardFooterProps) {
  const show =
    appointment?.status !== 'pending' &&
    (appointment?.scheduled_by || appointment?.confirmation_number)

  return show ? (
    <Text variant="body-sm" color="color-text-tertiary">
      {appointment.scheduled_by && `Scheduled by ${appointment.scheduled_by} `}
      {appointment.confirmation_number &&
        `- Confirmation code ${appointment.confirmation_number}`}
    </Text>
  ) : null
}
