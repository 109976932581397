import { createSummarySetup } from 'components/Summary'
import type { FacilityDetailsV2 } from 'services/facilities'

type FacilitySummaryContextValue = {
  facility: FacilityDetailsV2
}

const { SummaryProvider, useSummaryContext } =
  createSummarySetup<FacilitySummaryContextValue>({
    contextName: 'FacilitySummaryContext',
  })

export {
  SummaryProvider as FacilitySummaryProvider,
  useSummaryContext as useFacilitySummaryContext,
}
