import { Banner } from '@loadsmart/loadsmart-ui'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deleteMessage } from 'features/message/actions'
import { messageListSelector } from 'features/message/selectors'

export default function MessageCenter({ id }: { readonly id?: string }) {
  const dispatch = useDispatch()
  const messages = useSelector(messageListSelector)

  const removeMessage = useCallback(
    (messageId: string) => {
      dispatch(deleteMessage(messageId))
    },
    [dispatch]
  )

  return (
    <>
      {messages
        .filter(({ containerId }) => containerId === id)
        .map((message) => (
          <Banner
            key={message.id}
            scale={message.description ? 'large' : 'default'}
            variant={message.type}
            title={message.message}
            description={message.description || ''}
            dismissible={message.closeable}
            onClose={() => removeMessage(message.id)}
          />
        ))}
    </>
  )
}
