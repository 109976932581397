import { useQuery } from 'react-query'

import type { TruckMileageStop } from 'orders/order-service'
import { getTruckMileage } from 'orders/order-service'

export function useTruckMileage(stops: TruckMileageStop[]) {
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ['getTruckMileage', stops],
    queryFn: () => getTruckMileage(stops),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  return {
    data,
    isError,
    isLoading,
    refetch,
  }
}
