import { isBlank } from '@loadsmart/utils-string'
import { get, identity, invert, isEmpty, isObject, isString, set } from 'lodash'

import toArray from 'utils/toArray'

import type { Filters, FiltersPluginHook } from '../../filters.types'

/**
 * Example: name=value&age=21
 */
export type PrimitiveFilter<F = unknown> = {
  type: 'primitive'
  toSearchParam?: (value: F) => F | null
  fromSearchParam?: (value: string | null, params: URLSearchParams) => F | null
}

/**
 * Example strings=X&strings=Y&strings=Z
 */
export type PrimitiveCollectionFilter<F = unknown> = {
  type: 'primitive-collection'
  toSearchParam?: (value: F[]) => F[] | null
  fromSearchParam?: (
    value: string[] | null,
    params: URLSearchParams
  ) => F[] | null
}

/**
 * Example: foo_id=99&foo_name=bas -> { id: 99, name: 'bas' }
 */
export type ObjectFilter<F extends { [key: string]: unknown }> = {
  type: 'object'
  mapping: Record<string, keyof F> // { foo_id: 'id', foo_name: 'name' }
  toSearchParam?: (value: F) => F | null
  fromSearchParam?: (value: F | null, params: URLSearchParams) => F | null
}

/**
 * Example: foos_id=989&foos_name=bas&foos_id=99&foos_name=baz -> [{ id: 989, name: 'bas' }, { id: 99, name: 'baz' }]
 */
export type ObjectCollectionFilter<F extends { [key: string]: unknown }> = {
  type: 'object-collection'
  mapping: Record<string, keyof F> // { foos_id: 'id', foos_name: 'name' }
  toSearchParam?: (value: F[]) => F[] | null
  fromSearchParam?: (value: F[] | null, params: URLSearchParams) => F[] | null
}

export type FilterConfig<T> = T extends { [key: string]: unknown }
  ? ObjectFilter<T> | ObjectCollectionFilter<T>
  : PrimitiveFilter<T> | PrimitiveCollectionFilter<T>

function isEmptyObject(value: unknown): boolean {
  return isObject(value) && isEmpty(value)
}

function isEmptyString(value: unknown): boolean {
  return isString(value) && isBlank(value.trim())
}

function shouldBeSkipped(value: unknown): boolean {
  return value == null || isEmptyObject(value) || isEmptyString(value)
}

/**
 * Generate a plugin hook to be used with `useFilter` with the given
 * @param config - config to define how to handle query params from/to filter values convertion.
 * @returns
 */
export function generateUseSearchParamsPlugin<C>(
  config: Record<keyof C, FilterConfig<any>>
): FiltersPluginHook<C> {
  function getType(filter: keyof C) {
    return get(config, [filter, 'type'])
  }

  /**
   * Get the transformer from search param to filter value. Returns `identity` by default.
   * @param filter filter name.
   */
  function getFromSearchParamTransformer(filter: keyof C) {
    return get(config, [filter, 'fromSearchParam'], identity)
  }

  /**
   * Get the transformer from filter value to search param. Returns `identity` by default.
   * @param filter filter name.
   */
  function getToSearchParamTransformer(filter: keyof C) {
    return get(config, [filter, 'toSearchParam'], identity)
  }

  function getSearchParamToPropertyMapping(filter: keyof C) {
    if (!(filter in config)) {
      return {}
    }

    if (
      ['object', 'object-collection'].includes(getType(filter)) &&
      'mapping' in config[filter]
    ) {
      return (config[filter] as ObjectFilter<any>).mapping
    }

    return { [filter]: filter }
  }

  function getPropertyToSearchParamMapping(filter: keyof C) {
    return invert(getSearchParamToPropertyMapping(filter))
  }

  /**
   * Get the `fromSearchParam` function for the given filter based on its `type`.
   * The returned function will be used to convert the query param value to the filter value.
   * @param filter filter name
   */
  function getSearchParamToValueTransformer(filter: keyof C) {
    const type = getType(filter)

    function transformPrimitiveSearchParamToValue(
      searchParams: URLSearchParams
    ) {
      const expectedSearchParams = getSearchParamToPropertyMapping(filter)
      const searchParam = searchParams.get(String(expectedSearchParams[filter]))

      const transformer = getFromSearchParamTransformer(filter) as NonNullable<
        PrimitiveFilter['fromSearchParam']
      >

      return transformer(searchParam, searchParams)
    }

    function transformPrimitiveCollectionSearchParamToValue(
      searchParams: URLSearchParams
    ) {
      const expectedSearchParams = getSearchParamToPropertyMapping(filter)
      const searchParam = searchParams.getAll(
        String(expectedSearchParams[filter])
      )

      const transformer = getFromSearchParamTransformer(filter) as NonNullable<
        PrimitiveCollectionFilter['fromSearchParam']
      >

      return transformer(searchParam, searchParams)
    }

    function transformObjectSearchParamToValue(searchParams: URLSearchParams) {
      const searchParamToPropertyMapping =
        getSearchParamToPropertyMapping(filter)

      let result: Record<string, unknown> = {}

      for (const searchParam in searchParamToPropertyMapping) {
        const property = searchParamToPropertyMapping[searchParam]
        const rawValue = searchParams.get(searchParam)

        // preventing filling out properties that are not in the query params
        if (!shouldBeSkipped(rawValue)) {
          result = set(result, [property], rawValue)
        }
      }

      const transformer = getFromSearchParamTransformer(filter) as NonNullable<
        ObjectFilter<any>['fromSearchParam']
      >

      return transformer(result, searchParams)
    }

    function transformObjectCollectionSearchParamToValue(
      searchParams: URLSearchParams
    ) {
      const searchParamToPropertyMapping =
        getSearchParamToPropertyMapping(filter)

      let result: Record<string, unknown>[] = []

      for (const searchParam in searchParamToPropertyMapping) {
        const property = searchParamToPropertyMapping[searchParam]
        const rawValues = searchParams.getAll(searchParam)

        for (let index = 0; index < rawValues.length; index++) {
          result = set(result, [index, property], rawValues[index])
        }
      }

      const transformer = getFromSearchParamTransformer(filter) as NonNullable<
        ObjectCollectionFilter<any>['fromSearchParam']
      >

      return transformer(result, searchParams)
    }

    const transformerByType = {
      primitive: transformPrimitiveSearchParamToValue,
      'primitive-collection': transformPrimitiveCollectionSearchParamToValue,
      object: transformObjectSearchParamToValue,
      'object-collection': transformObjectCollectionSearchParamToValue,
    }

    return transformerByType[type] ?? (() => null)
  }

  /**
   * Get the `toSearchParam` function for the given filter based on its `type`.
   * The returned function will be used to convert the filter value to the query param value(s) that will represent it.
   * @param filter filter name
   */
  function getValueToSearchParamTransformer(filter: keyof C) {
    const type = getType(filter)

    function transformPrimitiveValueToSearchParam(value: any) {
      const transformer = getToSearchParamTransformer(filter) as NonNullable<
        PrimitiveFilter['toSearchParam']
      >
      const primitive = transformer(value)

      if (!shouldBeSkipped(primitive)) {
        return [[filter as string, String(primitive)]]
      }

      return []
    }

    function transformPrimitiveCollectionValueToSearchParam(value: any[]) {
      const expectedProperties = getPropertyToSearchParamMapping(filter)
      const transformer = getToSearchParamTransformer(filter) as NonNullable<
        PrimitiveCollectionFilter['toSearchParam']
      >
      const collection = toArray(transformer(value)).filter(
        (item) => !shouldBeSkipped(item)
      )

      const result: string[][] = []
      for (const property in expectedProperties) {
        const searchParam = expectedProperties[property]

        collection.forEach((item) => {
          result.push([searchParam, String(item)])
        })
      }

      return result
    }

    function transformObjectValueToSearchParam(value: any) {
      const expectedProperties = getPropertyToSearchParamMapping(filter)
      const transformer = getToSearchParamTransformer(filter) as NonNullable<
        ObjectFilter<any>['toSearchParam']
      >
      const object = transformer(value)

      const result: string[][] = []

      for (const property in expectedProperties) {
        const searchParam = expectedProperties[property]
        const propertyValue = get(object, property)

        if (!shouldBeSkipped(propertyValue)) {
          result.push([searchParam, String(propertyValue)])
        }
      }

      return result
    }

    function transformObjectCollectionValueToSearchParam(value: any[]) {
      const expectedProperties = getPropertyToSearchParamMapping(filter)
      const transformer = getToSearchParamTransformer(filter) as NonNullable<
        ObjectCollectionFilter<any>['toSearchParam']
      >
      const collection = transformer(value)

      const result: string[][] = []
      toArray(collection).forEach((item) => {
        for (const property in expectedProperties) {
          const searchParam = expectedProperties[property]

          /**
           * Opposite to other filter value to query param handlers, here we need to
           * keep even falsy-valued properties values so we know the order of the object collection
           * when parsing the query params.
           *
           * Example:
           * [{ id: 1 }, { id: 2, name: 'John'}}]
           *
           * We will return:
           * { id: [1, 2], name: ['', 'John'] }
           *
           * That will go to the query params as:
           * id=1&name=&id=2&name=John
           *
           * In this example, with this approach we will know - based on the order of the parameters - that the
           * first object does not have a `name` property.
           */
          result.push([searchParam, get(item, property, '')])
        }
      })

      return result
    }

    const transformerByType = {
      primitive: transformPrimitiveValueToSearchParam,
      'primitive-collection': transformPrimitiveCollectionValueToSearchParam,
      object: transformObjectValueToSearchParam,
      'object-collection': transformObjectCollectionValueToSearchParam,
    }

    return transformerByType[type] ?? (() => null)
  }

  return function useSearchParamsPlugin() {
    return {
      _name: 'useSearchParamsPlugin',
      onInit: (initialFilters: Filters<C>) => {
        let result = { ...initialFilters }

        const filters = Object.keys(config) as (keyof C)[]
        const params = new URLSearchParams(window.location.search)

        // we use the config filters because that's our reference for all filters that will be managed
        try {
          filters.forEach((filter) => {
            const handler = getSearchParamToValueTransformer(filter)
            const value = handler(params)

            if (shouldBeSkipped(value)) {
              return
            }

            result = set(result, [filter], value)
          })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error) //?
        }

        return result
      },
      onChange: (changedFilters: Filters<C>) => {
        const filters = Object.keys(changedFilters) as (keyof C)[]

        const params = new URLSearchParams(window.location.search)

        for (const filter of filters) {
          try {
            const handler = getValueToSearchParamTransformer(filter)

            const entries = handler(changedFilters[filter] as any)

            // clean up query params related to this filter
            Object.keys(getSearchParamToPropertyMapping(filter)).forEach(
              (param) => {
                params.delete(param)
              }
            )

            toArray(entries).forEach(([key, value]) => {
              params.append(key, value)
            })
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        }

        const url = new URL(window.location.pathname, window.location.origin)
        url.search = `?${params.toString()}`

        window.history.pushState({}, '', String(url))
      },
    }
  }
}
