import { useCallback, useEffect, useState } from 'react'

import type { CustomEventListener, CustomEventMap } from './custom-events.types'
import { subscribeEvent, unsubscribeEvent } from './events'

/**
 * Subscribes to a custom event and returns the data from the event.
 *
 * @param eventName The name of the event to subscribe to. It must be prefixed with `sg:` and be declared in the `DocumentEventMap`.
 * @example
 * ```ts
 * declare global {
 *   interface DocumentEventMap {
 *     'sg:open-chat-window': CustomEvent<ChatWindowEventData>
 *  }
 * }
 * ```
 */
export function useCustomEventSubscription<K extends keyof CustomEventMap>(
  eventName: K,
  callback?: (event: CustomEventMap[K]) => void
) {
  const [data, setData] = useState<CustomEventMap[K]['detail']>()

  const handleEvent = useCallback<CustomEventListener<K>>(
    function customEventHandler(event) {
      setData(event.detail)
      callback?.(event)
    },
    [callback]
  )

  useEffect(() => {
    subscribeEvent(eventName, handleEvent)

    return () => {
      unsubscribeEvent(eventName, handleEvent)
    }
  }, [eventName, handleEvent])

  const clearEvent = useCallback(() => {
    setData(undefined)
  }, [])

  return { data, clearEvent }
}
