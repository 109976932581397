import type { RouteComponentProps } from 'react-router-dom'

import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

export const isActivePath = (path: string) => {
  return window.location.pathname.toLowerCase().startsWith(path)
}

export function navbarLinkClicked(
  address: string,
  url?: string,
  history?: RouteComponentProps['history']
) {
  analytics.track(AnalyticsEvent.NavbarLink, AnalyticsEventTrigger.click, {
    address,
  })

  if (url) {
    history?.push(url)
  }
}
