import { Card, Layout, Tag } from '@loadsmart/miranda-react'
import { useFulfillmentFormContext } from 'fulfillments/create/CreateFulfillmentContext'

import type { TransientStop } from 'components/StopsManager'
import {
  createTransientStop,
  StopForm,
  StopsForm,
} from 'components/StopsManager'
import { getTransientErrorsCount } from 'utils/transient'

export function FulfillmentLane() {
  const { fulfillment, setPartialFulfillment } = useFulfillmentFormContext()

  const errorsCount = getTransientErrorsCount(fulfillment.stops)

  function handleStopChange(stops: TransientStop[]) {
    setPartialFulfillment({ stops })
  }

  return (
    <StopsForm
      stops={fulfillment.stops}
      createStop={createTransientStop}
      onChange={handleStopChange}
    >
      <Card id="fulfillment-lane">
        <Card.Title>
          <Layout.Group align="center" padding="none" gap="spacing-1">
            Lane
            {errorsCount > 0 && (
              <Tag variant="danger" size="small">
                {errorsCount} {errorsCount > 1 ? 'Errors' : 'Error'}
              </Tag>
            )}
          </Layout.Group>
        </Card.Title>
        <Card.Divider />
        <Card.Body>
          <Layout.Stack>
            <StopForm index={0}>
              <Layout.Group data-testid="pickup-stop">
                {/* TODO - Implement customer's facilitiy field */}
                {fulfillment.owner == 'us' && (
                  <StopForm.Facility
                    label="Pickup"
                    required
                    style={{ flex: 1 }}
                  />
                )}

                <StopForm.Date label="Pickup date" required />
              </Layout.Group>
            </StopForm>
            <StopForm index={fulfillment.stops.length - 1}>
              <Layout.Group data-testid="delivery-stop">
                {/* TODO - Implement customer's facilitiy field */}
                {fulfillment.owner == 'us' && (
                  <StopForm.Facility
                    label="Delivery"
                    required
                    style={{ flex: 1 }}
                  />
                )}

                <StopForm.Date label="Delivery date" required />
              </Layout.Group>
            </StopForm>
          </Layout.Stack>
        </Card.Body>
      </Card>
    </StopsForm>
  )
}
