import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const PO_NUMBER_NAME = 'po_number'
const PO_NUMBERS_NAME = 'po_numbers'
const PO_NUMBER_LABEL = 'PO number(s)'

type FreightInformationPONumberSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationPONumberSummaryField(
  props: FreightInformationPONumberSummaryFieldProps
) {
  const { shipment } = useFreightInformationSummaryContext()
  const value = get(shipment, PO_NUMBERS_NAME) ?? get(shipment, PO_NUMBER_NAME)

  return (
    <FreightInformationSummaryField
      label={PO_NUMBER_LABEL}
      name={PO_NUMBER_NAME}
      value={value}
      {...props}
    />
  )
}

type FreightInformationPONumberFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly placeholder?: string
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationPONumberFormField({
  required,
  placeholder = 'Enter PO number',
  hint = 'Separate values with commas',
  error,
  style,
}: FreightInformationPONumberFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: PO_NUMBER_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${PO_NUMBER_NAME}-field`}
    >
      <Field.Label id={`label-${PO_NUMBER_NAME}`}>
        {PO_NUMBER_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${PO_NUMBER_NAME}`}
        placeholder={placeholder}
        value={get(shipment, PO_NUMBER_NAME)}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [PO_NUMBER_NAME]: get(event, 'target.value') },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
