import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'
import Talk from 'talkjs'

import { triggerEvent } from '_shared_/custom-events/events'

import { ChatSource } from './chatEvents.type'
import { TALKJS_APP_ID } from './constants'
import {
  createConversation,
  getUserContactInfo,
  updateConversation,
} from './services'
import type {
  ChatContactInfoResponse,
  ChatType,
  ConversationParticipant,
} from './types'

type UseCreateNewChatProps = {
  otherUser: { uuid: string; name: string }
}

export const createSession = (userData: ChatContactInfoResponse) => {
  const user = new Talk.User({
    id: userData.uuid,
    name: userData.name,
    email: userData.email,
    role: 'shipper',
  })

  const session = new Talk.Session({
    appId: TALKJS_APP_ID,
    me: user,
  })

  return session
}

async function createNewChat({ otherUser }: UseCreateNewChatProps) {
  const currentUser = await getUserContactInfo()

  const data = createConversation({
    participants: [
      {
        uuid: currentUser.uuid,
        type: 'shipper',
      },
      {
        uuid: otherUser.uuid,
        type: 'carrier',
      },
    ],
    subject: otherUser.name,
    type: 'direct',
  })

  return data
}

export async function createAndOpenChatWindow(
  contactUUID: string,
  contactName: string
) {
  try {
    const response = await createNewChat({
      otherUser: {
        uuid: contactUUID,
        name: contactName,
      },
    })

    openChatWindow({
      conversationId: response.uuid,
      subject: response.subject,
      type: 'direct',
      source: ChatSource.Direct,
    })
  } catch (error) {
    toast.error('Could not initialize conversation, please contact an admin', {
      position: 'top-left',
    })

    const newError = new Error('Failed to create direct chat', { cause: error })
    Sentry.captureException(newError)
  }
}

export async function updateChatGroup(
  conversationId: string,
  subject?: string,
  participants?: ConversationParticipant[]
) {
  const payload = {
    uuid: conversationId,
    ...(participants && {
      participants: [
        ...participants.map((participant) => ({
          uuid: participant.uuid,
          type: participant.type,
        })),
      ],
    }),
    ...(subject && { subject }),
    type: 'group' as const,
  }

  try {
    const response = await updateConversation(payload)

    openChatWindow({
      conversationId: response.uuid,
      subject: response.subject,
      participants: response.participants,
      type: response.type,
      source: ChatSource.GroupUpdate,
    })
  } catch (error) {
    toast.error(
      'Could not update group conversation, please contact an admin',
      {
        position: 'top-left',
      }
    )

    const newError = new Error('Failed to update group chat', { cause: error })
    Sentry.captureException(newError)
  }
}

export function openChatWindow({
  conversationId,
  subject,
  participants,
  type,
  source,
}: {
  conversationId: string
  subject: string
  participants?: ConversationParticipant[]
  type: ChatType
  source: ChatSource
}) {
  try {
    triggerEvent('sg:open-chat-window', {
      conversationId,
      subject,
      participants,
      type,
      source,
    })
  } catch (error) {
    let toastError

    switch (source) {
      case ChatSource.Direct:
        toastError = 'initialize conversation'
        break

      case ChatSource.Group:
        toastError = 'initialize group conversation'
        break

      case ChatSource.GroupUpdate:
        toastError = 'update group conversation'
        break

      case ChatSource.RateItemDropdown:
        toastError = 'initialize group conversation'
        break
    }

    toast.error(`Could not ${toastError}, please contact an admin`, {
      position: 'top-left',
    })

    const newError = new Error('Failed to open chat window', { cause: error })
    Sentry.captureException(newError)
  }
}
