import type { CommodityPackageType } from 'components/ShippingItemsManager'
import { COMMODITY_PACKAGE_TYPES_OPTIONS_MAP } from 'components/ShippingItemsManager'
import type { HazmatClass, HazmatPackingGroup } from 'utils/types'

import {
  HAZMAT_CLASSES_OPTIONS_MAP,
  HAZMAT_PACKING_GROUP_OPTIONS_MAP,
} from './HazmatInformation.constants'

export function getHazmatPackageTypeOption(
  packageType: CommodityPackageType | null
) {
  if (!packageType || !COMMODITY_PACKAGE_TYPES_OPTIONS_MAP.has(packageType)) {
    return null
  }

  return COMMODITY_PACKAGE_TYPES_OPTIONS_MAP.get(packageType)
}

export function getHazmatClassOption(hazmatClass: HazmatClass | null) {
  if (!hazmatClass || !HAZMAT_CLASSES_OPTIONS_MAP.has(hazmatClass)) {
    return null
  }

  return HAZMAT_CLASSES_OPTIONS_MAP.get(hazmatClass)
}

export function getHazmatPackingGroupOption(
  packingGroup: HazmatPackingGroup | null
) {
  if (!packingGroup || !HAZMAT_PACKING_GROUP_OPTIONS_MAP.has(packingGroup)) {
    return null
  }

  return HAZMAT_PACKING_GROUP_OPTIONS_MAP.get(packingGroup)
}
