import toArray from 'utils/toArray'

import type {
  RawShipmentCharge,
  RawShipmentChargeAttachment,
  RawShipmentChargeEvent,
  RawShipmentChargeStop,
  ShipmentCharge,
  ShipmentChargeAttachment,
  ShipmentChargeAction,
  ShipmentChargeEventType,
  Stop,
} from '../types'
import { shipmentChargeTypeAdapter } from './shipmentChargeTypes'

const getChargeEvent = (
  chargeEvents: RawShipmentChargeEvent[],
  type: ShipmentChargeEventType
) => chargeEvents.find((event) => event.type === type)

const hasAvailableAction = (
  availableActions: ShipmentChargeAction[],
  action: ShipmentChargeAction
) => availableActions.some((availableAction) => availableAction === action)

export const getStopName = (
  stop: RawShipmentChargeStop | Stop | null
): string => {
  if (!stop) {
    return ''
  }
  if (stop.facility?.company_name) {
    return stop.facility.company_name
  }
  return [stop.address, stop.city, stop.state, stop.country]
    .filter(Boolean)
    .join(', ')
}

export const getCreatedBy = (
  charge: RawShipmentCharge,
  creationEvent: RawShipmentChargeEvent | undefined
): string => {
  if (charge.source === 'quote') {
    return 'From quote'
  }
  if (creationEvent?.user?.username) {
    return creationEvent.user.username
  }
  if (charge.source === 'carrier') {
    return charge.carrier.name ?? charge.carrier.company_name ?? 'Carrier'
  }
  return 'Shipper'
}

export const getCanceledBy = (
  charge: RawShipmentCharge,
  canceledEvent: RawShipmentChargeEvent | undefined
): string | null | undefined => {
  if (charge.status.value !== 'canceled') {
    return undefined
  }
  // If the cancel event doesn't have a user, we can assume it came from the carrier
  return (
    canceledEvent?.user?.username ??
    charge.carrier.name ??
    charge.carrier.company_name
  )
}

const shipmentChargeAttachmentAdapter = (
  attachment?: RawShipmentChargeAttachment | null
): ShipmentChargeAttachment | undefined => {
  if (!attachment?.file_url) {
    return undefined
  }
  return {
    fileName: attachment.file_name || 'Attachment',
    fileUrl: attachment.file_url,
  }
}

export const shipmentChargeAdapter = (
  charge: RawShipmentCharge
): ShipmentCharge => {
  const creationEvent = getChargeEvent(
    charge.shipment_charge_events,
    'creation'
  )
  const canceledEvent = getChargeEvent(
    charge.shipment_charge_events,
    'cancellation'
  )
  const approvedEvent = getChargeEvent(
    charge.shipment_charge_events,
    'approval'
  )
  const rejectedEvent = getChargeEvent(
    charge.shipment_charge_events,
    'rejection'
  )

  return {
    uuid: charge.uuid,
    type: charge.shipment_charge_type
      ? shipmentChargeTypeAdapter(charge.shipment_charge_type)
      : undefined,
    createdAt: creationEvent?.created_at ?? '',
    createdBy: getCreatedBy(charge, creationEvent),
    canceledBy: getCanceledBy(charge, canceledEvent) ?? undefined,
    approvedBy: approvedEvent?.user?.username,
    rejectedBy: rejectedEvent?.user?.username,
    status: charge.status,
    quantity: charge.quantity,
    unitPrice: Number(charge.amount_per_unit) || 0,
    total: charge.total,
    stopName: getStopName(charge.shipment_stop),
    attachment: shipmentChargeAttachmentAdapter(
      charge.shipment_charge_attachment
    ),
    description: charge.notes ?? '',
    rejectReason: rejectedEvent?.description ?? undefined,
    cancelReason: canceledEvent?.description ?? undefined,
    canBeApproved: hasAvailableAction(charge.available_actions, 'approve'),
    canBeRejected: hasAvailableAction(charge.available_actions, 'reject'),
    canBeCanceled: hasAvailableAction(charge.available_actions, 'cancel'),
    priceItemType: charge.price_item_type ?? undefined,
  }
}

export const shipmentChargesAdapter = (
  charges?: RawShipmentCharge[] | null
): ShipmentCharge[] =>
  toArray(charges).map((charge) => shipmentChargeAdapter(charge))
