import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { CreateFulfillmentParams } from '../../fulfillment-service'
import { createFulfillment } from '../../fulfillment-service'

export function useCreateFulfillment() {
  return useMutation<unknown, AxiosError, CreateFulfillmentParams>({
    mutationKey: ['createFulfillment'],
    mutationFn: createFulfillment,
  })
}
