export function phoneFormatter(phone?: string) {
  if (!phone) {
    return ''
  }

  return `+1${phone.replace(/[()-\s]/g, '')}`
}

/**
 * Removes non-digit characters and add leading 1 if missing.
 * [!] This is a very naive implementation and does not consider phone numbers from other countries.
 *
 * we should evaluate adopting a more complete solution, like [google's libphonenumber](https://github.com/google/libphonenumber).
 * @param phoneNumber
 */
function normalizePhoneNumber(phoneNumber?: string | null) {
  if (!phoneNumber) {
    return ''
  }

  phoneNumber = phoneNumber.replace(/\D/g, '')

  // this "rule" applies only to US numbers
  if (phoneNumber.length < 10) {
    return ''
  }

  if (!phoneNumber.startsWith('1')) {
    phoneNumber = `1${phoneNumber}`
  }

  return phoneNumber
}

/**
 * Formats the given phone number, without separators.
 *
 * [!] This is a very naive implementation and does not consider phone numbers from other countries.
 *
 * @example
 * ```js
 * // returns '+17603323545'
 * formatPhoneNumber('7603323545');
 *
 * // returns '+17603323545'
 * formatPhoneNumber('17603323545');
 * ```
 *
 */
export function formatPhoneNumberForPayload(phone?: string) {
  phone = normalizePhoneNumber(phone)

  if (!phone) {
    return ''
  }

  return `+${phone}`
}

/**
 * Formats the given phone number, including separators.
 *
 * [!] This is a very naive implementation and does not consider phone numbers from other countries.
 * Based on:
 * - `formatPhoneNumber` from _src/screens/Shipper/Shipments/NewShipment/utils.ts_
 * - `phoneFormatter` from _src/utils/phone.ts_
 *
 * @example
 * ```js
 * // returns '+1(760) 332-3545'
 * formatPhoneNumber('7603323545');
 *
 * // returns '+1(760) 332-3545'
 * formatPhoneNumber('17603323545');
 * ```
 *
 */
export function formatPhoneNumber(phone?: string | null) {
  phone = normalizePhoneNumber(phone)

  if (!phone) {
    return ''
  }

  return `+${phone[0]}(${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(
    7,
    11
  )}`
}
