import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface State {
  data: Shipper
  meta: {
    isLoading: boolean
  }
}

const initialState: State = {
  data: {} as Shipper,
  meta: {
    isLoading: false,
  },
}

export const slice = createSlice({
  name: 'shipper',
  initialState,
  reducers: {
    loadingStart(state) {
      state.meta.isLoading = true
    },
    loadingEnd(state) {
      state.meta.isLoading = false
    },
    updateShipper(state, action: PayloadAction<Partial<Shipper>>) {
      const { payload } = action

      if (
        payload.logo_email_url &&
        payload.logo_email_url.includes('rfp_guide_email_logo')
      ) {
        payload.logo_email_url = undefined
      }
      state.data = { ...state.data, ...payload }
    },
  },
})

export default slice.reducer
