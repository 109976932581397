import type { LiteralUnion } from 'type-fest'

import toArray from 'utils/toArray'

import { toSelectOption } from './selectOption'

export const EQUIPMENT_TYPES_ALIASES = {
  '40hc': ['40hc', '40ft_high_cube_container', '40ft high cube container'],
  btr: ['btr', 'box truck'],
  cgv: ['cgv', 'cargo van'],
  cst: ['cst', 'conestoga', 'conestoga'],
  drb: ['drb', 'dry_box', 'dry box', 'drybox'],
  drv: ['drv', 'dry_van', 'dry van', 'dryvan'],
  fbe: ['fbe', 'flatbed'],
  fra: ['fra', 'flat_rack', 'flat rack', 'flatrack'],
  hot: ['hst', 'hotshot', 'hotshot', 'hot'],
  imc: ['imc', 'port_drayage', 'port drayage'],
  ltl: ['ltl', 'less_than_truckload', 'less than truckload'],
  pnmc: ['pnmc', 'bulk_pneumatics', 'bulk pneumatics'],
  pwr: ['pwr', 'power_only', 'power only'],
  rail: ['rail'],
  rfr: ['rfr', 'reefer', 'reefer'],
  roro: ['roro', 'roll_on_roll_off', 'roll on roll off'],
  sbt: ['sbt', 'straight_box_truck', 'straight box truck'],
  spr: ['spr', 'sprinter', 'sprinter', 'sprinter van'],
  str: ['str', 'straight truck'],
  tank: ['tank', 'bulk_tanker', 'bulk tanker'],
  vnt: ['vnt', 'reefer_or_vented_van', 'reefer or vented van'],
}

export type EquipmentTypeV2 = {
  abbr: string
  value: string
  label: string
}

export type CanonicalEquipmentTypeName = keyof typeof EQUIPMENT_TYPES_ALIASES

export const EQUIPMENT_TYPES: Record<
  CanonicalEquipmentTypeName,
  EquipmentTypeV2
> = {
  '40hc': {
    abbr: '40HC',
    value: '40ft_high_cube_container',
    label: '40ft High Cube Container',
  },
  btr: {
    abbr: 'BTR',
    value: 'BTR',
    label: 'Box Truck',
  },
  cgv: {
    abbr: 'CGV',
    value: 'CGV',
    label: 'Cargo Van',
  },
  cst: {
    abbr: 'CST',
    value: 'conestoga',
    label: 'Conestoga',
  },
  drb: {
    abbr: 'DRB',
    value: 'dry_box',
    label: 'Dry Box',
  },
  drv: {
    abbr: 'DRV',
    value: 'dry_van',
    label: 'Dry Van',
  },
  fbe: {
    abbr: 'FBE',
    value: 'flatbed',
    label: 'Flatbed',
  },
  fra: {
    abbr: 'FRA',
    value: 'flat_rack',
    label: 'Flat Rack',
  },
  hot: {
    abbr: 'HOT',
    value: 'HOT',
    label: 'Hotshot',
  },
  imc: {
    abbr: 'IMC',
    value: 'port_drayage',
    label: 'Port Drayage',
  },
  ltl: {
    // TODO: is this an equipment type?
    abbr: 'LTL',
    value: 'less_than_truckload',
    label: 'Less Than Truckload',
  },
  pnmc: {
    abbr: 'PNMC',
    value: 'bulk_pneumatics',
    label: 'Bulk Pneumatics',
  },
  pwr: {
    abbr: 'PWR',
    value: 'power_only',
    label: 'Power Only',
  },
  rail: {
    // TODO: is this an equipment type?
    abbr: 'RAIL',
    value: 'rail',
    label: 'Rail',
  },
  rfr: {
    abbr: 'RFR',
    value: 'reefer',
    label: 'Reefer',
  },
  roro: {
    abbr: 'RORO',
    value: 'roll_on_roll_off',
    label: 'Roll on Roll off',
  },
  sbt: {
    abbr: 'SBT',
    value: 'straight_box_truck',
    label: 'Straight Box Truck',
  },
  spr: {
    abbr: 'SPR',
    value: 'SPR',
    label: 'Sprinter Van',
  },
  str: {
    abbr: 'STR',
    value: 'STR',
    label: 'Straight Truck',
  },
  tank: {
    abbr: 'TANK',
    value: 'bulk_tanker',
    label: 'Bulk Tanker',
  },
  vnt: {
    abbr: 'VNT',
    value: 'reefer_or_vented_van',
    label: 'Reefer or Vented Van',
  },
} as const

/**
 * Converts a equipment type string to a canonical equipment type name.
 * The canonical type name is considered an implicit alias of itself.
 * @param type string for type to be resolved to a canonical equipment type name.
 * @returns canonical name for the given `type`; `null` if `type` is invalid.
 */
export function resolveEquipmentType(
  type: LiteralUnion<CanonicalEquipmentTypeName, string>
): CanonicalEquipmentTypeName | null {
  if (!type) {
    return null
  }

  const lowerCaseMode = type.toLowerCase()

  if (lowerCaseMode in EQUIPMENT_TYPES_ALIASES) {
    return lowerCaseMode as CanonicalEquipmentTypeName
  }

  for (const canonicalName in EQUIPMENT_TYPES_ALIASES) {
    if (
      EQUIPMENT_TYPES_ALIASES[
        canonicalName as CanonicalEquipmentTypeName
      ].includes(lowerCaseMode)
    ) {
      return canonicalName as CanonicalEquipmentTypeName
    }
  }

  return null
}

export function isValidEquipmentType(
  type: LiteralUnion<CanonicalEquipmentTypeName, string>
): boolean {
  return resolveEquipmentType(type) !== null
}

type EquipmentTypeTranformFunction<T> = (type: EquipmentTypeV2) => T

export function getEquipmentType<T = EquipmentTypeV2>(
  type: LiteralUnion<CanonicalEquipmentTypeName, string>,
  transform: EquipmentTypeTranformFunction<T> = (value) => value as T
): T | null {
  const canonicalName = resolveEquipmentType(type)

  return canonicalName != null
    ? transform(EQUIPMENT_TYPES[canonicalName])
    : null
}

export function getEquipmentTypes<T = EquipmentTypeV2>(
  types: string | string[],
  transform: (type: EquipmentTypeV2) => T = (type) => type as T
): T[] {
  return toArray(types).reduce((array, type) => {
    const tranformedMode = getEquipmentType(type, transform)

    if (tranformedMode == null) {
      return array
    }

    return array.concat(tranformedMode)
  }, [] as T[])
}

export function areEquipmentTypesEqual(type1: string, type2: string): boolean {
  const canonicalName1 = resolveEquipmentType(type1)
  const canonicalName2 = resolveEquipmentType(type2)

  if (canonicalName1 == null || canonicalName2 == null) {
    return false
  }

  return canonicalName1 === canonicalName2
}

export function isEquipmentTypeOneOf(
  type: string,
  oneOfTypes: string[]
): boolean {
  const canonicalTypeName = resolveEquipmentType(type)
  const canonicalOneOfTypeNames = toArray(oneOfTypes).map(resolveEquipmentType)

  if (canonicalTypeName == null) {
    return false
  }

  return canonicalOneOfTypeNames.includes(canonicalTypeName)
}

export function fromEquipmentToSelectOption(
  value: string
): SelectOption | null {
  return getEquipmentType(value, (type: EquipmentTypeV2) =>
    toSelectOption(type.label, type.value)
  )
}
