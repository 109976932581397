import { defaultTo } from 'lodash'
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from 'suppliers/supplier-service'

import { generateUseSearchParamsPlugin } from 'utils/filters'

export type SuppliersListPaginationParams = {
  limit?: number
  offset?: number
}

export function getDefaultSuppliersListPaginationParams(
  overrides?: Partial<SuppliersListPaginationParams>
): SuppliersListPaginationParams {
  return {
    limit: defaultTo(overrides?.limit, DEFAULT_LIMIT),
    offset: defaultTo(overrides?.offset, DEFAULT_OFFSET),
  }
}

export function mapLimitParam(pagination?: SuppliersListPaginationParams) {
  return { limit: Number(defaultTo(pagination?.limit, DEFAULT_LIMIT)) }
}

export function mapOffsetParam(pagination?: SuppliersListPaginationParams) {
  return { offset: Number(defaultTo(pagination?.offset, DEFAULT_OFFSET)) }
}

export function mapParams(pagination?: SuppliersListPaginationParams) {
  return {
    ...mapLimitParam(pagination),
    ...mapOffsetParam(pagination),
  }
}

export function convertNumberFromSearchParam(value: string | null) {
  const parsedOffset = value ? parseInt(value, 10) : null

  if (!parsedOffset) {
    return null
  }

  return parsedOffset
}

export function convertNumberToSearchParam(value: any) {
  if (value == null) {
    return null
  }

  return value
}

export const useSuppliersListPaginationQueryParamsPlugin =
  generateUseSearchParamsPlugin<SuppliersListPaginationParams>({
    offset: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
    limit: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
  })
