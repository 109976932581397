import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, TextField } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

export const EQUIPMENT_REQUIREMENTS_NAME = 'equipment_requirements'
const TEMPERATURE_NAME = 'temperature'
const TEMPERATURE_LABEL = 'Temperature'

type FreightInformationTemperatureFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationTemperatureFormField({
  required,
  hint,
  error,
  style,
}: FreightInformationTemperatureFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: EQUIPMENT_REQUIREMENTS_NAME,
    hint,
    error,
  })

  const temperature = get(
    shipment,
    `${EQUIPMENT_REQUIREMENTS_NAME}.${TEMPERATURE_NAME}`,
    ''
  )

  const fieldValue = temperature == null ? '' : temperature

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${TEMPERATURE_NAME}-field`}
    >
      <Field.Label id={`label-${TEMPERATURE_NAME}`}>
        {TEMPERATURE_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${TEMPERATURE_NAME}`}
        placeholder="In °F"
        value={fieldValue}
        type="number"
        inputMode="numeric"
        onChange={(event) => {
          const value = get(event, 'target.value')
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [EQUIPMENT_REQUIREMENTS_NAME]: {
                ...shipment[EQUIPMENT_REQUIREMENTS_NAME],
                [TEMPERATURE_NAME]: value === '' ? null : Number(value),
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
