import { Select } from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import type { FieldProps } from '@loadsmart/miranda-react'
import { Field } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties } from 'react'
import { useCallback } from 'react'

import {
  fromEquipmentToSelectOption,
  getEquipmentType,
  resolveEquipmentType,
} from 'utils/equipmentTypeV2'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { SUB_EQUIPMENT_TYPE_NAME } from './FreightInformationSubEquipment'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { TARP_SIZE_NAME } from './FreightInformationTarpSize'
import { TARP_TYPE_NAME } from './FreightInformationTarpType'
import { EQUIPMENT_REQUIREMENTS_NAME } from './FreightInformationTemperature'
import { WITH_TARP_NAME } from './FreightInformationWithTarp'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const EQUIPMENT_TYPE_NAME = 'equipment_type'
const EQUIPMENT_TYPE_LABEL = 'Equipment'

type FreightInformationEquipmentSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name' | 'value'
>

export function FreightInformationEquipmentSummaryField(
  props: FreightInformationEquipmentSummaryFieldProps
) {
  const { shipment } = useFreightInformationSummaryContext()

  return (
    <FreightInformationSummaryField
      label={EQUIPMENT_TYPE_LABEL}
      name={EQUIPMENT_TYPE_NAME}
      value={getEquipmentType(shipment[EQUIPMENT_TYPE_NAME] ?? '')?.label}
      {...props}
    />
  )
}

function resetEquipmentTypeDependantFields(equipmentType?: string) {
  return {
    [EQUIPMENT_TYPE_NAME]: equipmentType,
    [SUB_EQUIPMENT_TYPE_NAME]: [],
    [TARP_TYPE_NAME]: undefined,
    [TARP_SIZE_NAME]: undefined,
    [WITH_TARP_NAME]: undefined,
    [EQUIPMENT_REQUIREMENTS_NAME]: undefined,
  }
}

type FreightInformationEquipmentFormFieldProps = {
  readonly equipments: SelectOption[]
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly label?: string
}

const getEquipmentTypeValue = (value: string) => {
  const equipmentType = resolveEquipmentType(value)

  if (equipmentType) {
    return equipmentType.toUpperCase()
  }

  return undefined
}

export function FreightInformationEquipmentFormField({
  equipments,
  required,
  disabled,
  hint,
  error,
  style,
  label = EQUIPMENT_TYPE_LABEL,
}: FreightInformationEquipmentFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: EQUIPMENT_TYPE_NAME,
    hint,
    error,
  })

  const handleEquipmentTypeChange = useCallback(
    (event: EventLike<Selectable | Selectable[] | null>) => {
      const value: string | undefined = get(event, 'target.value.value')

      if (value === undefined) {
        dispatch({
          type: 'HANDLE_CHANGE',
          payload: { [EQUIPMENT_TYPE_NAME]: undefined },
        })
        return
      }

      const equipmentType = getEquipmentTypeValue(value)

      dispatch({
        type: 'HANDLE_CHANGE',
        payload: resetEquipmentTypeDependantFields(equipmentType),
      })
    },
    [dispatch]
  )

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${EQUIPMENT_TYPE_NAME}-field`}
    >
      <Field.Label>{label}</Field.Label>
      <Select
        name={EQUIPMENT_TYPE_NAME}
        placeholder="Equipment type"
        options={equipments}
        value={
          shipment[EQUIPMENT_TYPE_NAME]
            ? fromEquipmentToSelectOption(shipment[EQUIPMENT_TYPE_NAME])
            : null
        }
        onChange={handleEquipmentTypeChange}
        disabled={disabled}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
