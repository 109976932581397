import { Accordion, Checkbox, Spinner, Text } from '@loadsmart/loadsmart-ui'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import { Layout, Tooltip, Tag, Text as MText } from '@loadsmart/miranda-react'
import { toCapitalizeFirstLetter } from '@loadsmart/utils-string'
import type { ReactNode } from 'react'
import {
  useReducer,
  useRef,
  useLayoutEffect,
  useState,
  forwardRef,
} from 'react'
import styled from 'styled-components'

import { TextClamp, isTextClamped } from 'components/TextClamp'
import { Divider } from 'screens/NewQuote/components/RateSelector/styles'

import type { AccessorialOption } from './useAccessorialsOptions'
import { useAccessorialsOptions } from './useAccessorialsOptions'

const OverPaddingDivider = styled(Divider)`
  margin-bottom: ${token('space-m')};
  margin-left: -${token('space-m')};
  margin-right: -${token('space-m')};
  margin-top: ${token('space-m')};
  width: calc(100% + ${token('space-m')} + ${token('space-m')});
`

type AccessorialNameProps = {
  readonly name: string
}

const AccessorialName = forwardRef<HTMLDivElement, AccessorialNameProps>(
  function AccessorialNameWithRef({ name }, ref) {
    return (
      <TextClamp ref={ref} wordBreak="break-all">
        <Text>{name}</Text>
      </TextClamp>
    )
  }
)

const AccessorialNameLabel = ({ name }: AccessorialNameProps) => {
  const clampRef = useRef<HTMLDivElement>(null)
  const [isClamped, setIsClamped] = useState(false)

  useLayoutEffect(() => {
    setIsClamped(isTextClamped(clampRef.current))
  }, [])

  if (isClamped) {
    return (
      <Tooltip trigger="hover" message={name}>
        <AccessorialName ref={clampRef} name={name} />
      </Tooltip>
    )
  }

  return <AccessorialName ref={clampRef} name={name} />
}

interface OptionGroupListWrapperProps {
  readonly groupName: 'General' | 'Pickup' | 'Delivery'
  readonly children: ReactNode
}

const OptionGroupListWrapper = ({
  groupName,
  children,
}: OptionGroupListWrapperProps) => {
  if (groupName === 'General') {
    return (
      <Layout.Grid
        minColumnWidth="30%"
        columnGap="spacing-4"
        rowGap="spacing-2"
      >
        {children}
      </Layout.Grid>
    )
  }

  return children
}

interface OptionGroupProps {
  readonly groupName: 'General' | 'Pickup' | 'Delivery'
  readonly options: AccessorialOption[]
  readonly value: string[]
  readonly expanded: boolean
  readonly handleChange: (eventValue: string) => () => void
}

const OptionGroup = ({
  groupName,
  options,
  value,
  expanded,
  handleChange,
}: OptionGroupProps) => {
  if (options.length === 0) {
    return null
  }

  return (
    <Layout.Stack gap="spacing-2">
      <Text variant="body-bold">{toCapitalizeFirstLetter(groupName)}</Text>
      <OptionGroupListWrapper groupName={groupName}>
        {options.map((option) => (
          <Checkbox
            key={option.value}
            tabIndex={expanded ? 0 : -1}
            checked={value.includes(option.value)}
            onChange={handleChange(option.value)}
          >
            <AccessorialNameLabel
              name={toCapitalizeFirstLetter(option.label)}
            />
          </Checkbox>
        ))}
      </OptionGroupListWrapper>
    </Layout.Stack>
  )
}

type AccessorialsMultiChoiceBodyProps = {
  readonly isLoading: boolean
  readonly generalOptions: AccessorialOption[]
  readonly pickup: AccessorialOption[]
  readonly delivery: AccessorialOption[]
  readonly expanded: boolean
  readonly value: string[]
  readonly handleChange: (eventValue: string) => () => void
}

const AccessorialsMultiChoiceBody = ({
  isLoading,
  generalOptions,
  pickup,
  delivery,
  expanded,
  value,
  handleChange,
}: AccessorialsMultiChoiceBodyProps) => {
  if (isLoading) {
    return (
      <Layout.Stack align="center" justify="center">
        <Spinner size={36} aria-label="Loading" />
      </Layout.Stack>
    )
  }

  return (
    <>
      <OptionGroup
        groupName="General"
        options={generalOptions}
        handleChange={handleChange}
        expanded={expanded}
        value={value}
      />

      {generalOptions.length > 0 && <OverPaddingDivider enabled />}

      <Layout.Grid gap="spacing-4" minColumnWidth="40%">
        <OptionGroup
          groupName="Pickup"
          options={pickup}
          handleChange={handleChange}
          expanded={expanded}
          value={value}
        />

        <OptionGroup
          groupName="Delivery"
          options={delivery}
          handleChange={handleChange}
          expanded={expanded}
          value={value}
        />
      </Layout.Grid>
    </>
  )
}

export type AccessorialsMultiChoiceProps = {
  readonly mode: TransportationModeCode
  readonly usage: AccountingObjectType
  readonly onChange: (value: string[]) => void
  readonly label: string
  readonly value: string[]
  readonly hint?: string
}

function AccessorialsMultiChoice({
  mode,
  usage,
  onChange,
  label,
  value,
  hint,
}: AccessorialsMultiChoiceProps) {
  const [expanded, toggleExpanded] = useReducer((prev) => !prev, false)

  const { isLoading, generalOptions, pickupOptions, deliveryOptions } =
    useAccessorialsOptions(mode, usage)

  const handleChange = (eventValue: string) => () => {
    onChange(
      value.includes(eventValue)
        ? value.filter((item) => item !== eventValue)
        : [...value, eventValue]
    )
  }

  return (
    <Accordion expanded={expanded} onExpandedChange={toggleExpanded}>
      <Accordion.Toggle>
        <Layout.Group gap="spacing-2" align="center">
          <span>{label}</span>
          {value.length > 0 && <Tag size="small">{value.length}</Tag>}
          {hint && (
            <MText variant="body-sm" color="color-text-tertiary">
              {hint}
            </MText>
          )}
        </Layout.Group>
      </Accordion.Toggle>
      <Accordion.Body>
        <AccessorialsMultiChoiceBody
          isLoading={isLoading}
          generalOptions={generalOptions}
          pickup={pickupOptions}
          delivery={deliveryOptions}
          expanded={expanded}
          value={value}
          handleChange={handleChange}
        />
      </Accordion.Body>
    </Accordion>
  )
}

export default AccessorialsMultiChoice
