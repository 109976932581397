import { IconCheck as IconCheckBase } from '@loadsmart/icons'
import { useCallback } from 'react'
import type { MouseEvent } from 'react'
import styled from 'styled-components'

import ConditionalWrapper from 'components/ConditionalWrapper'

const IconCheck = styled(IconCheckBase)`
  color: ${({ theme }) => theme.colors.backgroundSecondary};
`

const InlineWrapper = styled.div`
  display: inline-block;
`

const Container = styled.button<{ size: number }>`
  align-items: center;
  appearance: none;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  height: ${({ size }) => size}px;
  justify-content: center;
  margin: 0;
  width: ${({ size }) => size}px;

  :disabled {
    cursor: default;
    opacity: 0.4;
  }
`

interface Props {
  readonly 'aria-label'?: string
  readonly value: boolean
  readonly onToggle: (currentValue: boolean) => void
  readonly size?: number
  readonly inline?: boolean
  readonly className?: string
  readonly id?: string
  readonly disabled?: boolean
}

/**
 * @deprecated use @loadsmart/miranda-react instead
 */
export default function Checkbox({
  value,
  onToggle,
  inline,
  size = 24,
  ...props
}: Props) {
  const iconSize = size * 0.75
  const handleClick = useCallback(
    (event: MouseEvent) => {
      onToggle(value)
      event.stopPropagation()
    },
    [value, onToggle]
  )

  return (
    <ConditionalWrapper wrapper={InlineWrapper} condition={!!inline}>
      <Container
        onClick={handleClick}
        role="checkbox"
        size={size}
        type="button"
        {...props}
      >
        {value && <IconCheck height={iconSize} width={iconSize} />}
      </Container>
    </ConditionalWrapper>
  )
}
