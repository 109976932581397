import { Layout, Tooltip } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import styled from 'styled-components'

import OpendockIcon from 'assets/imgs/opendock-icon.png'
import { StatusIndicator } from 'components/StatusIndicator'
import type { FacilityDetailsV2 } from 'services/facilities'
import transition from 'utils/transition'

const HoverableBox = styled(Layout.Box)`
  ${transition({ property: 'border-color', duration: '450ms' })}

  --m-box-border-color: ${toCSSValue('color-border')};

  &:hover {
    --m-box-border-color: ${toCSSValue('color-brand')};
  }
`

export function FacilityOpendockConnected({
  warehouse_uuid,
}: Pick<FacilityDetailsV2, 'warehouse_uuid'>) {
  if (!warehouse_uuid) {
    return null
  }

  return (
    <Tooltip message="Opendock connected" placement="top">
      <HoverableBox
        borderWidth="border-thin"
        paddingX="spacing-4"
        paddingY="none"
        style={{ borderRadius: '100px', flexShrink: 0 }}
        data-testid="facility-opendock-connected"
      >
        <Layout.Group
          gap="spacing-2"
          align="center"
          style={{ height: toCSSValue('spacing-10') }}
        >
          <StatusIndicator />
          <img src={OpendockIcon} height={24} alt="Opendock connected" />
        </Layout.Group>
      </HoverableBox>
    </Tooltip>
  )
}
