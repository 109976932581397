import type { NmfcResponse } from '@loadsmart/strangercodes'
import type { AxiosRequestConfig } from 'axios'

import type { QuoteDetails } from 'screens/NewQuote/types'
import type { BulkInviteCarriersPayload } from 'screens/Quotes/types'
import type { QuoteContactDetail } from 'screens/Shipper/Checkout/Checkout.types'
import httpClient from 'utils/httpClient'

export const createNewQuote = async (
  quote: any,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post('/spot_quotes/request', quote, config)
  return data
}

export const runUpdateContractedRatesProcess = async (quoteId: string) => {
  const { data } = await httpClient.post(
    `/spot_quotes/${quoteId}/update-contracted-rates`
  )
  return data
}

export const getQuoteDetails = async (
  quoteUUID: string
): Promise<QuoteDetails> => {
  const { data } = await httpClient.get(`/quotes/${quoteUUID}`)

  return data
}

export const saveContactDetails = async ({
  quoteUUID,
  payload,
}: {
  quoteUUID: string
  payload: QuoteContactDetail[]
}) => {
  const { data } = await httpClient.patch(
    `/spot_quotes/${quoteUUID}/contact_details`,
    payload
  )
  return data
}

export const bulkInviteCarriers = async (
  payload: BulkInviteCarriersPayload
) => {
  const { data } = await httpClient.post('/bulk/invite-carriers', payload)
  return data
}

export const getAvailableCarriersToBulk = async (bulkInviteUUID: string) => {
  const { data } = await httpClient.get(
    `/bulk/${bulkInviteUUID}/carriers_available`
  )
  return data
}

export const searchNMFC = async (
  searchTerm = '',
  options?: AxiosRequestConfig
): Promise<NmfcResponse> => {
  const response = await httpClient.get<NmfcResponse>('/spot_quotes/nmfc', {
    ...options,
    params: {
      search: searchTerm,
    },
  })

  return response.data
}
