import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { useFormik as useForm } from 'formik'

import { getShipperFacilities } from 'services/shipments'
import facilitiesAdapter from 'utils/adapters/facilities'

interface AdaptedFacility extends Facility {
  label: string
  value: string
  className: string
}

const facilitySearchConfig = {
  type: 'facility',
  adapter: {
    getKey: (facility: AdaptedFacility) => facility.uuid,
    getLabel: (facility: AdaptedFacility) =>
      facility?.label || facility?.company_name || facility.address,
  },
}

export function filterFacilities({
  adaptedFacilities,
  regex,
}: {
  adaptedFacilities: Array<AdaptedFacility>
  regex: RegExp
}): Array<AdaptedFacility> {
  return adaptedFacilities.filter((facility) => {
    const fieldsToFilter = [
      'label',
      'address',
      'zipcode',
      'city',
      'state',
      'country',
    ] as const
    return fieldsToFilter.some((field) => regex.test(facility[field]))
  })
}

export function useSelectFacilitySearch(): SelectDatasource<AdaptedFacility> {
  return {
    ...facilitySearchConfig,
    async fetch({ query, regex }) {
      const facilities = await getShipperFacilities<Facility>({
        search: query,
        sort: 'company_name',
      })
      const adaptedFacilities = facilitiesAdapter({
        facilities,
      })

      return filterFacilities({ adaptedFacilities, regex })
    },
  }
}

export { useForm }
