import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import type { CSSProperties, ChangeEvent } from 'react'

import { Phone } from 'components/Input'

import { useHazmatContactFormField } from './HazmatContactForm'
import type { useHazmatContactFormFieldProps } from './HazmatContactForm'

export const HazmatContactPhone = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: useHazmatContactFormFieldProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    contact,
    controlProps,
    fieldProps,
    hintProps,
    labelProps,
    name,
    dispatch,
  } = useHazmatContactFormField({
    name: 'hazmat_phone_number',
    hint,
  })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Phone number</Field.Label>
      <Phone
        {...controlProps}
        placeholder="(         )         -"
        value={get(contact, name, '')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const {
            target: { value },
          } = event

          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [name]: value },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
