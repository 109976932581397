import { Button, Header } from '@loadsmart/miranda-react'
import { useGoToNewFulfillment } from 'fulfillments/hooks/navigation'

export function ListFulfillmentsPageHeader() {
  const goToNewFulfillmentForm = useGoToNewFulfillment()

  return (
    <Header>
      <Header.Content>
        <Header.Title>Fulfillments</Header.Title>
      </Header.Content>

      <Header.Actions>
        <Button variant="primary" onClick={goToNewFulfillmentForm}>
          New fulfillment
        </Button>
      </Header.Actions>
    </Header>
  )
}
