import openDockIcon from 'assets/imgs/opendock-icon.png'

type AppointmentOpenDockLogoProps = Readonly<{
  warehouseUUID: string | null | undefined
}>

export default function AppointmentOpenDockLogo({
  warehouseUUID,
}: AppointmentOpenDockLogoProps) {
  const showOpenDockLogo = Boolean(warehouseUUID)

  return showOpenDockLogo ? (
    <img src={openDockIcon} alt="OpenDock" height={16} />
  ) : null
}
